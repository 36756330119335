/**
 * @flow
 * @relayHash 3a9be9765dd5a3d3bf7168dd3502f4d6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApporteurSignUpMutationVariables = {|
  token: string,
  password: string,
|};
export type ApporteurSignUpMutationResponse = {|
  +apporteurSignUp: ?{|
    +ok: boolean,
    +error: ?string,
    +signInLink: ?string,
  |}
|};
export type ApporteurSignUpMutation = {|
  variables: ApporteurSignUpMutationVariables,
  response: ApporteurSignUpMutationResponse,
|};
*/


/*
mutation ApporteurSignUpMutation(
  $token: String!
  $password: String!
) {
  apporteurSignUp(token: $token, password: $password) {
    ok
    error
    signInLink
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "concreteType": "ApporteurSignUpMutationResponse",
    "kind": "LinkedField",
    "name": "apporteurSignUp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signInLink",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ApporteurSignUpMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ApporteurSignUpMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "3a9be9765dd5a3d3bf7168dd3502f4d6",
    "metadata": {},
    "name": "ApporteurSignUpMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a1f586285239824d01a0c70280f84bc5';

module.exports = node;
