import { useState } from 'react';
import { ApporteurSignUpMutation } from '@@mutations/apporteur';
import useFetching from './useFetching';

const signUp = (token) => {
  const [signInLink, setSignInLink] = useState();
  const fetching = useFetching();

  const onSignUp = (password) => {
    fetching.start();
    ApporteurSignUpMutation({ token, password }, (ok, error, signInLink) => {
      if (!ok) return fetching.stop('ERROR');
      setSignInLink(signInLink);
      fetching.stop();
    });
  };

  return {
    onSignUp,
    fetching,
    signInLink
  };
};

const useApporteur = {
  signUp
};

export default useApporteur;
