import * as R from 'ramda';

const getFirstCollege = R.compose(
  R.head,
  R.pathOr([''], ['values', 'colleges'])
);

export const collegeSort = (a, b) => {
  return getFirstCollege(a).localeCompare(getFirstCollege(b));
};
