import { applySpec, compose, drop, join, nth, replace, split } from 'ramda';

export const bordereauxCompleteNameToData = (fileName) => {
  const splitFileName = split('-');

  const getName = compose(
    replace('.xlsx', ''),
    join('-'),
    drop(3)
  );

  const createObjectFromFileNameSplitted = applySpec({
    networkId: nth(0),
    year: nth(1),
    periode: nth(2),
    name: getName,
    completeFileName: join('-')
  });

  // @ts-ignore
  return compose(
    createObjectFromFileNameSplitted,
    splitFileName
  )(fileName);
};
