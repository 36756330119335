const customSubjects = [{
  label: 'Demande sur mes paiements',
  value: 'payments'
}, {
  label: 'Demande sur mon contrat',
  value: 'contracts'
}, {
  label: 'Demande d‘activation de la DSN',
  value: 'enablingDsn'
}, {
  label: 'Demande de changement de RIB Cotisations',
  value: 'addBankDebitDetails'
}, {
  label: 'Demande de changement de RIB Prestations',
  value: 'addTransferDetails'
}];

export default customSubjects;
