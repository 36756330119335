import React, { useEffect, useState } from 'react';
import { Block, Page } from '@@components';

import { applySpec, assoc, compose, defaultTo, descend, find, head, identity, map, prop, propEq, propOr, sort } from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';

import useFetchQuery from '../../../hooks/useFetchQuery';
import { USER_PROPTYPES } from '../../../propTypes/user/UserPropTypes';
import { QAvailableYearSinistresQuery } from '../../../_graphql/queries/QAvailableYearSinistres';
import withUser from '../../userSession/withUser';

import ChartsSinistres from './ChartsSinistres';
import SelectOneOption from '../../../components/input/SelectOneOption';
import propTypes from 'prop-types';

import { CURRENT_YEAR } from '../../../constants';
import { yearsOptions } from '../../../lib/dateFilters';

const Index = ({ user }) => {
  const { register, controls } = Block.useBlocks();
  const [filters, setFilters] = useState({ year: CURRENT_YEAR });

  const { availableYearsSinistres, isLoaded } = useFetchQuery({
    query: QAvailableYearSinistresQuery,
    dataProp: 'availableYearsSinistres'
  });

  const options = yearsOptions(availableYearsSinistres);
  const defaultOption = head(options);

  const value = find(propEq('value', propOr(CURRENT_YEAR, 'year', filters)), options);
  const onChangeYear = compose(setFilters, applySpec({ year: identity }), defaultTo(CURRENT_YEAR), prop('value'));

  useEffect(() => {
    if (isLoaded && isNotNilOrEmpty(defaultOption)) {
      setFilters(assoc('year', defaultOption.value));
    }
  }, [isLoaded]);

  return (
    <Page title='Sinistres'>
      <Block
        name='sinistre-list'
        title='mes prestations'
        color='pink'
        register={register({ opened: true, canClose: false })}
        controls={controls}>
        {isLoaded && (
          <div className="flex justify-end mb-4">
            <SelectOneOption
              className='w-64'
              placeholder={isNotNilOrEmpty(options) ? 'Sélectionner une année' : 'Aucune donnée disponible'}
              disabled={isNilOrEmpty(options)}
              isClearable
              options={options}
              value={value}
              onChange={onChangeYear}
              defaultOption={filters}
            />
          </div>
        )}
        <ChartsSinistres user={user} year={filters.year} />

        {/* Disabled waiting for Karma 10.3 for implementation */}
        {/* <QApporteurSinistres>
          {({ apporteurSinistres }) => <SinistreList sinistres={apporteurSinistres} />}
        </QApporteurSinistres> */}
      </Block>
    </Page>
  );
};

Index.propTypes = {
  user: propTypes.shape(USER_PROPTYPES)
};

export default withUser(Index);
