/**
 * @flow
 * @relayHash 94205f87776584becbf253ba915ad071
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QAvailableYearSinistresQueryVariables = {||};
export type QAvailableYearSinistresQueryResponse = {|
  +availableYearsSinistres: ?$ReadOnlyArray<?number>
|};
export type QAvailableYearSinistresQuery = {|
  variables: QAvailableYearSinistresQueryVariables,
  response: QAvailableYearSinistresQueryResponse,
|};
*/


/*
query QAvailableYearSinistresQuery {
  availableYearsSinistres
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "availableYearsSinistres",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QAvailableYearSinistresQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QAvailableYearSinistresQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "94205f87776584becbf253ba915ad071",
    "metadata": {},
    "name": "QAvailableYearSinistresQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fcc63a4221f518942dbf2f0c46650f59';

module.exports = node;
