
import React from 'react';
import propTypes from 'prop-types';
import withUser, { UserContext } from './withUser';
import UserSession from './UserSession';
import { Page } from '@@components';

import {
  withRouter,
  Redirect,
  Route,
  Switch,
  Link
} from 'react-router-dom';

import SignIn from './SignIn';
import RequestPassword from './RequestPassword';
import ResetPassword from './ResetPassword';
import { isConnected as userIsConnected } from '../../lib/user';
import { USER_PROPTYPES } from '../../propTypes/user/UserPropTypes';

const UserSessionRoutes = ({
  user,
  match
}) => {
  return (
    <div className="f-layout bg-gray-100">
      <div className="f-layout-top">
        <div className="f-top">
          <Link to="/">
            <img src="/image/logo.svg" alt="Logo Unmi" width="70.2" style={{ marginLeft: '16px' }}/>
          </Link>
        </div>
      </div>
      <div className="f-layout-row">
        <div className="f-layout-body">
          <Page fw>
            <Switch>
              <Route exact path={`${match.url}/mot-de-passe-perdu`} component={RequestPassword} />
              <Route exact path={`${match.url}/nouveau-mot-de-passe`} component={ResetPassword} />
              {userIsConnected(user) && <Redirect to="/"/>}
              <Route exact path={`${match.url}/signin`} component={() => <Redirect to={'/'}/>} />
            </Switch>
          </Page>
        </div>
      </div>
    </div>
  );
};

UserSessionRoutes.propTypes = {
  user: propTypes.shape(USER_PROPTYPES),
  match: propTypes.shape({
    path: propTypes.string,
    isExact: propTypes.bool,
    url: propTypes.string
  })
};

export default withRouter(withUser(UserSessionRoutes));

export {
  withUser,
  UserSession,
  UserContext
};
