import React from 'react';
import ColoredIcon from '../ColoredIcon';
import { Link } from 'react-router-dom';

const StickyButton = () => {
  return (
    <Link to="/contacteznous" className="cursor-pointer fixed right-0 bottom-0 mb-24 h-16 w-16 z-20 p-4 bg-gradient-to-b from-light-plum to-plum rounded-l-lg flex flex-col justify-center text-white">
      <div className="flex justify-center">
        <ColoredIcon height={28} width={28} icon="bulle-contact.svg" color="white"/>
      </div>
      <div className="flex justify-center uppercase text-xs">
        Contact
      </div>
    </Link>);
};

export default StickyButton;
