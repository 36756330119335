/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [

{
name: 'username',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Votre identifiant",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'password',
required: true,
input: 'password',
source: '__UNDEFINED__',
label: "Votre mot de passe",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

];

export default formData;