import { fetchDocument } from './request';
import * as R from 'ramda';
import { toast } from 'react-toastify';

export const downloadDocument = async (url, documentName, callback) => {
  const toastId = toast.info('Téléchargement en cours, veuillez patienter...');

  try {
    const { blob, filename } = await fetchDocument(url);

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([blob]));
    link.setAttribute('download', R.when(R.isNil, R.always(filename), documentName));

    document.body.append(link);
    link.click();

    // link.parentNode.removeChild(link);

    toast.dismiss(toastId);
    toast.success('Le document est téléchargé sur votre ordinateur');

    if (R.is(Function, callback)) callback();
  } catch (e) {
    toast.dismiss(toastId);
    switch (e.code) {
      case 401:
        toast.error('Vous n\'êtes pas autorisé');
        break;
      case 404:
        toast.error('Aucun document disponible');
        break;
      default:
        toast.error('Une erreur est survenue');
    }
  }
};

export default downloadDocument;
