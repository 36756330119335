import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

export const QAvailableYearInContractQuery = graphql`
  query QAvailableYearInContractQuery {
    availableYearsContracts
  }
`;

const QAvailableYearInContract = ({ args, children, childDataProp, mockData }) => {
  return (
    <Query
      query={QAvailableYearInContractQuery}
      args={args}
      mockKey="QAvailableYearInContract"
      mockData={mockData}
    >
      {populateChildren(['availableYearsContracts'])(children, childDataProp)}
    </Query>
  );
};

export default QAvailableYearInContract;
