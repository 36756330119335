import useFetchQuery from './useFetchQuery';
import { QApporteurContractsQuery } from '../_graphql/queries/QApporteurContracts';

const useApporteurContracts = (args) => {
  return useFetchQuery({
    query: QApporteurContractsQuery,
    args: args,
    dataProp: 'apporteurContracts'
  });
};

export default useApporteurContracts;
