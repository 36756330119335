/**
 * @flow
 * @relayHash a797f6a243255901d1edf28cf68d8a46
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SinistreTypeEnum = "CLAIM_FOR_BENEFITS" | "DECEASE" | "INABILITY" | "INFORMARION" | "OTHER" | "SICK_LEAVE" | "%future added value";
export type AddSinistreMutationVariables = {|
  type: SinistreTypeEnum
|};
export type AddSinistreMutationResponse = {|
  +addSinistre: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type AddSinistreMutation = {|
  variables: AddSinistreMutationVariables,
  response: AddSinistreMutationResponse,
|};
*/


/*
mutation AddSinistreMutation(
  $type: SinistreTypeEnum!
) {
  addSinistre(type: $type) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "AddSinistreMutationResponse",
    "kind": "LinkedField",
    "name": "addSinistre",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddSinistreMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddSinistreMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "a797f6a243255901d1edf28cf68d8a46",
    "metadata": {},
    "name": "AddSinistreMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '434d6185daf8551db126fa8d1498c5de';

module.exports = node;
