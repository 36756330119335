/**
 * @flow
 * @relayHash 63adb38ed65f6ae548dd221e0d8896d1
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QApporteurCommissionsManagerQueryVariables = {|
  year?: ?number,
  commissionType?: ?string,
|};
export type QApporteurCommissionsManagerQueryResponse = {|
  +apporteurCommissionsReferential: ?$ReadOnlyArray<?{|
    +category: ?string,
    +amountCommission: ?string,
    +year: ?number,
    +trimestre: ?number,
  |}>
|};
export type QApporteurCommissionsManagerQuery = {|
  variables: QApporteurCommissionsManagerQueryVariables,
  response: QApporteurCommissionsManagerQueryResponse,
|};
*/


/*
query QApporteurCommissionsManagerQuery(
  $year: Int
  $commissionType: String
) {
  apporteurCommissionsReferential(year: $year, commissionType: $commissionType) {
    category
    amountCommission
    year
    trimestre
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "commissionType"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "year"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "commissionType",
        "variableName": "commissionType"
      },
      {
        "kind": "Variable",
        "name": "year",
        "variableName": "year"
      }
    ],
    "concreteType": "ApporteurCommissionReferential",
    "kind": "LinkedField",
    "name": "apporteurCommissionsReferential",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "category",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amountCommission",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "year",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "trimestre",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QApporteurCommissionsManagerQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QApporteurCommissionsManagerQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "63adb38ed65f6ae548dd221e0d8896d1",
    "metadata": {},
    "name": "QApporteurCommissionsManagerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'debde74d717baf246fbf2011fede6d7d';

module.exports = node;
