/**
 * @flow
 * @relayHash a34cb4a7cfef9a0aee702e6b1519fa1e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ChartApporteurRangeEnum = "month" | "semester" | "year" | "%future added value";
export type ContractTypeEnum = "COMPANY" | "PARTICULAR" | "TNS" | "%future added value";
export type QChartApporteurRepartitionQueryVariables = {|
  range: ChartApporteurRangeEnum,
  year: number,
  type?: ?ContractTypeEnum,
  apporteurs?: ?$ReadOnlyArray<?string>,
|};
export type QChartApporteurRepartitionQueryResponse = {|
  +apporteurDashboardRepartition: ?{|
    +labels: ?$ReadOnlyArray<?string>,
    +contracts: ?{|
      +transformed: ?$ReadOnlyArray<?number>,
      +proposition: ?$ReadOnlyArray<?number>,
    |},
    +ca: ?{|
      +transformed: ?$ReadOnlyArray<?number>,
      +proposition: ?$ReadOnlyArray<?number>,
    |},
    +effectifs: ?{|
      +transformed: ?$ReadOnlyArray<?number>,
      +proposition: ?$ReadOnlyArray<?number>,
    |},
  |}
|};
export type QChartApporteurRepartitionQuery = {|
  variables: QChartApporteurRepartitionQueryVariables,
  response: QChartApporteurRepartitionQueryResponse,
|};
*/


/*
query QChartApporteurRepartitionQuery(
  $range: ChartApporteurRangeEnum!
  $year: Int!
  $type: ContractTypeEnum
  $apporteurs: [String]
) {
  apporteurDashboardRepartition(range: $range, year: $year, type: $type, apporteurs: $apporteurs) {
    labels
    contracts {
      transformed
      proposition
    }
    ca {
      transformed
      proposition
    }
    effectifs {
      transformed
      proposition
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "apporteurs"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "range"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "year"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "transformed",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "proposition",
    "storageKey": null
  }
],
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "apporteurs",
        "variableName": "apporteurs"
      },
      {
        "kind": "Variable",
        "name": "range",
        "variableName": "range"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      },
      {
        "kind": "Variable",
        "name": "year",
        "variableName": "year"
      }
    ],
    "concreteType": "ApporteurRepartitionStats",
    "kind": "LinkedField",
    "name": "apporteurDashboardRepartition",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "labels",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DataApporteurRepartitionStats",
        "kind": "LinkedField",
        "name": "contracts",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DataApporteurRepartitionStats",
        "kind": "LinkedField",
        "name": "ca",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "DataApporteurRepartitionStats",
        "kind": "LinkedField",
        "name": "effectifs",
        "plural": false,
        "selections": (v4/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QChartApporteurRepartitionQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QChartApporteurRepartitionQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "id": "a34cb4a7cfef9a0aee702e6b1519fa1e",
    "metadata": {},
    "name": "QChartApporteurRepartitionQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'af23d82584a235b5a343950ce23d86dd';

module.exports = node;
