import * as R from 'ramda';
import { __, and, compose, equals, includes, prop, propOr, toLower } from 'ramda';
import rolesList from '../../refs/rolesList';

// tns == "Entreprise en nom propre"
// collectif == "Personne Morale"
export const isTns = compose(
  includes(__, rolesList.TNS),
  toLower,
  propOr('', 'companyType')
);

export const isCompany = compose(
  equals(rolesList.COMPANY),
  toLower,
  propOr('', 'companyType')
);

export const isCompanyAndDisability = (user, sinistre) => (
  and(isCompany(user),
    equals('DISABILITY', prop('value', sinistre))
  ));

export const isContractType = (value) => (key) => R.compose(
  R.equals(value),
  R.prop(key)
);

export default {
  isTns,
  isCompany,
  isCompanyAndDisability,
  isContractType
};
