import React, { useEffect } from 'react';
import redirectToLoginPage from '../../lib/redirectToLoginPage';

const RedirectLoginPage = () => {
  useEffect(() => redirectToLoginPage(), []);

  return <></>;
};

export default RedirectLoginPage;
