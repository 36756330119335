import React from 'react';
import { graphql } from 'react-relay';

import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QAccountHistoriesQuery = graphql`
  query QAccountHistoriesQuery($type: String!, $pagination: PaginationInput) {
    getAccountHistories(type: $type, pagination: $pagination) {
        items {
            username
            codeApporteur
            codeManager
            createdAt
            lastUpdatedAt
            status
            statusDetail
            tiersId
            type
        }
        pagination {
            limit
            page
            pages
            total
        }
    }
  }
`;

const QAccountHistories = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QAccountHistoriesQuery}
      args={args}
      mockKey="QAccountHistories"
      mockData={mockData}
    >
      {populateChildren(['getAccountHistories'])(children, childDataProp)}
    </Query>
  );
};

export default QAccountHistories;
