import React from 'react';
import PropTypes from 'prop-types';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { Link } from 'react-router-dom';
import { BANK_ACCOUNT_PROPTYPES } from '../../../propTypes/user/BankPropTypes';

const R = require('ramda');

const TiersBankDetailBlock = ({ bankAccounts }) => {
  const { formsData, onFormsSubmit } = useForms('updateCompanyInformation');

  const onSubmitHandler = ({ ribs }) => {
    const propIsNil = (propName) => R.compose(
      R.isNil,
      R.prop(propName)
    );
    const payload = {
      ribs: R.reject(
        R.anyPass([
          propIsNil('label'),
          propIsNil('iban'),
          propIsNil('bic')
        ]),
        ribs
      )
    };
    onFormsSubmit(payload);
  };

  return (<>
    <Forms
      formsData={formsData}
      onSubmit={onSubmitHandler}
      columns={1}
      defaultValues={{
        ribs: bankAccounts
      }}
      submitSection={() => (
        <Link to="/contacteznous" >
          <button aria-label="Ajouter un rib" type="submit" className="f-button f-button-pink">Ajouter un rib</button>
        </Link>)}
    />
  </>);
};

TiersBankDetailBlock.propTypes = {
  bankAccounts: PropTypes.arrayOf(PropTypes.shape(BANK_ACCOUNT_PROPTYPES))
};

export default TiersBankDetailBlock;
