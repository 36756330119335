import React from 'react';

const CompanyContext = React.createContext();

export default function withCompany (Component) {
  return function CompanyComponent (props) {
    return (
      <CompanyContext.Consumer>
        {({ company }) => <Component
          {...props}
          company={company}
        />}
      </CompanyContext.Consumer>
    );
  };
}

export {
  CompanyContext
};
