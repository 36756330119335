import React from 'react';
import * as R from 'ramda';
import { Bar } from 'react-chartjs-2';
import ChartLegend from '../components/ChartLegend';
import { COMMISSION_TYPE } from '../../../constants';

const options = {
  plugins: {
    legend: {
      display: false
    }
  },
  scales: {
    y: {
      stacked: true,
      ticks: {
        beginAtZero: true
      }
    },
    x: {
      grid: {
        display: false
      },
      stacked: true
    }
  }
};

const toFixedNumber = (fixed = 2) => R.compose(parseFloat, v => v.toFixed(fixed));

const formatNumbersCommissions = R.evolve({
  // amountCommission: (n) => Number.parseFloat(n.replace(',', '.'))
  amountCommission: R.compose(toFixedNumber(2), parseFloat, R.replace(',', '.'))
});

const sumAmountCommission = R.compose(
  R.reduce((acc, el) => {
    return R.evolve({
      amountCommission: R.add(R.propOr(0, 'amountCommission', acc))
    })(el);
  }, { amountCommission: 0 })
);

const formatAsChartJs = R.compose(
  R.map(
    R.applySpec({
      label: (v) => `T${R.prop('trimestre', v)}`,
      value: R.prop('amountCommission'),
      category: R.prop('category')
    })
  )
);

const getTotalForCategory = (category) => R.compose(
  (n) => n.toLocaleString(undefined),
  R.sum,
  R.propOr([], 'data'),
  R.find(R.propEq('label', category)),
  R.prop('datasets')
);

const ChartCommissions = ({ data, commissionType }) => {
  if (R.isEmpty(data)) return (<div className="text-center text-gray-600">Aucune donnée disponible</div>);

  const selectedYear = R.compose(R.prop('year'), R.head)(data);
  const formatedData = R.compose(
    R.groupBy(R.prop('category')),
    R.flatten,
    R.map(
      R.compose(
        formatAsChartJs,
        R.map(sumAmountCommission),
        R.values,
        R.groupBy(R.prop('trimestre'))
      )
    ),
    R.values,
    R.groupBy(R.prop('category')),
    R.map(formatNumbersCommissions)
  )(data);

  const datasets = R.reject(
    R.compose(
      R.isEmpty,
      R.prop('data')
    )
  )([
    {
      label: 'COLLECTIF',
      data: R.compose(R.map(R.prop('value')), R.propOr([], 'COLLECTIF'))(formatedData),
      backgroundColor: '#4f121c'
    },
    {
      label: 'INDIVIDUEL',
      data: R.compose(R.map(R.prop('value')), R.propOr([], 'INDIVIDUEL'))(formatedData),
      backgroundColor: '#E2007A'
    }
  ]);

  const labels = R.compose(
    R.map(R.prop('label')),
    R.head,
    R.values
  )(formatedData);
  const chartData = {
    labels,
    datasets
  };

  const totalIndividuel = getTotalForCategory('INDIVIDUEL')(chartData);
  const totalCollectif = getTotalForCategory('COLLECTIF')(chartData);

  const commissionTypeLabel = R.cond([
    [R.equals(COMMISSION_TYPE.DISTRIBUTION), R.always('distribution')],
    [R.equals(COMMISSION_TYPE.DELEGATION), R.always('délégation')]
  ])(commissionType);

  const chartOptions = R.compose(
    R.assocPath(['plugins', 'title', 'text'], `Commissions de ${commissionTypeLabel} versées en ${selectedYear} : Collectif ${totalCollectif} € Individuel ${totalIndividuel} €`),
    R.assocPath(['plugins', 'title', 'display'], true)
  )(options);
  return (
    <>
      <Bar
        data={chartData}
        options={chartOptions}
      />
      <ChartLegend
        datasets={chartData.datasets}
        labels={chartData.labels}
      />
    </>
  );
};

ChartCommissions.propTypes = {
};

ChartCommissions.defaultProps = {
};

export default ChartCommissions;
