import React, { useState } from 'react';
import propTypes from 'prop-types';

import { Message, Block } from '@@components';
import { SelectOneOption } from '@@components/input';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { affiliatesToOptions, onSelectAffiliate } from '../../../../lib/affiliates';
import { useBeforeUnload, useBlockTransition } from '@@hooks';
import {
  employType,
  titles,
  workStoppingReasonType,
  yesNoType,
  accidentReason,
  workRestartMethodType,
  documentTypeIncapaciteOptions
} from '@@refs';
import { isCompany, isTns } from '../../../../lib/getCompanyType';
import { useForm } from 'react-hook-form';
import DocumentUploadBlock from '../DocumentUploadBlock';

import { getRequiredDocumentsValidator, nameIsIncluded } from '../../../../lib/documentUploadHelpers';
import useCompany from '../../../../hooks/useCompany';
import { downloadDocument } from '../../../../lib/downloadDocument';
import { ColoredIcon } from '@@components/ColoredIcon';
import { USER_PROPTYPES } from '../../../../propTypes/user/UserPropTypes';
import { COMPANY_PROPTYPES } from '../../../../propTypes/user/CompanyPropTypes';
import { ADDRESS_PROPTYPES } from '../../../../propTypes/user/AddressPropTypes';
import parseFloatOr from '../../../../lib/parseFloatOr';

const R = require('ramda');
const RA = require('ramda-adjunct');

const attachmentsFields = [
  'attachments'
];

const requiredDocumentsValidators = getRequiredDocumentsValidator(documentTypeIncapaciteOptions);

const DeclareIncapacite = ({ address, affiliates, user, company, contractId }) => {
  const [showDocumentsErrorMessage, setShowDocumentsErrorMessage] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const { fetching, onFormsSubmit, formsData } = useForms('declareSinistreIncapacite');
  const { register: registerBlock, controls } = Block.useBlocks();

  const attachmentsForm = R.filter(nameIsIncluded(attachmentsFields), formsData);
  const informationsForm = R.reject(nameIsIncluded(attachmentsFields), formsData);

  const getDefault = () => {
    if (isCompany(user)) return defaultValues;

    const birthdate = R.compose(
      R.when(RA.isNotNil, (v) => new Date(v)),
      R.pathOr(null, ['details', 'birthDate'])
    )(company);

    return {
      firstname: R.propOr(null, 'firstname', company),
      birthname: R.propOr(null, 'name', company),
      title: R.propOr(null, 'title', company),
      birthdate,
      nir: R.pathOr(null, ['details', 'socialInsuranceNumber'], company),
      address: R.propOr(null, 'line', address),
      postal: R.propOr(null, 'zipCode', address),
      city: R.propOr(null, 'city', address)
    };
  };

  useBeforeUnload(defaultValues);
  const { handleSubmit, getValues, errors, register, control, reset, watch, setValue } = useForm({ defaultValues: getDefault() });

  if (fetching.isDone()) {
    return <Message
      value="Le sinistre a été ajouté"
      action='Ok'
      onClick={() => fetching.reset()}
    />;
  }

  const onSubmit = () => {
    const data = R.assoc('contractId', contractId, getValues());
    setShowDocumentsErrorMessage(false);
    const attachments = R.propOr([], 'attachments', data);

    const validatedDocuments = R.reduce((acc, validator) => !acc ? acc : validator(attachments), true)(requiredDocumentsValidators);

    if (validatedDocuments) {
      onFormsSubmit(R.evolve({
        trimesterPayBrut: parseFloatOr(0),
        primeAmount: parseFloatOr(0)
      })(data));
    } else {
      setShowDocumentsErrorMessage(true);
    }
  };

  const getRequiredDocuments = (values) => {
    const rules = {
      workRestartPartialPJ: R.propEq('workRestartMethod', 'PARTIAL', values),
      leavingPJ: R.compose(RA.isNotNilOrEmpty, R.prop('dateLeaving'))(values)
    };

    return R.compose(
      R.filter((document) => {
        if (R.has('only', document)) {
          return document.only.includes(user.companyType.toLowerCase());
        }
        return true;
      }),
      R.map((document) => {
        const key = document.value;

        if (R.has(key, rules)) {
          const isRequired = R.prop(key, rules);
          return R.assoc('required', isRequired, document);
        }
        return document;
      })
    )(documentTypeIncapaciteOptions);
  };

  const listAttachments = getRequiredDocuments(getValues());

  const { getBankAccounts } = useCompany();
  const ribs = getBankAccounts().map(account => ({
    value: account.iban,
    label: account.iban
  }));

  const onDownloadDocumentItt = () => {
    downloadDocument('/documents/prestations/itt');
  };

  return <>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block
        name="informations"
        register={registerBlock({ open: true, canClose: false })}
        color="pink"
        icon="icon-sinistre.svg"
        title={'Information sur la personne'}
        controls={controls}
      >
        {isCompany(user) && (
          <SelectOneOption
            options={affiliatesToOptions(affiliates)}
            onChange={({ value }) => onSelectAffiliate(affiliates)(setDefaultValues, value)}
            className="mt-5"
            placeholder={'Sélectionner un affilié'}
          />)}
        {isTns(user) && (
          <div className="mt-4">
            <p>
              {'Pour déclarer une incapacité temporaire de travail, nous vous invitons à télécharger et renseigner le formulaire de demande ci dessous et de le renvoyer par voie postale à l\'adresse indiquée.'}
            </p>
            <div className="flex justify-center">
              <div className="document text-plum font-bold cursor-pointer" onClick={onDownloadDocumentItt}>
                <ColoredIcon
                  icon="icon-documents.svg"
                  color="pink"
                  className="document-icon"
                  height="64px"
                  width="64px"
                />
                <div className="document-name">
                  {'Déclaration d\'incapacité temporaire de travail'}
                </div>
              </div>
            </div>
          </div>
        )}
        {isCompany(user) && (
          <Forms
            columns={2}
            wrapInForm={false}
            forwardedUseForm={{ handleSubmit, register, control, reset, getValues, watch, errors, setValue }}
            formsData={informationsForm}
            defaultValues={getDefault()}
            options={{
              accidentReason,
              employType,
              titles,
              workStoppingReasonType,
              yesNoType,
              ribs,
              workRestartMethodType
            }}

            onSubmit={onFormsSubmit}
            submitSection={() => { }}
          />
        )}
      </Block>
      {isCompany(user) && (
        <>
          <DocumentUploadBlock
            name="sinistreAttachments"
            register={register}
            controls={controls}
            listAttachments={listAttachments}
            showErrorMessage={showDocumentsErrorMessage}
            form={{ handleSubmit, register, control, reset, getValues, watch, errors, setValue }}
            formData={attachmentsForm}
          />
          <button className="f-button f-button-pink mt-4"
            aria-label="Déclarer mon sinistre"
            disabled={fetching.isFetching()}
            type="submit">
            {'Déclarer mon sinistre'}
          </button>
        </>
      )}

    </form>
    {useBlockTransition(defaultValues)}
  </>;
};

DeclareIncapacite.propTypes = {
  affiliates: () => {},
  user: propTypes.shape(USER_PROPTYPES),
  company: propTypes.shape(COMPANY_PROPTYPES),
  address: propTypes.shape(ADDRESS_PROPTYPES),
  contractId: propTypes.string
};

export default DeclareIncapacite;
