import { SignOutMutation } from '@@mutations/userSession';
import useFetching from './useFetching';
import redirectToLoginPage from '../lib/redirectToLoginPage';

const useUser = (token) => {
  const fetching = useFetching();

  const onSignOut = () => {
    fetching.start();
    SignOutMutation((ok, error) => {
      if (!ok) return fetching.stop('failed');
      fetching.stop();
      redirectToLoginPage();
    });
  };

  return {
    fetching,
    onSignOut
  };
};

export default useUser;
