import React from 'react';
import PropTypes from 'prop-types';
import ChartPerPrestation from './ChartPerPrestation';
import ChartPiePrestationRisque from './ChartPiePrestationRisque';
import QApporteurSinistresGraph from '@@queries/QApporteurSinistresGraph';
import ChartDataToDataset from '../../../lib/ChartDataToDataset';
import { USER_PROPTYPES } from '../../../propTypes/user/UserPropTypes';

const ChartsSinistres = ({ user, year }) => {
  return (
    <QApporteurSinistresGraph args={{ year }}>
      {({ apporteurSinistresChartDeces, apporteurSinistresChartInvalidite, apporteurSinistresChartIncapacite, apporteurSinistresChartPerRisk }) => (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-4">
          <ChartPerPrestation title="Prestations risque Décès" data={ChartDataToDataset(apporteurSinistresChartDeces)}/>
          <ChartPerPrestation title="Prestations risque Invalidité" data={ChartDataToDataset(apporteurSinistresChartInvalidite)}/>
          <ChartPerPrestation title="Prestations risque Incapacité" data={ChartDataToDataset(apporteurSinistresChartIncapacite)}/>
          <ChartPiePrestationRisque title="Prestations versées par risque" data={ChartDataToDataset(apporteurSinistresChartPerRisk)}/>
        </div>
      )}
    </QApporteurSinistresGraph>
  );
};

ChartsSinistres.propTypes = {
  user: PropTypes.shape(USER_PROPTYPES),
  year: PropTypes.number.isRequired
};

ChartsSinistres.defaultProps = {
};

export default ChartsSinistres;
