import React from 'react';
import QNews from '@@queries/QNews';
import ReactMarkdown from 'react-markdown';
import { Block } from '@@components';
const R = require('ramda');
const NewsPage = () => {
  const { register, controls } = Block.useBlocks();
  return (
    <div>
      <QNews>
        {({ news = [] }) => {
          return news.items.map(item => {
            const imageUrl = R.path(['image', 'path'], item);
            return (
              <Block key={item.id} name="news" register={register({ canClose: false })} controls={controls} title={item.title} color="pink" titleColor="plum">
                <div className="news-item">
                  {!R.isNil(imageUrl) &&
                <div className="news-image">
                  <img src={imageUrl} className="image-url" alt="Image d'accueil"/>
                </div>
                  }
                  <div className="news-content">
                    <ReactMarkdown linkTarget="_blank">{item.text}</ReactMarkdown>
                  </div>
                </div>
              </Block>
            );
          });
        }}
      </QNews>
    </div >
  );
};

export default NewsPage;
