import React from 'react';
import { graphql } from 'react-relay';

import Query from '../Query';
import { populateChildren } from './toolbox';

const QApporteurContractDetailQuery = graphql`
  query QApporteurContractDetailQuery($id: String, $type: String) {
    apporteurContractDetail(id: $id, type: $type) {
      num
      unencryptedId
      idCrypt
      social
      productName
      identity
      cotisation
      dateEffect
      soldeContract
      typeContract
      tauxCotisation {
        codeId,
        rates,
        contributionLongName
      }
      college
      garantyList
      apporteurName
      statusContract
      dsnEnabled
    }
  }
`;

const QApporteurContractDetail = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QApporteurContractDetailQuery}
      args={args}
      mockKey="QApporteurContractDetail"
      mockData={mockData}
    >
      {populateChildren(['apporteurContractDetail'])(children, childDataProp)}
    </Query>
  );
};
export default QApporteurContractDetail;
