import propTypes from 'prop-types';

export const ADDRESS_PROPTYPES = {
  city: propTypes.string,
  line: propTypes.string,
  zipCode: propTypes.string,
  placeholder: propTypes.string,
  required: propTypes.bool,
  source: propTypes.string
};
