import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Bar } from 'react-chartjs-2';
import ChartLegend from '../../components/ChartLegend';
import colors from '@@refs/colors';
import moment from 'moment';
import * as RA from 'ramda-adjunct';

const options = {
  responsive: true,
  aspectRatio: 1 / 0.3,
  scales: {
    x: {
      grid: {
        display: false
      }
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
};

const toKValue = R.compose(
  (n) => n.toLocaleString(undefined, { maximumFractionDigits: 2 }) + 'K',
  R.when(R.gte(R.__, 1), RA.round),
  R.divide(R.__, 1000)
);

const getTotal = (key, datasets) => {
  const values = R.compose(
    R.reduce((acc, el) => R.add(el, acc), 0),
    R.propOr([0], 'data'),
    R.find(R.propEq('label', key))
  )(datasets);
  return values;
};

const getYear = R.compose(
  R.propOr(moment().year(), 'year'),
  R.head
);

const addMissingTrimesterInGroups = (availablesTrimester) => (group) => {
  return R.map(
    (el) => {
      const item = R.find(
        R.both(
          R.propEq('year', el.year),
          R.propEq('trimestre', el.trimestre)
        )
      )(group);

      const category = R.compose(
        R.prop('category'),
        R.head
      )(group);

      return R.when(
        R.isNil,
        R.always({ year: el.year, trimestre: el.trimestre, amountCotisation: 0, category })
      )(item);
    }
  )(availablesTrimester);
};

const sortPerYearAndTrimester = R.sortBy(({ year, trimestre }) => year * 10 + trimestre);

const ChartCotisationsAppels = ({ data }) => {
  const reduce = (acc, el) => {
    return R.compose(
      R.evolve({ amountCotisation: (v) => +v.toFixed(2) }),
      R.evolve({ amountCotisation: R.add(R.propOr(0, 'amountCotisation', acc)) }),
      R.evolve({ amountCotisation: (v) => Number.parseFloat(v.replace(',', '.')) }),
      R.pick(['year', 'trimestre', 'amountCotisation', 'category'])
    )(el);
  };

  const availablesTrimesters = R.compose(
    R.uniq,
    R.map(R.pick(['year', 'trimestre'])),
    R.flatten
  )(data);

  const grouped = R.compose(
    R.groupBy(R.prop('category')),
    sortPerYearAndTrimester,
    R.flatten(),
    R.map(addMissingTrimesterInGroups(availablesTrimesters)),
    R.values(),
    R.groupBy(R.prop('category')),
    sortPerYearAndTrimester,
    R.map(R.reduce(reduce, {})),
    R.values,
    R.groupBy((el) => `T${R.prop('trimestre', el)} ${R.prop('year', el)} ${R.prop('category', el)}`)
  )(data);

  const keys = [
    { key: 'COLLECTIF', color: colors.plum },
    { key: 'INDIVIDUEL', color: colors.pink }
  ];

  const datasets = R.map(
    ({ key, color }) => {
      const values = R.propOr([], key, grouped);

      return {
        label: key,
        labels: R.map(({ trimestre, year }) => `T${trimestre} ${year}`, values),
        data: R.map(R.prop('amountCotisation'), values),
        backgroundColor: R.map(R.always(color), values)
      };
    },
    keys
  );

  const chartData = {
    labels: R.compose(
      R.propOr([], 'labels'),
      R.find(
        R.compose(
          R.not,
          R.isEmpty,
          R.propOr([], 'labels')
        )
      )
    )(datasets),
    datasets
  };

  const totalCollectif = getTotal('COLLECTIF', datasets);
  const totalIndividuel = getTotal('INDIVIDUEL', datasets);

  return (<div className="justify-center text-center w-full">
    <Bar
      data={chartData}
      options={options}
    />

    <ChartLegend
      datasets={chartData.datasets}
      labels={chartData.labels}
    />

    <div className="text-plum mt-2 font-semibold text-lg">Cotisations encaissées en {getYear(data)} : Collectif {toKValue(totalCollectif)} € Individuel {toKValue(totalIndividuel)} €</div>
  </div>);
};

ChartCotisationsAppels.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    amountCotisation: PropTypes.string.isRequired,
    category: PropTypes.oneOf(['COLLECTIF', 'INDIVIDUEL']).isRequired,
    commissionType: PropTypes.string,
    networkId: PropTypes.strint,
    productLabel: PropTypes.string,
    trimestre: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired

  }))
};

ChartCotisationsAppels.defaultProps = {
};

export default ChartCotisationsAppels;
