const contactsList = [
  {
    title: 'Responsables des partenariats',
    contacts: [
      {
        name: 'Mesut Turk',
        phone: '06 66 86 52 13',
        email: 'mturk@unmi.eu'
      },
      {
        name: 'Isabelle Bertrand',
        phone: '06 08 05 62 49',
        email: 'ibertrand@unmi.eu'
      },
      {
        name: 'Julien Bouchet',
        phone: '06 95 41 65 84',
        email: 'jbouchet@unmi.eu'
      }
    ]
  },
  {
    title: 'Directeur du développement',
    contacts: [
      {
        name: 'Patrick Ratta',
        phone: '06 66 80 44 00',
        email: 'pratta@unmi.eu'
      }
    ]
  },
  {
    title: 'Technico-commerciaux',
    contacts: [
      {
        name: 'Céline Boutin',
        phone: '05 56 08 03 66',
        email: 'cboutin@unmi.eu'
      },
      {
        name: 'Jennifer Tresse',
        phone: '05 56 08 03 66',
        email: 'jtresse@unmi.eu'
      }
    ]
  }
];

export default contactsList;
