import React, { memo, useEffect, useState } from 'react';
import * as R from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';

import useFetchQuery from '../../../hooks/useFetchQuery';
import QApporteurPortefeuilleManager from '../../../_graphql/queries/QApporteurPortefeuilleManager';
import { QAvailableYearInContractQuery } from '../../../_graphql/queries/QAvailableYearInContract';

import SelectOneOption from '../../../components/input/SelectOneOption';
import ChartContract from '../containers/portefeuille/ChartContract';
import ChartCotisations from '../containers/portefeuille/ChartCotisations';
import ChartCotisationsAppels from '../containers/portefeuille/ChartCotisationsAppels';

import { CURRENT_YEAR } from '../../../constants';
import { yearsOptions } from '../../../lib/dateFilters';

const ContractCharts = memo(function ContractsCharts () {
  const [filters, setFilters] = useState({ year: CURRENT_YEAR });

  const { availableYearsContracts, isLoaded } = useFetchQuery({
    query: QAvailableYearInContractQuery,
    dataProp: 'availableYearsContracts'
  });

  const options = yearsOptions(availableYearsContracts);
  const defaultOption = R.head(options);

  const value = R.find(R.propEq('value', R.propOr(CURRENT_YEAR, 'year', filters)), options);
  const onChangeYear = R.compose(setFilters, R.applySpec({ year: R.identity }), R.defaultTo(CURRENT_YEAR), R.prop('value'));

  useEffect(() => {
    if (isLoaded && isNotNilOrEmpty(defaultOption)) {
      setFilters(R.assoc('year', defaultOption.value));
    }
  }, [isLoaded]);

  return (
    <div className="flex flex-col gap-4 mb-4">
      <div className="flex gap-2 w-full">
        <div className="flex-1">
          {isLoaded && (
            <SelectOneOption
              placeholder={isNotNilOrEmpty(options) ? 'Année' : 'Aucune donnée disponible'}
              disabled={isNilOrEmpty(options)}
              value={value}
              defaultValue={filters}
              onChange={onChangeYear}
              options={options}
              isClearable
            />
          )}
        </div>
      </div>

      <QApporteurPortefeuilleManager args={filters}>
        {({ apporteurContractsReferential, apporteurEncaissementsReferential }) => (
          <div className="grid md:grid-cols-2 gap-4">
            <div>
              <div className="font-bold text-lg text-pink text-center">Répartition du nombre de contrats</div>
              {isNilOrEmpty(apporteurContractsReferential)
                ? (<div className="text-center text-gray-600">Aucune donnée disponible</div>)
                : (<ChartContract data={apporteurContractsReferential}/>)
              }

            </div>
            <div>
              <div className="font-bold text-lg text-pink text-center">Cotisations encaissées par produit</div>
              {isNilOrEmpty(apporteurEncaissementsReferential)
                ? (<div className="text-center text-gray-600">Aucune donnée disponible</div>)
                : (<ChartCotisations data={apporteurEncaissementsReferential}/>)
              }
            </div>

            <div className="md:col-span-2">
              <div className="font-bold text-lg text-pink text-center">Cotisations encaissées par période d&apos;appel</div>
              {isNilOrEmpty(apporteurEncaissementsReferential)
                ? (<div className="text-center text-gray-600">Aucune donnée disponible</div>)
                : (<ChartCotisationsAppels data={apporteurEncaissementsReferential}/>)
              }
            </div>
          </div>)}
      </QApporteurPortefeuilleManager>
    </div>
  );
});

ContractCharts.propTypes = {
};

ContractCharts.defaultProps = {
};

export default ContractCharts;
