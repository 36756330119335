import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import ColoredIcon from './ColoredIcon';

import {
  equals
} from 'ramda';

const Card = ({ title, label, color, to, icon }) => {
  const cardClasses = classNames(
    'p-3 shadow h-24 relative overflow-hidden',
    'flex flex-col justify-around rounded-lg',
    'cursor-pointer hover:bg-gray-200 transition-colors',
    'border-l-9',
    {
      'border-gray': equals('gray', color),
      'border-pink': equals('pink', color),
      'border-yellow': equals('yellow', color),
      'border-cyan': equals('cyan', color),
      'border-plum': equals('plum', color)
    }
  );
  return (<Link to={to} className={cardClasses}>
    <div style={{
      opacity: 0.075,
      position: 'absolute',
      left: '-5rem',
      top: '0.1rem',
      bottom: '0.1rem',
      zIndex: '1',
      transition: 'all 0.8s'
    }}>
      <img src="/icons/bubble.svg" className="w-full h-full" alt="Bulle d'information" />
    </div>
    <div className="text-plum text-lg -mt-2 uppercase font-bold">
      {title}
    </div>

    <div className="flex divide-x-2 divide-plum">
      <div className="px-2">
        <ColoredIcon icon={icon} color="plum" height={28} width={28}/>
      </div>
      <div className="px-2 flex flex-col justify-center">
        <div className="w-full font-semibold text-pink">{label}</div>
      </div>
    </div>
  </Link>);
};

Card.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.string,
  action: PropTypes.func,
  color: PropTypes.oneOf([
    'gray',
    'pink',
    'yellow',
    'cyan',
    'plum'
  ])
};

Card.defaultProps = {
  color: 'pink',
  action: () => {}
};

export default Card;
