import React from 'react';

import { Controller } from 'react-hook-form';
import { Attachments, UploadFile } from '@@components/input';
import ErrorMessage from './helpers/ErrorMessage';
import { INPUT_DEFAULT_FILE_PROPTYPES } from '../../../propTypes/form/InputPropTypes';
const R = require('ramda');

const Input = ({ register, field, control, getValues, errors }) => (
  <Controller name={field.name} rules={{ required: field.required }} control={control} render={({ name, onChange, value }) => (
    <>
      <ErrorMessage field={field} errors={errors} />
      <Attachments
        max={1}
        files={value || []}
        onChange={onChange}
      />
      {(R.length(value) === 0) && <UploadFile
        name="file"
        uploadUrl="/upload/file"
        uploadText={field.placeholder}
        disabled={field.disabled}
        onChange={file => { onChange([...(getValues(name) || []), file]); }}
      />}
    </>
  )}/>
);

Input.propTypes = INPUT_DEFAULT_FILE_PROPTYPES;

export default Input;
