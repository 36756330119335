import React from 'react';
import Loading from './Loading';
import propTypes from 'prop-types';

const PreventLoading = ({ children, isLoaded }) => {
  return <>{isLoaded ? children : <Loading />}</>;
};

PreventLoading.propTypes = {
  isLoaded: propTypes.bool.isRequired
};

export default PreventLoading;
