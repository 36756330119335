import { QChartApporteurQuery } from '../_graphql/queries/QChartApporteur';
import { prop, reject, isNil, equals } from 'ramda';
import useFetchQuery from './useFetchQuery';
import { useEffect, useRef } from 'react';

export const useFetchStatsApporteurDashboard = (args) => {
  const queryArgs = reject(isNil, {
    range: prop('range', args),
    year: prop('year', args),
    type: prop('type', args),
    apporteurs: prop('apporteurs', args)
  });

  const cachedArgs = useRef(queryArgs);

  const { dashboardContractRepartition, isLoaded, reFetch, data } = useFetchQuery({
    query: QChartApporteurQuery,
    dataProp: 'dashboardContractRepartition',
    args: queryArgs
  });

  useEffect(() => {
    if (equals(cachedArgs.current, queryArgs)) {
      return;
    }
    cachedArgs.current = queryArgs;
    reFetch();
  }, [queryArgs, reFetch]);

  return {
    dashboardContractRepartition,
    isLoaded,
    apporteurContractsStats: data.apporteurContractsStats
  };
};

export default useFetchStatsApporteurDashboard;
