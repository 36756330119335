import Address from './Address';
import Text from './Text';
import TextArea from './TextArea';
import Email from './Email';
import File from './File';
import Files from './Files';
import Hidden from './Hidden';
import Number from './Number';
import Radio from './Radio';
import RibList from './RibList';
import Rib from './Rib';
import Switch from './Switch';
import Date from './Date';
import Select from './Select';
import Password from './Password';

export default {
  address: Address,
  text: Text,
  textarea: TextArea,
  email: Email,
  file: File,
  files: Files,
  hidden: Hidden,
  number: Number,
  radio: Radio,
  password: Password,
  rib: Rib,
  ribList: RibList,
  select: Select,
  date: Date,
  switch: Switch
};
