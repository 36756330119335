import React from 'react';
import { Message } from '@@components';
import { genders } from '@@refs';

import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { onAskEnablingDsn } from '@@mutations/standard';
import { useFeedbackCb } from '../../../hooks/useFeedback';

const R = require('ramda');

const AddAffilieBlock =
() => {
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('addAffilie');

  const onAskEnablingDsnClick = useFeedbackCb(onAskEnablingDsn);

  const onSubmit = ({
    gender,
    lastname,
    firstname,
    date,
    nir,
    contract
  }) => {
    onFormsSubmit({
      gender: R.prop('value', gender),
      lastname,
      firstname,
      date,
      nir,
      contract: R.prop('value', contract)
    });
  };

  if (fetching.isDone()) {
    return <Message
      value="La demande d'ajout de l'affilié a bien été prise en compte."
      action='Ok'
      onClick={() => fetching.reset()}
    />;
  }

  return <>
    <p style={{ marginTop: 10, marginBottom: 10 }}>Pour ne plus avoir à ajouter manuellement les affiliés, vous pouvez activer la DSN, ils seront ainsi ajoutés automatiquement.</p>
    <Forms
      formsData={formsData}
      defaultValues={{
        gender: null,
        date: null,
        firstname: null,
        nir: null,
        lastname: null,
        contract: null
      }}
      options={{ genders }}
      onSubmit={onSubmit}
      submitSection={() => (
        <button
          aria-label="Ajouter affilié"
          className="f-button f-button-pink"
          disabled={fetching.isFetching()}
          type="submit"
        >
          {'Ajouter cet affilié'}
        </button>
      )}
    />

    <button aria-label="Demande activation de la DSN" className="mt-4 f-button f-button-plum" onClick={onAskEnablingDsnClick} >
      {'Demander l\'activation de la DSN'}
    </button>
  </>;
};

export default AddAffilieBlock;
