import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ReseauListPage from './ReseauListPage';
import ReseauDetailPage from './ReseauDetailPage';

const Index = () => {
  return (
    <Switch>
      <Route exact path="/reseau/"><ReseauListPage /></Route>
      <Route exact path="/reseau/:id"><ReseauDetailPage /></Route>
    </Switch>
  );
};

export default Index;
