import React from 'react';
import propTypes from 'prop-types';

import { Message, Block } from '@@components';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import {
  employType,
  titles,
  workStoppingReasonType,
  yesNoType,
  accidentReason
} from '@@refs';
import { isCompany } from '../../../../lib/getCompanyType';
import { ColoredIcon } from '@@components/ColoredIcon';
import { onDownloadDeclarationMiseInvalidite } from '@@mutations/standard';
import useCompany from '../../../../hooks/useCompany';
import { USER_PROPTYPES } from '../../../../propTypes/user/UserPropTypes';
import { COMPANY_PROPTYPES } from '../../../../propTypes/user/CompanyPropTypes';
import { ADDRESS_PROPTYPES } from '../../../../propTypes/user/AddressPropTypes';

const R = require('ramda');

const DeclareInvalidite = ({ affiliates, user, company, address, contractId }) => {
  const { fetching, onFormsSubmit, formsData } = useForms('declareSinistreInvalidite');
  const { register: registerBlock, controls } = Block.useBlocks();

  const { getBankAccounts } = useCompany();

  if (fetching.isDone()) {
    return <Message
      value="Votre demande est envoyée à nos services de gestion"
      action='Ok'
      onClick={() => fetching.reset()}
    />;
  }
  const onDownloadHandle = () => {
    window.open('/documents/prestations/invalidite', '_blank');
    onDownloadDeclarationMiseInvalidite(() => {
    });
  };

  const onSubmit = (value) => {
    const data = R.assoc('contractId', contractId, value);
    onFormsSubmit(data);
  };

  const ribs = getBankAccounts().map(account => ({
    value: account.iban,
    label: account.iban
  }));

  return <>
    <Block
      register={registerBlock({ open: true, canClose: false })}
      color="pink"
      icon="icon-sinistre.svg"
      title={'Information sur la personne'}
      controls={controls}
    >
      {isCompany(user) ? (
        <div className="mt-4">
          <p>
      Pour déclarer un salarié en Invalidité,
      merci de télécharger le document ci-dessous,
      de nous le renvoyer complété avec les pièces justificatives par email à <a className="font-semibold" rel="noreferrer" target="_blank" href="mailto:prestations@unmi.eu">prestations@unmi.eu</a>
          </p>
          <div className="flex justify-center">
            <div className="document text-plum font-bold cursor-pointer" onClick={onDownloadHandle}>
              <ColoredIcon
                icon="icon-documents.svg"
                color="pink"
                className="document-icon"
                height="64px"
                width="64px"
              />
              <div className="document-name">
          Déclaration de mise en invalidité
              </div>
            </div>
          </div>
        </div>)
        : (<Forms
          columns={2}
          formsData={formsData}
          defaultValues= {{
            title: R.propOr(null, 'title', company),
            firstname: R.propOr(null, 'firstname', company),
            birthname: R.propOr(null, 'name', company),
            birthdate: R.compose(
              R.when(R.complement(R.isNil), (v) => new Date(v)),
              R.pathOr(null, ['details', 'birthDate'])
            )(company),
            nir: R.pathOr(null, ['details', 'socialInsuranceNumber'], company),
            address: R.propOr(null, 'line', address),
            postal: R.propOr(null, 'zipCode', address),
            city: R.propOr(null, 'city', address),
            rib: null,
            accidentTiers: null,
            invalidityDate: null,
            workStoppingReason: null,
            dateWorkStopping: null,
            invalidityRegime: null,
            taxNotice: null,
            paymentStatement: null
          }}
          options={{
            accidentReason,
            employType,
            titles,
            workStoppingReasonType,
            yesNoType,
            ribs
          }}

          onSubmit={onSubmit}
          submitSection={() => (
            <button className="f-button f-button-pink"
              aria-label="Déclarer mon sinistre"
              disabled={fetching.isFetching()}
              type="submit">
              {'Déclarer mon sinistre'}
            </button>
          )}
        />)}
    </Block>
  </>;
};

DeclareInvalidite.propTypes = {
  affiliates: () => {},
  user: propTypes.shape(USER_PROPTYPES),
  company: propTypes.shape(COMPANY_PROPTYPES),
  address: propTypes.shape(ADDRESS_PROPTYPES),
  contractId: propTypes.string
};

export default DeclareInvalidite;
