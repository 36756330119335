import React from 'react';

import { SignOutMutation } from '@@mutations/userSession';

const R = require('ramda');
const UserContext = React.createContext();

const userIsSignedIn = R.compose(R.not, R.isNil, R.prop('username'));

export default function withUser (Component) {
  return function UserComponent (props) {
    const handleSignOut = () => {
      SignOutMutation((ok, error) => {
        if (error) {
          console.error('Une erreur c\'est produite lors de la déconnexion');
        }
      });
    };
    return (
      <UserContext.Consumer>
        {({ user }) => <Component
          {...props}
          user={user}
          userIsSignedIn={userIsSignedIn(user)}
          onSignOut={handleSignOut}
        />}
      </UserContext.Consumer>
    );
  };
}

export {
  UserContext
};
