import React from 'react';
import PropTypes from 'prop-types';
import { Pie } from 'react-chartjs-2';

import * as R from 'ramda';
import ChartLegend from '../../components/ChartLegend';

import colors from '@@refs/colors';

const getNbParticularContract = R.propOr(0, 'nbParticularContracts');
const getNbTnsContracts = R.propOr(0, 'nbTnsContracts');
const getNbCollectiveContract = R.propOr(0, 'nbCollectiveContracts');

const ChartContract = ({ data }) => {
  const formated = R.reduce((acc, el) => {
    const nbParticularContracts = getNbParticularContract(el) + getNbParticularContract(acc);
    const nbTnsContracts = getNbTnsContracts(el) + getNbTnsContracts(acc);
    const nbCollectiveContracts = getNbCollectiveContract(el) + getNbCollectiveContract(acc);

    return ({
      nbParticularContracts,
      nbTnsContracts,
      nbCollectiveContracts
    });
  }, {}, data);

  const sortedData = R.sortBy(R.prop('value'), [
    { label: 'Entreprise', value: R.prop('nbCollectiveContracts', formated), color: colors.enterprise },
    { label: 'TNS', value: R.prop('nbTnsContracts', formated), color: colors.tns },
    { label: 'Particulier', value: R.prop('nbParticularContracts', formated), color: colors.individual }
  ]);

  const chartData = {
    labels: R.map(R.prop('label'), sortedData),
    datasets: [
      {
        key: 'contracts',
        data: R.map(R.prop('value'), sortedData),
        backgroundColor: R.map(R.prop('color'), sortedData)
      }
    ]
  };

  return (<div>
    <Pie
      data={chartData}
      options={{
        responsive: true,
        aspectRatio: 1 / 0.50,
        plugins: {
          legend: {
            display: false
          }
        }
      }}
    />

    <ChartLegend
      isPie
      datasets={chartData.datasets}
      labels={chartData.labels}
    />

  </div>);
};

ChartContract.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.exact({
      networkId: PropTypes.string,
      networkLabel: PropTypes.string,
      nbParticularContracts: PropTypes.number,
      nbTnsContracts: PropTypes.number,
      nbCollectiveContracts: PropTypes.number,
      year: PropTypes.number,
      trimestre: PropTypes.number
    })
  )
};

ChartContract.defaultProps = {
};

export default ChartContract;
