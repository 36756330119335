/**
 * @flow
 * @relayHash 59041259ee5b734117b0f5e3e5724f19
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TiersSignUpMutationVariables = {|
  token: string,
  password: string,
|};
export type TiersSignUpMutationResponse = {|
  +tiersSignUp: ?{|
    +ok: boolean,
    +error: ?string,
    +signInLink: ?string,
  |}
|};
export type TiersSignUpMutation = {|
  variables: TiersSignUpMutationVariables,
  response: TiersSignUpMutationResponse,
|};
*/


/*
mutation TiersSignUpMutation(
  $token: String!
  $password: String!
) {
  tiersSignUp(token: $token, password: $password) {
    ok
    error
    signInLink
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "token",
        "variableName": "token"
      }
    ],
    "concreteType": "TiersSignUpMutationResponse",
    "kind": "LinkedField",
    "name": "tiersSignUp",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signInLink",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TiersSignUpMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TiersSignUpMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "59041259ee5b734117b0f5e3e5724f19",
    "metadata": {},
    "name": "TiersSignUpMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '03afc1fe6c6d35804b8d190a371cf510';

module.exports = node;
