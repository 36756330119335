/**
 * @flow
 * @relayHash 6f22d5b8d236ff859480147fd5bd00cc
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QApporteurQueryVariables = {|
  id?: ?string
|};
export type QApporteurQueryResponse = {|
  +apporteur: ?{|
    +id: ?string,
    +firstname: ?string,
    +lastname: ?string,
    +email: ?string,
    +network: ?string,
    +openning_date: ?string,
  |}
|};
export type QApporteurQuery = {|
  variables: QApporteurQueryVariables,
  response: QApporteurQueryResponse,
|};
*/


/*
query QApporteurQuery(
  $id: String
) {
  apporteur(id: $id) {
    id
    firstname
    lastname
    email
    network
    openning_date
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "Apporteur",
    "kind": "LinkedField",
    "name": "apporteur",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "network",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "openning_date",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QApporteurQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QApporteurQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "6f22d5b8d236ff859480147fd5bd00cc",
    "metadata": {},
    "name": "QApporteurQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2f051abd2492dbae1d1be35041c153af';

module.exports = node;
