import React from 'react';
import PropTypes from 'prop-types';
import RibListInput from '../../input/RibListInput';
import { Controller } from 'react-hook-form';

const RibList = ({ field, control, errors }) => {
  return (
    <Controller
      name={field.name}
      control={control}
      defaultValue={[]}
      rules={{ required: field.required }}
      render={({ onChange, value }) => (
        <RibListInput onChange={onChange} value={value}/>
      )}
    />
  );
};

RibList.propTypes = {
  control: PropTypes.any.isRequired,
  field: PropTypes.any.isRequired,
  errors: PropTypes.any
};

RibList.defaultProps = {
};

export default RibList;
