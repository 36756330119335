import { SendMessageMutation } from '@@mutations/message';
import useFetching from './useFetching';

const sendMessage = () => {
  const fetching = useFetching();

  const onSendMessage = ({ subject, message, attachments }) => {
    fetching.start();
    SendMessageMutation({ subject, message, attachments }, (ok, error) => {
      fetching.stop();
    });
  };

  return {
    onSendMessage,
    fetching
  };
};

const useMessage = {
  sendMessage
};

export default useMessage;
