/* eslint-disable react/display-name */
import React from 'react';
import { Block } from '@@components';
import Table from '@@components/table';
import MoneyFormat from '@@components/elements/MoneyFormat';
import QPrestations from '@@queries/QPrestations';
import ColoredIcon from '../../../../components/ColoredIcon';
import { isNilOrEmpty } from 'ramda-adjunct';

import * as R from 'ramda';

const getFullname = R.compose(
  R.join(' '),
  R.values,
  R.pick(['lastname', 'firstname'])
);

const PrestationListBlock = ({ contractId }) => {
  const columns = React.useMemo(() => ([
    { Header: 'Date', id: 'date', accessor: 'date' },
    {
      Header: 'Affilié',
      id: 'affiliate',
      accessor: getFullname
    },
    { Header: 'Contrat', id: 'contract', accessor: 'contract' },
    { Header: 'Type de prestations', id: 'type', accessor: 'type' },
    { Header: 'Réglée à', id: 'to', accessor: 'to' },
    { Header: 'période d\'indemnisation', id: 'rangeIndemnisation', accessor: 'rangeIndemnisation' },
    { Header: 'Statut', id: 'status', accessor: 'status' },
    {
      Header: 'Montant',
      id: 'amount',
      accessor: 'amount',
      Cell: ({ cell, row }) => (
        <div className="flex justify-between">
          <MoneyFormat value={cell.value} />
          <a target="_blank" rel="noreferrer" href={`/documents/prestations/download/${row.original.id}`}>
            <ColoredIcon color="pink" icon="icon-pdf.svg"/>
          </a>
        </div>
      )
    }
  ]), []);

  return (
    <Block
      name="prestation-list"
      color="pink"
      title="mes prestations"
      icon="menu-sinistres.svg"
    >
      <QPrestations
        args={{ contractId }}
      >
        {({ prestations }) => (
          isNilOrEmpty(prestations) ? <p>Aucune prestation pour cette personne et/ou ce contrat</p> 
            : <Table
              data={prestations}
              columns={columns}
            />
        )}
      </QPrestations>

    </Block>
  );
};

PrestationListBlock.propTypes = {
};

PrestationListBlock.defaultProps = {
};

export default PrestationListBlock;
