import React from 'react';
import propTypes from 'prop-types';
const R = require('ramda');

const RadioInput = ({ options, name, onChange }) => (
  <>
    {R.map((option) => (
      <label key={option.value} className="m-3">
        <input className="mx-1" type="radio" onChange={() => onChange(option)} name={name} value={option.value}/>
        {option.label}
      </label>
    ), R.defaultTo([], options)
    )}
  </>
);

RadioInput.propTypes = {
  options: propTypes.arrayOf(propTypes.shape({
    label: propTypes.string,
    value: propTypes.any
  })),
  onChange: propTypes.func,
  name: propTypes.string
};

export default RadioInput;
