/**
 * @flow
 * @relayHash 51a32c5e05d9ab9765862a440bee4020
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type onAutreMutationVariables = {|
  email: string
|};
export type onAutreMutationResponse = {|
  +onAutre: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type onAutreMutation = {|
  variables: onAutreMutationVariables,
  response: onAutreMutationResponse,
|};
*/


/*
mutation onAutreMutation(
  $email: String!
) {
  onAutre(email: $email) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "EmailMutationResponse",
    "kind": "LinkedField",
    "name": "onAutre",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "onAutreMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "onAutreMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "51a32c5e05d9ab9765862a440bee4020",
    "metadata": {},
    "name": "onAutreMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '69c2088eca5ea454501e5d665b52fc15';

module.exports = node;
