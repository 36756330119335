import React from 'react';
import { graphql } from 'react-relay';

import Query from '../Query';
import { populateChildren } from './toolbox';

const QApporteurOavContractsQuery = graphql`
  query QApporteurOavContractsQuery($page: Int, $nbItemPerPage: Int) {
    apporteurOavContracts(page: $page, nbItemPerPage: $nbItemPerPage) {
      page
      count
      nbItemPerPage
      nbPages
      data {
        contractNumber
        name
        num
        cotisation
        soldeContract
        date
        apporteur
        taux
        souscripteur
        type
        college
        status {
          percent
          status
        }
      }
    }
  }
`;

const QApporteurOavContracts = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QApporteurOavContractsQuery}
      args={args}
      mockKey="QApporteurContracts"
      mockData={mockData}
    >
      {populateChildren(['apporteurOavContracts'])(children, childDataProp)}
    </Query>
  );
};

export default QApporteurOavContracts;
