import React from 'react';
import { Message } from '@@components';

import Forms from '@@components/forms';
import useForms from '@@formsHook';

const AddApporteurBlock = () => {
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('apporteurSignUpRequest');

  if (fetching.isDone()) {
    return (
      <Message
        value="Le mail a été envoyé"
        action='Ok'
        onClick={() => fetching.reset()}
      />
    );
  }

  return (
    <>
      <Forms
        formsData={formsData}
        columns={2}
        defaultValues={{
          isManager: false
        }}
        onSubmit={onFormsSubmit}
        submitSection={() => (
          <button
            aria-label="Envoyer les emails de création de compte"
            className="mt-4 f-button f-button-pink"
            disabled={fetching.isFetching()}
            type="submit"
          >
            {'ENVOYER LES EMAILS DE CRÉATION DE COMPTE'}
          </button>
        )}
      />
    </>
  );
};

export default AddApporteurBlock;
