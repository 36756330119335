import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

const QPrestationsQuery = graphql`
  query QPrestationsQuery($contractId: String!) {
    prestations(contractId: $contractId) {
      id
      date
      firstname
      lastname
      contract
      type
      to
      rangeIndemnisation
      status
      amount
    }
  }
`;

const QPrestations = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QPrestationsQuery}
      args={args}
      mockKey="QPrestations"
      mockData={mockData}
    >
      {populateChildren(['prestations'])(children, childDataProp)}
    </Query>
  );
};

export default QPrestations;
