import Moment from 'moment';
import * as R from 'ramda';
import { useState } from 'react';
import { getOptions } from '../../../../lib/dateFilters';
import { removeNulls } from '../../../../lib/removeNulls';

const getGroupedContracts = R.compose(
  R.groupBy(R.prop('date')),
  R.map(
    R.evolve({ date: (d) => Moment(d).format('YYYY-MM') })
  )
);

const getApporteurs = R.compose(
  R.uniq,
  R.reject(R.anyPass([R.isNil, R.isEmpty])),
  R.map(R.prop('apporteur'))
);

const getStatus = R.compose(
  R.uniq,
  R.reject(R.anyPass([R.isNil, R.isEmpty])),
  R.map(R.path(['status', 'status']))
);

const toStatusLabel = R.ifElse(
  R.equals('- Afficher tous les statuts -'),
  R.always('- Afficher tous les statuts -'),
  R.cond([
    [R.equals('IN_PROGRESS'), R.always('En cours')],
    [R.equals('SENT_FOR_SIGNATURE'), R.always('En signature')],
    [R.equals('SIGNED'), R.always('Signé en attente')],
    [R.equals('ACTIVE'), R.always('Actif')],
    [R.T, R.always('En cours')]
  ])
);

const filterStatus = [
  {
    label: 'En cours',
    value: 'IN_PROGRESS'
  },
  {
    label: 'En signature',
    value: 'SENT_FOR_SIGNATURE'
  },
  {
    label: 'Signé en attente',
    value: 'SIGNED'
  },
  {
    label: 'Actif',
    value: 'ACTIVE',
    disable: true
  }
];

export const PER_PAGE = 20;

export const useOavContractList = (contracts = []) => {
  const [selectedApporteur, setSelectedApporteur] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const queryFilter = R.compose(
    R.defaultTo({}),
    removeNulls,
    R.applySpec({
      apporteur: R.path(['selectedApporteur', 'value']),
      __status__: R.ifElse(
        R.compose(R.isNil, R.prop('selectedStatus')),
        R.always(null),
        R.path(['selectedStatus', 'value'])
      )
    })
  )({ selectedApporteur, selectedStatus });

  const filteredContracts = R.compose(
    R.filter(R.whereEq(queryFilter)),
    R.map(v => R.assoc('__status__', R.path(['status', 'status'], v))(v))
  )(contracts);

  const paginationlist = R.compose(
    R.map(getGroupedContracts),
    R.splitEvery(PER_PAGE)
  )(filteredContracts);

  const allStatus = R.compose(
    R.append({ label: 'Tout afficher', value: 'all' }),
    R.compose(
      R.map(
        R.applySpec({
          label: (e) => toStatusLabel(e),
          value: R.identity
        })
      ),
      R.defaultTo([])
    ),
    getStatus)(contracts);

  const allApporteurs = R.compose(
    getOptions,
    getApporteurs)(contracts);
  const handleChangeApporteur = setSelectedApporteur;

  const handleChangeStatus = setSelectedStatus;

  return {
    onChangeApporteur: handleChangeApporteur,
    apporteurs: allApporteurs,
    onChangeStatus: handleChangeStatus,
    selectedApporteur,
    selectedStatus,
    status: allStatus,
    toStatusLabel,
    paginationlist,
    filterStatus,
    totalFilteredContracts: R.length(filteredContracts)
  };
};
