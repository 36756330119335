/* eslint-disable react/display-name */
import React from 'react';
import { Block } from '@@components';
import Table from '@@components/table';
import MoneyFormat from '@@components/elements/MoneyFormat';

const AffiliePrestationBlock = ({ prestations }) => {
  const { register, controls } = Block.useBlocks();
  const columns = React.useMemo(() => ([
    { Header: 'Date', id: 'date', accessor: 'date' },
    { Header: 'Paiement', id: 'paymentDate', accessor: 'paymentDate' },
    { Header: 'Montant', id: 'amount', accessor: 'amount', Cell: ({ cell }) => (<MoneyFormat value={cell.value} />) }
  ]), []);

  return (
    <Block
      name="prestations-list"
      color="pink"
      icon="apporteur.svg"
      title="Prestations"
      register={register}
      controls={controls}
    >
      <Table
        data={prestations}
        columns={columns}
        isBlock={false}
      />
    </Block>
  );
};

export default AffiliePrestationBlock;
