import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

export const QApporteursQuery = graphql`
  query QApporteursQuery {
    apporteurs {
      networkId
      eltNetworkId
      apportName
    }
  }
`;

const QApporteurs = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QApporteursQuery}
      args={args}
      mockKey="QApporteurs"
      mockData={mockData}
    >
      {populateChildren(['apporteurs'])(children, childDataProp)}
    </Query>
  );
};

export default QApporteurs;
