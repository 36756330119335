import {
  commitMutation,
  graphql
} from 'react-relay';
import environment from '../../index';

const mutation = graphql`
mutation ApporteurSignUpMutation($token: String!, $password: String!) {
  apporteurSignUp(token: $token, password: $password) {
    ok
    error
    signInLink
  }
}
`;

export default async ({ token, password }, done) => {
  const variables = {
    token,
    password
  };

  commitMutation(
    await environment(),
    {
      mutation,
      variables,
      updater: (proxyStore) => {
      },
      onCompleted: ({ apporteurSignUp }) => {
        const { ok, error, signInLink } = apporteurSignUp;
        done(ok, error, signInLink);
      },
      onError: err => console.error(err)
    }
  );
};
