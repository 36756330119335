import moment from 'moment';
import { map, tap, join, applySpec, compose, find, props, prop, propEq } from 'ramda';

export const onSelectAffiliate = affiliates => (setterFn, affiliateId) => compose(
  setterFn,
  applySpec({
    birthname: prop('lastName'),
    firstname: prop('firstName'),
    nir: prop('nir'),
    birthdate: compose(date => moment(date).toDate(), prop('birthDate')),
    startDate: compose(date => moment(date).toDate(), prop('employmentDate'))
  }),
  find(propEq('id', affiliateId))
)(affiliates);

export const affiliatesToOptions = map(
  applySpec({
    value: prop('id'),
    label: compose(join(' '), props(['firstName', 'lastName']))
  })
);
