import { compose, gte, propOr, propEq, cond, T, always, length, __, ifElse, isNil } from 'ramda';

const haveAtLeastAttachments = amount => compose(
  gte(__, amount),
  length,
  propOr([], 'attachments')
);

export const haveRequiredAttachments = ifElse(
  isNil,
  always(false),
  cond([
    [propEq('subject', 'addTransferDetails'), haveAtLeastAttachments(1)],
    [propEq('subject', 'addBankDebitDetails'), haveAtLeastAttachments(2)],
    [T, always(true)]
  ])
);
