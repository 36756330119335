import './TextArea.less';
import React from 'react';
import propTypes from 'prop-types';

const TextArea = ({ name, rows, onChange, value, hasError }) => (
  <textarea
    rows={rows}
    className={(hasError ? 'f-textarea-error' : 'f-textarea')}
    name={name}
    onChange={onChange}
    value={value}
  />
);

TextArea.propTypes = {
  hasError: propTypes.bool,
  rows: propTypes.number,
  name: propTypes.string,
  value: propTypes.string,
  onChange: propTypes.func
};

export default TextArea;
