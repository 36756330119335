import React, { useEffect, useState, useRef } from 'react';
import propTypes from 'prop-types';

import { Block } from '@@components';
import AccountsTable from '../accounts/AccountsTable';

import QAccountHistories from '../../../_graphql/queries/QAccountHistories';

const BlockAccounts = ({ name, title, selectedType }) => {
  const { register, controls } = Block.useBlocks();

  const [page, setPage] = useState(1);
  const type = useRef(selectedType);

  const onChangePage = (currentPage) => setPage(currentPage);

  useEffect(() => {
    if (type.current !== selectedType) {
      type.current = selectedType;
    }
  }, [selectedType]);

  const limit = 10;

  return (
    <Block name={name} register={register({ canClose: true })} controls={controls} title={title} color="pink" titleColor="plum">
      <div className='flex flex-col justify-between min-h-500'>
        <QAccountHistories args={{
          type: type.current,
          pagination: {
            limit,
            page
          }
        }}>
          {({ getAccountHistories }) => <AccountsTable accountHistories={getAccountHistories} isApporteur={type.current === 'APPORTEUR'} onChangePage={onChangePage}/>}
        </QAccountHistories>
      </div>
    </Block>);
};

BlockAccounts.propTypes = {
  name: propTypes.string,
  title: propTypes.string,
  selectedType: propTypes.string
};

export default BlockAccounts;
