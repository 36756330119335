/**
 * @flow
 * @relayHash 442569bc3f86d44883f2a5eb909da940
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CompanyContextQueryVariables = {||};
export type CompanyContextQueryResponse = {|
  +company: ?{|
    +id: ?string,
    +partyId: ?string,
    +title: ?string,
    +firstname: ?string,
    +name: ?string,
    +siret: ?string,
    +banner: ?string,
    +fiscalDate: ?string,
    +bankAccounts: ?$ReadOnlyArray<?{|
      +holder: ?string,
      +bic: ?string,
      +iban: ?string,
      +type: ?string,
    |}>,
    +details: ?{|
      +contactName: ?string,
      +phone: ?string,
      +email: ?string,
      +socialInsuranceNumber: ?string,
      +birthDate: ?string,
    |},
    +contracts: ?$ReadOnlyArray<?{|
      +id: ?string,
      +contractId: ?string,
      +contractIdCrypt: ?string,
      +type: ?string,
      +status: ?string,
      +info: ?{|
        +college: ?string,
        +dateEffet: ?string,
        +tauxCotisation: ?string,
        +nomProduit: ?string,
        +paymentType: ?string,
        +dsn: ?string,
        +address: ?string,
        +paymentMode: ?string,
        +rib: ?{|
          +label: ?string,
          +IBAN: ?string,
          +BIC: ?string,
        |},
      |},
      +garanties: ?$ReadOnlyArray<?{|
        +name: ?string
      |}>,
      +billings: ?$ReadOnlyArray<?{|
        +id: ?string,
        +dates: ?{|
          +startDate: ?string,
          +endDate: ?string,
        |},
        +contract: ?string,
        +total: ?string,
        +lastPayment: ?string,
        +status: ?string,
      |}>,
      +affiliates: ?$ReadOnlyArray<?{|
        +id: ?string,
        +baseId: ?string,
        +affiliateIdCrypt: ?string,
        +affiliateId: ?string,
        +contractId: ?string,
        +contractIdCrypt: ?string,
        +externalContractId: ?string,
        +firstName: ?string,
        +lastName: ?string,
        +dateEffet: ?string,
        +status: ?string,
        +nir: ?string,
        +birthDate: ?string,
        +employmentDate: ?string,
        +terminationDate: ?string,
      |}>,
      +documents: ?$ReadOnlyArray<?{|
        +name: string,
        +documentId: string,
        +isGlobal: ?string,
        +type: ?string,
        +effectDate: ?string,
      |}>,
    |}>,
  |}
|};
export type CompanyContextQuery = {|
  variables: CompanyContextQueryVariables,
  response: CompanyContextQueryResponse,
|};
*/


/*
query CompanyContextQuery {
  company {
    id
    partyId
    title
    firstname
    name
    siret
    banner
    fiscalDate
    bankAccounts {
      holder
      bic
      iban
      type
    }
    details {
      contactName
      phone
      email
      socialInsuranceNumber
      birthDate
    }
    contracts {
      id
      contractId
      contractIdCrypt
      type
      status
      info {
        college
        dateEffet
        tauxCotisation
        nomProduit
        paymentType
        dsn
        address
        paymentMode
        rib {
          label
          IBAN
          BIC
        }
      }
      garanties {
        name
      }
      billings {
        id
        dates {
          startDate
          endDate
        }
        contract
        total
        lastPayment
        status
      }
      affiliates {
        id
        baseId
        affiliateIdCrypt
        affiliateId
        contractId
        contractIdCrypt
        externalContractId
        firstName
        lastName
        dateEffet
        status
        nir
        birthDate
        employmentDate
        terminationDate
      }
      documents {
        name
        documentId
        isGlobal
        type
        effectDate
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birthDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractIdCrypt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dateEffet",
  "storageKey": null
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Company",
    "kind": "LinkedField",
    "name": "company",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "partyId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "siret",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "banner",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fiscalDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompanyBankAccount",
        "kind": "LinkedField",
        "name": "bankAccounts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "holder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bic",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "iban",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompanyDetails",
        "kind": "LinkedField",
        "name": "details",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contactName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "socialInsuranceNumber",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompanyContract",
        "kind": "LinkedField",
        "name": "contracts",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v2/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyContractInfo",
            "kind": "LinkedField",
            "name": "info",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "college",
                "storageKey": null
              },
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tauxCotisation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nomProduit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dsn",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "address",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paymentMode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RIB",
                "kind": "LinkedField",
                "name": "rib",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "IBAN",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "BIC",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyContractInfoGaranty",
            "kind": "LinkedField",
            "name": "garanties",
            "plural": true,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyContractInfoBilling",
            "kind": "LinkedField",
            "name": "billings",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "BillingDate",
                "kind": "LinkedField",
                "name": "dates",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endDate",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "contract",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "total",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastPayment",
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CompanyContractInfoAffiliate",
            "kind": "LinkedField",
            "name": "affiliates",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "baseId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "affiliateIdCrypt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "affiliateId",
                "storageKey": null
              },
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "externalContractId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "firstName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastName",
                "storageKey": null
              },
              (v7/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "nir",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "terminationDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Document",
            "kind": "LinkedField",
            "name": "documents",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "documentId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isGlobal",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "effectDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyContextQuery",
    "selections": (v8/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CompanyContextQuery",
    "selections": (v8/*: any*/)
  },
  "params": {
    "id": "442569bc3f86d44883f2a5eb909da940",
    "metadata": {},
    "name": "CompanyContextQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4262db7e657e0b99949a91c4799e1c18';

module.exports = node;
