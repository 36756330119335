import React, { useState } from 'react';
import moment from 'moment';
import ColoredIcon from '@@components/ColoredIcon';
import './DocumentsList.less';
import { is, map, prop, compose, groupBy, values, when, head, cond, T, equals, always, or, propEq, filter, converge, length, lt } from 'ramda';
import { lengthEq } from 'ramda-adjunct';
import downloadDocument from '../../../lib/downloadDocument';
import sentenceCaseFormat from '../../../lib/formatName';
import Pagination from '../../../components/Pagination';
const groupDocumentsByName = compose(
  map(when(
    lengthEq(1),
    head
  )),
  values,
  groupBy(
    prop('name')
  ),
  filter((document) => !propEq('type', 'Fiche de paramétrage DSN', document))
);

const mapName = (document, college) => {
  const conditionMembershipByCollege = converge(or, [equals('CERTIF AVEC REC'), equals('ADHESION')]);
  const membershipByCollege = cond([
    [equals('CDR - Cadres'), always('Adhésion cadres')],
    [equals('NCD - Non Cadres'), always('Adhésion non cadres')]
  ])(college);

  return compose(
    cond([
      [equals('Déclaration bénéficiaire'), always('Désignation de bénéficiaire')],
      [equals('Appel fonds groupe DSN'), always('Appel de cotisations')],
      [conditionMembershipByCollege, always(membershipByCollege)],
      [T, sentenceCaseFormat]
    ]),
    prop('name')
  )(document);
};

const onDownloadDocument = (document) => () => {
  downloadDocument(`/documents/download/${document.documentId}?isGlobal=${document.isGlobal}&type=${document.type}`);
};

const MultipleDocument = ({ documents, college }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const sortPerDate = (a, b) => moment(b.effectDate).unix() - moment(a.effectDate).unix();

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const showPagination = compose(lt(pageSize), length)(documents);

  const limitedDocuments = documents.sort(sortPerDate).slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return <>
    <div className="document text-plum font-bold select-none">
      <div onClick={toggle} style={{ cursor: 'pointer' }}>
        <ColoredIcon
          icon="icon-documents.svg"
          color="pink"
          className="document-icon"
          height="64px"
          width="64px"
        />
        <div className="document-name">
          {mapName(documents[0], college)}
        </div>
      </div>
      {isOpen && (
        <>
          <ul>
            {limitedDocuments.map((document, index) => (
              <li key={index}>
                <div className="text-xs cursor-pointer" onClick={onDownloadDocument(document)}>
                  • {moment(document.effectDate).format('DD/MM/YYYY')} <span className="text-pink">↓ </span>
                </div>
              </li>
            ))}
          </ul>
          {showPagination && (
            <div className="pt-2">
              <Pagination
                onChange={handlePageChange}
                current={currentPage}
                total={documents.length}
                pageSize={pageSize}
              />
            </div>
          )}
        </>
      )}
    </div>
  </>;
};

const SingleDocument = ({ document, college }) => (
  <div className="cursor-pointer document text-plum font-bold select-none">
    <div onClick={onDownloadDocument(document)}>
      <ColoredIcon
        icon="icon-documents.svg"
        color="pink"
        className="document-icon"
        height="64px"
        width="64px"
      />
      <div className="document-name">
        {mapName(document, college)}
      </div>
    </div>
  </div>
);

const DocumentsList = ({ documents, college }) => (
  <div className="documents-list flex-col md:flex-row">
    {groupDocumentsByName(documents).map((document, index) => (
      is(Array, document)
        ? <MultipleDocument documents={document} college={college} key={index} />
        : <SingleDocument key={index} document={document} college={college} />
    ))}
  </div>
);

export default DocumentsList;
