import React, { useState } from 'react';
import classNames from 'classnames';
import Cookies from 'universal-cookie';
import Moment from 'moment';

const CookieSquarespace = () => {
  const cookies = new Cookies();
  const [hasAcceptedCookie, setHasAcceptedCookie] = useState(cookies.get('cookie_consent'));

  const handleCookie = () => {
    cookies.set('cookie_consent', 'accepted', { path: '/', expires: Moment().add(25, 'M').toDate() });
    setHasAcceptedCookie(cookies.get('cookie_consent'));
  };

  return (
    <div className={classNames({ squarespace: !hasAcceptedCookie, squarespace_close: hasAcceptedCookie })}>
      <div className="cookie-squarespace">
        <div className="cookie-squarespace__text">
        En poursuivant votre navigation sur ce site, vous acceptez l‘utilisation de traceurs (cookies) afin de réaliser des statistiques anonymes de visites.
        </div>
        <div className="cookie-squarespace__buttons">
          <a href="http://unmi.eu/mentions-legales" target="_blank" rel="noopener noreferrer" className="f-link">
            <button aria-label="En savoir plus" className='f-button f-button-pink'>En savoir plus</button>
          </a>
          <button aria-label="Accepter les cookies" onClick={handleCookie} className='f-button f-button-pink'>OK</button>
        </div>
      </div>
    </div>
  );
};

export default CookieSquarespace;
