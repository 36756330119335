/**
 * @flow
 * @relayHash 9cadd3e470f5ecb970c561861dcdc6a3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AddApporteurInput = {|
  email?: ?string,
  firstname?: ?string,
  lastname?: ?string,
  openning_date?: ?string,
|};
export type AddApporteurMutationVariables = {|
  input: AddApporteurInput
|};
export type AddApporteurMutationResponse = {|
  +addApporteur: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type AddApporteurMutation = {|
  variables: AddApporteurMutationVariables,
  response: AddApporteurMutationResponse,
|};
*/


/*
mutation AddApporteurMutation(
  $input: AddApporteurInput!
) {
  addApporteur(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AddApporteurMutationResponse",
    "kind": "LinkedField",
    "name": "addApporteur",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddApporteurMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddApporteurMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "9cadd3e470f5ecb970c561861dcdc6a3",
    "metadata": {},
    "name": "AddApporteurMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a69be5b4993518e513a5f843cd0e3a3e';

module.exports = node;
