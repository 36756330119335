/* eslint-disable react/display-name */
import React from 'react';

import { DatePicker } from '@@components/input';
import moment from 'moment';

const SelectDateFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
  rows,
  setAllFilters
}) => {
  // * Basic general function for update the sort
  const onChangeHandler = (value) => {
    if (value) {
      setFilter(moment(value).format('DD/MM/YYYY'));
    } else {
      setFilter(null);
    }
  };

  return (
    <DatePicker className="p-1"
      format='dd/MM/yyyy'
      value={(filterValue) ? moment(filterValue, 'DD/MM/YYYY').toDate() : null}
      onChange={onChangeHandler}
    />
  );
};

export default SelectDateFilter;
