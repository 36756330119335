import { commitMutation, graphql } from 'react-relay';
import environment from '../../index';

const mutation = graphql`
mutation onDownloadDeclarationMiseInvaliditeMutation {
  onDownloadDeclarationMiseInvalidite {
    ok
    error
  }
}`;

export default async (done) => {
  const variables = {
  };

  commitMutation(
    await environment(),
    {
      mutation,
      variables,
      updater: (proxyStore) => {},
      onCompleted: ({ onDownloadDeclarationMiseInvalidite }) => {
        const { ok, error } = onDownloadDeclarationMiseInvalidite;
        done(ok, error);
      },
      onError: err => console.error(err)
    }
  );
};
