import { prop, reject, isNil, equals } from 'ramda';
import useFetchQuery from './useFetchQuery';
import { useEffect, useRef } from 'react';
import { QChartApporteurRepartitionQuery } from '../_graphql/queries/QChartApporteurRepartition';

export const useFetchChartApporteurRepartition = (args) => {
  const queryArgs = reject(isNil, {
    range: prop('range', args),
    year: prop('year', args),
    type: prop('type', args),
    apporteurs: prop('apporteurs', args)
  });

  const cachedArgs = useRef(queryArgs);

  const { apporteurDashboardRepartition, isLoaded, reFetch, data } = useFetchQuery({
    query: QChartApporteurRepartitionQuery,
    dataProp: 'apporteurDashboardRepartition',
    args: queryArgs
  });

  useEffect(() => {
    if (equals(cachedArgs.current, queryArgs)) {
      return;
    }
    cachedArgs.current = queryArgs;
    reFetch();
  }, [queryArgs, reFetch]);

  return {
    apporteurDashboardRepartition,
    isLoaded,
    apporteurContractsStats: data.apporteurContractsStats
  };
};

export default useFetchChartApporteurRepartition;
