import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

export const QContractsYearsAvailableQuery = graphql`
  query QContractsYearsAvailableQuery {
    apporteurContractsYears{
      years
    }
  }
`;

export const QContractStatus = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QContractsYearsAvailableQuery}
      args={args}
      mockKey={'QContractsYearsAvailable'}
      mockData={mockData}
    >
      {populateChildren(['apporteurContractsYears'])(children, childDataProp)}
    </Query>
  );
};
