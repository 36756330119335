const R = require('ramda');
const { default: rolesList } = require('@@refs/rolesList');

const isPersonnePhysique = (values) => {
  const accountType = R.compose(
    R.toLower,
    R.trim,
    R.prop('accountType')
  )(values);

  return R.includes(accountType, rolesList.TNS);
};

export default isPersonnePhysique;
