import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import useFeedback from '../../hooks/useFeedback';

const ResetPassword = () => {
  const { onFormsSubmit, formsData, fetching } = useForms('resetPassword');
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');
  const [isSend, setIsSend] = useState(false);

  const onSubmit = ({ password }) => {
    onFormsSubmit({ token, password });
  };

  useEffect(() => {
    if (fetching.isDone()) {
      setIsSend(true);
      fetching.reset();
    }
  }, [fetching]);

  useFeedback(fetching);
  if (isSend) {
    return (<div className="flex flex-col gap-4">
      <div>Votre mot de passe a bien été défini. Vous pouvez dès à présent bénéficier des outils UNMI à partir du lien :</div>
      <Link to='/account/signin' className="text-lg font-bold text-pink">Connexion</Link>
    </div>
    );
  }

  return (
    <Forms
      formsData={formsData}
      onSubmit={onSubmit}
      submitSection={() => (
        <button aria-label="Enregistrer le nouveau mot de passe" type="submit" className="f-button f-button-pink">Enregistrer le nouveau mot de passe</button>
      )}
    />
  );
};

export default ResetPassword;
