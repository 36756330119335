import { useEffect, useState } from 'react';
import { fetchQuery } from 'react-relay';
import environment from '../../app/_graphql';
import { QApporteurContractsQuery } from '../_graphql/queries/QApporteurContracts';
import * as R from 'ramda';

const hasNotEmptyValues = R.compose(R.not, R.isEmpty, R.values);

const useFetchApporteursContracts = (args, authorizeFetchMaxContracts) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    let hasBeenCancelled = false;
    const fetchData = async () => {
      if (isLoaded) setIsLoaded(false);
      if (hasNotEmptyValues(args)) {
        const data = await fetchQuery(environment(), QApporteurContractsQuery, {
          siren: R.prop('siren', args),
          souscripteur: R.prop('souscripteur', args),
          allowMaxContracts: authorizeFetchMaxContracts
        });
        if (!hasBeenCancelled) {
          setData(R.prop('apporteurContracts', data));
          setIsLoaded(true);
        }
      }
    };
    fetchData();
    return () => (hasBeenCancelled = true);
  }, [args, authorizeFetchMaxContracts]);

  return {
    data,
    isLoaded
  };
};

export default useFetchApporteursContracts;
