import React from 'react';
import PropTypes from 'prop-types';

const R = require('ramda');

const getErrorMessage = (field, errors) => R.cond([
  [R.pathEq([field.name, 'type'], 'required'), R.always(`Le champ "${field.label}" est requis.`)],
  [R.T, R.always(null)]
])(errors);

const ErrorMessage = ({ field, errors }) => {
  const message = getErrorMessage(field, errors);

  if (message === null) { return null; }
  return <p className="text-red-600">
    {message}
  </p>;
};

ErrorMessage.propTypes = {
  field: PropTypes.object,
  errors: PropTypes.object
};

export default ErrorMessage;
