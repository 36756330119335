const documentTypeOptions = [
  {
    label: 'Acte de décès ou notification de l’incapacité absolue et définitive *',
    value: 'acte_deces',
    required: true
  },
  {
    label: 'Copie du livret de famille du salarié *',
    value: 'livret_famille',
    required: true
  },
  {
    label: 'Copies des pièces d’identité recto/verso des bénéficiaires *',
    value: 'identite',
    required: true
  },
  {
    label: 'Relevé d’identité bancaire ou postal des bénéficiaires *',
    value: 'rib',
    required: true
  },
  {
    label: 'Copie des bulletins de salaire des 4 trimestres civils précédant le décès *',
    value: 'bulletin_salaire',
    required: true
  },
  {
    label: 'Justificatifs pour les enfants à charge',
    value: 'justificatif_enfants',
    required: false
  },
  {
    label: 'Procès-verbal de gendarmerie ou rapport de police',
    value: 'pv',
    required: false
  },
  {
    label: 'Facture des frais d’obsèques acquittée',
    value: 'facture_obseque',
    required: false
  }
];

export default documentTypeOptions;
