import './Contract.less';
import React from 'react';
import { BlockField } from '@@components';
import Moment from 'moment';
import withUser from '..//../userSession/withUser';
import { isCompany } from '../../../lib/getCompanyType';

import { useHistory } from 'react-router-dom';
import { DsnStatus } from '../../../../refs/dsnStatus';
import { contractStatusType } from '../../../../refs/contractStatusType';

import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import useCompany from '../../../hooks/useCompany';
import downloadDocument from '../../../lib/downloadDocument';

const transformTaux = (taux, isCompany) => {
  const tauxRegex = /\d+(?:\.\d+)?/g;
  if (R.not(isCompany)) {
    const periodicityRegex = /\b[^\d\W]+\b/g;
    return R.compose(
      R.join(' '),
      R.match(periodicityRegex)
    )(taux);
  }
  return R.compose(
    R.join(' / '),
    R.map((str) => {
      // * Transform values
      return R.compose(
        R.replace(tauxRegex, R.__, str),
        (v) => {
          const options = {
            style: isCompany ? 'percent' : 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          };
          return (Number.parseFloat(v).toLocaleString(undefined, options));
        },
        R.head,
        R.match(tauxRegex)
      )(str);
    }),
    R.map(R.trim),
    R.split('/')
  )(taux);
};

const Contract = ({
  contracts,
  contract,
  onSelectContract,
  user
}) => {
  const { getDocuments } = useCompany();

  const history = useHistory();

  const onAskEnablingDsnClick = () => {
    history.push('/contacteznous', {
      contract: contract.id,
      subject: 'enablingDsn',
      message: 'Bonjour\n\nPouvez vous m\'accompagner dans la mise en place de la DSN ?\n\nCordialement'
    });
  };

  const onAskPrelevementClick = () => {
    history.push('/contacteznous', {
      contract: contract.id,
      subject: 'addBankDebitDetails',
      message: 'Bonjour,\nVeuillez trouver ci joint mes coordonnées bancaires (RIB/IBAN) et le mandat SEPA signé pour le prélèvement de mes cotisations.'
    });
  };

  const documentDSN = R.find(R.propEq('type', 'Fiche de paramétrage DSN'), getDocuments(contract.id));

  const cg = R.find(R.propEq('type', 'CG'), R.propOr([], 'documents', contract));
  const optionGaranties = R.find(R.propEq('name', 'Certificat d\'adhésion prévoyance individuelle'), R.propOr([], 'documents', contract));
  const isUserCompany = isCompany(user);

  const paymentMode = R.pathOr('Non-Défini', ['info', 'paymentType'], contract);

  const onDownloadDocument = (document) => () => {
    downloadDocument(`/documents/download/${document.documentId}?isGlobal=${document.isGlobal}&type=${document.type}`);
  };
  return <div className='f-contract grid grid-cols-1 md:grid-cols-2'>
    <div className="f-contract-left">
      <BlockField label="Mon numéro de contrat" value={contract.id}/>
      <BlockField label="Statut" value={contract.status}/>
      <BlockField label="Date d'effet" value={Moment(contract.info.dateEffet, 'YYYY-MM-DD').format('DD/MM/YYYY')}/>
      <BlockField label="Nom du produit" value={contract.info.nomProduit}/>
      {RA.isNotNil(cg) && (
        <a className="text-pink text-sm" href={`/documents/download/${cg.documentId}?isGlobal=${cg.isGlobal}&type=${cg.type}`}>
          Voir les conditions générales
        </a>
      )}

      <BlockField label="Mode de paiement" value={paymentMode}/>
      {!R.equals(paymentMode, 'Prélèvement') && (<button aria-label="Passer en prélèvement" className="m-4 f-button f-button-plum" onClick={onAskPrelevementClick}>Passer en prélèvement </button>)}
      <BlockField label={isUserCompany ? 'Taux de cotisation' : 'Périodicité de cotisation'} value={transformTaux(contract.info.tauxCotisation, isUserCompany)}/>
    </div>
    <div className="f-contract-right">
      <BlockField label="Liste des garanties">
        <ul className="f-contract-garanties">
          {R.propOr([], 'garanties', contract).map((garanty, index) => (
            <li key={index}>{garanty.name}</li>
          ))}
        </ul>
      </BlockField>

      {RA.isNotNil(optionGaranties) && (
        <a className="text-pink text-sm" href={`/documents/download/${optionGaranties.documentId}?isGlobal=${optionGaranties.isGlobal}&type=${optionGaranties.type}`}>
          Voir mes options de garanties
        </a>
      )}

      {isCompany(user) && (R.not(R.propEq('status', contractStatusType.terminated, contract))) && (
        <>
          {R.equals(contract.info.dsn, DsnStatus.disabled) ? (
            <>
              <BlockField label="DSN" value={(
                <button aria-label="Demande activation de la DSN" className="mt-4 f-button f-button-plum" onClick={onAskEnablingDsnClick}>
                  {'Demander l\'activation de la DSN'}
                </button>
              )} />
            </>
          ) : (
            <>
              <BlockField label="DSN" value="Activée" />
              <button className="text-pink text-sm" onClick={onDownloadDocument(documentDSN)}>
              Voir le fichier de paramétrage DSN
              </button>
            </>
          )}
        </>
      )}
    </div>
  </div>;
};

export default withUser(Contract);
