import React from 'react';

import ListAffilies from './ListAffilies';
import AffilieDetail from './AffilieDetail';

import { Switch, Route } from 'react-router-dom';

const MesAffilies = () => {
  return (<Switch>
    <Route exact path="/mesaffilies/"><ListAffilies /></Route>
    <Route exact path="/mesaffilies/:contractIdCrypt/:id"><AffilieDetail /></Route>
  </Switch>);
};

export default MesAffilies;
