import React, { useState } from 'react';
import { Block, Page } from '@@components';
import ApporteurList from '../containers/reseau/ApporteurList';
import AddApporteurBlock from '../containers/reseau/AddApporteurBlock';
import { QNetworkApporteurs } from '../../../_graphql/queries/QNetworkApporteurs';
import ApporteurDetail from '../containers/reseau/ApporteurDetail';
import OavContractListByApporteur from '../containers/reseau/OavContractListByApporteur';

const ReseauListPage = () => {
  const { register, controls } = Block.useBlocks();
  const [networkApporteurData, setNetworkApporteurData] = useState(null);

  return (
    <>
      { networkApporteurData && (
        <Page title={(
          <div className="flex">
            <img
              className="pr-3 cursor-pointer mt-2 h-6 w-6"
              src="/icons/back.svg"
              alt="Icone flèche de retour"
              onClick={() => setNetworkApporteurData(null)}
            />
            Fiche apporteur
          </div>
        )}>
          <Block name="apporteur-detail" color="pink" icon="apporteurs.svg" title="Informations du compte"
            register={register({ opened: true, canClose: false })}
            controls={controls}
          >
            <ApporteurDetail apporteur={networkApporteurData} />
          </Block>
          <Block name="apporteur-contracts" color="pink" title="Contrats en cours"
            register={register({ opened: true, canClose: false })}
            controls={controls}
          >
            <OavContractListByApporteur apporteur={networkApporteurData}/>
          </Block>
        </Page>
      )}
      <div className={networkApporteurData ? 'hidden' : ''} >
        <Page title="Réseau">
          <Block name="apporteur-list" icon="apporteurs.svg" title="Mes apporteurs" color="pink"
            register={register({ opened: true, canClose: false })}
            controls={controls}
          >
            <QNetworkApporteurs>
              {({ networkApporteurs }) => (<ApporteurList apporteurs={networkApporteurs} onSelect={setNetworkApporteurData} />)
              }
            </QNetworkApporteurs>
          </Block>
          <Block name="add-apporteur" color="pink" icon="add-apporteur.svg" title="Ajouter un apporteur"
            register={register({ opened: true, canClose: false })}
            controls={controls}
          >
            <AddApporteurBlock />
          </Block>
        </Page>
      </div>
    </>
  );
};

export default ReseauListPage;
