import React from 'react';
import propTypes from 'prop-types';
import classNames from 'classnames';
import Moment from 'moment';
import PillTypeApporteur from '../../components/PillTypeApporteur';
import Progress from '../../../../components/Progress';
import { compose, groupBy, prop, map, evolve, keys, isEmpty, cond, equals, always, T } from 'ramda';
import { Pagination } from '../../../../components';

const OavContractListDetailByApporteur = ({ contracts, setPage, setNbItemPerPage }) => {
  const { count, page, nbPages, data, nbItemPerPage } = contracts;

  const contractList = compose(
    groupBy(prop('date')),
    map(evolve({ date: (d) => Moment(d).format('YYYY-MM') }))
  )(data);

  const toStatusLabel = cond([
    [equals('IN_PROGRESS'), always('En cours')],
    [equals('ACTIVE'), always('Actif')],
    [T, always('En cours')]
  ]);

  const onChangePage = (currentPage) => setPage(currentPage - 1);

  return (
    <div>
      {isEmpty(contractList) ? (
        <p className='mb-2'>Aucun contrat en cours</p>
      ) : (
        <>
          <table className='w-full my-2 mb-4'>
            <thead>
              <tr className='text-grey-blue text-left font-bold uppercase text-sm md:text-base'>
                <th>Apporteur</th>
                <th>Souscripteur</th>
                <th>Statut du contrat</th>
              </tr>
            </thead>
            <tbody className='divide-solid text-plum'>
              {keys(contractList).map((key) => {
                return (
                  <React.Fragment key={key}>
                    <tr className='text-left capitalize border-t-2 first:border-0 border-gray-200'>
                      <th colSpan={3}>
                        {Moment(key, 'YYYY-MM').format('MMMM YYYY')}
                      </th>
                    </tr>
                    {prop(key, contractList).map((row, i) => (
                      <tr
                        key={i}
                        className={classNames({ 'bg-gray-200': i % 2 })}
                      >
                        <td className='py-2'>{row.apporteur}</td>
                        <td>
                          {row.souscripteur}
                          <PillTypeApporteur type={row.type} />
                        </td>
                        <td>
                          <div className='flex items-center text-left md:block'>
                            <Progress value={row.status.percent} />
                            <span className='w-150'>{toStatusLabel(row.status.status)}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          {nbPages > 1 ? (
            <Pagination
              onChange={onChangePage}
              current={page + 1}
              pageSize={nbItemPerPage}
              total={count}
            />
          ) : (
            ''
          )}
        </>
      )}
    </div>
  );
};

OavContractListDetailByApporteur.propTypes = {
  contracts: propTypes.shape({
    page: propTypes.number,
    count: propTypes.number,
    nbItemPerPage: propTypes.number,
    nbPages: propTypes.number,
    data: propTypes.arrayOf(
      propTypes.shape({
        apporteur: propTypes.string,
        college: propTypes.string,
        contractNumber: propTypes.number,
        cotisation: propTypes.any,
        date: propTypes.string,
        name: propTypes.any,
        num: propTypes.number,
        soldeContract: propTypes.any,
        souscripteur: propTypes.string,
        status: propTypes.shape({
          percent: propTypes.number,
          status: propTypes.string
        }),
        taux: propTypes.string,
        type: propTypes.string
      })
    )
  }),
  apporteurLogin: propTypes.string,
  setPage: propTypes.func,
  setNbItemPerPage: propTypes.func
};

export default OavContractListDetailByApporteur;
