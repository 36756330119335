import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'ramda';

const Popover = ({ children, content }) => {
  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const shouldShowTooltip = isHover && !isEmpty(content);

  const tooltipClasses = classNames(
    'absolute',
    'bg-gray-200 rounded-lg p-4 shadow-lg z-10 ',
    {
      visible: shouldShowTooltip,
      hidden: !shouldShowTooltip
    }
  );

  return (
    <div className="overflow-visible relative" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div style={{ width: '24rem', left: '4rem' }} className={tooltipClasses}>{content}</div>
      <div>{children}</div>
    </div>
  );
};

Popover.propTypes = {
  content: PropTypes.string
};

export default Popover;
