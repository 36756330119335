/**
 * @flow
 * @relayHash eed5fc2386bc5b3bb17345776934b22e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QDocumentsByAffiliateIdQueryVariables = {|
  affiliateId: string,
  type?: ?string,
  partyId?: ?string,
  status?: ?string,
  direction?: ?string,
  page?: ?string,
|};
export type QDocumentsByAffiliateIdQueryResponse = {|
  +documentsByAffiliateId: ?{|
    +ok: ?boolean,
    +error: ?string,
    +documents: ?$ReadOnlyArray<?{|
      +name: string,
      +documentId: string,
      +isGlobal: ?string,
      +type: ?string,
      +effectDate: ?string,
    |}>,
    +pagination: ?{|
      +total: number,
      +limit: number,
      +page: number,
      +pages: number,
    |},
  |}
|};
export type QDocumentsByAffiliateIdQuery = {|
  variables: QDocumentsByAffiliateIdQueryVariables,
  response: QDocumentsByAffiliateIdQueryResponse,
|};
*/


/*
query QDocumentsByAffiliateIdQuery(
  $affiliateId: String!
  $type: String
  $partyId: String
  $status: String
  $direction: String
  $page: String
) {
  documentsByAffiliateId(affiliateId: $affiliateId, type: $type, partyId: $partyId, status: $status, direction: $direction, page: $page) {
    ok
    error
    documents {
      name
      documentId
      isGlobal
      type
      effectDate
    }
    pagination {
      total
      limit
      page
      pages
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "affiliateId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "direction"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "partyId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "affiliateId",
        "variableName": "affiliateId"
      },
      {
        "kind": "Variable",
        "name": "direction",
        "variableName": "direction"
      },
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "partyId",
        "variableName": "partyId"
      },
      {
        "kind": "Variable",
        "name": "status",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "DocumentsByAffilateIdPaginated",
    "kind": "LinkedField",
    "name": "documentsByAffiliateId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Document",
        "kind": "LinkedField",
        "name": "documents",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "documentId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isGlobal",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "effectDate",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Pagination",
        "kind": "LinkedField",
        "name": "pagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "limit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pages",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QDocumentsByAffiliateIdQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "QDocumentsByAffiliateIdQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "id": "eed5fc2386bc5b3bb17345776934b22e",
    "metadata": {},
    "name": "QDocumentsByAffiliateIdQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c52be7e92b7dacf7377d188a4f342019';

module.exports = node;
