import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Block } from '@@components';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import useCompany from '@@hooks/useCompany';

import * as R from 'ramda';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import useFeedback from '../../../hooks/useFeedback';

const ProfilEditBlock = ({ userSession }) => {
  const { company } = useCompany();

  const getDefaultValue = () =>
    R.evolve(
      {
        fiscalDate: R.when(R.complement(R.isNil), (d) => Date.parse(d))
      },
      {
        accountType: R.prop('companyType', userSession),
        ...R.pathOr({}, ['tiersDetails'], userSession),
        ...R.pathOr({}, ['tiersDetails', 'address'], userSession),
        ...R.pathOr({}, ['details'], company)
      }
    );

  const { fetching, onFormsSubmit, formsData } = useForms('updateTiersProfil');
  const form = useForm({ defaultValues: getDefaultValue() });

  useFeedback(fetching, {
    successMessage: 'Votre demande de modification a été envoyée et sera prise en compte dans les meilleurs délais par nos équipes.'
  });

  const onSubmitHandle = (data) => {
    onFormsSubmit(
      R.evolve(
        {
          fiscalDate: (v) => moment(v).format('YYYY-MM-DD')
        },
        data
      )
    );
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmitHandle)}>
      <Block title="Mes coordonnées de correspondance" color="pink">
        <Forms
          columns={2}
          formsData={formsData}
          submitSection={() => (
            <button aria-label="Sauvegarder les modifications" type="submit" className="f-button f-button-pink">
              {'Sauvegarder les modifications'}
            </button>
          )}
          wrapInForm={false}
          forwardedUseForm={form}
        />
      </Block>
    </form>
  );
};

ProfilEditBlock.propTypes = {
  userSession: PropTypes.object.isRequired
};

ProfilEditBlock.defaultProps = {};

export default ProfilEditBlock;
