import { Message } from '@@components';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import * as R from 'ramda';
import React from 'react';

const addMinMaxParamsToFormsDataDate = (dataParamName) =>
  R.map(R.when(R.propEq('name', dataParamName), R.compose(R.assoc('maxDate', null), R.assoc('minDate', new Date()))));

const ApporteurBlock = () => {
  const { fetching, formsData, onFormsSubmit } = useForms('addApporteur');

  const updatedFormsData = addMinMaxParamsToFormsDataDate('openning_date')(formsData);

  const onSubmit = (apporteurInfo) => {
    onFormsSubmit(apporteurInfo);
  };

  if (fetching.isDone()) {
    return (
      <Message value="Demande de création d'un nouveau compte envoyée" action='Ok' onClick={() => fetching.reset()} />
    );
  }

  if (fetching.isDoneWithError()) {
    const error = fetching.getError();
    return <Message value={error} action='Ok' onClick={() => fetching.reset()} />;
  }

  return (
    <Forms
      formsData={updatedFormsData}
      onSubmit={onSubmit}
      defaultValues={{ openning_date: null }}
      submitSection={() => (
        <button aria-label='Ajouter apporteur' type='submit' className='f-button f-button-pink'>
          Ajouter cet apporteur
        </button>
      )}
    />
  );
};

export default ApporteurBlock;
