import * as R from 'ramda';
import moment from 'moment';

export const DateSort = (row1, row2, column) => {
  const date1 = R.path(['values', column], row1);
  const date2 = R.path(['values', column], row2);
  const moment1 = moment(date1, 'DD/MM/YYYY');
  const moment2 = moment(date2, 'DD/MM/YYYY');
  if (moment1.isBefore(moment2)) return -1;
  if (moment1.isAfter(moment2)) return 1;
  return 0;
};
