/**
 * @flow
 * @relayHash e75b9b737f96de8192b91a151b45ef1c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TitleEnum = "Madame" | "Monsieur" | "%future added value";
export type AffilieDeclarePortabiliteInput = {|
  address?: ?string,
  birthDate?: ?string,
  city?: ?string,
  contractId?: ?string,
  firstname?: ?string,
  lastname?: ?string,
  nir?: ?string,
  postCode?: ?string,
  reason?: ?string,
  releaseDate?: ?string,
  title?: ?TitleEnum,
|};
export type AffilieDeclarePortabiliteMutationVariables = {|
  input: AffilieDeclarePortabiliteInput
|};
export type AffilieDeclarePortabiliteMutationResponse = {|
  +affilieDeclarePortabilite: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type AffilieDeclarePortabiliteMutation = {|
  variables: AffilieDeclarePortabiliteMutationVariables,
  response: AffilieDeclarePortabiliteMutationResponse,
|};
*/


/*
mutation AffilieDeclarePortabiliteMutation(
  $input: AffilieDeclarePortabiliteInput!
) {
  affilieDeclarePortabilite(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AffilieDeclarePortabiliteMutationResponse",
    "kind": "LinkedField",
    "name": "affilieDeclarePortabilite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AffilieDeclarePortabiliteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AffilieDeclarePortabiliteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "e75b9b737f96de8192b91a151b45ef1c",
    "metadata": {},
    "name": "AffilieDeclarePortabiliteMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8607612b2a29ef13de20bc67453b2a01';

module.exports = node;
