const familySituation = [
  { label: 'Célibataire', value: 'SINGLE' },
  { label: 'Marié', value: 'MARRY' },
  { label: 'PACS', value: 'CIVIL_UNION' },
  { label: 'Concubinage', value: 'COHABITATION' },
  { label: 'Veuf', value: 'WIDOVER' },
  { label: 'Divorcé', value: 'DIVORCED' },
  { label: 'Séparé', value: 'SEPARATED' }
];

export default familySituation;
