import React from 'react';
import { Page, Block } from '@@components';
import QApporteur from '@@graphql/queries/QApporteur';
import ApporteurDetail from '../containers/reseau/ApporteurDetail';
import { useParams, useHistory } from 'react-router-dom';

const ReseauDetailPage = () => {
  const { register, controls } = Block.useBlocks();

  const { id } = useParams();
  const history = useHistory();

  return (
    <Page title={(
      <div className="flex">
        <img
          className="pr-3 cursor-pointer mt-2 h-6 w-6"
          src="/icons/back.svg"
          onClick={() => history.goBack()}
        />
      Fiche apporteur
      </div>
    )}>
      <Block name="apporteur-detail" color="pink" icon="apporteurs.svg" title="Informations du compte"
        register={register({ opened: true, canClose: false })}
        controls={controls}
      >
        <QApporteur args={{ id }}>
          {({ apporteur }) => (<ApporteurDetail apporteur={apporteur} />)}
        </QApporteur>
      </Block>
    </Page>
  );
};

export default ReseauDetailPage;
