import React, { useEffect, useState } from 'react';
import { Block } from '@@components';
import downloadDocument from '../../../lib/downloadDocument';
import ColoredIcon from '../../../components/ColoredIcon';
import { dateTransform } from '../../../lib/transformers';
import Loading from '../../../components/Loading';
import { useDocumentsByAffiliateId } from '../../../hooks';
import useCompany from '../../../hooks/useCompany';
import { gt, inc, prop, length, isEmpty } from 'ramda';

const DecompteDetail = ({ affiliate }) => {
  const [documents, setDocuments] = useState([]);
  const [currentPage, setCurrentPAge] = useState(1);

  const { company } = useCompany();
  const partyId = prop('partyId', company);

  const affiliateId = prop('affiliateId', affiliate);
  const { data, isLoaded } = useDocumentsByAffiliateId({ affiliateId, partyId, type: '129', status: '1', direction: '1', page: currentPage });

  const pagination = prop('pagination', data);

  useEffect(() => {
    const affiliateDocuments = prop('documents', data);
    setDocuments(affiliateDocuments);
  }, [currentPage, isLoaded]);

  const onDownloadDocument = (document) => () => {
    downloadDocument(`/documents/download/${document.documentId}?isGlobal=${document.isGlobal}&type=${document.type}`);
  };

  const hasMoreDocumentsToDisplay = gt(prop('pages', pagination), prop('page', pagination));

  const displayMoreDocuments = () => {
    const actualPage = prop('page', pagination);
    const nextPage = inc(actualPage);
    setCurrentPAge(nextPage);
  };

  const hasManyDocuments = gt(length(documents), 1);
  const hasNoDocuments = isEmpty(documents);

  const décomptesDePrestationsTitle = hasManyDocuments ? 'Décomptes de prestations' : 'Décompte de prestation';

  return (
    <Block name="informations-list" color="pink" icon="menu-prestas.svg" title={'Décomptes de prestations'}>
      <div className="document text-plum font-bold select-none">
        {!isLoaded && <Loading />}
        {isLoaded && <div>
          {hasNoDocuments
            ? <p>Aucun document disponible</p>
            : <>
              <ColoredIcon
                icon="icon-documents.svg"
                color="pink"
                className="document-icon"
                height="64px"
                width="64px"
              />
              <p>{décomptesDePrestationsTitle}</p>
              <div className="document-name">
                <ul>
                  {documents.map((document, index) => {
                    return (
                      <div key={index} className="text-xs cursor-pointer" onClick={onDownloadDocument(document)}>
                      • {dateTransform(document.effectDate)} <span className="text-pink">↓ </span>
                      </div>
                    );
                  })}
                </ul>
                {hasMoreDocumentsToDisplay && <div onClick={displayMoreDocuments}>
                      Afficher plus de documents</div>}
              </div>
            </>}
        </div>}
      </div>

    </Block>
  );
};
export default DecompteDetail;
