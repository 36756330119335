import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import propTypes from 'prop-types';

import S from 'string';
import classNames from 'classnames';
import ColoredIcon from '../ColoredIcon';
import useUser from '../../hooks/useUser';
import colors from '@@refs/colors';

const R = require('ramda');
const Menu = ({ items, mode }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { onSignOut } = useUser();

  return (
    <ul className="f-menu">
      {
        R.compose(
          R.map(({ label, path, icon, showInMenu = true }, index) => (
            <li
              key={label}
              onClick={() => history.push(path)}
              className={classNames({ 'f-menu-item-selected': S(pathname).startsWith(path) })}
            >
              <ColoredIcon
                width={24}
                height={24}
                icon={icon}
                className="inline-block mx-1"
                color={S(pathname).startsWith(path) ? colors.pink : colors.gray}
              />

              {label}
            </li>
          )),
          R.filter(R.propEq('showInMenu', true))
        )(items)
      }

      <li className="profil-menu">
        <ul className="flex items-center">
          <li className="border-none inline-block hover:text-pink'" onClick={onSignOut}>Me déconnecter</li>
          {/* <li className="border-none inline-block">|</li>
          <li className={classNames('border-none inline-block hover:text-pink', { 'f-menu-item-selected-text': S(pathname).startsWith('/faq') })} onClick={() => history.push('/faq')}>faq</li> */}
          {mode !== 'apporteur' && (<>
            <li className="border-none inline-block">|</li>
            <li
              className={classNames('border-none inline-block hover:text-pink', { 'f-menu-item-selected-text': S(pathname).startsWith('/monprofil') })}
              onClick={() => history.push('/monprofil')}
            >
              <img src="/icons/icon-neutre.svg" className="float-left" alt="Icone profil"/>
              Mon profil
            </li>
          </>
          )}
        </ul>
      </li>
    </ul>
  );
};

Menu.propTypes = {
  items: propTypes.arrayOf(propTypes.shape({
    label: propTypes.string,
    path: propTypes.string,
    icon: propTypes.string
  }))
};

export default Menu;
