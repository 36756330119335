import useFetchQuery from './useFetchQuery';
import { QCollegesQuery } from '../_graphql/queries/QColleges';

const useColleges = () => {
  const { colleges, isLoaded } = useFetchQuery({
    query: QCollegesQuery,
    dataProp: 'colleges'
  });

  return { colleges, isLoaded };
};

export default useColleges;
