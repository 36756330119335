import rolesList from './rolesList';
import * as R from 'ramda';

const documentTypeIncapaciteOptions = [
  {
    label: 'Décompte de paiement ou de non paiement des indemnités journalières servies par la Sécurité sociale depuis le 1er jour d’arrêt de travail',
    value: 'decompte',
    required: true
  },
  {
    label: 'Une attestation relative aux modalités de prise en charge de l’arrêt sur les 12 mois glissants pour les CCN',
    value: 'attestation',
    required: false
  },
  {
    label: 'Copie de de la déclaration établie pour la Sécurité Sociale',
    value: 'workRestartPartialPJ',
    required: false,
    only: R.flatten([rolesList.TNS])
  },
  {
    label: 'Photocopie de la lettre recommandée',
    value: 'leavingPJ',
    required: false,
    only: R.flatten([rolesList.TNS])
  },
  {
    label: 'Relevé d\'identité bancaire',
    value: 'rib',
    required: false
  }

];

export default documentTypeIncapaciteOptions;
