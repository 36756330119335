import React, { useState } from 'react';
import propTypes from 'prop-types';
import QOavContractsListByApporteur from '../../../../_graphql/queries/QOavContractsListByApporteur.graphql';
import OavContractListDetailByApporteur from './OavContractListDetailByApporteur';

const OavContractListByApporteur = ({ apporteur }) => {
  const [page, setPage] = useState(0);
  const [nbItemPerPage, setNbItemPerPage] = useState(20);

  return (
    <QOavContractsListByApporteur args={{ apporteur: apporteur.login, page, nbItemPerPage }}>
      {({ oavContractsListByApporteur }) => (
        <OavContractListDetailByApporteur
          contracts={oavContractsListByApporteur}
          setPage={setPage}
          setNbItemPerPage={setNbItemPerPage}
        />
      )}
    </QOavContractsListByApporteur>
  );
};

OavContractListByApporteur.propTypes = {
  apporteur: propTypes.shape({
    id: propTypes.string,
    email: propTypes.string,
    firstname: propTypes.string,
    lastname: propTypes.string,
    login: propTypes.string,
    openning_date: propTypes.string
  })
};

export default OavContractListByApporteur;
