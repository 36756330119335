import React from 'react';
import propTypes from 'prop-types';

import ErrorMessage from './helpers/ErrorMessage';
import classNames from 'classnames';
import { INPUT_FIELD_PROPTYPES } from '../../../propTypes/form/InputPropTypes';

const R = require('ramda');

const Text = ({ register, field, errors }) => {
  const hasError = R.keys(errors).includes(field.name);

  const classes = classNames({
    'f-input-red': hasError,
    'f-input': !hasError,
    'f-input-disabled': field.disabled
  });

  return (<>
    <input className={classes} ref={register({ required: field.required })} readOnly={field.disabled} name={field.name} placeholder={field.placeholder}/>
    <ErrorMessage field={field} errors={errors}/>
  </>
  );
};

Text.propTypes = {
  register: propTypes.func,
  field: propTypes.shape(INPUT_FIELD_PROPTYPES),
  errors: propTypes.object
};

export default Text;
