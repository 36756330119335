import React from 'react';
import { graphql } from 'react-relay';

import Query from '../Query';
import {
  populateChildren
} from './toolbox';

export const QChartApporteurQuery = graphql`
  query QChartApporteurQuery($year: Int!, $type: ContractTypeEnum) {
    apporteurContractsStats(year: $year, type: $type) {
      currentYear {
        contractsPotentiels
        contractsActifs
      }
      previousYear {
        contractsPotentiels
        contractsActifs
      }
    }
  }
`;

const QChartApporteur = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  const variables = {
    range: args.range,
    year: args.year,
    type: args.type,
    apporteurs: args.apporteurs
  };

  return (
    <Query
      query={QChartApporteurQuery}
      args={variables}
      mockKey="QChartApporteur"
      mockData={mockData}
    >
      {populateChildren(['apporteurContractsStats'])(children, childDataProp)}
    </Query>);
};

export default QChartApporteur;
