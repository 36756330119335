/**
 * @flow
 * @relayHash 45ee3aa940a6490c97b18e2c86da12ce
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type onModifCoordonnesBancairesMutationVariables = {|
  email: string
|};
export type onModifCoordonnesBancairesMutationResponse = {|
  +onModifCoordonnesBancaires: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type onModifCoordonnesBancairesMutation = {|
  variables: onModifCoordonnesBancairesMutationVariables,
  response: onModifCoordonnesBancairesMutationResponse,
|};
*/


/*
mutation onModifCoordonnesBancairesMutation(
  $email: String!
) {
  onModifCoordonnesBancaires(email: $email) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "EmailMutationResponse",
    "kind": "LinkedField",
    "name": "onModifCoordonnesBancaires",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "onModifCoordonnesBancairesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "onModifCoordonnesBancairesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "45ee3aa940a6490c97b18e2c86da12ce",
    "metadata": {},
    "name": "onModifCoordonnesBancairesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a2bea8b8c1788515fc68088a3e3f8098';

module.exports = node;
