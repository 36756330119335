import React, { createContext, useEffect, useState } from 'react';
import { fetchQuery, graphql } from 'relay-runtime';
import { allPass, compose, isNil, not, prop } from 'ramda';
import { isNotNil, notEqual } from 'ramda-adjunct';
import environment from '../_graphql';
import withUser from '../pages/userSession/withUser';

export const CompanyContext = createContext({});

const baseQuery = graphql`
  query CompanyContextQuery{
    company {
      id
      partyId
      title
      firstname
      name
      siret
      banner
      fiscalDate
      bankAccounts {
        holder
        bic
        iban
        type
      }
      details {
        contactName
        phone
        email
        socialInsuranceNumber
        birthDate
      }
      contracts {
        id
        contractId
        contractIdCrypt
        type
        status

        info {
          college
          dateEffet
          tauxCotisation
          nomProduit
          paymentType
          dsn
          address
          paymentMode
          rib {
            label
            IBAN
            BIC
          }
        }

        garanties {
          name
        }

        billings {
          id
          dates {
            startDate
            endDate
          }
          contract
          total
          lastPayment
          status
        }

        affiliates {
          id
          baseId
          affiliateIdCrypt
          affiliateId
          contractId
          contractIdCrypt
          externalContractId
          firstName
          lastName
          dateEffet
          status
          nir
          birthDate
          employmentDate
          terminationDate
        }

        documents {
          name
          documentId
          isGlobal
          type
          effectDate
        }
      }
    }
  }
`;

const CompanyProvider = ({ user, children }) => {
  const [company, setCompany] = useState(null);

  const userId = prop('id', user);
  const userRole = prop('role', user);

  const userIdNotNil = isNotNil(userId);

  const isNotEmployee = allPass([
    notEqual('MANAGER'),
    notEqual('APPORTEUR'),
    notEqual('ADMIN')
  ])(userRole);

  useEffect(() => {
    const getCompany = async () => {
      setCompany();
      try {
        if (compose(not, isNil, prop('id'))(user)) {
          const { company: data } = await fetchQuery(environment(), baseQuery);
          setCompany(data);
        }
      } catch (err) {
        return null;
      }
    };
    if (userIdNotNil && isNotEmployee) {
      getCompany();
    } else {
      setCompany({});
    }
  }, [user.username, setCompany]);

  return (
    <CompanyContext.Provider value={{ company }}>
      {children}
    </CompanyContext.Provider>
  );
};

export default withUser(CompanyProvider);
