/**
 * @flow
 * @relayHash c3e1a867f545e175550b5cc833ece800
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QApporteurReferentialsAccessQueryVariables = {||};
export type QApporteurReferentialsAccessQueryResponse = {|
  +apporteurReferentialsAccess: ?{|
    +commissions: ?boolean
  |}
|};
export type QApporteurReferentialsAccessQuery = {|
  variables: QApporteurReferentialsAccessQueryVariables,
  response: QApporteurReferentialsAccessQueryResponse,
|};
*/


/*
query QApporteurReferentialsAccessQuery {
  apporteurReferentialsAccess {
    commissions
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ApporteurReferentialsAccess",
    "kind": "LinkedField",
    "name": "apporteurReferentialsAccess",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "commissions",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QApporteurReferentialsAccessQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QApporteurReferentialsAccessQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "c3e1a867f545e175550b5cc833ece800",
    "metadata": {},
    "name": "QApporteurReferentialsAccessQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c99de630d3fe439bc3ffbd0fd839e4c9';

module.exports = node;
