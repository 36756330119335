import { useContext } from 'react';
import { CompanyContext } from '../contexts/CompanyContext';
import { getAffiliates, getAffiliate, getBillings, getContracts, getAffiliatesByContractId, getDocuments, getDocumentsByContractId, getBankAccounts } from '../lib/company';

const useCompany = () => {
  const { company } = useContext(CompanyContext);

  return {
    company,
    getContracts: () => getContracts(company),
    getAffiliates: () => getAffiliates(company),
    getAffiliatesByContractId: (contractId) => getAffiliatesByContractId(contractId)(company),
    getAffiliate: (contractIdCrypt, affiliateId) => getAffiliate(contractIdCrypt, affiliateId)(company),
    getBillings: () => getBillings(company),
    getDocuments: () => getDocuments(company),
    getDocumentsByContractId: (contractId) => getDocumentsByContractId(contractId)(company),
    getBankAccounts: () => getBankAccounts(company)
  };
};

export default useCompany;
