import React from 'react';

import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { useFeedback } from '@@hooks';

const TiersContactBlock = ({ contact }) => {
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('tiersContactBlock');
  useFeedback(fetching);

  const onSubmit = (data) => {
    onFormsSubmit({
      name: data.name,
      phone: data.phone,
      email: data.email
    });
  };

  return <Forms
    formsData={formsData}
    onSubmit={onSubmit}
    defaultValues={contact}
    submitSection={() => (
      <>
        <button aria-label="Sauvegarder les modifications" type="submit" className="f-button f-button-pink">{'Sauvegarder les modifications'}</button>
      </>
    )}
  />;
};

export default TiersContactBlock;
