/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [

{
name: 'ribs',
required: false,
input: 'ribList',
source: '__UNDEFINED__',
label: " ",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

];

export default formData;