import React from 'react';
import { Controller } from 'react-hook-form';
import { RadioInput } from '@@components/input';
import ErrorMessage from './helpers/ErrorMessage';
import { INPUT_DEFAULT_PROPTYPES } from '../../../propTypes/form/InputPropTypes';

const Input = ({ field, control, options, errors }) => {
  return (<Controller
    name={field.name}
    control={control}
    rules={{ required: field.required }}
    render={({ onChange, value }) => (<>
      <RadioInput
        onChange={(v) => onChange(v.value)}
        name={field.name}
        value={value}
        options={options[field.source]}/>
      <ErrorMessage field={field} errors={errors} disabled={field.disabled} />
    </>)}
  />);
};

Input.propTypes = INPUT_DEFAULT_PROPTYPES;

export default Input;
