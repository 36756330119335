import './index.less';
import React from 'react';
import classNames from 'classnames';

import useCompany from '../hooks/useCompany';

const R = require('ramda');

const Loader = () => (
  <div className="f-layout-row">
    <div className="f-loader">
      <h1 className="f-block-title text-plum text-center">
        Veuillez patienter pendant le chargement de vos informations
      </h1>
      <img src="/icons/spinner.svg" alt="Cercle de chargement" width="100px" />
    </div>
  </div>
);

const Layout = ({
  top,
  menu,
  children,
  showMenu,
  footer,
  cookieSquarespace
}) => {
  const menuClasses = classNames('f-layout-menu', {
    show: showMenu
  });

  const { company } = useCompany();
  return (
    <div className="f-layout">
      <div className="f-layout-top">{top}</div>
      {company
        ? <div className="f-layout-row">
          {!R.isNil(menu) && <div className={menuClasses}>{menu}</div>}
          <div className='f-layout-body'>
            {children}
            {footer}
          </div>
        </div>
        : <Loader />
      }
      {cookieSquarespace}
    </div>
  );
};

export default Layout;
