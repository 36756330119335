import React from 'react';
import { Page } from '@@components';
import { DocumentsBlock } from '../containers';
import useCompany from '../../../hooks/useCompany';

const Index = () => {
  const { getContracts } = useCompany();

  return (
    <Page title={'Mon coffre-fort'}>
      <DocumentsBlock contracts={getContracts()} />
    </Page>
  );
};

export default Index;
