import rolesList from './rolesList';

const exchangeSubjects = [{
  label: 'Mes paiements',
  value: 'payments',
  roles: [rolesList.COMPANY, rolesList.TNS]
}, {
  label: 'Mon contrat',
  value: 'contracts',
  roles: [rolesList.COMPANY, rolesList.TNS]
}, {
  label: 'Activation de la DSN',
  value: 'enablingDsn',
  roles: [rolesList.COMPANY]
}, {
  label: 'Mes coordonnées de prélèvement de cotisations',
  value: 'addBankDebitDetails',
  roles: [rolesList.COMPANY, rolesList.TNS]
}, {
  label: 'Mes coordonnées de virement de prestations',
  value: 'addTransferDetails',
  roles: [rolesList.COMPANY, rolesList.TNS]
}];

export default exchangeSubjects;
