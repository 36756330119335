/**
 * @flow
 * @relayHash 0578c79dcdb9f5586c2d1a6891de60cb
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TypeSouscripteurEnum = "COMPANY" | "PARTICULAR" | "TNS" | "%future added value";
export type QApporteurContractDetailQueryVariables = {|
  id?: ?string,
  type?: ?string,
|};
export type QApporteurContractDetailQueryResponse = {|
  +apporteurContractDetail: ?{|
    +num: ?string,
    +unencryptedId: ?number,
    +idCrypt: ?string,
    +social: ?string,
    +productName: ?string,
    +identity: ?string,
    +cotisation: ?number,
    +dateEffect: ?string,
    +soldeContract: ?number,
    +typeContract: ?TypeSouscripteurEnum,
    +tauxCotisation: ?{|
      +codeId: ?number,
      +rates: ?$ReadOnlyArray<?number>,
      +contributionLongName: ?string,
    |},
    +college: ?string,
    +garantyList: ?$ReadOnlyArray<?string>,
    +apporteurName: ?string,
    +statusContract: ?string,
    +dsnEnabled: ?boolean,
  |}
|};
export type QApporteurContractDetailQuery = {|
  variables: QApporteurContractDetailQueryVariables,
  response: QApporteurContractDetailQueryResponse,
|};
*/


/*
query QApporteurContractDetailQuery(
  $id: String
  $type: String
) {
  apporteurContractDetail(id: $id, type: $type) {
    num
    unencryptedId
    idCrypt
    social
    productName
    identity
    cotisation
    dateEffect
    soldeContract
    typeContract
    tauxCotisation {
      codeId
      rates
      contributionLongName
    }
    college
    garantyList
    apporteurName
    statusContract
    dsnEnabled
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "type"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "ApporteurContractDetail",
    "kind": "LinkedField",
    "name": "apporteurContractDetail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "num",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "unencryptedId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "idCrypt",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "social",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "identity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cotisation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dateEffect",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "soldeContract",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "typeContract",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CotisationType",
        "kind": "LinkedField",
        "name": "tauxCotisation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "codeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rates",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contributionLongName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "college",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "garantyList",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "apporteurName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "statusContract",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "dsnEnabled",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QApporteurContractDetailQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QApporteurContractDetailQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "0578c79dcdb9f5586c2d1a6891de60cb",
    "metadata": {},
    "name": "QApporteurContractDetailQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2a8b3e6bb5d584ce6ac906b20afbac45';

module.exports = node;
