import React, { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import QUserSession from '@@queries/QUserSession';
import Moment from 'moment';

const R = require('ramda');

Moment.locale('fr');

const useUser = (value) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (!R.isNil(value)) setUser(value);
  }, [value]);

  return user;
};

const UserSessionMemoized = memo(function UserSession ({
  userSession,
  render
}) {
  const $user = useUser(userSession);
  if (R.isNil($user)) return null;
  if (R.isNil(render)) return null;
  return render({ user: $user });
});

const useUserRefresh = (interval) => {
  const [userRefreshKey, setUserRefreshKey] = useState(0);
  const ref = useRef();
  useEffect(() => {
    ref.current = setInterval(
      () => {
        setUserRefreshKey(R.inc);
      },
      interval
    );
    return () => clearInterval(ref.current);
  });

  return userRefreshKey;
};

const UserSessionWrapper = ({
  refreshInterval,
  children
}) => {
  /**
   * on déclare une variable key qui à chaque fois que sa valeur change va générer une nouvelle requête.
   * on préfère cette solution à l'utilisation d'une prop key car si la clef change les enfants sont détruits.
   */
  return (
    <QUserSession
      args={{
        key: useUserRefresh(refreshInterval)
      }}
    >
      <UserSessionMemoized render={children} />
    </QUserSession>
  );
};

UserSessionWrapper.propTypes = {
  refreshInterval: PropTypes.number
};

UserSessionWrapper.defaultProps = {
  refreshInterval: 600000
};

export default UserSessionWrapper;
