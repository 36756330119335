
class HttpError extends Error {
  constructor (code, ...params) {
    super(...params);

    // Maintenir dans la pile une trace adéquate de l'endroit où l'erreur a été déclenchée (disponible seulement en V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, HttpError);
    }

    this.name = 'HttpError';
    this.code = code;
    this.date = new Date();
  }
}

export class HttpUnauthorized extends HttpError {
  constructor (...params) {
    super(401, ...params);
  }
}

export class HttpNotFound extends HttpError {
  constructor (...params) {
    super(404, ...params);
  }
}

export class HttpGone extends HttpError {
  constructor (...params) {
    super(410, ...params);
  }
}
