import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ContractListPage from './ContractListPage';
import ContractDetailPage from './ContractDetailPage';

const Index = () => {
  return (
    <Switch>
      <Route exact path="/monportefeuille/"><ContractListPage /></Route>
      <Route path="/monportefeuille/:id"><ContractDetailPage /></Route>
    </Switch>
  );
};

export default Index;
