import React from 'react';
import propTypes from 'prop-types';

import ErrorMessage from './helpers/ErrorMessage';
const R = require('ramda');
const Input = ({ register, field, errors }) => {
  const classes = R.keys(errors).includes(field.name) ? 'f-input-red' : 'f-input';
  return (<>
    <input className={classes} ref={register({ required: field.required })} disabled={field.disabled} type="email" name={field.name} placeholder={field.placeholder}/>
    <ErrorMessage field={field} errors={errors} />
  </>);
};

Input.propTypes = {
  field: propTypes.object,
  register: propTypes.func,
  errors: propTypes.object
};

export default Input;
