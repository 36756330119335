import React from 'react';
import { Block } from '@@components';

import useForms from '@@formsHook';
import Forms from '@@components/forms';
import { applySpec, prop, compose } from 'ramda';

const AffilieInformationBlock = ({ affiliate }) => {
  const { formsData, onFormsSubmit } = useForms('updateAffilie');
  const { register, controls } = Block.useBlocks();

  const onSubmit = (data) => {
    onFormsSubmit(data);
  };

  const formatDefault = applySpec({
    firstname: prop('firstName'),
    lastname: prop('lastName'),
    nir: prop('nir'),
    date: compose(date => new Date(date), prop('employmentDate')),
    birthdate: compose(date => new Date(date), prop('birthDate'))
  });

  return affiliate
    ? <Block name="informations-list" color="pink" icon="apporteur.svg" title="Informations" register={register} controls={controls}>
      <Forms
        defaultValues={formatDefault(affiliate)}
        formsData={formsData}
        onSubmit={onSubmit}
        columns={2}
        submitSection={() => {}}
      />
    </Block>
    : <div>Affilie introuvable</div>;
};

export default AffilieInformationBlock;
