import { chain, compose, equals, find, propOr, where, filter, propEq, prop } from 'ramda';

export const getContracts = propOr([], ['contracts']);

export const getAffiliates = compose(
  chain(propOr([], 'affiliates')),
  propOr([], 'contracts')
);

export const getAffiliate = (contractIdCrypt, affiliateId) => compose(
  find(
    where({
      id: equals(affiliateId),
      contractIdCrypt: equals(contractIdCrypt)
    })
  ),
  chain(propOr([], 'affiliates')),
  propOr([], 'contracts')
);

export const getAffiliatesByContractId = (contractId) => compose(
  filter(propEq('contractIdCrypt', contractId)),
  chain(prop('affiliates')),
  prop('contracts')
);

export const getDocuments = compose(
  chain(propOr([], 'documents')),
  propOr([], 'contracts')
);

export const getDocumentsByContractId = (contractId) => compose(
  propOr([], 'documents'),
  find(propEq('contractId', contractId)),
  prop('contracts')
);

export const getBillings = compose(
  chain(propOr([], 'billings')),
  propOr([], 'contracts')
);

export const getBankAccounts = compose(
  propOr([], 'bankAccounts')
);
