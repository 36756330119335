/**
 * @flow
 * @relayHash 14789704fc2ecbc6dd9373c57966429e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QApporteursQueryVariables = {||};
export type QApporteursQueryResponse = {|
  +apporteurs: ?$ReadOnlyArray<?{|
    +networkId: ?string,
    +eltNetworkId: ?string,
    +apportName: ?string,
  |}>
|};
export type QApporteursQuery = {|
  variables: QApporteursQueryVariables,
  response: QApporteursQueryResponse,
|};
*/


/*
query QApporteursQuery {
  apporteurs {
    networkId
    eltNetworkId
    apportName
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "networkId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "eltNetworkId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "apportName",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QApporteursQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Apporteur",
        "kind": "LinkedField",
        "name": "apporteurs",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QApporteursQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Apporteur",
        "kind": "LinkedField",
        "name": "apporteurs",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "14789704fc2ecbc6dd9373c57966429e",
    "metadata": {},
    "name": "QApporteursQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f4f640a22cbaa309a106c1b70af00c40';

module.exports = node;
