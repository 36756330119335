/**
 *
 * WARNING : fichier généré par forms, ne pas le modifier directement
 *
 **/
const R = require('ramda');

import useFetching from '@@hooks/useFetching';

import {

 AddAffilieMutation,

 AddApporteurMutation,

 AffilieDeclarePortabiliteMutation,

 ApporteurContactBlockMutation,

 ApporteurSignUpRequestMutation,

 ApporteurSignUpRequestsMutation,

 ContactBlockMutation,

 DeclareSinistreMutation,

 DeclareSinistreDecesMutation,

 DeclareSinistreIncapaciteMutation,

 DeclareSinistreInvaliditeMutation,

 EditApporteurMutation,

 EditProfilMutation,

 MeBlockMutation,

 RequestPasswordMutation,

 ResetPasswordMutation,

 SignInMutation,

 TestFormMutation,

 TierSignUpRequestsMutation,

 UpdateAffilieMutation,

 UpdateCompanyInformationMutation,

 UpdateTiersInformationMutation,

 UpdateTiersProfilMutation,
} from '@@formsMutations';

import {
  
   addAffilieFormData,
  
   addApporteurFormData,
  
   affilieDeclarePortabiliteFormData,
  
   apporteurContactBlockFormData,
  
   apporteurSignUpRequestFormData,
  
   apporteurSignUpRequestsFormData,
  
   contactBlockFormData,
  
   declareSinistreFormData,
  
   declareSinistreDecesFormData,
  
   declareSinistreIncapaciteFormData,
  
   declareSinistreInvaliditeFormData,
  
   editApporteurFormData,
  
   editProfilFormData,
  
   meBlockFormData,
  
   requestPasswordFormData,
  
   resetPasswordFormData,
  
   signInFormData,
  
   testFormFormData,
  
   tierSignUpRequestsFormData,
  
   updateAffilieFormData,
  
   updateCompanyInformationFormData,
  
   updateTiersInformationFormData,
  
   updateTiersProfilFormData,
  } from '@@formsData';

const handler = {

  addAffilie: {
    mutation: AddAffilieMutation,
    formData: addAffilieFormData
  },

  addApporteur: {
    mutation: AddApporteurMutation,
    formData: addApporteurFormData
  },

  affilieDeclarePortabilite: {
    mutation: AffilieDeclarePortabiliteMutation,
    formData: affilieDeclarePortabiliteFormData
  },

  apporteurContactBlock: {
    mutation: ApporteurContactBlockMutation,
    formData: apporteurContactBlockFormData
  },

  apporteurSignUpRequest: {
    mutation: ApporteurSignUpRequestMutation,
    formData: apporteurSignUpRequestFormData
  },

  apporteurSignUpRequests: {
    mutation: ApporteurSignUpRequestsMutation,
    formData: apporteurSignUpRequestsFormData
  },

  contactBlock: {
    mutation: ContactBlockMutation,
    formData: contactBlockFormData
  },

  declareSinistre: {
    mutation: DeclareSinistreMutation,
    formData: declareSinistreFormData
  },

  declareSinistreDeces: {
    mutation: DeclareSinistreDecesMutation,
    formData: declareSinistreDecesFormData
  },

  declareSinistreIncapacite: {
    mutation: DeclareSinistreIncapaciteMutation,
    formData: declareSinistreIncapaciteFormData
  },

  declareSinistreInvalidite: {
    mutation: DeclareSinistreInvaliditeMutation,
    formData: declareSinistreInvaliditeFormData
  },

  editApporteur: {
    mutation: EditApporteurMutation,
    formData: editApporteurFormData
  },

  editProfil: {
    mutation: EditProfilMutation,
    formData: editProfilFormData
  },

  meBlock: {
    mutation: MeBlockMutation,
    formData: meBlockFormData
  },

  requestPassword: {
    mutation: RequestPasswordMutation,
    formData: requestPasswordFormData
  },

  resetPassword: {
    mutation: ResetPasswordMutation,
    formData: resetPasswordFormData
  },

  signIn: {
    mutation: SignInMutation,
    formData: signInFormData
  },

  testForm: {
    mutation: TestFormMutation,
    formData: testFormFormData
  },

  tierSignUpRequests: {
    mutation: TierSignUpRequestsMutation,
    formData: tierSignUpRequestsFormData
  },

  updateAffilie: {
    mutation: UpdateAffilieMutation,
    formData: updateAffilieFormData
  },

  updateCompanyInformation: {
    mutation: UpdateCompanyInformationMutation,
    formData: updateCompanyInformationFormData
  },

  updateTiersInformation: {
    mutation: UpdateTiersInformationMutation,
    formData: updateTiersInformationFormData
  },

  updateTiersProfil: {
    mutation: UpdateTiersProfilMutation,
    formData: updateTiersProfilFormData
  },

}
const useForms = (formName) => {
  const fetching = useFetching();
 
  const onFormsSubmit = (input) => {
    fetching.start();
    handler[formName].mutation(
      input, 
      (ok, error) => {
        if (!ok) return fetching.stop(R.isNil(error) ? 'undefined_error' : error);
        fetching.stop();
      }
    );
  };

  return {
    fetching,
    onFormsSubmit,
    formsData: handler[formName].formData
  };
 };
 
 export default useForms;
 