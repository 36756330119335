/**
 * @flow
 * @relayHash 866712f5cfd29247662cee2e41a6abd8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type SinistreTypeEnum = "CLAIM_FOR_BENEFITS" | "DECEASE" | "INABILITY" | "INFORMARION" | "OTHER" | "SICK_LEAVE" | "%future added value";
export type DeclareSinistreInput = {|
  sinistre?: ?SinistreTypeEnum
|};
export type DeclareSinistreMutationVariables = {|
  input: DeclareSinistreInput
|};
export type DeclareSinistreMutationResponse = {|
  +declareSinistre: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type DeclareSinistreMutation = {|
  variables: DeclareSinistreMutationVariables,
  response: DeclareSinistreMutationResponse,
|};
*/


/*
mutation DeclareSinistreMutation(
  $input: DeclareSinistreInput!
) {
  declareSinistre(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeclareSinistreMutationResponse",
    "kind": "LinkedField",
    "name": "declareSinistre",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeclareSinistreMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeclareSinistreMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "866712f5cfd29247662cee2e41a6abd8",
    "metadata": {},
    "name": "DeclareSinistreMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bcfea6112b75fe0a1d777f1edaead0fb';

module.exports = node;
