import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

const QApporteursQuery = graphql`
  query QApporteurQuery($id: String) {
    apporteur(id: $id) {
      id
      firstname
      lastname
      email
      network
      openning_date
    }
  }
`;

const QApporteur = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QApporteursQuery}
      args={args}
      mockKey="QApporteur"
      mockData={mockData}
    >
      {populateChildren(['apporteur'])(children, childDataProp)}
    </Query>
  );
};

export default QApporteur;
