/**
 * @flow
 * @relayHash d0f79434603997aba1d5923bf3ff7eac
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TestFormInput = {|
  firstname?: ?string,
  gender?: ?string,
  lastname?: ?string,
|};
export type TestFormMutationVariables = {|
  input: TestFormInput
|};
export type TestFormMutationResponse = {|
  +testForm: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type TestFormMutation = {|
  variables: TestFormMutationVariables,
  response: TestFormMutationResponse,
|};
*/


/*
mutation TestFormMutation(
  $input: TestFormInput!
) {
  testForm(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TestFormMutationResponse",
    "kind": "LinkedField",
    "name": "testForm",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TestFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TestFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "d0f79434603997aba1d5923bf3ff7eac",
    "metadata": {},
    "name": "TestFormMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f89b13d2a9a0b2226aa2a69a2b070a27';

module.exports = node;
