/**
 * @flow
 * @relayHash 992c5136c8858fcc291b386369e4b429
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QAvalaibleYearCommissionsQueryVariables = {||};
export type QAvalaibleYearCommissionsQueryResponse = {|
  +availableYearsCommissions: ?$ReadOnlyArray<?number>
|};
export type QAvalaibleYearCommissionsQuery = {|
  variables: QAvalaibleYearCommissionsQueryVariables,
  response: QAvalaibleYearCommissionsQueryResponse,
|};
*/


/*
query QAvalaibleYearCommissionsQuery {
  availableYearsCommissions
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "availableYearsCommissions",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QAvalaibleYearCommissionsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QAvalaibleYearCommissionsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "992c5136c8858fcc291b386369e4b429",
    "metadata": {},
    "name": "QAvalaibleYearCommissionsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a50cc91ca4611a4e0a2c0bdd89d16596';

module.exports = node;
