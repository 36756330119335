import useFetchQuery from './useFetchQuery';
import { QApporteursQuery } from '../_graphql/queries/QApporteurs';

const useApporteurs = () => {
  const { apporteurs, isLoaded } = useFetchQuery({
    query: QApporteursQuery,
    dataProp: 'apporteurs'
  });

  return { apporteurs, isLoaded };
};

export default useApporteurs;
