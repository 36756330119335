import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

export const QDocumentsByAffiliateIdQuery = graphql`
  query QDocumentsByAffiliateIdQuery($affiliateId: String!, $type: String, $partyId: String, $status: String, $direction: String, $page: String) {
    documentsByAffiliateId(affiliateId: $affiliateId, type: $type, partyId: $partyId, status: $status, direction: $direction, page: $page){
      ok
      error
      documents {
        name
        documentId
        isGlobal
        type
        effectDate
      }
      pagination {
        total
        limit
        page
        pages
      }
    }
  }
`;

export const QDocumentsByAffiliateId = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QDocumentsByAffiliateIdQuery}
      args={args}
      mockKey={'QDocumentsByAffiliateId'}
      mockData={mockData}
    >
      {populateChildren(['documentsByAffiliateId'])(children, childDataProp)}
    </Query>
  );
};
