const genders = [
  {
    label: 'Homme',
    value: 'M'
  },
  {
    label: 'Femme',
    value: 'F'
  }
];

export default genders;
