/**
 * @flow
 * @relayHash ef17e578ec7dd907bfed78525214b820
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ApporteurSignUpRequestInput = {|
  codeApporteur?: ?string,
  codeManager?: ?string,
  email?: ?string,
  isManager?: ?boolean,
  name?: ?string,
  userLogin?: ?string,
|};
export type ApporteurSignUpRequestMutationVariables = {|
  input: ApporteurSignUpRequestInput
|};
export type ApporteurSignUpRequestMutationResponse = {|
  +apporteurSignUpRequest: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type ApporteurSignUpRequestMutation = {|
  variables: ApporteurSignUpRequestMutationVariables,
  response: ApporteurSignUpRequestMutationResponse,
|};
*/


/*
mutation ApporteurSignUpRequestMutation(
  $input: ApporteurSignUpRequestInput!
) {
  apporteurSignUpRequest(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ApporteurSignUpRequestMutationResponse",
    "kind": "LinkedField",
    "name": "apporteurSignUpRequest",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ApporteurSignUpRequestMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ApporteurSignUpRequestMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ef17e578ec7dd907bfed78525214b820",
    "metadata": {},
    "name": "ApporteurSignUpRequestMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1830af483cac91a3bd99e9d9e7309986';

module.exports = node;
