import React from 'react';
import propTypes from 'prop-types';
import { SelectOneOption } from '@@components/input';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

const Attachements = ({
  max,
  files,
  onChange,
  documentTypeOptions
}) => {
  const $onDocumentTypeChange = (uploadId) => ({ value }) => {
    const newFiles = R.map(
      R.when(R.propEq('uploadId', uploadId), R.assoc('documentType', value)),
      files
    );

    onChange(newFiles);
  };

  return (
    <ul className="f-attachments">
      {files.map(({ fileName, uploadId, documentType }) => (
        <li key={uploadId} className="my-2">
          <div>{fileName}</div>
          {!RA.isNilOrEmpty(documentTypeOptions) && (
            <SelectOneOption
              options={documentTypeOptions}
              value={R.find(R.propEq('value', documentType), documentTypeOptions)}
              onChange={$onDocumentTypeChange(uploadId)}
            />
          )}
          <div className="f-attachments-remove cursor-pointer" onClick={() => onChange(R.filter(R.compose(R.not, R.propEq('uploadId', uploadId)), files))}>
            <img src="/icon/cross.svg" alt="Supprimer" />
          </div>
        </li>
      ))
      }
    </ul >
  );
};
Attachements.defaultProps = {
  max: undefined
};

Attachements.propTypes = {
  max: propTypes.func,
  files: propTypes.arrayOf(propTypes.shape({
    uploadId: propTypes.string,
    fileName: propTypes.string,
    documentType: propTypes.string
  })),
  onChange: propTypes.func,
  documentTypeOptions: propTypes.array
};

export default Attachements;
