/**
 * @flow
 * @relayHash 70c628ccc5ead83a127e9780d50a3c41
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EditApporteurInput = {|
  email?: ?string,
  firstname?: ?string,
  id?: ?string,
  lastname?: ?string,
  login?: ?string,
  openning_date?: ?string,
|};
export type EditApporteurMutationVariables = {|
  input: EditApporteurInput
|};
export type EditApporteurMutationResponse = {|
  +editApporteur: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type EditApporteurMutation = {|
  variables: EditApporteurMutationVariables,
  response: EditApporteurMutationResponse,
|};
*/


/*
mutation EditApporteurMutation(
  $input: EditApporteurInput!
) {
  editApporteur(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditApporteurMutationResponse",
    "kind": "LinkedField",
    "name": "editApporteur",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditApporteurMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditApporteurMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "70c628ccc5ead83a127e9780d50a3c41",
    "metadata": {},
    "name": "EditApporteurMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e3563de92b089358c047807334540408';

module.exports = node;
