import React from 'react';
import PropTypes from 'prop-types';
import DoughnutWithText from '../../../../components/DoughnutWithText';
import colors from '@@refs/colors';

const options = {
  cutout: '90%',
  aspectRatio: 1 / 0.5,
  plugins: {
    legend: {
      display: false
    }
  }
};

const getData = (nbContractsActif = 0, nbContractsPotentiel = 0) => ({
  labels: [
    'Nombre de contrats transformés',
    'Nombre de contrats potentiels'
  ],
  datasets: [{
    data: [
      nbContractsActif,
      nbContractsPotentiel
    ],
    backgroundColor: [
      colors.yellow,
      colors.yellowLight
    ]
  }]
});

const ChartRatioContract = ({ nbContractsPotentiel, nbContractsActif, year }) => {
  const percent = (nbContractsActif / (nbContractsPotentiel || 0));

  const doughnutText = `Taux de transformation de ${(percent || 0).toLocaleString(undefined, { style: 'percent' })} des contrats pour ${year}`;

  const data = getData(nbContractsActif, nbContractsPotentiel);
  return (
    <div>
      <DoughnutWithText
        options={options}
        data={data}
        text={doughnutText}
      />
    </div>
  );
};

ChartRatioContract.propTypes = {
  year: PropTypes.number.isRequired,
  nbContractsPotentiel: PropTypes.number,
  nbContractsActif: PropTypes.number
};

ChartRatioContract.defaultProps = {
  nbContractsPotentiel: 0,
  nbContractsActif: 0
};

export default ChartRatioContract;
