import React from 'react';
import propTypes from 'prop-types';

import { Block, Page } from '@@components';
import TiersBankDetailBlock from '../../account/containers/TiersBankDetailBlock';
import useCompany from '../../../hooks/useCompany';
import ProfilEditBlock from '../containers/ProfilEditBlock';
import { QUserTiersDetailsQuery } from '../../../_graphql/queries/QUserTiersDetails';
import withUser from '../../userSession/withUser';
import useFetchQuery from '../../../hooks/useFetchQuery';
import PreventLoading from '../../../components/PreventLoading';
import { USER_PROPTYPES } from '../../../propTypes/user/UserPropTypes';

const Index = ({ user }) => {
  const { getBankAccounts } = useCompany();
  const { tiersDetails, isLoaded } = useFetchQuery({
    query: QUserTiersDetailsQuery,
    dataProp: 'tiersDetails'
  });

  return (
    <Page title="Mon profil">
      <PreventLoading isLoaded={isLoaded}>
        <>
          <ProfilEditBlock userSession={{ ...user, tiersDetails }} />
          <Block
            title="Mes coordonnées bancaires"
            color="pink"
            icon="menu-sinistres.svg"
          >
            <TiersBankDetailBlock bankAccounts={getBankAccounts()} />
          </Block>
        </>
      </PreventLoading>
    </Page>
  );
};

Index.propTypes = USER_PROPTYPES;

export default withUser(Index);
