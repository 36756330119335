import React from 'react';
import ErrorMessage from './helpers/ErrorMessage';
import { Controller } from 'react-hook-form';
import { INPUT_DEFAULT_PROPTYPES } from '../../../propTypes/form/InputPropTypes';

const R = require('ramda');

const NumberInput = ({ register, control, field, errors }) => {
  const classes = R.keys(errors).includes(field.name) ? 'f-input-red' : 'f-input';

  return (<Controller
    name={field.name}
    control={control}
    rules={{ required: field.required, pattern: /^\d*[.,]?\d*$/i }}
    render={({ onChange, value }) => {
      const onChangeHandle = R.compose(
        onChange,
        R.path(['target', 'value'])
      );

      return (
        <>
          <input
            type="text"
            className={classes}
            disabled={field.disabled}
            placeholder={field.placeholder}
            value={value}
            onChange={onChangeHandle}
          />
          <ErrorMessage field={field} errors={errors} />
        </>
      );
    }}
  />);
};

NumberInput.propTypes = INPUT_DEFAULT_PROPTYPES;

export default NumberInput;
