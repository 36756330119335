import propTypes from 'prop-types';

export const INPUT_FIELD_PROPTYPES = {
  input: propTypes.string,
  label: propTypes.string,
  name: propTypes.string,
  placeholder: propTypes.string,
  required: propTypes.bool,
  source: propTypes.string
};

export const INPUT_DEFAULT_PROPTYPES = {
  field: propTypes.shape(INPUT_FIELD_PROPTYPES),
  control: propTypes.object,
  errors: propTypes.object,
  register: propTypes.func
};

export const INPUT_DEFAULT_FILE_PROPTYPES = {
  field: propTypes.shape(INPUT_FIELD_PROPTYPES),
  control: propTypes.object,
  errors: propTypes.object,
  register: propTypes.func,
  getValues: propTypes.func,
  options: propTypes.object
};
