import React from 'react';
import { Block, Page } from '@@components';
import {
  AddApporteurBlock,
  AddApporteursBlock
} from '../containers';
import AddTiersBlock from '../containers/AddTiersBlock';

const Accounts = () => {
  const { register, controls } = Block.useBlocks();
  return (
    <Page title="Gestion des comptes">
      <Block name="new_user" register={register({ canClose: true })} controls={controls} title='Ajouter un manager ou apporteur' color="pink" titleColor="plum">
        <AddApporteurBlock/>
      </Block>
      <Block name="new_users" register={register({ canClose: true })} controls={controls} title='Ajouter plusieurs manager ou apporteurs' color="pink" titleColor="plum">
        <AddApporteursBlock/>
      </Block>
      <Block name="new_tiers" register={register({ canClose: true })} controls={controls} title='Ajouter plusieurs tiers' color="pink" titleColor="plum">
        <AddTiersBlock />
      </Block>
    </Page>
  );
};

export default Accounts;
