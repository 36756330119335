import React from 'react';
import DatePicker from '../../input/DatePicker';
import { Controller } from 'react-hook-form';
import ErrorMessage from './helpers/ErrorMessage';
import { INPUT_DEFAULT_PROPTYPES } from '../../../propTypes/form/InputPropTypes';

const R = require('ramda');

const Input = ({ control, register, field, errors }) => {
  return (<Controller
    control={control}
    name={field.name}
    rules={{ required: field.required }}
    render={({ onChange, value }) => (<>
      <DatePicker
        value={value}
        minDate={R.prop('minDate', field)}
        maxDate={R.prop('maxDate', field)}
        format={R.when(R.isNil, R.always('dd/MM/yyyy'), field.format)}
        disabled={field.disabled}
        onChange={onChange}
        hasError={R.keys(errors).includes(field.name)}
      />
      <ErrorMessage field={field} errors={errors} />
    </>)}
  />);
};

Input.propTypes = INPUT_DEFAULT_PROPTYPES;

export default Input;
