/**
 * @flow
 * @relayHash e00f3747c77cc957f463e7c7657ae317
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type GenderEnum = "F" | "M" | "%future added value";
export type EditProfilInput = {|
  email?: ?string,
  firstname?: ?string,
  gender?: ?GenderEnum,
  lastname?: ?string,
  network?: ?string,
|};
export type EditProfilMutationVariables = {|
  input: EditProfilInput
|};
export type EditProfilMutationResponse = {|
  +editProfil: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type EditProfilMutation = {|
  variables: EditProfilMutationVariables,
  response: EditProfilMutationResponse,
|};
*/


/*
mutation EditProfilMutation(
  $input: EditProfilInput!
) {
  editProfil(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EditProfilMutationResponse",
    "kind": "LinkedField",
    "name": "editProfil",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditProfilMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditProfilMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "e00f3747c77cc957f463e7c7657ae317",
    "metadata": {},
    "name": "EditProfilMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '2eafc21e9fef32baa5c88129232b9e68';

module.exports = node;
