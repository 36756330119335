import propTypes from 'prop-types';
import { BANK_ACCOUNT_PROPTYPES } from './BankPropTypes';
import { CONTRACTS_PROPTYPES } from './ContractsPropTypes';

export const COMPANY_PROPTYPES = {
  bankAccount: propTypes.arrayOf(propTypes.shape(BANK_ACCOUNT_PROPTYPES)),
  contratcs: propTypes.arrayOf(propTypes.shape(CONTRACTS_PROPTYPES)),
  details: propTypes.object,
  firstname: propTypes.string,
  fiscalDate: propTypes.string,
  id: propTypes.string,
  name: propTypes.string,
  siren: propTypes.string,
  title: propTypes.string
};
