/**
 * @flow
 * @relayHash 774e0833a02b858307243b6c6c4f3d56
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployTypeEnum = "CADRE" | "NCADRE" | "%future added value";
export type TypeSouscripteurEnum = "COMPANY" | "PARTICULAR" | "TNS" | "%future added value";
export type QApporteurOavContractsQueryVariables = {|
  page?: ?number,
  nbItemPerPage?: ?number,
|};
export type QApporteurOavContractsQueryResponse = {|
  +apporteurOavContracts: ?{|
    +page: ?number,
    +count: ?number,
    +nbItemPerPage: ?number,
    +nbPages: ?number,
    +data: ?$ReadOnlyArray<?{|
      +contractNumber: ?number,
      +name: ?string,
      +num: ?number,
      +cotisation: ?number,
      +soldeContract: ?number,
      +date: ?string,
      +apporteur: ?string,
      +taux: ?string,
      +souscripteur: ?string,
      +type: ?TypeSouscripteurEnum,
      +college: ?EmployTypeEnum,
      +status: ?{|
        +percent: ?number,
        +status: ?string,
      |},
    |}>,
  |}
|};
export type QApporteurOavContractsQuery = {|
  variables: QApporteurOavContractsQueryVariables,
  response: QApporteurOavContractsQueryResponse,
|};
*/


/*
query QApporteurOavContractsQuery(
  $page: Int
  $nbItemPerPage: Int
) {
  apporteurOavContracts(page: $page, nbItemPerPage: $nbItemPerPage) {
    page
    count
    nbItemPerPage
    nbPages
    data {
      contractNumber
      name
      num
      cotisation
      soldeContract
      date
      apporteur
      taux
      souscripteur
      type
      college
      status {
        percent
        status
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "nbItemPerPage"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "nbItemPerPage",
        "variableName": "nbItemPerPage"
      },
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      }
    ],
    "concreteType": "ApporteurOavContractsList",
    "kind": "LinkedField",
    "name": "apporteurOavContracts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "page",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nbItemPerPage",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nbPages",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ApporteurOavContract",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contractNumber",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "num",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cotisation",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "soldeContract",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "apporteur",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "taux",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "souscripteur",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "college",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ApporteurContractStatus",
            "kind": "LinkedField",
            "name": "status",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "percent",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QApporteurOavContractsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QApporteurOavContractsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "774e0833a02b858307243b6c6c4f3d56",
    "metadata": {},
    "name": "QApporteurOavContractsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '902c820c98c8365a15127401c6a25d9d';

module.exports = node;
