import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

const QApporteurReferentialsAccessQuery = graphql`
  query QApporteurReferentialsAccessQuery {
    apporteurReferentialsAccess {
      commissions
    }
  }
`;

const QApporteurReferentialsAccess = ({ args, children, childDataProp, mockData }) => {
  return (
    <Query
      query={QApporteurReferentialsAccessQuery}
      args={args}
      mockKey="QApporteurReferentialsAccess"
      mockData={mockData}
    >
      {populateChildren(['apporteurReferentialsAccess'])(children, childDataProp)}
    </Query>
  );
};

export default QApporteurReferentialsAccess;
