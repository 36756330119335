import React from 'react';
import { Block, Page } from '@@components';
import { hasMoreThanOneElement } from '../../../lib/hasMoreThanOneElement';

import {
  ContractBlock
} from '../containers';
import useCompany from '../../../hooks/useCompany';

const Index = () => {
  const { register, controls } = Block.useBlocks();
  const { getContracts } = useCompany();
  const contracts = getContracts();

  return (
    <Page title={hasMoreThanOneElement(contracts) ? 'Mes contrats' : 'Mon contrat'}>
      <Block name="contract" register={register({ opened: true })} color="pink" icon="menu-sinistres.svg" title="Détails du contrat" controls={controls}>
        <ContractBlock contracts={contracts} />
      </Block>
    </Page>
  );
};

export default Index;
