import React from 'react';
import { Block, Page } from '@@components';
import { MeBlock } from './containers';
import { QMe } from '../../_graphql/queries/QMe';

const Index = () => {
  return (
    <Page title="Mon profil">
      <Block title="Mes infos" color="pink">
        <QMe>
          {({ me }) => {
            return (<MeBlock me={me}/>);
          }}
        </QMe>
      </Block>
    </Page>
  );
};

export default Index;
