import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

export const QAvailableYearSinistresQuery = graphql`
  query QAvailableYearSinistresQuery {
    availableYearsSinistres
  }
`;

const QAvailableYearSinistres = ({ args, children, childDataProp, mockData }) => {
  return (
    <Query
      query={QAvailableYearSinistresQuery}
      args={args}
      mockKey="QAvailableYearSinistres"
      mockData={mockData}
    >
      {populateChildren(['availableYearsSinistres'])(children, childDataProp)}
    </Query>
  );
};

export default QAvailableYearSinistres;
