import { Block, Page } from '@@components';
import classNames from 'classnames';
import propTypes from 'prop-types';
import * as R from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';
import React, { useState } from 'react';
import NewsPage from '../../../components/NewsPage';
import { CURRENT_YEAR } from '../../../constants';
import useFetchQuery from '../../../hooks/useFetchQuery';
import useFetchStatsApporteurDashboard from '../../../hooks/useFetchStatApporteurDashboard';
import QApporteurOavContracts from '../../../_graphql/queries/QApporteurOavContracts';
import { QContractsYearsAvailableQuery } from '../../../_graphql/queries/QContractsYearsAvailable';
import ChartDashboard from '../containers/dashboard/ChartDashboard';
import OavContractList from '../containers/dashboard/OavContractList';
import ChartFilters from './ChartFilters';

const ChartDateSelector = ({ active, onChange }) => {
  const getClassesActive = (type) => ({
    'text-gray-400': active !== type,
    'border-b-4 border-pink pb-1': active === type
  });

  return (<ul className="divide-x-2 divide-solid flex text-base">
    <li onClick={() => onChange('month') } className={'px-2 cursor-pointer'}>
      <span className={classNames(getClassesActive('month'))}>Mois</span>
    </li>
    <li onClick={() => onChange('semester') } className={'px-2 cursor-pointer'}>
      <span className={classNames(getClassesActive('semester'))}>Semestre</span>
    </li>
    <li onClick={() => onChange('year') } className={'px-2 cursor-pointer'}>
      <span className={classNames(getClassesActive('year'))}>Année</span>
    </li>
  </ul>);
};

const Chart = ({ allYears }) => {
  const { register, controls } = Block.useBlocks();
  const [chartRange, setChartRange] = useState('semester');
  const [filters, setFilters] = useState({ year: R.compose(parseInt, R.propOr(CURRENT_YEAR, 'value'), R.head)(allYears), type: null, apporteurs: null });

  const { isLoaded, apporteurContractsStats } = useFetchStatsApporteurDashboard({
    range: chartRange,
    year: filters.year,
    type: filters.type,
    apporteurs: filters.apporteurs
  });

  return (
    <Block
      name="stats-contracts"
      color="pink"
      title={(
        <ChartDateSelector active={chartRange} onChange={setChartRange} />
      )}
      actions={
        <ChartFilters defaultValue={allYears} onChange={setFilters} />
      }
      register={register({ opened: true, canClose: false })} controls={controls}
    >
      <ChartDashboard
        range={chartRange}
        year={filters.year}
        type={filters.type}
        apporteurs={[filters.apporteurs]}
        isStatsApporteurLoaded={isLoaded}
        contractCount={apporteurContractsStats}
      />
    </Block>

  );
};
const Index = () => {
  const page = 0;
  const nbItemPerPage = 200;
  const { apporteurContractsYears, isLoaded } = useFetchQuery({
    query: QContractsYearsAvailableQuery,
    dataProp: 'apporteurContractsYears'
  });

  const allYears = R.compose(
    R.map(
      R.applySpec({
        label: R.identity,
        value: R.identity
      })
    ),
    R.sort(R.descend(R.identity)),
    R.map(Number),
    R.propOr([], ['years'])
  )(apporteurContractsYears);

  return (
    <Page>
      <NewsPage />
      {(isLoaded && isNotNilOrEmpty(allYears))
        ? <Chart allYears={allYears} />
        : <Block name="stats-contracts" color="pink"><div className="text-gray-600">Aucune donnée disponible</div></Block>}

      <QApporteurOavContracts args={{ page, nbItemPerPage }}>
        {({ apporteurOavContracts }) => (
          <OavContractList
            contracts={apporteurOavContracts}
          />
        )}
      </QApporteurOavContracts>
    </Page>
  );
};

ChartDateSelector.propTypes = {
  active: propTypes.string,
  onChange: propTypes.func
};

export default Index;
