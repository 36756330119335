/**
 *
 * WARNING : fichier généré par forms, ne pas le modifier directement
 *
 **/

const R = require('ramda');
import {
  commitMutation,
  graphql
} from 'react-relay';

import environment from '@@graphql';

const mutation = graphql`
mutation ApporteurSignUpRequestsMutation($input: ApporteurSignUpRequestsInput!) {
  apporteurSignUpRequests(input: $input) {
    ok
    error
    
      
    accounts 
      
    
  }
}
`;

export default async (input, done, updater) => {
  const variables = { input };

  commitMutation(
    await environment(),
    {
      mutation,
      variables,
      updater: (proxyStore) => {
        if (R.is(Function, updater)) updater(input, proxyStore);
      },
      onCompleted: ({ apporteurSignUpRequests }) => {
        const { ok, error } = apporteurSignUpRequests;
        done(ok, error);
      },
      onError: err => console.error(err)
    }
  );
};