import React from 'react';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { Block } from '@@components';
import PillTypeApporteur from '../../components/PillTypeApporteur';
import { MoneyFormat } from '../../../../components/elements';
import { getContractDocument } from '../../../../lib/documentDownloadHelpers';
import { isContractType } from '../../../../lib/getCompanyType';
import Moment from 'moment';

const FieldBlock = ({ label, value }) => (
  <div className="flex flex-col">
    <div className="text-plum uppercase font-bold">{label}</div>
    <div>{value}</div>
  </div>
);

const getCotisations = (rates) => (contributionLongName) => R.cond([
  [R.equals(1), () => {
    return `${R.head(rates)} % Masse salariale`;
  }],
  [R.equals(2), () => {
    const text = ['% TA', '% TB', '% TC', '% TD'];
    return R.compose(
      R.trim,
      R.join(' '),
      RA.mapIndexed((v, index) => {
        const rate = R.prop(index, rates);
        return R.gt(rate, 0) ? (`${rate}${v}`) : '';
      })
    )(text);
  }],
  [R.equals(3), () => {
    return `${R.head(rates)}€/affilié`;
  }],
  [R.equals(4), () => {
    return `${R.head(rates)}% ${contributionLongName}`;
  }],
  [R.T, R.always('Information absente')]
]);

const dateToString = (d) => {
  const date = Moment(d);
  return date.isValid() ? date.format('DD/MM/YYYY') : 'Information absente';
};

const ContractDetail = ({ contract }) => {
  const { register, controls } = Block.useBlocks();

  if (R.isNil(contract)) {
    return (
      <Block name="contract-detail" title={'Erreur'} color="pink" register={register({ opened: true, canClose: false })} controls={controls}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          Echec de la récupération des détails du contrat.
        </div>
      </Block>
    );
  }

  const isCompany = isContractType('COMPANY')('typeContract')(contract);
  const isTNS = isContractType('TNS')('typeContract')(contract);
  const isParticular = isContractType('PARTICULAR')('typeContract')(contract);
  const tauxCotisation = R.prop('tauxCotisation', contract);
  const cotisation = getCotisations(R.prop('rates', tauxCotisation))(R.prop('contributionLongName', tauxCotisation))(R.prop('codeId', tauxCotisation));

  return (
    <Block name="contract-detail" title={'Contrat-' + contract.num} color="pink" register={register({ opened: true, canClose: false })} controls={controls}>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        {(isCompany || isTNS) && <FieldBlock label="Raison sociale" value={contract.social}/>}
        <FieldBlock label="Nom du produit" value={contract.productName}/>
        {(isTNS || isParticular) && <FieldBlock label="identité Personne physique" value={contract.identity}/>}
        {(isTNS || isParticular) && <FieldBlock label="Cotisation" value={<MoneyFormat value={contract.cotisation}/>}/>}
        <FieldBlock label="Date d’effet" value={dateToString(contract.dateEffect)}/>
        <FieldBlock label="Solde du contrat" value={<MoneyFormat value={contract.soldeContract} />}/>
        <FieldBlock label="Type de contrat" value={<PillTypeApporteur type={contract.typeContract} large/>}/>
        {isCompany && <FieldBlock label="Taux de cotisation" value={cotisation}/>}
        {isCompany && <FieldBlock label="Collège couvert" value={contract.college || 'Information absente'}/>}
        <FieldBlock label="Liste des garanties" value={<ul className="list-disc list-inside">
          {contract.garantyList.map((el) => (<li key={el}>{el}</li>))}
        </ul>}/>
        <FieldBlock label="Nom Apporteur contrat" value={contract.apporteurName}/>
        <FieldBlock label="Statut du contrat" value={contract.statusContract}/>
        {isCompany && <FieldBlock label="DSN" value={contract.dsnEnabled ? 'Oui' : 'Non'}/>}
      </div>
      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-4">
        <button
          aria-label="Télécharger le contrat"
          className="f-button f-button-pink"
          onClick={() => {
            const unencryptedId = R.prop('unencryptedId', contract);
            const typeContract = R.prop('typeContract', contract);
            getContractDocument(unencryptedId, typeContract);
          }}
        >Télécharger le contrat au format PDF
        </button>
      </div>
    </Block>
  );
};

export default ContractDetail;
