/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/


import FirstnameVisibleHandler from '@@formsHandlers/testForm/FirstnameVisibleHandler';


const formData = [

{
name: 'firstname',
required: true,
input: '__UNDEFINED__',
source: '__UNDEFINED__',
label: "Prénom",
placeholder: "__UNDEFINED__",



handlers: {
visible: FirstnameVisibleHandler
}
},

{
name: 'lastname',
required: true,
input: '__UNDEFINED__',
source: '__UNDEFINED__',
label: "__UNDEFINED__",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'gender',
required: true,
input: '__UNDEFINED__',
source: 'gender',
label: "Genre",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

];

export default formData;