import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

export const QLegalFormsQuery = graphql`
  query QLegalFormsQuery {
    legalForms {
      id
      label
    }
  }
`;

const QLegalForms = ({ args, children, childDataProp, mockData }) => {
  return (
    <Query
      query={QLegalFormsQuery}
      args={args}
      mockKey="QLegalForms"
      mockData={mockData}
    >
      {populateChildren(['legalForms'])(children, childDataProp)}
    </Query>
  );
};

export default QLegalForms;