import React from 'react';
import { Company } from '../components';
import { Page } from '@@components';
import DashboardButtonBlock from '../components/DashboardButtonBlock';
import Card from '../../../components/Card';
import withUser from '../../userSession/withUser';
import { isTns, isCompany } from '../../../lib/getCompanyType';
// import NewsPage from './NewsPage';
import NewsPage from '../../../components/NewsPage';

import { length } from 'ramda';
import useCompany from '../../../hooks/useCompany';

const Index = ({ user }) => {
  const { company, getContracts, getAffiliates, getDocuments } = useCompany();
  const contracts = getContracts();
  const contractCount = length(contracts);
  const affiliates = getAffiliates();
  const affiliatesCount = length(affiliates);
  const documentsCount = length(getDocuments());
  return (
    <Page>
      <Company company={company} user={user} />
      <NewsPage />
      <div className="grid grid-cols-1 mt-2 md:grid-cols-2 gap-4">

        {isTns(user)
          ? (<Card to="/mescotisations" icon="menu-calendar.svg" color="yellow" title="Mes cotisations" label="Voir le détail"/>)
          : (<Card to="/moncoffrefort" icon="menu-coffre.svg" color="yellow" title="Mon coffre-fort" label="Voir mes documents"/>)
        }

        <Card to="/mesprestations" icon="menu-prestas.svg" color="cyan" title="Mes prestations" label="Déclarer un sinistre"/>

        <DashboardButtonBlock
          to="/moncontrat"
          image="/images/home-prevoyance.jpg"
          label={`Voir ${contractCount === 1 ? 'mon' : 'mes'} contrat${contractCount === 1 ? '' : 's'}`}
          title={`${contractCount} contrat${contractCount > 1 ? 's' : ''}`}
        />
        {isTns(user) && (
          <DashboardButtonBlock
            to="/moncoffrefort"
            image="/images/home-coffre-fort.jpg"
            label="Voir mon coffre-fort"
            title={`${documentsCount} document${documentsCount > 1 ? 's' : ''}`}
          />
        )}
        {isCompany(user) && (
          <DashboardButtonBlock
            to="/mesprestations"
            image="/images/home-rse-01.jpg"
            label="declarer un sinistre"
            title={`${affiliatesCount} affilié${affiliatesCount > 1 ? 's' : ''}`}
          />
        )}
      </div>

    </Page>
  );
};

export default withUser(Index);
