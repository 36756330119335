import {
  commitMutation,
  graphql
} from 'react-relay';
import environment from '../../index';

const mutation = graphql`
mutation SendMessageMutation($contract: String!, $subject: String!, $message: String!, $attachments: [AttachmentInput]) {
  sendMessage(contract: $contract, subject: $subject, message: $message, attachments: $attachments) {
    ok
    error
  }
}
`;

export default async ({ subject, message, attachments }, done) => {
  const variables = {
    subject,
    message,
    attachments
  };

  commitMutation(
    await environment(),
    {
      mutation,
      variables,
      updater: (proxyStore) => {
      },
      onCompleted: ({ sendMessage }) => {
        const { ok, error } = sendMessage;
        done(ok, error);
      },
      onError: err => console.error(err)
    }
  );
};
