import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import Layout from '@@layout';
import Menu, { menuItems } from '@@pages/standard';
import MenuAdmin, { menuAdminItems } from '@@pages/admin';
import MenuApporteur, { menuApporteurItems } from '@@pages/apporteur';
import ApporteurSignUp from '@@pages/apporteur/account/SignUp';
import TiersSignUp from '@@pages/standard/account/SignUp';
import GQLEnvironment from './_graphql/Environment';
import UserSessionRoutes, { UserContext, UserSession } from '@@pages/userSession';
import { CompanyContext } from '@@pages/standard/withCompany';
import { CookieSquarespace, RgpdFooter } from '@@components';
import Emails from './pages/action/Emails';

import { MeasureContext } from '@@hooks/useMeasure';
import Measure from 'react-measure';
import StickyButton from './components/elements/StickyButton';

import useUser from './hooks/useUser';
import { BrowserRouter as Router, Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import CompanyProvider from './contexts/CompanyContext';
import { USER_PROPTYPES } from './propTypes/user/UserPropTypes';
import RedirectLoginPage from './pages/userSession/RedirectLoginPage';

const R = require('ramda');
const { RelayEnvironmentProvider } = require('react-relay/hooks');

const getMenu = (mode, companyType, user) => R.prop(mode, {
  admin: {
    items: menuAdminItems,
    menu: <MenuAdmin />
  },
  standard: {
    items: menuItems(companyType),
    menu: <Menu />
  },
  apporteur: {
    items: menuApporteurItems(user),
    menu: <MenuApporteur />
  }
});

const Top = ({ mode, username, showMenu, toggleShowMenu }) => {
  const { onSignOut } = useUser();

  return (
    <div className="f-top">
      <Link to="/tableaudebord">
        <img src="/image/logo.svg" width="70.2" style={{ marginLeft: '16px' }} alt="Logo Unmi" />
      </Link>
      <div className="flex">
        <button aria-label="Basculer l'affichage du menu" className="f-burger" onClick={toggleShowMenu}>
          <img src={showMenu ? '/icons/cross.svg' : '/icons/menu-blanc.svg'} alt="Icone du menu"/>
        </button>
        <div className="flex gap-2 divide-x mr-8">

          <Link className="hidden md:flex flex-row justify-between items-center" to={mode === 'apporteur' ? '#' : '/monprofil'}>
            {mode === 'apporteur' ? (
              <div className="mr-2 h-10 rounded-full">
                <img className=" h-full" src={`/image/distributer?t=${username}-${mode}`} onError={e => { e.target.src = '/icon/icon-neutre.svg'; } } alt="Logo de la Compagnie" />
              </div>
            ) : (
              <img className="mr-2" src="/icon/icon-neutre.svg" alt="Icone profil" />
            )}
            <span>{username}</span>
          </Link>
          <button aria-label="Me déconnecter" className="hidden md:flex items-center px-2 text-gray-600 hover:text-gray-800 cursor-pointer" onClick={onSignOut}>{'Me déconnecter'}</button>
        </div>
      </div>
    </div>
  );
};

const ContextualRoutes = ({ mode, username, companyType, user }) => {
  const conf = getMenu(mode, companyType, user);
  const { pathname } = useLocation();

  const [showMenu, setShowMenu] = useState(false);
  useEffect(() => { setShowMenu(false); }, [pathname]);

  return (
    <Layout
      top={<Top username={username} mode={mode} showMenu={showMenu} toggleShowMenu={() => setShowMenu(!showMenu)} />}
      menu={conf.menu}
      showMenu={showMenu}
      footer={<RgpdFooter />}
      cookieSquarespace={<CookieSquarespace />}
    >
      {R.includes(mode, ['standard', 'apporteur']) && <StickyButton />}
      {showMenu && <div onClick={() => setShowMenu(false)} className="bg-black md:hidden fixed top-0 right-0 bottom-0 left-0 z-20 opacity-50"></div>}
      {conf.items.map(({ path, component }) => (
        <Route
          key={path}
          path={path}
          component={component}
        />
      ))}
    </Layout>
  );
};

const Routes = () => {
  const [dimensions, setDimensions] = useState({ height: -1, width: -1 });
  const onResize = (contentRect) => {
    setDimensions(contentRect.bounds);
  };

  const ManualRedirect = ({ to }) => {
    const location = useLocation();
    return <Redirect to={to + location.search}/>;
  };

  return (
    <RelayEnvironmentProvider environment={GQLEnvironment.get()}>
      <UserSession refreshInterval={60000}>
        {({ user }) => {
          return (
            <UserContext.Provider value={{ user }}>
              <CompanyProvider>
                <Measure bounds onResize={onResize}>
                  {({ measureRef }) => {
                    return <div className="overflow-x-hidden" ref={measureRef}>
                      <MeasureContext.Provider value={dimensions}>
                        <Router>
                          <Switch>
                            <Route path="/apporteur/signup" component={ApporteurSignUp} />
                            <Route path="/tiers/signup" component={TiersSignUp} />
                            <Route path="/action/emails" component={Emails} />
                            <Route path="/account" component={UserSessionRoutes} />
                            <Route path="/signin" component={RedirectLoginPage} />

                            {R.isNil(R.propOr(null, 'username', user)) && <ManualRedirect to={'/signin'}/>}
                            <Route path="/" exact component={() => (<Redirect to='/tableaudebord' />)} />
                            {(user.role === 'ADMIN') && (
                              <ContextualRoutes mode="admin" username={user.username} user={user}/>
                            )}
                            {(user.role === 'TIERS') && (
                              <ContextualRoutes mode="standard" username={user.username} companyType={user.companyType} user={user}/>
                            )}
                            {(user.role === 'APPORTEUR' || user.role === 'MANAGER') && (
                              <CompanyContext.Provider value={ {} }>
                                <ContextualRoutes mode="apporteur" username={user.username} user={user}/>
                              </CompanyContext.Provider>
                            )}
                          </Switch>
                        </Router>
                      </MeasureContext.Provider>
                    </div>;
                  }}
                </Measure>
              </CompanyProvider>
            </UserContext.Provider>
          );
        }}
      </UserSession>
    </RelayEnvironmentProvider>
  );
};

Top.propTypes = {
  username: propTypes.string,
  showMenu: propTypes.bool,
  toggleShowMenu: propTypes.func
};

ContextualRoutes.propTypes = {
  id: propTypes.string,
  username: propTypes.string,
  role: propTypes.string,
  companyType: propTypes.string,
  mode: propTypes.string,
  user: propTypes.shape(USER_PROPTYPES)
};

export default hot(Routes);
