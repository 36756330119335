import { commitMutation, graphql } from 'react-relay';
import environment from '../../index';

const mutation = graphql`
mutation onCloseApporteurAccountMutation($apporteurInfo: CloseApporteurAccountInput!) {
  onCloseApporteurAccount(apporteurInfo: $apporteurInfo) {
    ok
    error
  }
}
`;

export default async ({ apporteurInfo }, done) => {
  const variables = { apporteurInfo };

  commitMutation(
    await environment(),
    {
      mutation,
      variables,
      updater: () => {},
      onCompleted: ({ onCloseApporteurAccount }) => {
        const { ok, error } = onCloseApporteurAccount;
        done(ok, error);
      },
      onError: err => console.error(err)
    }
  );
};
