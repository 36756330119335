import React, { useEffect, useState } from 'react';
import { Block } from '@@components';
import { SelectOneOption } from '@@components/input';
import { sinistreType } from '@@refs';
import { DeclareDeces, DeclareIncapacite, DeclareInvalidite } from '../DeclareSinistreForms';
import withUser from '../../../userSession/withUser';
import { getOptionsByCompanyType } from '../../../../lib/getOptionsByCompanyType';
import { isCompanyAndDisability } from '../../../../lib/getCompanyType';

import * as R from 'ramda';
import useCompany from '../../../../hooks/useCompany';
import { hasOneElement } from '../../../../lib/hasOneElement';

const DeclareSinistreBlock = ({ user, address }) => {
  const { register, controls } = Block.useBlocks();
  const [sinistre, setSinistre] = useState(null);
  const { company, getAffiliatesByContractId } = useCompany();
  const [selectedIndex, setSelectedIndex] = useState(hasOneElement(R.prop('contracts', company)) ? 0 : -1);
  const [selectedContract, setSelectedContract] = useState(hasOneElement(R.prop('contracts', company)) ? R.prop('contracts', company)[0] : null);
  const [affiliates, setAffiliates] = useState(getAffiliatesByContractId(selectedContract));
  const [contractId, setContractId] = useState();
  useEffect(() => {
    setAffiliates(getAffiliatesByContractId(selectedContract));
  }, [selectedContract]);

  const formSinistre = (affiliates) => {
    return R.compose(
      R.cond([
        [R.equals('DECEASE'), R.always(<DeclareDeces affiliates={affiliates} user={user} company={company} contractId={contractId} />)],
        [R.equals('INABILITY'), R.always(<DeclareIncapacite address={address} affiliates={affiliates} user={user} company={company} contractId={contractId} />)],
        [R.equals('DISABILITY'), R.always(<DeclareInvalidite address={address} company={company} user={user} contractId={contractId}/>)],
        [R.T, R.always(null)]
      ]),
      R.propOr(null, 'value')
    )(sinistre);
  };

  const onSelectContract = ({ value }, company) => {
    setContractId(company[value].id);
    setSelectedContract(company[value].contractIdCrypt);
    setSelectedIndex(value);
  };

  const options = (contracts) => contracts.map((contract, index) => ({ label: `${contract.info.nomProduit} ${contract.id} ${R.pathOr('', ['info', 'college'], contract)}`, value: index }));

  const contractOptions = options(R.propOr(null, 'contracts', company));

  useEffect(() => {
    if (contractOptions.length <= 1) {
      onSelectContract(contractOptions[0], R.prop('contracts', company));
    }
  }, contractOptions);

  return <>
    <Block
      name="addSinistre"
      register={register({ open: true, canClose: false })}
      color="pink"
      icon="icon-sinistre.svg"
      title={'Déclarer un sinistre'}
      controls={controls}
    >
      <SelectOneOption
        title="Type de sinistre"
        options={getOptionsByCompanyType(sinistreType, user)}
        placeholder="Sélectionner un sinistre"
        onChange={(value) => {
          setSinistre(value);
        }}
        value={sinistre}
      />
      {(sinistre && !isCompanyAndDisability(user, sinistre)) && (
        <SelectOneOption
          title="Contrat"
          className={'mt-5'}
          options={contractOptions}
          value={contractOptions[selectedIndex]}
          onChange={value => onSelectContract(value, R.prop('contracts', company))}
          placeholder="Sélectionnez un contrat"
        />
      )}
    </Block>
    {(selectedContract || isCompanyAndDisability(user, sinistre)) && formSinistre(affiliates)}
  </>;
};

export default withUser(DeclareSinistreBlock);
