import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { BlockField, Badge } from '@@components';
import InputSplit from './InputSplit';

const R = require('ramda');
const IBAN = require('iban');

const RibInput = ({ onDelete, onChange, value, hasError }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [ribValue, setRibValue] = useState(value || '');
  const [ibanIsValid, setIbanIsValid] = useState(true);

  const [isShaking, setShaking] = useState(false);

  useEffect(() => {
    setShaking(hasError);
  }, [hasError]);

  const onToggleEditMode = () => {
    if (isEditing) {
      // * Handle validation
      if (!IBAN.isValid(ribValue.iban)) {
        setIbanIsValid(false);
        return;
      }
      setIbanIsValid(true);
      onChange(ribValue);
    }

    setIsEditing(!isEditing);
  };

  const buttonClasses = classNames({
    'f-circle-btn-pink': isEditing,
    'f-circle-btn': !isEditing,
    shake: isShaking
  });

  return <div className="flex">
    <div className={`flex-auto border p-2 border-${hasError && !isEditing ? 'red' : 'gray'}-300 rounded`}>
      {!isEditing && <BlockField>

        <div className="flex justify-start">
          <div><span className="font-bold mr-2">TITULAIRE</span></div>
          <div>
            {(R.not(R.isEmpty(R.propOr('', 'holder', value))) &&
          R.propOr('', 'holder', value)) ||
          <Badge>Non défini</Badge>
            }
          </div>
          {(R.not(R.isEmpty(R.propOr('', 'type', value))) &&
          <Badge>{R.propOr('', 'type', value)}</Badge>)
          }
        </div>
        <div>
          <div><span className="font-bold mr-2">IBAN</span>{R.splitEvery(4, R.propOr('', 'iban', value).split(' ').join('')).join(' ')} </div>
          <div><span className="font-bold mr-2">BIC</span>{R.propOr('', 'bic', value)}</div>
        </div>
      </BlockField>
      }
      {isEditing &&
    <>
      <BlockField label="Libellé RIB">
        <input
          name="label"
          className="f-input-gray"
          value={R.propOr('', 'label', ribValue)}
          onChange={(e) => setRibValue(R.assoc('label', R.path(['target', 'value'], e)))}
        />
      </BlockField>

      {/* IBAN */}
      <BlockField label="IBAN">
        <InputSplit
          sizes={[4, 4, 4, 4, 4, 4, 3]}
          tranform={'uppercase'}
          isValid={ibanIsValid && !hasError}
          value={R.propOr('', 'iban', ribValue)}
          onChange={(v) => setRibValue(R.assoc('iban', v))}
        />
      </BlockField>

      {/* BIC */}
      <BlockField label="BIC">
        <input
          name="bic"
          className="f-input-gray"
          value={R.propOr('', 'bic', ribValue)}
          onChange={(e) => setRibValue(R.assoc('bic', R.path(['target', 'value'], e)))}
        />
      </BlockField>
    </>
      }
    </div>
    {/* <div className="flex-none mx-2 p-2 flex flex-col gap-2">
      <button type="button" className={buttonClasses} onClick={onToggleEditMode}>
        <img src={'/icons/bt-edit.svg'}/>
      </button>

      {R.not(R.isNil(onDelete)) && (
        <button type="button" className="f-circle-btn" onClick={onDelete}>
          <div className="flex justify-center h-full w-full p-2">
            <img src={'/icons/icon-delete.svg'} />
          </div>
        </button>
      )}
    </div> */}
  </div>;
};

RibInput.propTypes = {
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  value: PropTypes.shape({
    label: PropTypes.string,
    iban: PropTypes.string,
    bic: PropTypes.string
  }),
  hasError: PropTypes.bool
};

RibInput.defaultProps = {
  onChange: () => {},
  onDelete: null,
  value: {
    label: null,
    iban: null,
    bic: null
  },
  hasError: false

};

export default RibInput;
