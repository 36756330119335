import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

export const QMeQuery = graphql`
  query QMeQuery {
    me {
      lastname
      firstname
      email
      network
    }
  }
`;

export const QMe = ({ args, children, childDataProp, mockData }) => {
  return (
    <Query
      query={QMeQuery}
      args={args}
      mockKey="QMe"
      mockData={mockData}
    >
      {populateChildren(['me'])(children, childDataProp)}
    </Query>
  );
};
