import React from 'react';
import PropTypes from 'prop-types';
import { Block, Page } from '@@components';
import { useHistory } from 'react-router-dom';
import ContactBlock from '../containers/contact/ContactBlock';
import contactsList from '../../../../refs/contactsList';

const ContactPage = ({}) => {
  const history = useHistory();
  const { register, controls } = Block.useBlocks();

  const titleContent = (<div className="flex">
    <img className="pr-3 cursor-pointer mt-2 h-6 w-6" src="/icons/back.svg" alt="Icone flèche de retour" onClick={() => history.goBack()}/>
    Contact
  </div>);

  return (
    <Page title={titleContent}>
      <Block name="contact-block" register={register({ opened: true, canClose: false })} color="pink" icon="bulle-contact.svg" title="Contactez-nous" controls={controls}>
        <div className="py-4 grid md:grid-cols-2 gap-4">
          {contactsList.map(({ title, contacts }) => (<ContactBlock key={title} title={title} contacts={contacts}/>))}
        </div>

      </Block>
    </Page>
  );
};

ContactPage.propTypes = {
};

ContactPage.defaultProps = {
};

export default ContactPage;
