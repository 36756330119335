import { compose, filter, includes, prop, toLower, propOr, flatten } from 'ramda';

// tns == "Entreprise en nom propre"
// collectif == "Personne Morale"
export const getOptionsByCompanyType = (options, user) => (
  compose(
    filter(
      compose(
        includes(toLower(propOr('', 'companyType', user))),
        flatten,
        prop('roles'))
    ))(options)
);
