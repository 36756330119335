import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QUserTiersQuery = graphql`
  query QUserTiersQuery {
    userSession {
      id
      username
      email
      role
      companyType
      tiersDetails {
        title
        firstname
        lastname
        banner
        siret
        fiscalDate
        contact {
          name
          email
          tel
        }
        address {
          line
          zipCode
          city
        }
      }
    }
  }
`;

const QUserTiers = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QUserTiersQuery}
      args={args}
      fetchPolicy="network"
      mockKey="QUser"
      mockData={mockData}
    >
      {populateChildren(['userSession'])(children, childDataProp)}
    </Query>
  );
};

export default QUserTiers;
