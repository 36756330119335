import { commitMutation, graphql } from 'react-relay';
import environment from '../../index';

const mutation = graphql`
mutation onDeclarationMiseInvaliditeMutation {
  onDeclarationMiseInvalidite {
    ok
    error
  }
}`;

export default async (done) => {
  const variables = {
  };

  commitMutation(
    await environment(),
    {
      mutation,
      variables,
      updater: (proxyStore) => {},
      onCompleted: ({ onDeclarationMiseInvalidite }) => {
        const { ok, error } = onDeclarationMiseInvalidite;
        done(ok, error);
      },
      onError: err => console.error(err)
    }
  );
};
