import React from 'react';
import PropTypes from 'prop-types';

import Forms from '@@components/forms';
import { useFeedback } from '@@hooks';
import useForms from '@@formsHook';

const TiersAddressBlock = ({ address: { line, zipCode, city } }) => {
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('tiersAddressBlock');

  useFeedback(fetching);

  const onSubmit = (data) => {
    onFormsSubmit({
      line: data.line,
      zipCode: data.zipCode,
      city: data.city
    });
  };

  return <Forms
    formsData={formsData}
    onSubmit={onSubmit}
    defaultValues={{ line, zipCode, city }}
    submitSection={() => (
      <>
        <button aria-label="Sauvegarder les modifications" type="submit" className="f-button f-button-pink">{'Sauvegarder les modifications'}</button>
      </>
    )}
  />;
};
TiersAddressBlock.propTypes = {
  address: PropTypes.shape({
    city: PropTypes.string,
    zipCode: PropTypes.string,
    line: PropTypes.string
  })
};

export default TiersAddressBlock;
