import React from 'react';
import { useHistory } from 'react-router-dom';

const DashboardButtonBlock = ({ image, to, label, title }) => {
  const history = useHistory();
  return (
    <div className="f-button-block">
      <div className="f-button-block-img" style={{ backgroundImage: `url(${image})` }} alt="Image d'arrière plan">
      </div>
      <div className="f-button-block-addon relative">
        <div className="f-text-part">
          {title}
        </div>
        <div className="f-button-part" >
          <button onClick={() => history.push(to)}>
            {label}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashboardButtonBlock;
