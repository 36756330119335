import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CommissionListPage from './CommissionListPage';

const Index = () => {
  return (<Switch>
    <Route exact path="/commissions"><CommissionListPage /></Route>
  </Switch>);
};

export default Index;
