import { useEffect } from 'react';
import { shouldBlockTransition } from '../constants';
import { isNilOrEmpty } from 'ramda-adjunct';

const useBeforeUnload = (isBlocking) => {
  useEffect(() => {
    if (!isNilOrEmpty(isBlocking) && shouldBlockTransition) {
      const onReload = (event) => {
        // We can't set custom message anymore in most modern browsers
        const e = event || window.event;
        // For IE and Firefox
        if (e) {
          e.returnValue = 'Vos saisies seront perdues si vous quittez cette page';
        }
        // For Safari
        return 'Vos saisies seront perdues si vous quittez cette page';
      };
      window.addEventListener('beforeunload', onReload);
      return () => window.removeEventListener('beforeunload', onReload);
    }
  }, [shouldBlockTransition, isBlocking]);
};

export default useBeforeUnload;
