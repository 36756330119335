import environment from '../../index';
import { commitMutation, graphql } from 'react-relay';

const mutation = graphql`
mutation onAskEnablingDsnDsnMutation {
  onAskEnablingDsn {
    ok
    error
  }
}
`;

export default async (done) => {
  commitMutation(
    await environment(),
    {
      mutation,
      variables: {},
      updater: () => {},
      onCompleted: ({ onAskEnablingDsn }) => {
        const { ok, errors } = onAskEnablingDsn;
        done(ok, errors);
      },
      onError: err => {
        console.error(err);
        done(false, err);
      }

    }
  );
};
