/**
 * @flow
 * @relayHash bdd0fce5a595978944497712cdfe8e2d
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserSessionRoleEnum = "ADMIN" | "APPORTEUR" | "MANAGER" | "TIERS" | "%future added value";
export type QUserTiersQueryVariables = {||};
export type QUserTiersQueryResponse = {|
  +userSession: ?{|
    +id: ?string,
    +username: ?string,
    +email: ?string,
    +role: ?UserSessionRoleEnum,
    +companyType: ?string,
    +tiersDetails: ?{|
      +title: ?string,
      +firstname: ?string,
      +lastname: ?string,
      +banner: ?string,
      +siret: ?string,
      +fiscalDate: ?string,
      +contact: ?{|
        +name: ?string,
        +email: ?string,
        +tel: ?string,
      |},
      +address: ?{|
        +line: ?string,
        +zipCode: ?string,
        +city: ?string,
      |},
    |},
  |}
|};
export type QUserTiersQuery = {|
  variables: QUserTiersQueryVariables,
  response: QUserTiersQueryResponse,
|};
*/


/*
query QUserTiersQuery {
  userSession {
    id
    username
    email
    role
    companyType
    tiersDetails {
      title
      firstname
      lastname
      banner
      siret
      fiscalDate
      contact {
        name
        email
        tel
      }
      address {
        line
        zipCode
        city
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserSession",
    "kind": "LinkedField",
    "name": "userSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TiersDetails",
        "kind": "LinkedField",
        "name": "tiersDetails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastname",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "banner",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "siret",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fiscalDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ContactDetails",
            "kind": "LinkedField",
            "name": "contact",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tel",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TiersAddress",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "line",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "zipCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QUserTiersQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QUserTiersQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "bdd0fce5a595978944497712cdfe8e2d",
    "metadata": {},
    "name": "QUserTiersQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9584f79f148074088970586433865b75';

module.exports = node;
