import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

const QNewsQuery = graphql`
  query QNewsQuery {
    news {
      items {
        id
        title
        text
        image {
          path
        }
      }
    }
  }
`;

const QNews = ({ args, childDataProp, mockData, children }) => {
  return <Query
    query={QNewsQuery}
    args={args}
    mockData={mockData}
    fetchPolicy="store-or-network"
  >
    {populateChildren(['news'])(children, childDataProp)}
  </Query>;
};

export default QNews;
