import React from 'react';
import classNames from 'classnames';

const R = require('ramda');
const RA = require('ramda-adjunct');

const ENTREPRISE = ['company', 'entreprise'];
const TNS = ['tns'];
const PARTICULIER = ['particular', 'particulier'];

const comparType = (key) => R.compose(
  R.includes(R.__, key),
  R.toLower,
  R.defaultTo('')
);

const PillTypeApporteur = ({ type, large }) => {
  if (RA.isNilOrEmpty(type)) {
    return null;
  }

  const classes = classNames(
    ['rounded-full border font-semibold text-center px-3 ml-3'],
    {
      'bg-company text-white': comparType(ENTREPRISE)(type),
      'bg-tns text-green-700': comparType(TNS)(type),
      'bg-particular text-teal-700': comparType(PARTICULIER)(type)
    }
  );

  const getPillLabelBySize = (isLargeSize) => R.cond([
    [comparType(ENTREPRISE), isLargeSize
      ? R.always('Entreprise')
      : R.always('Ent.')
    ],
    [comparType(TNS), R.always('TNS')],
    [comparType(PARTICULIER), isLargeSize
      ? R.always('Particulier')
      : R.always('Part.')
    ]
  ]);

  return (<span className={classes}>{getPillLabelBySize(large)(type)}</span>);
};

export default PillTypeApporteur;
