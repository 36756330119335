import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

export const QApporteurContractsQuery = graphql`
  query QApporteurContractsQuery($siren: String, $souscripteur: String, $allowMaxContracts: Int) {
    apporteurContracts(siren: $siren, souscripteur: $souscripteur, allowMaxContracts: $allowMaxContracts)  {
      count
      contracts {
        unencryptedId
        idCrypt
        num
        apporteur
        souscripteur
        typeSouscripteur
        colleges
        status
        detail {
          num
          unencryptedId
          idCrypt
          social
          productName
          identity
          cotisation
          dateEffect
          soldeContract
          typeContract
          tauxCotisation {
            codeId,
            rates,
            contributionLongName
          }
          college
          garantyList
          apporteurName
          statusContract
          dsnEnabled
        }
      }
    }
  }
`;

const QApporteurContracts = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QApporteurContractsQuery}
      args={args}
      mockKey="QApporteurContracts"
      mockData={mockData}
    >
      {populateChildren(['apporteurContracts'])(children, childDataProp)}
    </Query>
  );
};

export default QApporteurContracts;
