import React, { memo, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Block, BlockField } from '@@components';
import { Controller, useForm } from 'react-hook-form';
import { always, applySpec, compose, ifElse, isEmpty, isNil, not, pathOr, prop, propOr, trim, when } from 'ramda';

import ContractList from './ContractList';
import Loading from '../../../../components/Loading';
import { useHistory } from 'react-router-dom';
import { useFetchApporteursContracts, useQueryUrl } from '../../../../hooks';
import { ScrollContext } from '../../../../components/Page';

const hasError = (fieldname) => compose(
  not,
  isNil,
  prop(fieldname)
);

const getNotEmptyValueOrNull = (key) => compose(
  ifElse(
    isNil,
    always(null),
    compose(
      when(
        isEmpty,
        always(null)
      ),
      trim
    )
  ),
  prop(key)
);

const ALLOW_MAX_CONTRACTS = 50;

const ContractBlock = memo(function ContractBlock ({ register, controls, onSelectContract }) {
  const [searchArgs, setSearchArgs] = useState(null);
  const [authorizeFetchMaxContracts, setAuthorizeFetchMaxContracts] = useState(ALLOW_MAX_CONTRACTS);
  const [fetching, setFetching] = useState(false);
  const history = useHistory();
  const [query] = useQueryUrl();
  const { handleSubmit, control, setValue, formState: { errors } } = useForm({
    defaultValues: {
      siren: propOr('', 'siren', query),
      souscripteur: propOr('', 'souscripteur', query)
    }
  });
  const { data, isLoaded } = useFetchApporteursContracts(searchArgs, authorizeFetchMaxContracts);
  const { setShouldScrollToTop } = useContext(ScrollContext);

  useEffect(() => {
    if (isLoaded) {
      setFetching(false);
    }
  }, [isLoaded]);

  const setUrlQueries = (siren = '', souscripteur = '') => {
    !isEmpty(siren)
      ? history.push(`${location.pathname}?siren=${siren}`)
      : history.push(`${location.pathname}?souscripteur=${souscripteur}`);
  };

  const onSearch = (values) => {
    setUrlQueries(propOr('', 'siren', values), propOr('', 'souscripteur', values));
    setAuthorizeFetchMaxContracts(ALLOW_MAX_CONTRACTS);
    const args = applySpec({
      siren: getNotEmptyValueOrNull('siren'),
      souscripteur: getNotEmptyValueOrNull('souscripteur')
    })(values);
    setSearchArgs(args);
    setFetching(true);
  };

  const onChangeResetOther = (fieldname, onChange) => (e) => {
    setValue(fieldname, '');
    onChange(pathOr('', ['target', 'value'], e));
  };

  return (
    <Block
      name="contract-seaarch"
      title='Chercher un ou plusieurs contrats'
      color='pink'
      register={register({ opened: true, canClose: false })}
      controls={controls}
    >
      <div className="gap-4 mb-4">
        <form onSubmit={handleSubmit(onSearch)}>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <BlockField label="N° Siren">
              <Controller
                control={control}
                name="siren"
                rules={{ pattern: /^\d{9}$/ }}
                render={({ value, onChange, name }) => (
                  <>
                    <input
                      placeholder="Siren"
                      type="text"
                      className="f-input"
                      name={name}
                      value={value}
                      onChange={onChangeResetOther('souscripteur', onChange)}
                    />

                    {hasError(name)(errors) && <div className="text-danger">La saisie est incorrect.</div>}
                  </>
                )}
              />
            </BlockField>

            <BlockField label="Nom du souscripteur">
              <Controller
                control={control}
                name="souscripteur"
                render={({ value, onChange, name }) => (
                  <>
                    <input
                      placeholder="Nom du souscripteur"
                      type="text"
                      className="f-input"
                      name={name}
                      value={value}
                      onChange={onChangeResetOther('siren', onChange)}
                    />
                    {hasError(name)(errors) && <div className="text-danger">La saisie est incorrect.</div>}
                  </>
                )}
              />

            </BlockField>
          </div>

          <div>
            <button
              aria-label="Recherche"
              type="submit"
              className="f-button f-button-plum text-center"
              onClick={() => setShouldScrollToTop(false)}
            >
              Recherche
            </button>
          </div>
        </form>
      </div>

      {fetching && <Loading />}
      {!fetching && isLoaded && (
        <div>
          {data.count > ALLOW_MAX_CONTRACTS && authorizeFetchMaxContracts
            ? (<div className={'flex flex-wrap flex-row items-center gap-4 lg:justify-start justify-center'}>
              <div>
                Cette recherche de contrat peut prendre beaucoup de temps, êtes-vous certain-e de vouloir continuer ?
              </div>
              <button
                aria-label="oui"
                className="f-button f-button-plum text-center"
                onClick={() => {
                  setFetching(true);
                  setAuthorizeFetchMaxContracts(null);
                }}
              >
              oui
              </button>
            </div>)
            : (<ContractList apporteurContracts={propOr([], 'contracts', data)} onSelect={onSelectContract}/>)}
        </div>
      )}
    </Block>
  );
});

ContractBlock.propTypes = {
  register: PropTypes.func.isRequired,
  controls: PropTypes.object.isRequired,
  onSelectContract: PropTypes.func.isRequired
};

ContractBlock.defaultProps = {
};

export default ContractBlock;
