
const distributerLogo = [
  { distrId: 'acoris_mutuelle', distrName: 'acoris_mutuelle', logoName: 'acoris_mutuelle.jpg' },
  { distrId: 'adrea_mutuelle', distrName: 'adrea_mutuelle', logoName: 'adrea_mutuelle.jpg' },
  { distrId: 'almutra', distrName: 'almutra', logoName: 'almutra.png' },
  { distrId: 'amellis', distrName: 'amellis', logoName: 'amellis.jpg' },
  { distrId: 'apreva', distrName: 'apreva', logoName: 'apreva.jpg' },
  { distrId: 'aspbtp', distrName: 'aspbtp', logoName: 'aspbtp.jpg' },
  { distrId: 'aubeane', distrName: 'aubeane', logoName: 'aubeane.jpg' },
  { distrId: 'avenir_sante_mutuelle', distrName: 'avenir_sante_mutuelle', logoName: 'avenir_sante_mutuelle.jpg' },
  { distrId: 'ccmo_mutuelle', distrName: 'ccmo_mutuelle', logoName: 'ccmo_mutuelle.png' },
  { distrId: 'cmip', distrName: 'cmip', logoName: 'cmip.jpg' },
  { distrId: 'cnm_prevoyance_sante', distrName: 'cnm_prevoyance_sante', logoName: 'cnm_prevoyance_sante.jpg' },
  { distrId: 'eovi_mcd', distrName: 'eovi_mcd', logoName: 'eovi_mcd.jpg' },
  { distrId: 'france_mutuelle', distrName: 'france_mutuelle', logoName: 'france_mutuelle.jpg' },
  { distrId: 'gsmc', distrName: 'gsmc', logoName: 'gsmc.jpg' },
  { distrId: 'harmonie_mutuelle', distrName: 'harmonie_mutuelle', logoName: 'harmonie_mutuelle.jpg' },
  { distrId: 'identites_mutuelle', distrName: 'identites_mutuelle', logoName: 'identites_mutuelle.png' },
  { distrId: 'lamie_mutuelle', distrName: 'lamie_mutuelle', logoName: 'lamie_mutuelle.png' },
  { distrId: 'le_libre_choix', distrName: 'le_libre_choix', logoName: 'le_libre_choix.jpg' },
  { distrId: 'le_refuge_mutualiste_aveyronnais', distrName: 'le_refuge_mutualiste_aveyronnais', logoName: 'le_refuge_mutualiste_aveyronnais.jpg' },
  { distrId: 'les_mutuelles_ligeriennes-01_0', distrName: 'les_mutuelles_ligeriennes-01_0', logoName: 'les_mutuelles_ligeriennes-01_0.png' },
  { distrId: 'lmp_les_menages_prevoyants', distrName: 'lmp_les_menages_prevoyants', logoName: 'lmp_les_menages_prevoyants.png' },
  { distrId: 'macd', distrName: 'macd', logoName: 'macd.jpg' },
  { distrId: 'malakoff_meredic_mutuelle', distrName: 'malakoff_meredic_mutuelle', logoName: 'malakoff_meredic_mutuelle.jpg' },
  { distrId: 'mba_mutuelle', distrName: 'mba_mutuelle', logoName: 'mba_mutuelle.jpg' },
  { distrId: 'mca', distrName: 'mca', logoName: 'mca.jpg' },
  { distrId: 'mcci', distrName: 'mcci', logoName: 'mcci.jpg' },
  { distrId: 'mclr', distrName: 'mclr', logoName: 'mclr.png' },
  { distrId: 'mfif', distrName: 'mfif', logoName: 'mfif.jpg' },
  { distrId: 'mgps', distrName: 'mgps', logoName: 'mgps.jpg' },
  { distrId: 'miltis', distrName: 'miltis', logoName: 'miltis.jpg' },
  { distrId: 'mip', distrName: 'mip', logoName: 'mip.jpg' },
  { distrId: 'mipcf_pleyel_sante', distrName: 'mipcf_pleyel_sante', logoName: 'mipcf_pleyel_sante.jpg' },
  { distrId: 'mmc_vesoul', distrName: 'mmc_vesoul', logoName: 'mmc_vesoul.jpg' },
  { distrId: 'mncap', distrName: 'mncap', logoName: 'mncap.jpg' },
  { distrId: 'mos', distrName: 'mos', logoName: 'mos.jpg' },
  { distrId: 'mpi', distrName: 'mpi', logoName: 'mpi.jpg' },
  { distrId: 'muros', distrName: 'muros', logoName: 'muros.jpg' },
  { distrId: 'mutame', distrName: 'mutame', logoName: 'mutame.jpg' },
  { distrId: 'mutlor', distrName: 'mutlor', logoName: 'mutlor.png' },
  { distrId: 'mutuale', distrName: 'mutuale', logoName: 'mutuale.jpg' },
  { distrId: 'mutualite_reunion', distrName: 'mutualite_reunion', logoName: 'mutualite_reunion.png' },
  { distrId: 'mutualp', distrName: 'mutualp', logoName: 'mutualp.jpg' },
  { distrId: 'mutuelle_403', distrName: 'mutuelle_403', logoName: 'mutuelle_403.jpg' },
  { distrId: 'mutuelle_bleue', distrName: 'mutuelle_bleue', logoName: 'mutuelle_bleue.jpg' },
  { distrId: 'mutuelle_choletaise', distrName: 'mutuelle_choletaise', logoName: 'mutuelle_choletaise.png' },
  { distrId: 'mutuelle_corse', distrName: 'mutuelle_corse', logoName: 'mutuelle_corse.png' },
  { distrId: 'mutuelle_dargenson', distrName: 'mutuelle_dargenson', logoName: 'mutuelle_dargenson.jpg' },
  { distrId: 'mutuelle_de_la_police_nationale', distrName: 'mutuelle_de_la_police_nationale', logoName: 'mutuelle_de_la_police_nationale.jpg' },
  { distrId: 'mutuelle_dentraide_sociale_allaso_', distrName: 'mutuelle_dentraide_sociale_allaso_', logoName: 'mutuelle_dentraide_sociale_allaso_.jpg' },
  { distrId: 'mutuelle_des_pays_de_vilaine', distrName: 'mutuelle_des_pays_de_vilaine', logoName: 'mutuelle_des_pays_de_vilaine.png' },
  { distrId: 'mutuelle_du_pays_haut', distrName: 'mutuelle_du_pays_haut', logoName: 'mutuelle_du_pays_haut.jpg' },
  { distrId: 'mutuelle_du_rempart', distrName: 'mutuelle_du_rempart', logoName: 'mutuelle_du_rempart.jpg' },
  { distrId: 'mutuelle_du_var_emoa', distrName: 'mutuelle_du_var_emoa', logoName: 'mutuelle_du_var_emoa.jpg' },
  { distrId: 'mutuelle_entrenous', distrName: 'mutuelle_entrenous', logoName: 'mutuelle_entrenous.jpg' },
  { distrId: 'mutuelle_europe', distrName: 'mutuelle_europe', logoName: 'mutuelle_europe.jpg' },
  { distrId: 'mutuelle_familiale_de_l_indre', distrName: 'mutuelle_familiale_de_l_indre', logoName: 'mutuelle_familiale_de_l_indre.jpg' },
  { distrId: 'mutuelle_generale_des_cheminots', distrName: 'mutuelle_generale_des_cheminots', logoName: 'mutuelle_generale_des_cheminots.jpg' },
  { distrId: 'mutuelle_just', distrName: 'mutuelle_just', logoName: 'mutuelle_just.jpg' },
  { distrId: 'mutuelle_mclr', distrName: 'mutuelle_mclr', logoName: 'mutuelle_mclr.jpg' },
  { distrId: 'mutuelle_mipss', distrName: 'mutuelle_mipss', logoName: 'mutuelle_mipss.png' },
  { distrId: 'mutuelle_previfrance', distrName: 'mutuelle_previfrance', logoName: 'mutuelle_previfrance.jpg' },
  { distrId: 'mutuelle_sante_rsi', distrName: 'mutuelle_sante_rsi', logoName: 'mutuelle_sante_rsi.jpg' },
  { distrId: 'mutuelle_union_des_travailleurs_udt', distrName: 'mutuelle_union_des_travailleurs_udt', logoName: 'mutuelle_union_des_travailleurs_udt.jpg' },
  { distrId: 'mutuelle_verte', distrName: 'mutuelle_verte', logoName: 'mutuelle_verte.jpg' },
  { distrId: 'mutuelle_viasante', distrName: 'mutuelle_viasante', logoName: 'mutuelle_viasante.jpg' },
  { distrId: 'novamut', distrName: 'novamut', logoName: 'novamut.png' },
  { distrId: 'ociane_1', distrName: 'ociane_1', logoName: 'ociane_1.png' },
  { distrId: 'pavillon_prevoyance', distrName: 'pavillon_prevoyance', logoName: 'pavillon_prevoyance.png' },
  { distrId: 'precocia', distrName: 'precocia', logoName: 'precocia.jpg' },
  { distrId: 'prevoyance_mutualiste_dile-de-france', distrName: 'prevoyance_mutualiste_dile-de-france', logoName: 'prevoyance_mutualiste_dile-de-france.jpg' },
  { distrId: 'reunisolidarite', distrName: 'reunisolidarite', logoName: 'reunisolidarite.png' },
  { distrId: 'simirp', distrName: 'simirp', logoName: 'simirp.jpg' },
  { distrId: 'smi', distrName: 'smi', logoName: 'smi.jpg' },
  { distrId: 'smie', distrName: 'smie', logoName: 'smie.jpg' },
  { distrId: 'so_lyon', distrName: 'so_lyon', logoName: 'so_lyon.jpg' },
  { distrId: 'solimut-centre-ocean', distrName: 'solimut-centre-ocean', logoName: 'solimut-centre-ocean.png' },
  { distrId: 'sud_ouest_mutualite', distrName: 'sud_ouest_mutualite', logoName: 'sud_ouest_mutualite.png' },
  { distrId: 'uitsem', distrName: 'uitsem', logoName: 'uitsem.jpg' },
  { distrId: 'unc', distrName: 'unc', logoName: 'unc.jpg' },
  { distrId: 'unimutuelles', distrName: 'unimutuelles', logoName: 'unimutuelles.jpg' },
  { distrId: 'unmos', distrName: 'unmos', logoName: 'unmos.jpg' }
];

// export default distributerLogo;
module.exports = distributerLogo;
