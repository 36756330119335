/**
 * @flow
 * @relayHash cb5cbef9601bc135add3e0a283078d9a
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type onDemandeCopieContratMutationVariables = {|
  email: string
|};
export type onDemandeCopieContratMutationResponse = {|
  +onDemandeCopieContrat: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type onDemandeCopieContratMutation = {|
  variables: onDemandeCopieContratMutationVariables,
  response: onDemandeCopieContratMutationResponse,
|};
*/


/*
mutation onDemandeCopieContratMutation(
  $email: String!
) {
  onDemandeCopieContrat(email: $email) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "EmailMutationResponse",
    "kind": "LinkedField",
    "name": "onDemandeCopieContrat",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "onDemandeCopieContratMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "onDemandeCopieContratMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "cb5cbef9601bc135add3e0a283078d9a",
    "metadata": {},
    "name": "onDemandeCopieContratMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '852549da9ebd0a6e22f59b32b2c262f3';

module.exports = node;
