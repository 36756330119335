import React from 'react';
import PropTypes from 'prop-types';
import ChartLegend from '../components/ChartLegend';
import { Bar } from 'react-chartjs-2';
import * as R from 'ramda';
import { isInteger } from 'ramda-adjunct';

const options = {
  responsive: true,
  aspectRatio: 1 / 0.5,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: ({ raw, dataset }) => {
          const label = dataset.label || '';
          const shouldFormat = !label.includes('Nombre de sinistres');

          if (shouldFormat) {
            const v = raw.toLocaleString(undefined, { style: 'currency', currency: 'EUR' });
            return `${label}: ${v}`;
          }
          return `${label}: ${raw.toLocaleString(undefined)}`;
        }
      }
    }
  },
  scales: {
    y: {
      ticks: {
        callback: (value) =>
          value.toLocaleString(undefined, {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0
          })
      }
    },
    y2: {
      display: true,
      position: 'right',
      ticks: {
        callback: (value) => {
          if (isInteger(value)) {
            return value.toLocaleString(undefined);
          }
        }
      },
      grid: {
        drawOnChartArea: false // only want the grid lines for one axis to show up
      }
    },
    x: {
      grid: {
        display: false
      }
    }
  }
};

const applySecondAxies = R.map(
  R.ifElse(
    R.propEq('label', 'Nombre de sinistres'),
    R.assoc('yAxisID', 'y2'),
    R.assoc('yAxisID', 'y')
  )
);
const ChartPerPrestation = ({ title, data }) => {
  console.log(data);
  if (R.isEmpty(data.datasets)) {
    return (
      <div className='flex flex-col'>
        <div className='font-bold text-lg text-pink text-center justify-self-start'>{title}</div>
        <div className='text-lg text-center text-gray-600 justify-self-center h-full flex flex-col'>
          Aucune donnée disponible
        </div>
      </div>
    );
  }

  const datasetWithSecondAxis = applySecondAxies(data.datasets);
  const cleanData = R.assoc('datasets', datasetWithSecondAxis, data);

  return (
    <div>
      <div className='font-bold text-lg text-pink text-center'>{title}</div>
      <Bar options={options} data={cleanData} />
      <ChartLegend
        datasets={datasetWithSecondAxis}
        labels={R.map(R.prop('label'), cleanData.datasets)}
      />
    </div>
  );
};

ChartPerPrestation.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.any
};

ChartPerPrestation.defaultProps = {};

export default ChartPerPrestation;
