import { always, append, applySpec, compose, flatten, prop, toString } from 'ramda';
import { useEffect, useState } from 'react';
import { fetchQuery } from 'react-relay';
import environment from '../_graphql';
import { QDocumentsByAffiliateIdQuery } from '../_graphql/queries/QDocumentsByAffiliateId';

const useDocumentsByAffiliateId = ({ affiliateId, partyId, type, status, direction, page }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState({ documents: [], pagination: {} });

  useEffect(() => {
    let hasBeenCancelled = false;
    const fetchData = async () => {
      setIsLoaded(false);
      if (affiliateId) {
        const response = await fetchQuery(environment(), QDocumentsByAffiliateIdQuery, { affiliateId, partyId, type, status, direction, page: toString(page) });
        if (!hasBeenCancelled) {
          const resDocuments = prop('documentsByAffiliateId', response);
          setData(applySpec({
            documents: compose(flatten, append(prop('documents', resDocuments)), prop('documents')),
            pagination: always(prop('pagination', resDocuments))
          }));
          setIsLoaded(true);
        }
      }
    };
    fetchData();
    return () => (hasBeenCancelled = true);
  }, [affiliateId, partyId, type, status, direction, page]);

  return {
    data,
    isLoaded
  };
};

export default useDocumentsByAffiliateId;
