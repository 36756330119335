import React from 'react';
import PropTypes from 'prop-types';
import ChartRatioContract from './ChartRatioContract';
import classNames from 'classnames';
import ChartStackedContracts from './ChartStackedContracts';
import useFetchChartApporteurRepartition from '../../../../hooks/useFetchChartApporteurRepartition';
import Loading from '../../../../components/Loading';
import { path } from 'ramda';

const ChartDashboard = ({
  range,
  isStatsApporteurLoaded,
  year,
  type,
  apporteurs
}) => {
  const { apporteurDashboardRepartition, isLoaded } = useFetchChartApporteurRepartition({
    range,
    year,
    type,
    apporteurs
  });

  const isYear = range === 'year';

  if (!isStatsApporteurLoaded) {
    return (
      <Loading />
    );
  }

  return (
    <div className={classNames('mt-4', { 'grid grid-cols-1 lg:grid-cols-2 gap-4': isYear })}>
      {isYear && (
        <>
          <ChartRatioContract
            year={year - 1}
            nbContractsPotentiel={path(['contracts', 'proposition', 0], apporteurDashboardRepartition)}
            nbContractsActif={path(['contracts', 'transformed', 0], apporteurDashboardRepartition)}
          />
          <ChartRatioContract
            year={year}
            nbContractsPotentiel={path(['contracts', 'proposition', 1], apporteurDashboardRepartition)}
            nbContractsActif={path(['contracts', 'transformed', 1], apporteurDashboardRepartition)}
          />
        </>
      )}
      {(isLoaded && apporteurDashboardRepartition !== undefined) && (
        <ChartStackedContracts
          className={classNames('mt-4', { 'lg:col-span-2': isYear })}
          labels={apporteurDashboardRepartition.labels}
          data={{
            ca: apporteurDashboardRepartition.ca,
            contracts: apporteurDashboardRepartition.contracts,
            effectifs: apporteurDashboardRepartition.effectifs
          }}
        />

      )}
    </div>
  );
};

ChartDashboard.propTypes = {
  year: PropTypes.number,
  type: PropTypes.string,
  range: PropTypes.oneOf(['year', 'semester', 'month']).isRequired,
  apporteurs: PropTypes.arrayOf(PropTypes.string),
  repartition: PropTypes.any,
  isStatsApporteurLoaded: PropTypes.bool,
  contractCount: PropTypes.shape({
    previousYear: PropTypes.shape({
      contractsActifs: PropTypes.number,
      contractsPotentiels: PropTypes.number
    }),
    currentYear: PropTypes.shape({
      contractsActifs: PropTypes.number,
      contractsPotentiels: PropTypes.number
    })
  })
};

ChartDashboard.defaultProps = {
};

export default ChartDashboard;
