import React, { useState } from 'react';
import * as R from 'ramda';
import { Block, Page } from '@@components';
import ContractCharts from './ContractCharts';
import withUser from '../../userSession/withUser';
import QApporteurContractDetail from '../../../_graphql/queries/QApporteurContractDetail';
import ContractDetail from '../containers/portefeuille/ContractDetail';
import ContractBlock from '../containers/portefeuille/ContractBlock';
import { USER_PROPTYPES } from '../../../propTypes/user/UserPropTypes';

const Index = ({ user }) => {
  const { register, controls } = Block.useBlocks();
  const [contractDetailData, setContractDetailData] = useState(null);

  const isApporteurOrManager = R.or(R.propEq('role', 'MANAGER', user), R.propEq('role', 'APPORTEUR', user));

  return (
    <>
      {contractDetailData && (
        <Page title={<div className="flex"><img className="pr-3 cursor-pointer mt-2 h-6 w-6" src="/icons/back.svg" alt="Icone flèche de retour" onClick={() => setContractDetailData(null)} />Détails du contrat</div>}>
          {!R.isNil(R.prop('detail', contractDetailData))
            ? (<ContractDetail contract={R.prop('detail', contractDetailData)} />)
            : (<QApporteurContractDetail args={{ id: R.prop('id', contractDetailData), type: R.prop('type', contractDetailData) }}>
              {({ apporteurContractDetail }) => {
                return (<ContractDetail contract={apporteurContractDetail} />);
              }}
            </QApporteurContractDetail>)
          }
        </Page>
      )}
      <div className={contractDetailData ? 'hidden' : ''} >
        <Page title="Mon portefeuille" >
          {isApporteurOrManager && (
            <Block name="contract-list" title={'Mes contrats'} color="pink"
              register={register({ opened: true, canClose: false })}
              controls={controls}
            >
              <ContractCharts />
            </Block>
          )}

          <ContractBlock register={register} controls={controls} onSelectContract={setContractDetailData}/>
        </Page>
      </div>
    </>
  );
};

Index.propTypes = USER_PROPTYPES;

export default withUser(Index);
