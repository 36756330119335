/**
 * @flow
 * @relayHash da365e1c2005f9328812254d16e1c9a3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type onProcedureResiliationContratMutationVariables = {|
  email: string
|};
export type onProcedureResiliationContratMutationResponse = {|
  +onProcedureResiliationContrat: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type onProcedureResiliationContratMutation = {|
  variables: onProcedureResiliationContratMutationVariables,
  response: onProcedureResiliationContratMutationResponse,
|};
*/


/*
mutation onProcedureResiliationContratMutation(
  $email: String!
) {
  onProcedureResiliationContrat(email: $email) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "concreteType": "EmailMutationResponse",
    "kind": "LinkedField",
    "name": "onProcedureResiliationContrat",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "onProcedureResiliationContratMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "onProcedureResiliationContratMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "da365e1c2005f9328812254d16e1c9a3",
    "metadata": {},
    "name": "onProcedureResiliationContratMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c5acbcf2a4db89dcb0687ccb0583d618';

module.exports = node;
