import React from 'react';
import * as R from 'ramda';
import { isTns } from '../../../lib/getCompanyType';
import ColoredIcon from '@@components/ColoredIcon';
import { Tooltip } from 'antd';
import removeStartsValue from '../../../lib/removeStartsValue';

const Company = ({ company, user }) => {
  const renderSentence = R.compose(
    removeStartsValue('Distributeur : '),
    R.propOr('', 'sentence')
  );

  return (
    <div className="f-company">
      <div className="sm:col-span-4 lg:col-span-2">
        <div className="f-company-title">{isTns(user) && R.prop('title', company)} {R.prop('firstname', company)} {R.prop('name', company)}</div>
        <div>{R.prop('siret', company)} {isTns(user) && R.prop('banner', company)}</div>
        {!isTns(user) && (
          <div>{ R.pathOr('Le nom du contact n\'est pas renseigné', ['details', 'contactName'], company) }</div>
        )}
        <div className="md:flex w-full gap-2">
          <div>
            <ColoredIcon color="plum" icon="icon-phone.svg" className="float-left mr-1 mt-1" height={14}/>
            {R.pathOr('Non renseigné', ['details', 'phone'], company)}
          </div>
          <div className="hidden md:flex"> - </div>
          <div>
            <ColoredIcon color="plum" icon="icon-mail.svg" className="float-left mr-1"/>
            <b>{R.pathOr('Non renseigné', ['details', 'email'], company)}</b>
          </div>
        </div>
      </div>
      <div className="f-company-logo">
        <Tooltip placement="top" color={'#4f121c'} title={`${renderSentence(user)}`}>
          <img src={'/images/icon-info-svg.svg'} className={'info-logo'} />
        </Tooltip>
        <img src={`/image/distributer?t=${Math.random()}`} onError={e => { e.target.style.display = 'none'; } } alt="Logo de la Compagnie" />
        <span className="ml-4">{R.path(['networkInformation', 'groupName'], user)}</span>
      </div>
    </div>
  );
};

export default Company;
