import React from 'react';
import classNames from 'classnames';
import { PropTypes } from 'prop-types';
import ColoredIcon from '@@components/ColoredIcon';

import * as R from 'ramda';

const toggleListItem = R.curry((value, list) => R.ifElse(
  R.contains(value),
  R.without(value),
  R.append(value)
)(list));

const ChartLegend = ({ datasets, disabledDatasets, onDisableDataset, labels, isPie }) => {
  if (isPie) { return (<LegendOne datasets={datasets} disabledDatasets={disabledDatasets} onDisableDataset={onDisableDataset} labels={labels}/>); }
  return (<LegendMulti datasets={datasets} disabledDatasets={disabledDatasets} onDisableDataset={onDisableDataset} />);
};

const LegendMulti = ({ datasets, disabledDatasets, onDisableDataset }) => {
  return (
    <div className="flex flex-row gap-4 flex-wrap justify-center">
      {datasets.map((dataset, index) => {
        const isDisabled = R.isEmpty(dataset.data);
        return (
          <div key={index} className={classNames('cursor-pointer flex', {
            'text-gray-600': isDisabled
          })} onClick={() => R.not(R.isNil(onDisableDataset)) && onDisableDataset(toggleListItem(dataset.label, disabledDatasets))}>
            {R.not(R.isNil(onDisableDataset)) && (
              <input
                className="mr-1 f-checkbox-pink"
                type="checkbox"
                readOnly
                checked={!disabledDatasets.includes(dataset.label) && !R.isEmpty(dataset.data)}
                disabled={isDisabled}
              />
            )}
            <ColoredIcon className="inline-block mx-1 font-roboto" icon='pill-graph.svg' color={R.ifElse(R.is(Array), R.head, R.identity)(dataset.backgroundColor)} height={15} width={15}/>
            <div className="-mt-1">
              {dataset.label}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const LegendOne = ({ datasets, disabledDatasets, onDisableDataset, labels }) => {
  const dataset = R.head(datasets);

  return (
    <div className="flex flex-row gap-4 flex-wrap justify-center mt-2">
      {dataset.data.map((data, i) => {
        const isDisabled = data === 0;
        return (
          <div key={i} className={classNames('cursor-pointer flex', {
            'text-gray-600': isDisabled
          })} onClick={() => R.not(R.isNil(onDisableDataset)) && onDisableDataset(toggleListItem(dataset.key, disabledDatasets))}>
            {R.not(R.isNil(onDisableDataset)) && (
              <input
                className="mr-1 f-checkbox-pink"
                type="checkbox"
                readOnly
                checked={!disabledDatasets.includes(dataset.key) && !R.isEmpty(dataset.data)}
                disabled={isDisabled}
              />
            )}
            <ColoredIcon className="inline-block mx-1" icon='pill-graph.svg' color={R.nth(i, dataset.backgroundColor)} height={15} width={15}/>
            <div className="-mt-1">
              {R.nth(i, labels)}
            </div>
          </div>
        );
      })}
    </div>
  );
};

ChartLegend.propTypes = {
  datasets: PropTypes.arrayOf(PropTypes.object).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string),
  disabledDatasets: PropTypes.arrayOf(PropTypes.string),
  onDisableDataset: PropTypes.func,
  isPie: PropTypes.bool,
  canDisable: PropTypes.bool

};

ChartLegend.defaultProps = {
  disabledDatasets: []
};

export default ChartLegend;
