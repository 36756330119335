import React from 'react';
import PropTypes from 'prop-types';

import {
  useTable,
  useFilters,
  usePagination,
  useFlexLayout,
  useSortBy
} from 'react-table';

import { useMeasure, isMobile } from '@@hooks/useMeasure';
import classNames from 'classnames';

import {
  dateFilter,
  numberFilter,
  textFilter
} from './filters';

import * as R from 'ramda';
import { Pagination } from '@@components';

const DefaultColumnFilter = ({
  column: { filterValue, setFilter }
}) => (
  <div>
    <input
      className="f-input"
      value={R.defaultTo('', filterValue)}
      onChange={(e) => {
        const value = R.pathOr('', ['target', 'value'], e);
        setFilter(value);
      }}
      placeholder={'Rechercher'}
    />
  </div>
);

const DefaultSort = ({ column }) => {
  const isSortable = R.allPass([
    R.propOr(true, 'canSort'),
    R.propOr(true, 'sortable')
  ]);

  if (!isSortable(column)) return (<></>);

  const header = R.compose(
    R.toLower,
    R.prop('Header')
  )(column);

  return (<div className="flex flex-col justify-center gap-1" style={{ width: 12 }}{...column.getSortByToggleProps({ title: `Trier par ${header}` })}>
    <img
      style={{ width: 12, height: 8, minWidth: 12, minHeight: 8 }}
      className={classNames(
        'opacity-50',
        {
          'opacity-100': (column.isSorted && !column.isSortedDesc)
        })}
      src='/icons/icon-tri.svg'
      alt="Icone de tri" />
    <img
      style={{ width: 12, height: 8, minWidth: 12, minHeight: 8 }}
      className={classNames(
        'transform rotate-180 opacity-50',
        {
          'opacity-100': (column.isSorted && column.isSortedDesc)
        })} src='/icons/icon-tri.svg'
      alt="Icone de tri" />
  </div>);
};

const Table = ({
  columns,
  data,
  pagination,
  hideFilters,
  onRowClick,
  isBlock,
  initialState,
  withPagination,
  onPageChange,
  manualPagination
}) => {
  const { dimensions } = useMeasure();

  const filterTypes = React.useMemo(
    () => ({
      dateFilter,
      numberFilter,
      textFilter,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
            : true;
        });
      }
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter
    }),
    []
  );

  const tablePlugins = R.reject(R.isNil, [
    hideFilters ? undefined : useFilters,
    useSortBy,
    useFlexLayout,
    usePagination
  ]);

  const {
    getTableProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    gotoPage,
    state: { pageIndex }
  } = useTable({
    columns,
    data,
    defaultColumn,
    filterTypes,
    autoResetFilters: false,
    autoResetSortBy: false,
    autoResetPage: false,
    manualPagination: manualPagination,
    initialState: {
      pageSize: R.propOr(10, 'limit', pagination),
      pageIndex: R.dec(R.propOr(1, 'page', pagination)),
      pageCount: R.propOr(-1, 'pages', pagination),
      ...initialState
    }
  },
  ...tablePlugins
  );

  if (isBlock && isMobile(R.prop('width', dimensions))) {
    return (
      <div>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <div
              key={row.id}
              className={classNames('f-block-table', { 'cursor-pointer': R.not(R.isNil(onRowClick)) })}
              onClick={() => R.not(R.isNil(onRowClick)) && onRowClick(row.original)}
            >
              <table>
                {row.cells.map((cell) => {
                  return <tr key={cell.column.id}>
                    <th scope="col">{cell.column.Header}</th><td>{cell.value}</td>
                  </tr>;
                })}
              </table>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      <table className="w-full min-heigth-25 overflow-x-scroll" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} className="border-collapse px-2" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th scope="col" key={index} className="flex flex-col justify-between text-left pr-3 select-none" {...column.getHeaderProps()}>
                  <div className={classNames(
                    'font-bold uppercase flex gap-4',
                    {
                      'text-grey-blue': !column.isSorted,
                      'text-plum': column.isSorted
                    }
                  )}>
                    {column.render('Header')}
                    {!column.canFilter && <DefaultSort column={column}/>}
                  </div>

                  {column.canFilter && (
                    <div className="flex gap-2">
                      {column.render('Filter')}
                      <DefaultSort column={column} />
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {(R.isNil(pagination) ? rows : page).map((row) => {
            prepareRow(row);

            return (<tr
              key={row.id}
              className={
                classNames(
                  'f-table-row border-collapse',
                  {
                    'cursor-pointer': R.not(R.isNil(onRowClick))
                  }
                )
              }
              onClick={() => R.not(R.isNil(onRowClick)) && onRowClick(row.original)}
              {...row.getRowProps()}
            >
              {row.cells.map((cell, index) => (
                <td
                  key={index}
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>);
          })}
        </tbody>
      </table>
      {withPagination && (
        <Pagination
          onChange={R.compose(
            R.tap(onPageChange),
            R.tap(gotoPage),
            R.dec
          )}
          current={manualPagination ? pagination.page : pageIndex + 1}
          pageSize={pagination.limit}
          total={manualPagination ? pagination.total : R.length(data)}
        />
      )}
    </div>
  );
};

Table.propTypes = {
  hideFilters: PropTypes.bool,
  withPagination: PropTypes.bool,
  isBlock: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  pagination: PropTypes.shape({
    pages: PropTypes.number,
    page: PropTypes.number,
    limit: PropTypes.number,
    count: PropTypes.number
  }),
  onRowClick: PropTypes.func,
  manualPagination: PropTypes.bool,
  onPageChange: PropTypes.func
};

Table.defaultProps = {
  manualPagination: false,
  hideFilters: false,
  isBlock: false,
  onPageChange: () => {}
};

export default Table;
