/**
 * @flow
 * @relayHash 7ec6a3f0d4527666d24a95ca2509bd82
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CloseApporteurAccountInput = {|
  closedAccountId: string,
  closedAccountLogin?: ?string,
  closedAccountName?: ?string,
  closingDate: string,
  transferApporteur?: ?$ReadOnlyArray<?TransferApporteur>,
|};
export type TransferApporteur = {|
  apporteurId: string,
  name?: ?string,
|};
export type onCloseApporteurAccountMutationVariables = {|
  apporteurInfo: CloseApporteurAccountInput
|};
export type onCloseApporteurAccountMutationResponse = {|
  +onCloseApporteurAccount: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type onCloseApporteurAccountMutation = {|
  variables: onCloseApporteurAccountMutationVariables,
  response: onCloseApporteurAccountMutationResponse,
|};
*/


/*
mutation onCloseApporteurAccountMutation(
  $apporteurInfo: CloseApporteurAccountInput!
) {
  onCloseApporteurAccount(apporteurInfo: $apporteurInfo) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "apporteurInfo"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "apporteurInfo",
        "variableName": "apporteurInfo"
      }
    ],
    "concreteType": "CloseApporteurAccountResponse",
    "kind": "LinkedField",
    "name": "onCloseApporteurAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "onCloseApporteurAccountMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "onCloseApporteurAccountMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "7ec6a3f0d4527666d24a95ca2509bd82",
    "metadata": {},
    "name": "onCloseApporteurAccountMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a211ec03ca5cbefdf3d73d3fadf3addc';

module.exports = node;
