import React, { useEffect, useState } from 'react';
import Forms from '@@components/forms';
import useForms from '@@formsHook';

import { useFeedback } from '../../hooks/useFeedback';

const RequestPassword = () => {
  const { onFormsSubmit, formsData, fetching } = useForms('requestPassword');
  const [isSend, setIsSend] = useState(false);

  useFeedback(fetching);

  useEffect(() => {
    if (fetching.isDone()) {
      setIsSend(true);
      fetching.reset();
    }
  }, [fetching]);

  const onSendHandler = (data) => {
    onFormsSubmit(data);
  };

  return (<>
    {isSend ? (
      <div className="flex flex-col gap-4">
        <div className="text-gray-600 text-lg font-semibold">La demande a été envoyée</div>
        <button aria-label="Faire une nouvelle demande" className="f-button f-button-pink w-1/2" onClick={() => setIsSend(false)}>Faire une nouvelle demande</button>
      </div>
    ) : (
      <Forms
        formsData={formsData}
        onSubmit={onSendHandler}
        submitSection={() => (
          <button aria-label="Demande de mot de passe" type="submit" className="f-button f-button-pink">Demande de mot de passe</button>
        )}
      />
    )}
  </>
  );
};

export default RequestPassword;
