import React, { useState, useEffect } from 'react';
import propTypes from 'prop-types';

import { SelectOneOption } from '@@components/input';
import { Message, Block } from '@@components';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { affiliatesToOptions, onSelectAffiliate } from '../../../../lib/affiliates';
import { useBeforeUnload, useBlockTransition } from '@@hooks';
import {
  employType,
  familySituation,
  titles,
  yesNoType,
  documentTypeOptions,
  affiliateRelations
} from '@@refs';
import { isCompany } from '../../../../lib/getCompanyType';
import { parseFloatOr } from '../../../../lib/parseFloatOr';

import { useForm } from 'react-hook-form';
import DocumentUploadBlock from '../DocumentUploadBlock';

import { getRequiredDocumentsValidator, nameIsIncluded } from '../../../../lib/documentUploadHelpers';
import { decesPostalFiles } from '../../../../../refs/postalFiles';
import { COMPANY_PROPTYPES } from '../../../../propTypes/user/CompanyPropTypes';
import { USER_PROPTYPES } from '../../../../propTypes/user/UserPropTypes';

const R = require('ramda');

const contactFields = [
  'contactCivility',
  'contactBirthname',
  'contactFirstname',
  'contactBirthdate',
  'contactParentLink',
  'contactPhone',
  'contactEmail'
];

const attachmentsFields = [
  'attachments'
];

const requiredDocumentsValidators = getRequiredDocumentsValidator(documentTypeOptions);

const DeclareDeces = ({ affiliates, user, company, contractId }) => {
  const [defaultValues, setDefaultValues] = useState({});
  const getDefaultValues = R.ifElse(
    isCompany,
    R.always(defaultValues),
    R.always({
      firstname: R.prop('firstname', company)
    })
  );

  useBeforeUnload(defaultValues);
  const [showDocumentsErrorMessage, setShowDocumentsErrorMessage] = useState(false);
  const { register: registerBlock, controls } = Block.useBlocks();
  const { fetching, onFormsSubmit, formsData } = useForms('declareSinistreDeces');
  const { handleSubmit, register, control, reset, getValues, watch, errors, setValue } = useForm({ defaultValues: getDefaultValues(user) });

  useEffect(() => {
    R.compose(
      R.map(pair => setValue(pair[0], pair[1])),
      R.toPairs
    )(defaultValues);
  }, [defaultValues]);

  if (fetching.isDone()) {
    return <Message
      value="Votre demande vient d'être transmise à notre service prestations"
      action='Ok'
      onClick={() => fetching.reset()}
    />;
  }

  const onSubmit = () => {
    const data = R.assoc('contractId', contractId, getValues());

    const newData = R.evolve({
      childrens: parseFloatOr(0),
      salaryTrimestrial: parseFloatOr(0),
      primesLastYear: parseFloatOr(0)
    })(data);
    setShowDocumentsErrorMessage(false);
    const attachments = R.propOr([], 'attachments', newData);

    const validatedDocuments = R.reduce((acc, validator) => !acc ? acc : validator(attachments), true)(requiredDocumentsValidators);
    if (validatedDocuments) {
      onFormsSubmit(
        R.evolve({
          childrens: parseFloatOr(0),
          salaryTrimestrial: parseFloatOr(0),
          primesLastYear: parseFloatOr(0),
          contractId
        })(data)
      );
    } else {
      setShowDocumentsErrorMessage(true);
    }
  };

  const baseForm = R.compose(
    R.reject(nameIsIncluded(contactFields)),
    R.reject(nameIsIncluded(attachmentsFields))
  )(formsData);

  const attachmentsForm = R.filter(nameIsIncluded(attachmentsFields), formsData);
  const contactForm = R.filter(nameIsIncluded(contactFields), formsData);

  return <>
    <form onSubmit={handleSubmit(onSubmit)} >
      <Block
        register={registerBlock({ open: true, canClose: false })}
        color="pink"
        icon="icon-sinistre.svg"
        title={'Information sur la personne'}
        controls={controls}
      >
        {isCompany(user) && (
          <SelectOneOption
            options={affiliatesToOptions(affiliates)}
            onChange={({ value }) => onSelectAffiliate(affiliates)(setDefaultValues, value)}
            className="mt-5"
            placeholder={'Sélectionner un affilié'}
          />)}
        <Forms
          columns={2}
          formsData={baseForm}
          options={{
            titles,
            yesNoType,
            employType,
            familySituation,
            affiliateRelations
          }}
          submitSection={() => { }}
          wrapInForm={false}
          forwardedUseForm={{ handleSubmit, register, control, reset, getValues, watch, errors, setValue }}
        />
      </Block>
      <Block
        name="sinistreContact"
        register={registerBlock({ open: true, canClose: false })}
        color="pink"
        icon="icon-mail.svg"
        title={'Personne à contacter'}
        controls={controls}
      >
        <div>
          Si l’ensemble des bénéficiaires souhaitent nommer un interlocuteur unique pour la constitution administrative du dossier et/ou le paiement de la prestation, merci de renseigner les éléments suivants :
        </div>
        <Forms
          columns={2}
          formsData={contactForm}
          options={{
            titles,
            affiliateRelations
          }}
          submitSection={() => {}}
          wrapInForm={false}
          forwardedUseForm={{ handleSubmit, register, control, reset, getValues, watch, errors, setValue }}
        />
      </Block>

      <div className="flex flex-col gap-4">
        <DocumentUploadBlock
          name="sinistreAttachments"
          register={register}
          controls={controls}
          listAttachments={documentTypeOptions}
          showErrorMessage={showDocumentsErrorMessage}
          form={{ handleSubmit, register, control, reset, getValues, watch, errors, setValue }}
          formData={attachmentsForm}
          postalFiles={decesPostalFiles}
        />
        <button className="f-button f-button-pink"
          aria-label="Déclarer mon sinistre"
          disabled={fetching.isFetching()}
          type="submit">
          {'Déclarer mon sinistre'}
        </button>
      </div>
    </form>
    {useBlockTransition(defaultValues)}
  </>;
};

DeclareDeces.propTypes = {
  affiliates: () => {},
  user: propTypes.shape(USER_PROPTYPES),
  company: propTypes.shape(COMPANY_PROPTYPES),
  contractId: propTypes.string
};

export default DeclareDeces;
