import React from 'react';
import withUser from '../userSession/withUser';
import Me from './account';
import ContacterNous from './contactez_nous';
import MesAffilies from './mes_affilies';
import MesCotisations from './mes_cotisations';
import MesPrestations from './mes_prestations';
import MonCoffreFort from './mon_coffre_fort';
//import MesSinistres from './mes_sinistres';
import MonContrat from './mon_contrat';
import TableauDeBord from './tableau_de_bord';
import { Menu } from '@@components/elements';
import { compose, not, equals, either } from 'ramda';
import { hasMoreThanOneElement } from '../../lib/hasMoreThanOneElement';

import * as R from 'ramda';
import useCompany from '../../hooks/useCompany';

const menuItems = (companyType, contracts, documentsCount) => {
  const items = [{
    label: 'Tableau de bord', path: '/tableaudebord', component: TableauDeBord, icon: 'menu-dashboard.svg', showInMenu: true
  }, {
    label: hasMoreThanOneElement(contracts) ? 'Mes contrats' : 'Mon contrat', path: '/moncontrat', component: MonContrat, icon: 'menu-contrat.svg', showInMenu: true
  }, {
    // FIXME: extract to function
    label: 'Mes affiliés', path: '/mesaffilies', component: MesAffilies, icon: 'menu-affilies.svg', showInMenu: compose(not, either(equals('Personne Physique'), equals('Entreprise en nom propre')))(companyType)
  }, {
    label: 'Mes cotisations', path: '/mescotisations', component: MesCotisations, icon: 'menu-calendar.svg', showInMenu: true, hideOn: ['Personne Morale']
  }, {
    label: 'Mes prestations', path: '/mesprestations', component: MesPrestations, icon: 'menu-prestas.svg', showInMenu: true
  }, {
    label: 'Mon coffre-fort', path: '/moncoffrefort', component: MonCoffreFort, icon: 'menu-coffre.svg', showInMenu: (documentsCount > 0)
  },
  // {
  //   label: 'Mes sinistres', path: '/messinistres', component: MesSinistres, icon: 'menu-sinistres.svg', showInMenu: true
  // }, 
  {
    label: 'Mon profil', path: '/monprofil', component: Me, icon: 'icon-neutre.svg', showInMenu: true
  }, {
    label: 'Contacter-nous', path: '/contacteznous', component: ContacterNous
  }];

  return R.reject(
    R.compose(
      R.includes(companyType),
      R.propOr([], 'hideOn')
    )
    , items
  );
};

const MenuStandard = ({ user }) => {
  const { getContracts, getDocuments } = useCompany();
  // FIXME fetch documentsCount
  const documentsCount = R.length(getDocuments());
  return <Menu items={menuItems(user.companyType, getContracts(), documentsCount)}/>;
};

export default withUser(MenuStandard);

export {
  menuItems
};
