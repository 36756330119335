import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

const QNetworkApporteursQuery = graphql`
  query QNetworkApporteursQuery {
    networkApporteurs {
      id
      firstname
      lastname
      login
      email
      openning_date
    }
  }
`;

export const QNetworkApporteurs = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QNetworkApporteursQuery}
      args={args}
      mockData={mockData}
    >
      {populateChildren(['networkApporteurs'])(children, childDataProp)}
    </Query>
  );
};
