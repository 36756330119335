/**
 * @flow
 * @relayHash cb9a284ebdb7f2973a0540d48669733e
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TypeSouscripteurEnum = "COMPANY" | "PARTICULAR" | "TNS" | "%future added value";
export type QApporteurContractsQueryVariables = {|
  siren?: ?string,
  souscripteur?: ?string,
  allowMaxContracts?: ?number,
|};
export type QApporteurContractsQueryResponse = {|
  +apporteurContracts: ?{|
    +count: ?number,
    +contracts: ?$ReadOnlyArray<?{|
      +unencryptedId: ?number,
      +idCrypt: ?string,
      +num: ?string,
      +apporteur: ?string,
      +souscripteur: ?string,
      +typeSouscripteur: ?TypeSouscripteurEnum,
      +colleges: ?$ReadOnlyArray<?string>,
      +status: ?string,
      +detail: ?{|
        +num: ?string,
        +unencryptedId: ?number,
        +idCrypt: ?string,
        +social: ?string,
        +productName: ?string,
        +identity: ?string,
        +cotisation: ?number,
        +dateEffect: ?string,
        +soldeContract: ?number,
        +typeContract: ?TypeSouscripteurEnum,
        +tauxCotisation: ?{|
          +codeId: ?number,
          +rates: ?$ReadOnlyArray<?number>,
          +contributionLongName: ?string,
        |},
        +college: ?string,
        +garantyList: ?$ReadOnlyArray<?string>,
        +apporteurName: ?string,
        +statusContract: ?string,
        +dsnEnabled: ?boolean,
      |},
    |}>,
  |}
|};
export type QApporteurContractsQuery = {|
  variables: QApporteurContractsQueryVariables,
  response: QApporteurContractsQueryResponse,
|};
*/


/*
query QApporteurContractsQuery(
  $siren: String
  $souscripteur: String
  $allowMaxContracts: Int
) {
  apporteurContracts(siren: $siren, souscripteur: $souscripteur, allowMaxContracts: $allowMaxContracts) {
    count
    contracts {
      unencryptedId
      idCrypt
      num
      apporteur
      souscripteur
      typeSouscripteur
      colleges
      status
      detail {
        num
        unencryptedId
        idCrypt
        social
        productName
        identity
        cotisation
        dateEffect
        soldeContract
        typeContract
        tauxCotisation {
          codeId
          rates
          contributionLongName
        }
        college
        garantyList
        apporteurName
        statusContract
        dsnEnabled
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "allowMaxContracts"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siren"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "souscripteur"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unencryptedId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "idCrypt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "num",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "allowMaxContracts",
        "variableName": "allowMaxContracts"
      },
      {
        "kind": "Variable",
        "name": "siren",
        "variableName": "siren"
      },
      {
        "kind": "Variable",
        "name": "souscripteur",
        "variableName": "souscripteur"
      }
    ],
    "concreteType": "ApporteurContracts",
    "kind": "LinkedField",
    "name": "apporteurContracts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "count",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ApporteurContract",
        "kind": "LinkedField",
        "name": "contracts",
        "plural": true,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "apporteur",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "souscripteur",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "typeSouscripteur",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "colleges",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ApporteurContractDetail",
            "kind": "LinkedField",
            "name": "detail",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "social",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "productName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "identity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cotisation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dateEffect",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "soldeContract",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "typeContract",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CotisationType",
                "kind": "LinkedField",
                "name": "tauxCotisation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "codeId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rates",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contributionLongName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "college",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "garantyList",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "apporteurName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "statusContract",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dsnEnabled",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QApporteurContractsQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QApporteurContractsQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "id": "cb9a284ebdb7f2973a0540d48669733e",
    "metadata": {},
    "name": "QApporteurContractsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b04812625ca8fc3a192334998a745a7f';

module.exports = node;
