import { applySpec, prop, map, compose, toUpper, replace } from 'ramda';

const reasons = [
  {
    label: 'Démission'
  },
  {
    label: 'Licenciement'
  },
  {
    label: 'Retraite'
  },
  {
    label: 'Départ (fin ou rupture du contrat)'
  },
  {
    label: 'Licenciement suite à liquidation judiciaire ou à redressement judiciaire'
  },
  {
    label: 'Licenciement suite à fermeture définitive de l\'établissement'
  },
  {
    label: 'Licenciement pour motif économique'
  },
  {
    label: 'Licenciement pour fin de chantier'
  },
  {
    label: 'Licenciement pour autre motif'
  },
  {
    label: 'Autre fin de contrat pour motif économique'
  },
  {
    label: 'Rupture pour motif économique dans le cadre d\'un contrat de sécurisation professionnelle CSP'
  },
  {
    label: 'Fin de contrat à durée déterminée ou fin d\'accueil occasionnel'
  },
  {
    label: 'Fin de mission d\'intérim'
  },
  {
    label: 'Rupture anticipée d\'un CDD ou d\'un contrat de mission en cas d\'inaptitude physique constatée par le médecin du travail'
  },
  {
    label: 'Fin de période d\'essai à l\'initiative de l\'employeur'
  },
  {
    label: 'Fin de période d\'essai à l\'initiative du salarié'
  },
  {
    label: 'Rupture anticipée d\'un CDD, d\'un contrat d\'apprentissage ou d\'un contrat de mission à l\'initiative de l\'employeur'
  },
  {
    label: 'Rupture anticipée d\'un CDD, d\'un contrat d\'apprentissage ou d\'un contrat de mission à l\'initiative du salarié'
  },
  {
    label: 'Mise à la retraite par l\'employeur'
  },
  {
    label: 'Départ à la retraite à l\'initiative du salarié'
  },
  {
    label: 'Rupture conventionnelle'
  },
  {
    label: 'Prise d\'acte de la rupture de contrat de travail'
  },
  {
    label: 'Décès de l\'employeur ou internement / conduit à un licenciement autre motif'
  },
  {
    label: 'Décès du salarié'
  },
  {
    label: 'Fin de contrat d\'apprentissage'
  },
  {
    label: 'Résiliation judiciaire du contrat de travail'
  },
  {
    label: 'Rupture de contrat de travail ou d\'un contrat de mission pour force majeure ou fait du prince'
  },
  {
    label: 'Rupture d\'un commun accord du CDD, du contrat d\'apprentissage ou d\'un contrat de mission'
  },
  {
    label: 'Fin de mandat'
  },
  {
    label: 'Licenciement convention CATS'
  },
  {
    label: 'Licenciement pour faute grave'
  },
  {
    label: 'Licenciement pour faute lourde'
  },
  {
    label: 'Licenciement pour force majeure'
  },
  {
    label: 'Licenciement pour inaptitude physique d\'origine non professionnelle'
  },
  {
    label: 'Licenciement pour inaptitude physique d\'origine professionnelle'
  },
  {
    label: 'Licenciement suite à décision d\'une autorité administrative'
  },
  {
    label: 'Rupture anticipée du contrat de travail pour arrêt de tournage'
  },
  {
    label: 'Rupture anticipée du contrat de travail ou d\'un contrat de mission pour faute grave'
  },
  {
    label: 'Rupture anticipée du contrat de travail ou d\'un contrat de mission pour faute lourde'
  },
  {
    label: 'Rupture anticipée d\'un contrat de travail ou d\'un contrat de mission suite à fermeture de l\'établissement'
  },
  {
    label: 'Retrait d\'enfant'
  },
  {
    label: 'Transfert du contrat de travail sans rupture du contrat vers un autre établissement n\'effectuant pas encore de DSN'
  },
  {
    label: 'Fin de relation avec l\'employeur (autres que contrat de travail, convention ou mandat)'
  }
];

const filtered = map(
  applySpec({
    label: prop('label'),
    value: compose(
      toUpper,
      replace(/à/g, 'a'),
      replace(/ê/g, 'e'),
      replace(/\)/g, '_'),
      replace(/\(/g, '_'),
      replace(/é/g, 'e'),
      replace(/ /g, '_'),
      replace(/'/, '_'),
      prop('label')
    )
  })
)(reasons);

export default filtered;
