import { graphql } from 'relay-runtime';
import useFetchQuery from './useFetchQuery';

const query = graphql`
  query useEnvQuery {
    env {
      SERVICE_OAV
    }
  }
`;

const useEnv = () => {
  const { isLoaded, env } = useFetchQuery({ query, dataProp: 'env' });

  return { env, isLoadedEnv: isLoaded };
};

export default useEnv;
