import React from 'react';
import PropTypes from 'prop-types';
import RibInput from '@@components/input/RibInput';

const R = require('ramda');

const RibListInput = ({ onChange, value }) => {
  const onChangeRib = (index) => (ribValue) => {
    if (R.equals(-1, index) || R.isEmpty(value)) {
      // * If it's a empty list -> First add
      onChange(R.append(ribValue, value));
    } else {
      // * If it's an edit information
      onChange(R.update(index, ribValue, value));
    }
  };

  const onDeleteRib = (index) => () => {
    onChange(R.remove(index, 1, value));
  };

  return (<div>
    <div className="flex flex-col gap-4">

      {R.isEmpty(value) && (<div className="text-gray-600 text-center">
          Aucun RIB renseigné
      </div>)}

      {value.map((rib, index) => (
        <RibInput
          value={rib}
          onDelete={onDeleteRib(index)}
          onChange={onChangeRib(index)}
          key={index}
        />
      )
      )}

    </div>
  </div>);
};

RibListInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      iban: PropTypes.string,
      bic: PropTypes.string
    })
  )
};

RibListInput.defaultProps = {
  onChange: () => {},
  value: []
};

export default RibListInput;
