/**
 * @flow
 * @relayHash b9f816f23578a53970d628d5c23c3d2c
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmployTypeEnum = "CADRE" | "NCADRE" | "%future added value";
export type TitleEnum = "Madame" | "Monsieur" | "%future added value";
export type WorkStoppingEnum = "ACCIDENT" | "SICKNESS" | "%future added value";
export type DeclareSinistreIncapaciteInput = {|
  accidentTiers?: ?boolean,
  address?: ?string,
  addressTiers?: ?string,
  assurAddress?: ?string,
  assurCity?: ?string,
  assurPostal?: ?string,
  assurSociety?: ?string,
  attachments?: ?$ReadOnlyArray<?AttachmentInput>,
  birthdate?: ?string,
  birthname?: ?string,
  birthnameTiers?: ?string,
  city?: ?string,
  cityTiers?: ?string,
  contractId?: ?string,
  dateAccident?: ?string,
  dateLeaving?: ?string,
  detailAccident?: ?string,
  emploiCategory?: ?EmployTypeEnum,
  firstname?: ?string,
  firstnameTiers?: ?string,
  folderTiers?: ?string,
  genderTiers?: ?TitleEnum,
  hasPoliceInvestigation?: ?boolean,
  hasResponsibleTiers?: ?boolean,
  nir?: ?string,
  postal?: ?string,
  postalTiers?: ?string,
  primeAmount?: ?number,
  procesVerbal?: ?string,
  reasonAccident?: ?string,
  reasonAccidentOther?: ?string,
  rib?: ?string,
  societyTiers?: ?string,
  startDate?: ?string,
  title?: ?TitleEnum,
  trimesterPayBrut?: ?number,
  workRestartDate?: ?string,
  workRestartMethod?: ?string,
  workStoppingDateAskFrom?: ?string,
  workStoppingDateAskTo?: ?string,
  workStoppingDateStart?: ?string,
  workStoppingReason?: ?WorkStoppingEnum,
|};
export type AttachmentInput = {|
  documentType?: ?string,
  fileName: string,
  uploadId: string,
|};
export type DeclareSinistreIncapaciteMutationVariables = {|
  input: DeclareSinistreIncapaciteInput
|};
export type DeclareSinistreIncapaciteMutationResponse = {|
  +declareSinistreIncapacite: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type DeclareSinistreIncapaciteMutation = {|
  variables: DeclareSinistreIncapaciteMutationVariables,
  response: DeclareSinistreIncapaciteMutationResponse,
|};
*/


/*
mutation DeclareSinistreIncapaciteMutation(
  $input: DeclareSinistreIncapaciteInput!
) {
  declareSinistreIncapacite(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeclareSinistreIncapaciteMutationResponse",
    "kind": "LinkedField",
    "name": "declareSinistreIncapacite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeclareSinistreIncapaciteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeclareSinistreIncapaciteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "b9f816f23578a53970d628d5c23c3d2c",
    "metadata": {},
    "name": "DeclareSinistreIncapaciteMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4bc933e64915411290934281969a1d7a';

module.exports = node;
