import { propEq } from 'ramda';
import React from 'react';
import withUser from '../pages/userSession/withUser';

const RgpdFooter = ({ user }) => {
  if (propEq('role', 'TIERS', user)) {
    return (
      <div className="footer-container">
        <div className="footer-rgpd">
          Les informations affichées et collectées sont nécessaires à la bonne gestion de votre contrat par le groupe UNMI
          (constitué des entités UNMI et UNMI’Mut responsables du traitement). Elles peuvent être transmises à ses réassureurs,
          délégataires de gestion ou autres prestataires intervenant dans le cadre de l’exécution du contrat.
          Elles sont conservées pendant la durée de votre contrat et jusqu’au terme des délais de prescription.
          Pour plus d’information sur l’utilisation de vos données et sur vos droits, vous pouvez contacter le délégué à la protection
          des données du groupe UNMI par email à l’adresse suivante :<a className="footer-rgpd__link" href="mailto:dpo@unmi.eu" target="_blank" rel="noopener noreferrer">{' dpo@unmi.eu'}</a> ou consulter notre
          <a className="footer-rgpd__link" href="http://unmi.eu/sites/default/files/politique_de_protection_des_donnees_unmi.pdf" target="_blank" rel="noopener noreferrer">
            {' Politique de protection des données à caractère personnel.'}
          </a>
        </div>
      </div>
    );
  }

  return null;
};

export default withUser(RgpdFooter);
