import { path, prop, propOr, uniq } from 'ramda';
import { isNilOrEmpty } from 'ramda-adjunct';

// * Helpers
const getTextLength = (ctx, text) => {
  const l = ctx.measureText(text).width + 20;
  return l;
};

// * ChartJS Event handler
const afterDatasetsDraw = function (chart, _easingValue, options) {
  if (options.display === false) return;

  const scale = path(['scales', 'x'], chart);
  if (isNilOrEmpty(scale)) return;

  const ticks = scale.getTicks();
  if (!ticks || ticks.length === 0) {
    return;
  }

  drawTicksLabels(chart, scale, options);
};

// * Main draw function
const drawTicksLabels = (chart, scale, options) => {
  const ctx = chart.ctx;
  const data = chart.data;
  const stacks = uniq(data.datasets.map(prop('stack')));
  const ticks = scale.getTicks();

  ticks.forEach(tick => {
    const pixel = scale.getPixelForTick(tick.value);
    const x = pixel + 10;
    const y = chart.chartArea.bottom - 10;

    ctx.save();

    ctx.translate(x, y);

    const labels = {
      stacks,
      tick: tick.label
    };

    const barWidth = path(['data', 0, 'width'], chart.getDatasetMeta(1));

    drawLabels(ctx, labels, options, barWidth);

    ctx.restore();
  });
};

// * Draw the labels for one group
const drawLabels = function (ctx, labels, options, padding) {
  const angle = propOr(45, 'angle', options) * (Math.PI / 180);

  ctx.translate(0, 10);

  ctx.font = `normal 100 ${propOr('10px', 'fontSize', options)} "Roboto", sans-serif`;
  ctx.strokeStyle = propOr('#666', 'color', options);

  if (options.stack !== false) {
    drawLabelPadded(ctx, -padding, labels.stacks[0], angle);
    drawLabelPadded(ctx, 0, labels.stacks[1], angle);
    drawLabelPadded(ctx, padding, labels.stacks[2], angle);
  }

  const xPos = (options.tilt) ? 0 : getTextLength(ctx, labels.tick) / 3;

  ctx.translate(xPos, getTextLength(ctx, labels.stacks[1]) + (options.tilt ? 0 : 15));

  if (options.tilt) ctx.rotate((-angle));

  if (options.tick !== false) {
    ctx.strokeText(labels.tick, -(getTextLength(ctx, labels.tick) / 1.25), -5);
  }
};

// * Draw one label at the right position
const drawLabelPadded = (ctx, padding, text, angle = 0.35) => {
  ctx.translate(padding, 0);

  ctx.rotate((-angle));
  ctx.strokeText(text, -getTextLength(ctx, text), -5);
  ctx.rotate(angle);

  ctx.translate(-padding, 0);
};

export const chartStackedLabelPlugin = {
  id: 'chartStackedLabel',
  afterDatasetsDraw
};

export default chartStackedLabelPlugin;
