import React from 'react';

import { Controller } from 'react-hook-form';
import { Attachments, UploadFile } from '@@components/input';
import { assoc, propOr } from 'ramda';
import { INPUT_DEFAULT_FILE_PROPTYPES } from '../../../propTypes/form/InputPropTypes';

const Input = ({ register, field, control, getValues, options }) => {
  const $options = propOr([], field.source, options);

  const applyUpload = (name, onChange) => (file) => {
    const newFile = assoc('documentType', '_UNDEFINED_', file);
    const oldFiles = getValues(name) || [];

    onChange([...oldFiles, newFile]);
  };

  return <Controller name={field.name} control={control} render={({ name, onChange, value }) => (
    <>
      <Attachments
        files={value || []}
        onChange={onChange}
        documentTypeOptions={$options}
      />
      <UploadFile
        name="file"
        uploadUrl="/upload/file"
        uploadText={field.placeholder}
        onChange={applyUpload(name, onChange)}
        disabled={field.disabled}
      />
    </>
  )}/>;
};

Input.propTypes = INPUT_DEFAULT_FILE_PROPTYPES;

export default Input;
