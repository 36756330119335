import React from 'react';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { Link } from 'react-router-dom';
import ColoredIcon from '@@components/ColoredIcon';

const SignIn = () => {
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('signIn');

  return (
    <div className="grid  grid-cols-3 sm:grid-cols-5 mt-6">
      <div className="z-10 col-span-4 col-start-1 col-end-4 row-start-1 row-end-5 bg-white rounded-lg p-6">
        <div className="mb-4 text-lg text-plum uppercase">Connexion</div>
        <Forms
          color="pink"
          formsData={formsData}
          columns={1}
          onChange={() => fetching.reset()}
          onSubmit={onFormsSubmit}
          submitSection={() => (
            <div className="flex flex-col mt-4 gap-4">
              <Link to="/account/mot-de-passe-perdu" className="text-pink mb-3 flex text-lg" >
                  Mot de passe perdu
                <ColoredIcon color="pink" icon="back.svg" className="transform rotate-180 mt-2 ml-2" width={12} height={12}/>
              </Link>
              <div>
                <button
                  aria-label="Connexion"
                  className="f-button f-button-pink"
                  disabled={fetching.isFetching()}
                  type="submit"
                >
                  {'CONNEXION'}
                </button>
              </div>
              {fetching.isDoneWithError() && <div className="text-pink">erreur de connexion</div>}
            </div>
          )}
        />
      </div>
      <div className="hidden sm:block col-start-3 col-span-4 row-span-4 row-start-2 row-end-6 rounded-lg">
        <img className="rounded-lg w-full" src="/images/img-connexion.jpg" alt="Image de connexion"/>
      </div>
    </div>
  );
};

export default SignIn;
