import React from 'react';
import { Message } from '@@components';

import Forms from '@@components/forms';
import useForms from '@@formsHook';

const AddApporteursBlock = () => {
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('apporteurSignUpRequests');

  const onSubmit = ({
    attachment
  }) => {
    onFormsSubmit({
      attachment
    });
  };

  if (fetching.isDone()) {
    return (
      <Message
        value="Le fichier est en cours de traitement"
        action='Ok'
        onClick={() => fetching.reset()}
      />
    );
  }

  return (
    <>
      <Forms
        formsData={formsData}
        columns={1}
        defaultValues={{
          attachment: []
        }}
        onSubmit={onSubmit}
        submitSection={() => (
          <button
            aria-label="Envoyer les emails de création de compte"
            className="mt-4 f-button f-button-pink"
            disabled={fetching.isFetching()}
            type="submit"
          >
            {'ENVOYER LES EMAILS DE CRÉATION DE COMPTE'}
          </button>
        )}
      />
    </>
  );
};

export default AddApporteursBlock;
