import React from 'react';
import propTypes from 'prop-types';

import { Message } from '@@components';
import useForms from '@@formsHook';
import Forms from '@@components/forms';
import { titles, reasons } from '@@refs';
import { useBeforeUnload, useBlockTransition } from '@@hooks';
import { CONTRACTS_PROPTYPES } from '../../../propTypes/user/ContractsPropTypes';

const R = require('ramda');

const AffiliePortabiliteBlock = ({ employeeInPortability, contracts }) => {
  useBeforeUnload(employeeInPortability);
  const { onFormsSubmit, formsData, fetching } = useForms('affilieDeclarePortabilite');

  const onSubmit = (data) => {
    onFormsSubmit(data);
  };

  if (fetching.isDone()) {
    return <Message
      value='Votre message a été envoyé'
      action="Envoyer un nouveau message"
      onClick={() => fetching.reset()}
    />;
  }

  const externalContractIds = R.map(
    R.applySpec({
      label: R.prop('id'),
      value: R.prop('id')
    })
  )(contracts);

  return (
    <>
      <Forms
        formsData={formsData}
        defaultValues= {{
          contractId: R.pathOr(null, ['value', 'contractId'], employeeInPortability),
          lastname: R.pathOr(null, ['value', 'lastName'], employeeInPortability),
          firstname: R.pathOr(null, ['value', 'firstName'], employeeInPortability),
          city: null,
          zipCode: null,
          address: null,
          nir: R.pathOr(null, ['value', 'nir'], employeeInPortability),
          birthDate: R.compose(date => new Date(date), R.pathOr(null, ['value', 'birthDate']))(employeeInPortability)
        }}
        options={{
          titles,
          reasons,
          externalContractIds
        }}
        onSubmit={onSubmit}
        submitSection = {() => (
          <button
            aria-label="Déclarer la probabilité"
            disabled={fetching.isFetching()}
            className="f-button f-button-pink"
            type="submit"
          >
            {'Déclarer la portabilité'}
          </button>
        )}
      />
      {useBlockTransition(employeeInPortability)}
    </>
  );
};

AffiliePortabiliteBlock.propTypes = {
  contracts: propTypes.shape(CONTRACTS_PROPTYPES),
  employeeInPortability: propTypes.bool
};

export default AffiliePortabiliteBlock;
