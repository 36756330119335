import React from 'react';
import { Contract } from '../components';
import { SelectOneOption } from '@@components/input';
const R = require('ramda');

const ContractBlock = ({ contracts = [] }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const onSelectContract = ({ value }) => {
    setSelectedIndex(value);
  };

  const options = contracts.map((contract, index) => ({ label: `${contract.info.nomProduit} ${contract.id} ${R.pathOr('', ['info', 'college'], contract)}`, value: index }));

  if (R.isEmpty(contracts)) {
    return (
      <>{'pas de contrat connu'}</>
    );
  }
  return (
    <>
      <SelectOneOption
        options={options}
        value={options[selectedIndex]}
        onChange={onSelectContract}
        placeholder="Sélectionner un contrat"
      />
      <Contract contract={contracts[selectedIndex]}/>
    </>
  );
};

export default ContractBlock;
