import * as R from 'ramda';
import moment from 'moment';
import downloadDocument from './downloadDocument';
import { fetchDocuments } from './request';
import { toast } from 'react-toastify';

const sortDocumentsPerEffectDate = (a, b) => moment(b.effectDate).unix() - moment(a.effectDate).unix();

const handleDownload = (documents) => {
  const document = R.head(documents);
  downloadDocument(`/documents/download/${document.documentId}?isGlobal=false&type=${document.type}`);
};

export const getContractDocument = async (contractId, type) => {
  const isCollectif = R.equals('Entreprise', type) || R.equals('COMPANY', type);
  const url = `/documents/contract/${contractId}?isCollectif=${isCollectif}`;
  const documents = await fetchDocuments(url);
  if (!R.isEmpty(documents)) {
    R.compose(
      handleDownload,
      R.sort(sortDocumentsPerEffectDate)
    )(documents);
  } else {
    toast.info('Aucun document éligible trouvé.');
  }
};
