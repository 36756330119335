import { QWalletFiltersDataQuery } from '../_graphql/queries/QWalletFiltersData';
import { useEffect, useState } from 'react';
import { fetchQuery } from 'react-relay';
import environment from '../../app/_graphql';
import * as R from 'ramda';

const useWalletFiltersData = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [colleges, setColleges] = useState(null);
  const [contractStatus, setContractStatus] = useState(null);
  const [apporteurs, setApporteurs] = useState(null);
  const [key] = useState(0);

  useEffect(() => {
    let hasBeenCancelled = false;
    const fetchData = async () => {
      if (isLoaded) setIsLoaded(false);
      const data = await fetchQuery(environment(), QWalletFiltersDataQuery);
      if (!hasBeenCancelled) {
        setColleges(R.prop('colleges', data));
        setContractStatus(R.prop('contractStatus', data));
        setApporteurs(R.prop('apporteurs', data));
        setIsLoaded(true);
      }
    };
    fetchData();
    return () => (hasBeenCancelled = true);
  }, [key]);

  return { colleges, contractStatus, apporteurs, isLoaded };
};

export default useWalletFiltersData;
