import { Controller } from 'react-hook-form';
import { RibInput } from '@@components/input';

import React from 'react';
import ErrorMessage from './helpers/ErrorMessage';
import { INPUT_DEFAULT_PROPTYPES } from '../../../propTypes/form/InputPropTypes';

const R = require('ramda');

const Input = ({ register, field, control, errors }) => {
  const hasError = R.keys(errors).includes(field.name);
  return (
    <Controller
      name={field.name}
      control={control}
      rules={{ required: field.required }}
      render={({ onChange, value }) => <>
        <ErrorMessage field={field} errors={errors} />
        <RibInput hasError={hasError} onChange={onChange} value={value} disabled={field.disabled} />
      </>}
    />
  );
};

Input.propTypes = INPUT_DEFAULT_PROPTYPES;

export default Input;
