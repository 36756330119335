import React from 'react';
import PropTypes from 'prop-types';
import { isNilOrEmpty } from 'ramda-adjunct';

const PostalFiles = ({ files }) => {
  if (isNilOrEmpty(files)) return null;

  return (
    <div className="m-4">
      <h2>Documents à fournir par voie postale :</h2>

      <ul>
        {files.map(({ name, to, address }) => (
          <li key={name + address}>- {name} *<br/>
            <div className="text-xs italic">({to} {address})</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

PostalFiles.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired
    })
  )
};

PostalFiles.defaultProps = {
  files: []
};

export default PostalFiles;
