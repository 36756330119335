import React from 'react';
import Portefeuille from './portefeuille';
import Commissions from './commissions';
import Sinistres from './sinistres';
import Reseau from './reseau';
import TableauDeBordApporteur from './tableau_de_bord';
import { Menu } from '@@components/elements';
import ContactPage from './contact/index';
import withUser from '../userSession/withUser';
import { isUserManager } from '../../lib/user';
import QApporteurReferentialsAccess from '@@queries/QApporteurReferentialsAccess';
import { propOr } from 'ramda';

const menuApporteurItems = (user, access) => (
  [{
    label: 'Tableau de bord', path: '/tableaudebord', component: TableauDeBordApporteur, icon: 'menu-dashboard.svg', showInMenu: true
  }, {
    label: 'Mon portefeuille', path: '/monportefeuille', component: Portefeuille, icon: 'menu-portefeuille.svg', showInMenu: true
  }, {
    label: 'Mes commissions', path: '/commissions', component: Commissions, icon: 'menu-commissions.svg', showInMenu: isUserManager(user) && propOr(false, 'commissions', access)
  }, {
    label: 'Sinistres', path: '/sinistres', component: Sinistres, icon: 'menu-sinistres.svg', showInMenu: true
  }, {
    label: 'Réseau', path: '/reseau', component: Reseau, icon: 'menu-reseau.svg', showInMenu: isUserManager(user)
  }, {
    label: 'Contacter-nous', path: '/contacteznous', component: ContactPage
  }]
);

const MenuApporteur = ({ user }) => (
  <QApporteurReferentialsAccess>
    {({ apporteurReferentialsAccess }) => {
      return (
        <Menu items={menuApporteurItems(user, apporteurReferentialsAccess)} mode="apporteur" />
      );
    }}
  </QApporteurReferentialsAccess>
);

export default withUser(MenuApporteur);

export {
  menuApporteurItems
};
