const R = require('ramda');

const useExtractData = (field) => {
  const extractForSelectOptions = R.compose(
    R.map( // Map tous les attributs pour les afficher dans le select
      R.applySpec({
        value: R.compose(R.toLower, R.identity),
        label: R.identity
      })
    ),
    R.sort((a, b) => a.localeCompare(b)), // Tri
    R.uniq, // Affiche les contracts dans le select sans doublons, triple ...
    R.reject(R.isNil),
    R.map(R.prop(field)) // map tout les prop field
  );
  return extractForSelectOptions;
};

module.exports = useExtractData;
