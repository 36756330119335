import React, { createContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';

import classNames from 'classnames';

export const ScrollContext = createContext(null);

const Page = ({ title, fw, actions, children }) => {
  const [shouldScrollToTop, setShouldScrollToTop] = useState(true);

  useEffect(() => {
    if (shouldScrollToTop) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [shouldScrollToTop]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const providerValue = {
    setShouldScrollToTop
  };

  return (
    <div className={
      classNames('f-page', {
        'f-page-fw': fw
      })
    }>
      <h1 className="f-page-title w-full flex justify-between">
        {title}
        {actions && <div>{actions}</div>}
      </h1>
      <ScrollContext.Provider value={providerValue}>
        {children}
      </ScrollContext.Provider>
    </div>
  );
};

Page.propTypes = {
  title: propTypes.oneOfType([
    propTypes.string,
    propTypes.object
  ]),
  fw: propTypes.bool,
  actions: propTypes.object
};

export default Page;
