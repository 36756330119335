/**
 * @flow
 * @relayHash 133fa2d0dd5fc82d1d89f198340165cf
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QNetworkApporteursQueryVariables = {||};
export type QNetworkApporteursQueryResponse = {|
  +networkApporteurs: ?$ReadOnlyArray<?{|
    +id: ?string,
    +firstname: ?string,
    +lastname: ?string,
    +login: ?string,
    +email: ?string,
    +openning_date: ?string,
  |}>
|};
export type QNetworkApporteursQuery = {|
  variables: QNetworkApporteursQueryVariables,
  response: QNetworkApporteursQueryResponse,
|};
*/


/*
query QNetworkApporteursQuery {
  networkApporteurs {
    id
    firstname
    lastname
    login
    email
    openning_date
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NetworkApporteur",
    "kind": "LinkedField",
    "name": "networkApporteurs",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "login",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "openning_date",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QNetworkApporteursQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QNetworkApporteursQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "133fa2d0dd5fc82d1d89f198340165cf",
    "metadata": {},
    "name": "QNetworkApporteursQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9791c1a17dc1c59fd008bcb7947a61c1';

module.exports = node;
