import React from 'react';
import ChartLegend from '../components/ChartLegend';
import { Pie } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';

const options = {
  responsive: true,
  aspectRatio: 1 / 0.5,
  scales: {
    grid: {
      display: false
    }
  },
  plugins: {
    legend: {
      display: false
    }
  }
};

const ChartPiePrestationRisque = ({ title, data }) => {
  if (isEmpty(data.datasets)) {
    return (
      <div className="flex flex-col justify-center">
        <div className="font-bold text-lg text-pink text-center">{title}</div>
        <div className="text-lg text-center text-gray-600">Aucune donnée disponible</div>
      </div>
    );
  }
  return (
    <div>
      <div className="font-bold text-lg text-pink text-center">{title}</div>
      <Pie options={options} data={data} />
      <ChartLegend
        isPie
        datasets={data.datasets}
        labels={data.labels}
      />
    </div>
  );
};

ChartPiePrestationRisque.propTypes = {
  title: PropTypes.string,
  data: PropTypes.any
};

ChartPiePrestationRisque.defaultProps = {
};

export default ChartPiePrestationRisque;
