import BlockField from '@@components/BlockField';
import Forms from '@@components/forms';
import Select from 'react-select';
import DatePicker from '@@components/input/DatePicker';
import Modale from '@@components/modale';
import useForms from '@@formsHook';
import { onCloseApporteurAccountMutation } from '@@mutations/apporteur';
import { QNetworkApporteurs } from '@@queries/QNetworkApporteurs';
import propTypes from 'prop-types';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import moment from 'moment';

const R = require('ramda');

const ApporteurDetail = ({ apporteur }) => {
  const { formsData, onFormsSubmit } = useForms('editApporteur');
  const f = useForm({ defaultValues: { transfer_apporteur: [], closing_date: null } });
  const [showModal, setShowModal] = useState(false);

  const onSubmit = (data) => {
    toast.info('Demande de modification du compte envoyée.');
    onFormsSubmit(data);
  };

  const updateDate = R.compose(R.assoc('openning_date', new Date(R.prop('openning_date', apporteur))));

  const onCloseApporteur = (data) => {
    const getApporteurs = R.compose(
      R.map(
        R.applySpec({
          apporteurId: R.prop('value'),
          name: R.prop('label')
        })
      ),
      R.propOr([], 'transfer_apporteur')
    );

    f.reset({ transfer_apporteur: [], closing_date: null });

    const apporteurInfo = R.applySpec({
      transferApporteur: getApporteurs,
      closingDate: R.always(moment(R.prop('closing_date', data)).toISOString()),
      closedAccountId: R.always(R.prop('id', apporteur)),
      closedAccountName: R.always(`${R.prop('firstname', apporteur)} ${R.prop('lastname', apporteur)}`),
      closedAccountLogin: R.always(R.prop('login', apporteur))
    })(data);

    onCloseApporteurAccountMutation({ apporteurInfo }, (ok, error) => {
      if (ok) {
        setShowModal(false);
        f.reset();
        toast.info('La demande de fermeture du compte a été envoyé.');
      } else {
        toast.error(error);
      }
    });
  };

  return (
    <>
      <Forms
        formsData={formsData}
        onSubmit={onSubmit}
        defaultValues={updateDate(apporteur)}
        submitSection={({ getValues }) => (
          <div className='flex gap-4'>
            <button type='submit' className='f-button f-button-pink'>
              Sauvegarder les modifications
            </button>
            <button
              type='button'
              onClick={() => setShowModal(true) }
              className='f-button f-button-plum'
            >
              Fermer le compte
            </button>
          </div>
        )}
      />
      <Modale
        show={showModal}
        onDisable={() => setShowModal(false)}
        title={'Fermeture du compte'}
        footer={
          <div className='flex gap-4'>
            <button
              aria-label='Confirmer la fermeture du compte'
              className='f-button f-button-pink p-4'
              type='button'
              onClick={f.handleSubmit(onCloseApporteur)}
            >
              Confirmer la fermeture du compte
            </button>
            <button
              aria-label='Annuler'
              className='f-button f-button-plum p-4'
              type='button'
              onClick={() => setShowModal(false)}
            >
              Annuler
            </button>
          </div>
        }
      >
        <div className='text-plum flex flex-col gap-4'>
          <div>
            <p>Choisissez la date à laquelle vous souhaitez clôturer ce compte.</p>
            <BlockField label='Date de fermeture du compte'>
              <Controller
                name='closing_date'
                rules={{ required: true }}
                control={f.control}
                render={({ value, onChange }) => (
                  <DatePicker
                    format={'dd/MM/yyyy'}
                    value={value}
                    onChange={onChange}
                    hasError={R.keys(f.errors).includes('closing_date')}
                  />
                )}
              />
            </BlockField>
          </div>
          <div>
            <p>Vous avez la possibilité de transférer ce compte vers un ou plusieurs autre(s) apporteur(s).</p>
            <BlockField label='Apporteur(s)'>
              <Controller
                name='transfer_apporteur'
                control={f.control}
                render={({ value, onChange }) => (
                  <QNetworkApporteurs>
                    {({ networkApporteurs }) => {
                      const options = networkApporteurs
                        .filter((app) => (app.eltNetworkId || app.id) !== apporteur.id)
                        .map((app) => ({
                          value: app.eltNetworkId || app.id, // Hack: Use the right value when we got apporteur
                          label: R.join(' ', [app.firstname, app.lastname])
                        }));
                      return (
                        <Select
                          hasError={R.keys(f.errors).includes('transfer_apporteur')}
                          options={options}
                          placeholder='Sélectionner'
                          value={R.find(R.propEq('value', R.prop('value', value)), options)}
                          onChange={(app) => onChange(app)}
                          isMulti
                        />
                      );
                    }}
                  </QNetworkApporteurs>
                )}
              />
            </BlockField>
          </div>
        </div>
      </Modale>
    </>
  );
};

ApporteurDetail.propTypes = {
  apporteur: propTypes.shape({
    id: propTypes.string.isRequired,
    firstname: propTypes.string.isRequired,
    lastname: propTypes.string.isRequired,
    login: propTypes.string.isRequired,
    email: propTypes.string.isRequired,
    openning_date: propTypes.string.isRequired
  })
};

export default ApporteurDetail;
