import React from 'react';
import PropTypes from 'prop-types';

const Progress = ({ value }) => {
  return (
    <progress
      className="h-4 mr-4"
      value={value}
      max={1}
    />
  );
};

Progress.propTypes = {
  value: PropTypes.number.isRequired
};

Progress.defaultProps = {
};

export default Progress;
