import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

export const QAvalaibleYearCommissionsQuery = graphql`
  query QAvalaibleYearCommissionsQuery {
      availableYearsCommissions
  }
`;

const QAvalaibleYearCommissions = ({ args, children, childDataProp, mockData }) => {
  return (
    <Query
      query={QAvalaibleYearCommissionsQuery}
      args={args}
      mockKey="QAvalaibleYearCommissions"
      mockData={mockData}
    >
      {populateChildren(['availableYearsCommissions'])(children, childDataProp)}
    </Query>
  );
};

export default QAvalaibleYearCommissions;
