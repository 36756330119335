import React from 'react';
import { graphql } from 'react-relay';

import Query from '../Query';
import { populateChildren } from './toolbox';

const QApporteurPortefeuilleManagerQuery = graphql`
  query QApporteurPortefeuilleManagerQuery($year: Int, $trimestre: Int) {
    apporteurContractsReferential(year: $year, trimestre: $trimestre) {
      networkId
      nbParticularContracts
      nbTnsContracts
      nbCollectiveContracts
      year
      trimestre
    }

    apporteurEncaissementsReferential(year: $year, trimestre: $trimestre) {
      networkId
      networkLabel
      productLabel
      category
      amountCotisation
      year
      trimestre
    }
  }
`;

const QApporteurPortefeuilleManager = ({ args, children, childDataProp, mockData }) => {
  return (
    <Query
      query={QApporteurPortefeuilleManagerQuery}
      args={args}
      mockKey='QApporteurPortefeuilleManager'
      mockData={mockData}
    >
      {populateChildren(['apporteurContractsReferential', 'apporteurCommissionsReferential', 'apporteurEncaissementsReferential'])(children, childDataProp)}
    </Query>
  );
};

export default QApporteurPortefeuilleManager;
