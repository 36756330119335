/**
 * @flow
 * @relayHash cc477aff15180e551673208f49473884
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ExchangeEnum = "addBankDebitDetails" | "addTransferDetails" | "contracts" | "enablingDsn" | "payments" | "%future added value";
export type ContactBlockInput = {|
  attachments?: ?$ReadOnlyArray<?AttachmentInput>,
  contract?: ?string,
  message?: ?string,
  subject?: ?ExchangeEnum,
|};
export type AttachmentInput = {|
  documentType?: ?string,
  fileName: string,
  uploadId: string,
|};
export type ContactBlockMutationVariables = {|
  input: ContactBlockInput
|};
export type ContactBlockMutationResponse = {|
  +contactBlock: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type ContactBlockMutation = {|
  variables: ContactBlockMutationVariables,
  response: ContactBlockMutationResponse,
|};
*/


/*
mutation ContactBlockMutation(
  $input: ContactBlockInput!
) {
  contactBlock(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ContactBlockMutationResponse",
    "kind": "LinkedField",
    "name": "contactBlock",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactBlockMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactBlockMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "cc477aff15180e551673208f49473884",
    "metadata": {},
    "name": "ContactBlockMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '32437d87baa9710572dfedfe1f6d71f3';

module.exports = node;
