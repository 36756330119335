import { equals } from 'ramda';
import moment from 'moment';

export const CURRENT_YEAR = moment().year();

export const shouldBlockTransition = equals(process.env.PREVENT_MESSAGE, 'true');

export const COMMISSION_TYPE = {
  DISTRIBUTION: 'Distribution',
  DELEGATION: 'Délégation de Gestion'
};
