/* eslint-disable react/display-name */
import ColoredIcon from '@@components/ColoredIcon';
import Table from '@@components/table';
import { isEmpty } from 'ramda';
import React from 'react';
import { bordereauxCompleteNameToData } from '../../../../lib/bordereauxCompleteNameToData';
import downloadDocument from '../../../../lib/downloadDocument';

const DownloadLinks = (completeFileName) => {
  const { name, networkId, year, periode } = bordereauxCompleteNameToData(completeFileName);
  return (
    <div className='flex gap-2 justify-center'>
      <div className='cursor-pointer' onClick={() => downloadDocument(`/documents/commissions/bordereaux/download?name=${encodeURI(name)}&networkId=${networkId}&year=${year}&periode=${periode}`) }>
        <ColoredIcon color='plum' icon='icon-xlsx.svg' height={32} width={32} />
      </div>
    </div>
  );
};

const CommissionsList = ({ bordereauxList }) => {
  const columns = React.useMemo(() => [
    {
      Header: 'Période',
      id: 'periode',
      accessor: 'periode',
      Cell: ({ cell }) => <div>{ cell.value }</div>
    },
    {
      Header: 'Fichier',
      id: 'name',
      accessor: 'name',
      Cell: ({ cell }) => <div>{ cell.value }</div>
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'completeFileName',
      Cell: ({ cell: { value } }) => DownloadLinks(value),
      sortable: false,
      width: 48
    }
  ]);

  return (
    isEmpty(bordereauxList)
      ? <div className='text-center text-gray-600'>Aucune donnée disponible</div>
      : <Table
        data={bordereauxList}
        columns={columns}
        isBlock={false}
        hideFilters
        withPagination
        pagination={{ limit: 10 }}
      />
  );
};

export default CommissionsList;
