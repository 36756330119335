import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

export const QCollegesQuery = graphql`
  query QCollegesQuery {
    colleges {
      id
      label
    }

  }
`;

export const QColleges = ({ args, children, childDataProp, mockData }) => {
  return (
    <Query
      query={QCollegesQuery}
      args={args}
      mockKey="QColleges"
      mockData={mockData}
    >
      {populateChildren([])(children, childDataProp)}
    </Query>
  );
};

export default QColleges;
