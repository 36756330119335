/* eslint-disable react/display-name */
import React from 'react';
import propTypes from 'prop-types';
import Table from '@@components/table';
import MoneyFormat from '@@components/elements/MoneyFormat';
import moment from 'moment';
import { BILLINGS_PROPTYPES } from '../../../propTypes/user/ContractsPropTypes';

const CotisationsList = ({ cotisations }) => {
  const columns = React.useMemo(() => [
    { Header: 'Période couv.', id: 'periodCouv', accessor: 'dates', Cell: ({ cell }) => <div>{moment(cell.value.startDate).format('DD/MM/YYYY')} - {moment(cell.value.endDate).format('DD/MM/YYYY')} </div> },
    { Header: 'Contrat', id: 'contract', accessor: 'contract' },
    // Only display for not tns user. For now we don't display cotisation for them
    // { Header: 'Type de déclaration', id: 'declarationType', accessor: 'declarationType' },
    { Header: 'Montant cotisation', id: 'totalDeclare', accessor: 'total', Cell: ({ cell }) => (<MoneyFormat value={parseFloat(cell.value)} />) },
    { Header: 'Statut de la cotisation', id: 'status', accessor: 'status' },
    { Header: 'Date d\'encaissement', id: 'lastPayment', accessor: 'lastPayment', Cell: ({ cell }) => <div>{moment(cell.value).format('DD/MM/YYYY')}</div> }
  ]);

  return (
    <Table
      data={cotisations}
      columns={columns}
      isBlock={false}
    />
  );
};

CotisationsList.propTypes = BILLINGS_PROPTYPES;

export default CotisationsList;
