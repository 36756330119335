import React from 'react';
import propTypes from 'prop-types';
import {
  BlockField
} from '../.';

import { useForm } from 'react-hook-form';
import inputComponents from './inputs';

const R = require('ramda');

const Forms = ({
  formsData,
  defaultValues,
  onChange,
  onSubmit,
  options,
  submitSection,
  columns,
  color,
  forwardedUseForm = null,
  wrapInForm = true
}) => {
  const { register, handleSubmit, control, reset, getValues, watch, errors, setValue } = forwardedUseForm || useForm({ defaultValues });
  const [visible, setVisible] = React.useState({});
  const getInput = ({ field, form }) => {
    const placeholder = field.placeholder === '__UNDEFINED__' ? field.label : field.placeholder;
    if (!R.has(field.input, inputComponents)) return null;
    return React.createElement(
      inputComponents[field.input], {
        getValues,
        control,
        register,
        field: { ...field, placeholder },
        options,
        errors
      }
    );
  };

  const valuesRef = React.useRef(null);
  const formValues = watch();

  const defaultRef = React.useRef(defaultValues);

  React.useEffect(() => {
    const updateVisible = async (fields, values) => {
      const response = await Promise.all(fields.map(async field => {
        if (R.is(Function, field.handlers.visible)) {
          let value = field.handlers.visible(values);
          if (R.is(Promise, value)) value = await value;
          return { [field.name]: value };
        }
        return { [field.name]: true };
      }));
      setVisible(R.mergeAll(response));
    };

    // * Check if the values changed
    if (R.equals(valuesRef.current, formValues)) return;

    // * Update form visibility
    valuesRef.current = formValues;
    updateVisible(formsData, valuesRef.current);
    if (R.is(Function, onChange)) onChange(valuesRef.current);
  }, [formValues]);

  React.useEffect(() => {
    if (R.equals(defaultRef.current, defaultValues)) {
      return;
    }

    defaultRef.current = defaultValues;
    R.compose(
      R.map(value => setValue(R.head(value), R.last(value))),
      R.toPairs
    )(defaultValues);
  }, [defaultValues]);

  const formBody = <>
    <div className={`flex flex-col md:grid md:grid-cols-${columns} gap-4`}>
      {formsData.map((field) => {
        if (!visible[field.name]) return null;
        return (
          <div className={field.columns && `col-span-${field.columns}`} key={field.name}>
            <BlockField color={color} label={R.when(R.equals('__UNDEFINED__'), R.always(''), field.label)}>
              {getInput({ field, form: { control, register } })}
            </BlockField>
          </div>
        );
      })}
    </div>
    {submitSection({ reset, getValues })}
  </>;

  return wrapInForm
    ? <form onSubmit={handleSubmit(onSubmit)}>{formBody}</form>
    : formBody
  ;
};

export default Forms;

Forms.propTypes = {
  columns: propTypes.number,
  color: propTypes.string,
  formsData: propTypes.array,
  defaultValues: propTypes.any,
  onChange: propTypes.func,
  onSubmit: propTypes.func,
  options: propTypes.object,
  submitSection: propTypes.func,
  forwardedUseForm: propTypes.object,
  wrapInForm: propTypes.bool
};
