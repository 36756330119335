/**
 * @flow
 * @relayHash 8d8f8508915268af54338b46aeac0bf3
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QWalletFiltersDataQueryVariables = {||};
export type QWalletFiltersDataQueryResponse = {|
  +colleges: ?$ReadOnlyArray<?{|
    +id: ?string,
    +label: ?string,
  |}>,
  +apporteurs: ?$ReadOnlyArray<?{|
    +id: ?string,
    +firstname: ?string,
    +lastname: ?string,
    +network: ?string,
  |}>,
  +contractStatus: ?$ReadOnlyArray<?{|
    +label: ?string
  |}>,
|};
export type QWalletFiltersDataQuery = {|
  variables: QWalletFiltersDataQueryVariables,
  response: QWalletFiltersDataQueryResponse,
|};
*/


/*
query QWalletFiltersDataQuery {
  colleges {
    id
    label
  }
  apporteurs {
    id
    firstname
    lastname
    network
  }
  contractStatus {
    label
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CollegesRef",
    "kind": "LinkedField",
    "name": "colleges",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Apporteur",
    "kind": "LinkedField",
    "name": "apporteurs",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "network",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "ContractStatusRef",
    "kind": "LinkedField",
    "name": "contractStatus",
    "plural": true,
    "selections": [
      (v1/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QWalletFiltersDataQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QWalletFiltersDataQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "8d8f8508915268af54338b46aeac0bf3",
    "metadata": {},
    "name": "QWalletFiltersDataQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f197ef466f9dd9eebe71cdcd1ceff06d';

module.exports = node;
