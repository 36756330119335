/* eslint-disable react/display-name */
import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import Table from '@@components/table';
import { SelectColumnFilter } from '@@components/table/filters';
import { text } from 'react-table/src/filterTypes';
import { useExtractData } from '../../../hooks';

const R = require('ramda');
const selectFilter = (rows, id, filterValue) => {
  /* id correspond à la valeur que l'on cherche (exemple contractId),
  rows toutes les lignes du tableau dans lesquelles on va chercher la value
  et filterValue est la valeur que l'on selectionne dans le select */
  filterValue = R.prop('search', filterValue);
  return filterValue === undefined ? rows : text(rows, id, filterValue);
};

const getSortedSelect = (data, attribut) => {
  return useExtractData(attribut)(data);
};

const placeSortFilter = (
  <div className='flex-initial w-64 flex-col text-xs' style={{ color: 'grey' }}>Tri croissant <br /> Tri décroissant</div>
);

const AffiliatesBlock = ({
  affiliates,
  isBlock,
  pagination,
  onSelect,
  showEnableDsnBtn
}) => {
  const contractSelect = getSortedSelect(affiliates, 'contractId');
  const statusSelect = getSortedSelect(affiliates, 'status');
  const columns = React.useMemo(
    () => [
      {
        Header: 'Nom Prénom',
        id: 'name',
        accessor: ({ firstName, lastName }) => {
          return `${lastName} ${firstName}`;
        }
      },
      {
        Header: 'Date de Naissance',
        id: 'birthDate',
        Filter: placeSortFilter,
        accessor: 'birthDate',
        Cell: ({ value }) => moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
      },
      {
        Header: 'Contrat',
        id: 'contractId',
        Filter: SelectColumnFilter(contractSelect),
        filter: selectFilter,
        accessor: 'contractId'
      },
      {
        Header: 'Date d\'affiliation',
        id: 'dateEffet',
        Filter: placeSortFilter,
        accessor: 'dateEffet',
        Cell: ({ value }) => moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
      },
      {
        Header: 'Fin d\'affiliation',
        id: 'terminationDate',
        Filter: placeSortFilter,
        accessor: 'terminationDate',
        Cell: ({ value }) => {
          if (value) {
            return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');
          }
          return '';
        }
      },
      {
        Header: 'Statut',
        id: 'statut',
        Filter: SelectColumnFilter(statusSelect),
        filter: selectFilter,
        accessor: 'status'
      }
    ],
    []
  );
  const history = useHistory();
  const onAskEnablingDsnClick = () => {
    history.push('/contacteznous', {
      // contract: contract.id,
      subject: 'enablingDsn',
      message: 'Bonjour\n\nPouvez vous m\'accompagner dans la mise en place de la DSN ?\n\nCordialement'
    });
  };

  if (R.isEmpty(affiliates)) {
    return (
      <div className="overflow-x-scroll md:overflow-x-auto text-center text-gray-600">
        {'Nous n\'avons actuellement pas d\'informations à jour sur vos affiliés'}
      </div>
    );
  }

  return (
    <>
      <div className="overflow-x-scroll md:overflow-x-auto pb-2">
        <Table
          data={affiliates}
          columns={columns}
          withPagination
          pagination={pagination}
          isBlock={isBlock}
          onRowClick={({ id, contractIdCrypt }) => onSelect(id, contractIdCrypt)}
          initialState={{
            sortBy: [{ id: 'name', desc: false }]
          }}
        />

        {showEnableDsnBtn && (
          <button aria-label="Demande de l'activation de la DSN" className="mt-4 f-button f-button-plum" onClick={onAskEnablingDsnClick}>
            {'Demander l\'activation de la DSN'}
          </button>
        )}

      </div>
    </>
  );
};

export default AffiliatesBlock;
