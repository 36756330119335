import React from 'react';

const Loading = () => {
  return (
    <div className='italic mt-5 text-center'>
      <p>Chargement en cours...</p>
    </div>
  );
};

export default Loading;
