import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SelectOneOption } from '@@components/input';
import contractType from '@@refs/contractType';
import { Controller, useForm } from 'react-hook-form';
import QApporteurs from '../../../_graphql/queries/QApporteurs';
import * as R from 'ramda';
import { UserContext } from '../../userSession/withUser';
import { isUserManager } from '../../../lib/user';

import { CURRENT_YEAR } from '../../../constants';

const yearOptions = R.pipe(
  R.range,
  R.reverse,
  R.map(v => {
    return {
      label: v,
      value: v
    };
  })
)(2020, CURRENT_YEAR + 1);

const apporteurToOptions = R.applySpec({
  label: R.prop('apportName'),
  value: R.propOr('', 'eltNetworkId')
});

const ChartFilters = ({ onChange, defaultValue = yearOptions }) => {
  const { watch, control } = useForm({ defaultValues: { year: CURRENT_YEAR, apporteur: null, apporteurs: null, type: null }, mode: 'onChange' });
  const valuesRef = useRef(null);
  const { user } = useContext(UserContext);
  const isManager = isUserManager(user);

  const values = watch();

  useEffect(() => {
    const cleanValue = R.evolve({
      year: Number.parseInt,
      type: R.defaultTo(null),
      apporteur: R.defaultTo(null)
    }, values);

    if (R.equals(valuesRef.current, cleanValue)) return;

    valuesRef.current = cleanValue;
    onChange(valuesRef.current);
  }, [values]);

  const handleOnChange = onChange => R.compose(onChange, R.prop('value'));
  const getValue = (options, value) => R.find(R.propEq('value', value), options);

  const selectApporteursClasses = classNames('grid gap-4 w-full mt-4 md:mt-0', {
    'grid-cols-3 md:w-2/3': isManager,
    'grid-cols-2 md:w-1/2': !isManager
  });

  return (
    <div className={selectApporteursClasses}>

      <Controller name="year" render={({ onChange, value }) => (
        <SelectOneOption
          options={defaultValue}
          value={getValue(defaultValue, value)}
          defaultValue={CURRENT_YEAR}
          onChange={handleOnChange(onChange)}
          placeholder="Année"
        />
      )} control={control}/>

      <Controller name="type" render={({ onChange, value }) => (
        <SelectOneOption
          options={contractType}
          value={getValue(contractType, value)}
          onChange={handleOnChange(onChange)}
          placeholder='Type'
          isClearable
        />
      )} control={control}/>
      {isManager && (
        <QApporteurs>
          {({ apporteurs }) => {
            const options = R.map(apporteurToOptions, apporteurs);
            return (
              <Controller name="apporteurs" render={({ onChange, value }) => (
                <SelectOneOption
                  options={options}
                  value={getValue(options, value)}
                  onChange={handleOnChange(onChange)}
                  placeholder='Apporteur'
                  isClearable
                />
              )} control={control}/>
            );
          }}
        </QApporteurs>
      )}
    </div>
  );
};

ChartFilters.propTypes = {
  onChange: PropTypes.func.isRequired
};

ChartFilters.defaultProps = {
  onChange: () => {}
};

export default ChartFilters;
