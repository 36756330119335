import React from 'react';
import { Block, Page } from '@@components';
import {
  AffiliatesBlock,
  AffiliePortabiliteBlock
} from '../containers';
import { useHistory } from 'react-router-dom';
import useCompany from '../../../hooks/useCompany';

import ColoredIcon from '@@components/ColoredIcon';
import { SelectOneOption } from '@@components/input';
import * as R from 'ramda';
import moment from 'moment';
import { DsnStatus } from '../../../../refs/dsnStatus';
import { contractStatusType } from '../../../../refs/contractStatusType';
import { isNilOrEmpty } from 'ramda-adjunct';

const ListAffiliates = () => {
  const { register, controls } = Block.useBlocks();
  const [isBlock, setIsBlock] = React.useState(false);
  const [employeeInPortability, setEmployeeInPortability] = React.useState(false);
  const { getContracts, getAffiliates } = useCompany();
  const contracts = getContracts();
  const affiliates = getAffiliates();
  const history = useHistory();

  const updStatusTermination = (elem) => R.assoc('status',
    isNilOrEmpty(R.prop('terminationDate', elem)) ? R.prop('status', elem) : 'Résilié', elem);

  const sortMoreRecentDateEffet = R.sort((a, b) =>
    moment(R.prop('dateEffet', b)).diff(R.prop('dateEffet', a), 'seconds'));

  const filteredAffiliates = R.compose(
    R.reduce((acc, val) => R.append(R.compose(
      updStatusTermination,
      R.head,
      sortMoreRecentDateEffet
    )(val), acc), {}),
    R.values,
    R.groupBy(aff => aff.id)
  )(affiliates);

  const hasDsnOnAllContracts = R.compose(
    R.all(R.equals(DsnStatus.enabled)),
    R.map(R.path(['info', 'dsn'])),
    R.filter(R.propEq('status', contractStatusType.running))
  )(contracts);

  const affiliatesPagination = {
    pages: R.length(filteredAffiliates),
    page: 1,
    limit: 10
  };

  return (
    <Page title="Mes affiliés" actions={<div className="f-button-group block md:hidden">
      <button onClick={() => setIsBlock(false)}>
        <ColoredIcon color={isBlock ? 'grey' : 'pink'} icon="icon-tableau-off.svg" />
      </button>
      <button onClick={() => setIsBlock(true)}>
        <ColoredIcon color={isBlock ? 'pink' : 'grey'} icon="icon-blocs-off.svg" />
      </button>
    </div>}>
      <Block name="list" register={register({ opened: true })} color="pink" icon="apporteurs.svg" title="Mes affiliés" controls={controls}>
        <AffiliatesBlock
          showEnableDsnBtn={!hasDsnOnAllContracts}
          affiliates={filteredAffiliates}
          isBlock={isBlock}
          pagination={affiliatesPagination}
          onSelect={(id, contractIdCrypt) => history.push(`/mesaffilies/${contractIdCrypt}/${id}`)}
        />
      </Block>

      <Block name="portabilite" register={register({ opened: true })} color="pink" icon="apporteur.svg" title="Déclarer un salarié en portabilité" controls={controls}>
        <SelectOneOption
          options={R.sortBy(R.propOr('', 'lastName'), filteredAffiliates).map(affiliate => ({ label: `${affiliate.lastName} ${affiliate.firstName}`, value: affiliate }))}
          placeholder="Sélectionner un salarié en portabilité"
          onChange={(value) => {
            setEmployeeInPortability(value);
          }}
        />
        {employeeInPortability && <AffiliePortabiliteBlock employeeInPortability={employeeInPortability} contracts={contracts} />}
      </Block>
    </Page>
  );
};

export default ListAffiliates;
