import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import {
  populateChildren
} from './toolbox';

export const QUserTiersDetailsQuery = graphql`
  query QUserTiersDetailsQuery {
    tiersDetails {
      title
      firstname
      lastname
      banner
      siret
      fiscalDate
      contact {
        name
        email
        tel
      }
      address {
        line
        zipCode
        city
      }
    }
  }
`;

const QUserTiersDetails = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QUserTiersDetailsQuery}
      args={args}
      fetchPolicy="network"
      mockKey="QUserTiersDetails"
      mockData={mockData}
    >
      {populateChildren(['tiersDetails'])(children, childDataProp)}
    </Query>
  );
};

export default QUserTiersDetails;
