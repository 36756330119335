const colors = {
  yellow: '#B1C200',
  yellowLight: '#e9f19e',
  cyan: '#428E92',
  pink: '#E2007A',
  purple: '#96558F',
  orange: '#C95A24',
  light_plum: '#87293c',
  plum: '#4f121c',
  light_red: '#fca5a5',

  white: '#FFF',
  f5: '#f5f5f5',
  // gray: '#D1D5DB',
  gray: '#66788a',
  light_gray: '#4f121c1a',
  danger: 'red',

  enterprise: '#428E92',
  tns: '#B1C200',
  individual: '#3CCEB1'
};

module.exports = colors;
