import { isNilOrEmpty } from 'ramda-adjunct';
import React from 'react';
import { Prompt } from 'react-router-dom';
import { shouldBlockTransition } from '../constants';

const useBlockTransition = (isBlocking) => {
  return (
    <>
      {shouldBlockTransition && (
        <Prompt
          when={!isNilOrEmpty(isBlocking)}
          message={'Vos saisies seront perdues si vous quittez cette page'}
        />)}
    </>

  );
};

export default useBlockTransition;
