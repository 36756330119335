/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/


import isTns from '@@formsHandlers/updateTiersProfil/isTns';


const formData = [

{
name: 'firstname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Prénom",
placeholder: "__UNDEFINED__",



handlers: {
visible: isTns
}
},

{
name: 'lastname',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Nom",
placeholder: "__UNDEFINED__",



handlers: {
visible: isTns
}
},

{
name: 'banner',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Raison sociale",
placeholder: "__UNDEFINED__",



handlers: {
visible: isTns
}
},

{
name: 'siret',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Siret",
placeholder: "__UNDEFINED__",



handlers: {
visible: isTns
}
},

{
name: 'line',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Adresse",
placeholder: "__UNDEFINED__",
columns: 2,


handlers: {
visible: undefined
}
},

{
name: 'zipCode',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Code postal",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'city',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Ville",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'phone',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Telephone",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'email',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Email",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'fiscalDate',
required: false,
input: 'date',
source: '__UNDEFINED__',
label: "Date clôture fiscale",
placeholder: "__UNDEFINED__",


format: "dd/MM",
handlers: {
visible: isTns
}
},

{
name: 'accountType',
required: false,
input: 'hidden',
source: '__UNDEFINED__',
label: "__UNDEFINED__",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

];

export default formData;