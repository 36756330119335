import React from 'react';
import Select from 'react-select';
import colors from '@@refs/colors';
import PropTypes from 'prop-types';

const R = require('ramda');

export const getDefaultOptions = R.when(
  R.isEmpty,
  R.always([{
    label: 'Aucune option',
    value: ''
  }])
);

const SelectOneOption = ({
  options,
  value,
  onChange,
  placeholder,
  hasError,
  className,
  defaultValue,
  disabled,
  title,
  isClearable,
  styles = {}
}) => {
  const customStyle = {
    control: (provided) => ({
      ...provided,
      borderColor: hasError ? colors.light_red : R.prop('borderColor', provided)
    }),
    ...styles
  };
  return (
    <div className={`f-select ${className}`}>
      <p className="f-block-field-label">{title}</p>
      <Select
        styles={customStyle}
        isClearable={isClearable}
        value={value || null}
        onChange={onChange}
        options={getDefaultOptions(options)}
        placeholder={placeholder}
        defaultValue={defaultValue}
        menuPosition='fixed'
        isDisabled={disabled}
      />
    </div>
  );
};

SelectOneOption.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
      ]),
      label: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
        PropTypes.object
      ])
    })
  ),
  value: PropTypes.any,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  isClearable: PropTypes.bool,
  styles: PropTypes.object
};

export default SelectOneOption;
