/**
 * @flow
 * @relayHash d713a84ff6eb96cdcd2eb5af8382132f
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QPartyTypesQueryVariables = {||};
export type QPartyTypesQueryResponse = {|
  +partyTypes: ?$ReadOnlyArray<?{|
    +label: ?string
  |}>
|};
export type QPartyTypesQuery = {|
  variables: QPartyTypesQueryVariables,
  response: QPartyTypesQueryResponse,
|};
*/


/*
query QPartyTypesQuery {
  partyTypes {
    label
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PartyTypesRef",
    "kind": "LinkedField",
    "name": "partyTypes",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QPartyTypesQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QPartyTypesQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "d713a84ff6eb96cdcd2eb5af8382132f",
    "metadata": {},
    "name": "QPartyTypesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5418500482cd1d82322ee8600ceda3b3';

module.exports = node;
