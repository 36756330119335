export const redirectToLoginPage = async () => {
  const params = (new URL(document.location)).searchParams;
  const maybeToken = params.get('token');

  const response = await fetch('/env');

  if (response.ok) {
    const { SSO_URL, sessionId } = await response.json();
    // * Build login page url
    const redirectTo = new URL(SSO_URL);

    const currentRoute = new URL(document.location);
    currentRoute.pathname = '';
    currentRoute.search = '';

    redirectTo.searchParams.set('redirectTo', currentRoute);
    redirectTo.searchParams.set('sessionId', sessionId);

    if (maybeToken) {
      redirectTo.searchParams.set('authtoken', maybeToken);
    }

    redirectTo.searchParams.set('sessionId', sessionId);
    // ! Redirect to login page full url not through react-router

    window.location.href = redirectTo.href;
  }

  throw new Error('SSO not available');
};

export default redirectToLoginPage;
