import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

export const QChartApporteurRepartitionQuery = graphql`
  query QChartApporteurRepartitionQuery($range: ChartApporteurRangeEnum!, $year: Int!, $type: ContractTypeEnum, $apporteurs: [String]) {
    apporteurDashboardRepartition(range: $range, year: $year, type: $type, apporteurs: $apporteurs) {
      labels
      contracts {
        transformed
        proposition
      }
      ca {
        transformed
        proposition
      }
      effectifs {
        transformed
        proposition
      }
    }
  }
`;

const QChartApporteurRepartition = ({ args, children, childDataProp, mockData }) => {
  const variables = {
    range: args.range,
    year: args.year,
    type: args.type,
    apporteurs: args.apporteurs
  };

  return (
    <Query
      query={QChartApporteurRepartitionQuery}
      args={variables}
      mockKey='QChartApporteurRepartition'
      mockData={mockData}
    >
      {populateChildren(['apporteurDashboardRepartition'])(children, childDataProp)}
    </Query>
  );
};

export default QChartApporteurRepartition;
