import React from 'react';
import { graphql } from 'react-relay';
import Query from '../Query';
import { populateChildren } from './toolbox';

const QApporteurSinistresGraphQuery = graphql`
query QApporteurSinistresGraphQuery($year: Int, $month: Int) {
  apporteurSinistresChartDeces(year: $year, month: $month) {
    label
    value
    color
    category
  }

  apporteurSinistresChartInvalidite(year: $year, month: $month) {
    label
    value
    color
    category
  }

  apporteurSinistresChartIncapacite(year: $year, month: $month) {
    label
    value
    color
    category
  }

  apporteurSinistresChartPerRisk(year: $year, month: $month) {
    label
    value
    color
    category
  }
}

`;

const QApporteurSinistresGraph = ({ args, children, childDataProp, mockData }) => {
  return (
    <Query
      query={QApporteurSinistresGraphQuery}
      args={args || {}}
      mockKey="QApporteurSinistresGraph"
      mockData={mockData}
    >
      {populateChildren(['apporteurSinistresChartDeces', 'apporteurSinistresChartInvalidite', 'apporteurSinistresChartIncapacite', 'apporteurSinistresChartPerRisk'])(children, childDataProp)}
    </Query>
  );
};

export default QApporteurSinistresGraph;