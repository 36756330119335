import React, { useEffect, useState } from 'react';
import { Block, Page } from '@@components';
import { SelectOneOption } from '@@components/input';
import * as R from 'ramda';
import { isNilOrEmpty, isNotNilOrEmpty } from 'ramda-adjunct';

import QCommissionsBordereauxList from '../../../_graphql/queries/QCommissionsBordereauxList';
import QApporteurCommissionsManager from '../../../_graphql/queries/QApporteurCommissionsManager';
import { QAvalaibleYearCommissionsQuery } from '../../../_graphql/queries/QAvalaibleYearCommissions';
import useFetchQuery from '../../../hooks/useFetchQuery';
import CommissionList from '../containers/commissions/CommissionList';
import ChartCommissions from './ChartCommissions';

import { COMMISSION_TYPE, CURRENT_YEAR } from '../../../constants';
import { yearsOptions } from '../../../lib/dateFilters';

const CommissionManagerDistribution = React.memo(function CommissionManagerDistribution ({ filters }) {
  return (
    <QApporteurCommissionsManager args={{ ...filters, commissionType: COMMISSION_TYPE.DISTRIBUTION }}>
      {({ apporteurCommissionsReferential }) => <ChartCommissions data={apporteurCommissionsReferential} commissionType={COMMISSION_TYPE.DISTRIBUTION} />}
    </QApporteurCommissionsManager>
  );
});

const CommissionManagerDelegation = React.memo(function CommissionManagerDelegation ({ filters }) {
  return (
    <QApporteurCommissionsManager args={{ ...filters, commissionType: COMMISSION_TYPE.DELEGATION }}>
      {({ apporteurCommissionsReferential }) => <ChartCommissions data={apporteurCommissionsReferential} commissionType={COMMISSION_TYPE.DELEGATION} />}
    </QApporteurCommissionsManager>
  );
});

const CommissionYear = React.memo(function CommissionYear ({ filters }) {
  return (
    <QCommissionsBordereauxList args={filters}>
      {({ getBordereauxList }) => {
        return getBordereauxList ? <CommissionList bordereauxList={getBordereauxList.data} /> : <p>Aucune donnée disponible</p>;
      }}
    </QCommissionsBordereauxList>
  );
});

const CommissionListPage = () => {
  const { register, controls } = Block.useBlocks();

  const [yearDistribution, setYearDistribution] = useState({ year: CURRENT_YEAR });
  const [yearDelegation, setYearDelegation] = useState({ year: CURRENT_YEAR });
  const [yearCommissionList, setYearCommissionList] = useState({ year: CURRENT_YEAR });

  const { availableYearsCommissions, isLoaded } = useFetchQuery({
    query: QAvalaibleYearCommissionsQuery,
    dataProp: 'availableYearsCommissions'
  });

  const options = yearsOptions(availableYearsCommissions);
  const defaultOption = R.head(options);

  const valueToChange = selectType => value => R.cond([
    [R.equals('distribution'), () => setYearDistribution(value)],
    [R.equals('delegation'), () => setYearDelegation(value)],
    [R.equals('commission_list'), () => setYearCommissionList(value)]
  ])(selectType);

  const valueDistribution = R.find(R.propEq('value', R.propOr(CURRENT_YEAR, 'year', yearDistribution)), options);
  const valueDelegation = R.find(R.propEq('value', R.propOr(CURRENT_YEAR, 'year', yearDelegation)), options);
  const valueCommissionList = R.find(R.propEq('value', R.propOr(CURRENT_YEAR, 'year', yearCommissionList)), options);

  const onChangeYear = (selectType) => R.compose(
    valueToChange(selectType),
    R.applySpec({ year: R.identity }),
    R.defaultTo(CURRENT_YEAR),
    R.prop('value')
  );

  useEffect(() => {
    if (isLoaded && isNotNilOrEmpty(defaultOption) && defaultOption !== CURRENT_YEAR) {
      setYearDistribution(R.assoc('year', defaultOption.value));
      setYearDelegation(R.assoc('year', defaultOption.value));
      setYearCommissionList(R.assoc('year', defaultOption.value));
    }
  }, [isLoaded]);

  return (
    <Page title='commissions'>
      <Block
        name='commission-list'
        title='Mes commissions'
        color='pink'
        register={register({ opened: true, canClose: false })}
        controls={controls}>
        {isLoaded && (
          <>
            <div className="flex justify-end mb-4">
              <SelectOneOption
                className='w-64'
                value={valueDistribution}
                options={options}
                placeholder={isNotNilOrEmpty(options) ? 'Sélectionner une année' : 'Aucune donnée disponible'}
                disabled={isNilOrEmpty(options)}
                isClearable
                defaultValue={yearDistribution}
                onChange={onChangeYear('distribution')}
              />
            </div>
            <div className='flex flex-col gap-4'>
              <CommissionManagerDistribution filters={ yearDistribution }/>
              <div className="flex justify-end mb-4">
                <SelectOneOption
                  className='w-64'
                  value={valueDelegation}
                  options={options}
                  placeholder={isNotNilOrEmpty(options) ? 'Sélectionner une année' : 'Aucune donnée disponible'}
                  disabled={isNilOrEmpty(options)}
                  isClearable
                  defaultValue={yearDelegation}
                  onChange={onChangeYear('delegation')}
                />
              </div>
              <CommissionManagerDelegation filters={ yearDelegation }/>
              <div className="flex justify-end mb-4">
                <SelectOneOption
                  className='w-64'
                  value={valueCommissionList}
                  options={options}
                  placeholder={isNotNilOrEmpty(options) ? 'Sélectionner une année' : 'Aucune donnée disponible'}
                  disabled={isNilOrEmpty(options)}
                  isClearable
                  defaultValue={yearCommissionList}
                  onChange={onChangeYear('commission_list')}
                />
              </div>
              <CommissionYear filters={ yearCommissionList }/>
            </div>
          </>
        )}
      </Block>
    </Page>
  );
};

export default CommissionListPage;
