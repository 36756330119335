import React from 'react';
import { Page } from '@@components';
import BlockAccounts from '../containers/BlockAccounts';

const FollowsAccounts = () => {
  return (
    <Page title="Suivi des comptes">
      <BlockAccounts name={'follows_accounts'} title={'Comptes apporteurs'} selectedType={'APPORTEUR'}/>
      <BlockAccounts name={'follows_accounts_tiers'} title={'Comptes tiers'} selectedType={'TIERS'}/>
    </Page>
  );
};

export default FollowsAccounts;
