/**
 * @flow
 * @relayHash f5046f5f4d26ee8537c07269713a6532
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type onDownloadDeclarationMiseInvaliditeMutationVariables = {||};
export type onDownloadDeclarationMiseInvaliditeMutationResponse = {|
  +onDownloadDeclarationMiseInvalidite: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type onDownloadDeclarationMiseInvaliditeMutation = {|
  variables: onDownloadDeclarationMiseInvaliditeMutationVariables,
  response: onDownloadDeclarationMiseInvaliditeMutationResponse,
|};
*/


/*
mutation onDownloadDeclarationMiseInvaliditeMutation {
  onDownloadDeclarationMiseInvalidite {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EmailMutationResponse",
    "kind": "LinkedField",
    "name": "onDownloadDeclarationMiseInvalidite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "onDownloadDeclarationMiseInvaliditeMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "onDownloadDeclarationMiseInvaliditeMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "f5046f5f4d26ee8537c07269713a6532",
    "metadata": {},
    "name": "onDownloadDeclarationMiseInvaliditeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a30458ee3fed703dedb071eca44108d2';

module.exports = node;
