import useFetchQuery from './useFetchQuery';
import { QPartyTypesQuery } from '../_graphql/queries/QPartyTypes';

const usePartyTypes = () => {
  return useFetchQuery({
    query: QPartyTypesQuery,
    dataProp: 'partyTypes'
  });
};

export default usePartyTypes;
