
const R = require('ramda');
const RA = require('ramda-adjunct');

export const parseFloatOr = R.curry((defaultValue, value) => {
  if (RA.isNumber(value)) {
    return value;
  }

  return R.compose(
    RA.toNumber,
    R.when(RA.isString, RA.replaceAll(',', '.')),
    R.when(
      R.compose(R.not, (n) => /^\d*[.,]?\d*$/i.test(n)),
      () => parseFloatOr(0, defaultValue)
    ),
    R.when(RA.isNilOrEmpty, R.always(0))
  )(value);
});

export default parseFloatOr;
