import { commitMutation, graphql } from 'react-relay';
import environment from '../../index';

const mutation = graphql`
mutation onDemandeAttestationMademinMutation($email: String!) {
  onDemandeAttestationMademin(email: $email) {
    ok
    error
  }
}`;

export default async ({ email }, done) => {
  const variables = {
    email
  };

  commitMutation(
    await environment(),
    {
      mutation,
      variables,
      updater: (proxyStore) => {},
      onCompleted: ({ onDemandeAttestationMademin }) => {
        const { ok, error } = onDemandeAttestationMademin;
        done(ok, error);
      },
      onError: err => console.error(err)
    }
  );
};
