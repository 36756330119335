/* eslint-disable react/display-name */
import React from 'react';
import propTypes from 'prop-types';

import Table from '@@components/table';
import ColoredIcon from '@@components/ColoredIcon';
import { textSort } from '../../../../components/table/sorts/textSort';

const MSG_WHEN_NIL = 'Information absente';

const ApporteurList = ({ apporteurs, onSelect }) => {
  const columns = React.useMemo(() => [
    {
      Header: 'Nom Prénom',
      id: 'name',
      accessor: (r) => r.lastname ? (`${r.lastname} ${r.firstname}`) : (MSG_WHEN_NIL),
      sortType: textSort
    },
    {
      Header: 'Identifiant',
      id: 'login',
      accessor: 'login',
      disableFilters: true,
      sortType: textSort,
      Cell: ({ cell }) => (<div className="flex justify-between cursor-pointer" onClick={() => onSelect(cell.row.original.id)}>
        <div>{cell.value}</div>
        <ColoredIcon width={24} height={24} icon="bt-edit.svg" color="pink" />
      </div>)
    }
  ]);

  return <Table
    data={apporteurs}
    columns={columns}
    isBlock={false}
    onRowClick={(row) => onSelect(row)}
  />;
};
ApporteurList.propTypes = {
  apporteurs: propTypes.arrayOf(propTypes.shape({
    id: propTypes.string,
    email: propTypes.string,
    firstname: propTypes.string,
    lastname: propTypes.string,
    login: propTypes.string,
    openning_date: propTypes.string
  })),
  onSelect: propTypes.func
};

export default ApporteurList;
