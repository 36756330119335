import React from 'react';
import ContractDetail from '../containers/portefeuille/ContractDetail';
import { Page } from '@@components';
import QApporteurContractDetail from '@@queries/QApporteurContractDetail';
import { useParams, useHistory } from 'react-router-dom';

const ContractDetailPage = () => {
  const { id } = useParams();
  const history = useHistory();

  return (
    <Page title={(
      <div className="flex">
        <img
          className="pr-3 cursor-pointer mt-2 h-6 w-6"
          src="/icons/back.svg"
          alt="Icone flèche de retour"
          onClick={() => history.goBack()}/>
        Détails du contrat
      </div>
    )}>
      <QApporteurContractDetail args={{ $id: id }}>
        {({ apporteurContractDetail }) => {
          return (<ContractDetail contract={apporteurContractDetail}/>);
        }}
      </QApporteurContractDetail>
    </Page>
  );
};

export default ContractDetailPage;
