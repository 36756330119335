import { Block, Progress } from '@@components';
import classNames from 'classnames';
import Moment from 'moment';
import propTypes from 'prop-types';
import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Pagination } from '../../../../components';
import SelectOneOption from '../../../../components/input/SelectOneOption';
import { ScrollContext } from '../../../../components/Page';
import useEnv from '../../../../hooks/useEnv';
import { hasMoreThanOneElement } from '../../../../lib/hasMoreThanOneElement';
import { isUserManager } from '../../../../lib/user';
import { UserContext } from '../../../userSession/withUser';
import PillTypeApporteur from '../../components/PillTypeApporteur';
import { PER_PAGE, useOavContractList } from './useOavContractList';

const R = require('ramda');

const customStyles = {
  option: (provided) => ({
    ...provided,
    textAlign: 'left',
    fontWeight: 'normal'
  }),
  control: (provider) => ({
    ...provider,
    height: 30,
    minHeight: 30,
    fontSize: 10,
    paddingTop: 0
  }),
  placeholder: (provider) => ({
    ...provider,
    height: 20,
    minHeight: 20,
    padding: 2
  }),
  dropdownIndicator: (provider) => ({
    ...provider,
    padding: 2
  }),
  valueContainer: () => ({
    height: 30
  }),
  clearIndicator: (provider) => ({
    ...provider,
    padding: 2
  })
};

const OavContractList = ({ contracts: rawContracts }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const { env, isLoadedEnv } = useEnv();
  const { register, controls } = Block.useBlocks();
  const { data } = rawContracts;
  const { setShouldScrollToTop } = useContext(ScrollContext);
  const { user } = useContext(UserContext);
  const isManager = isUserManager(user);

  const onChangePage = (currentPage) => setPageIndex(currentPage - 1);

  const {
    onChangeApporteur,
    apporteurs,
    selectedApporteur,
    selectedStatus,
    onChangeStatus,
    toStatusLabel,
    paginationlist,
    totalFilteredContracts,
    filterStatus
  } = useOavContractList(data);

  useEffect(() => {
    setShouldScrollToTop(false);
    setPageIndex(0);
  }, [selectedApporteur, selectedStatus]);

  return (
    <div style={{ minHeight: '654px' }}>
      <Block name="running-contract" title={hasMoreThanOneElement(data) ? 'Mes propositions en cours' : 'Ma proposition en cours'} color="pink" register={register({ opened: true, canClose: false })} controls={controls}>
        <Block>
          <table className="w-full my-2 mb-4" >
            <thead>
              <tr className="text-grey-blue text-left font-bold uppercase text-sm md:text-base">
                <th>Apporteur</th>
                <th>Souscripteur</th>
                <th>Statut</th>
              </tr>
              <tr>
                <th className='w-1/3 pb-2'>
                  {isManager && (
                    <SelectOneOption
                      styles={customStyles}
                      isClearable={true}
                      value={selectedApporteur}
                      options={apporteurs}
                      onChange={onChangeApporteur}
                      placeholder={'Filtrer par apporteur...'}
                    />
                  )}
                </th>
                <th className='w-1/3'>&nbsp;</th>
                <th className='w-1/3 pb-2'>
                  <SelectOneOption
                    isClearable={true}
                    styles={customStyles}
                    value={selectedStatus}
                    options={filterStatus}
                    onChange={onChangeStatus}
                    placeholder={'Filtrer par statut...'}
                  />
                </th>
              </tr>
            </thead>
            <tbody className="divide-solid text-plum">
              {R.isEmpty(paginationlist) ? (
                <tr>
                  <th colSpan={3}>Aucune donnée correspondante à afficher</th>
                </tr>
              ) : (R.keys(paginationlist[pageIndex]).map((key) => {
                return (<React.Fragment key={key}>
                  <tr className="text-left capitalize border-t-2 first:border-0 border-gray-200">
                    <th colSpan={3}>{Moment(key, 'YYYY-MM').format('MMMM YYYY')}</th>
                  </tr>
                  {R.prop(key, paginationlist[pageIndex]).map((contract, i) => (<tr key={i} className={classNames({ 'bg-gray-200': i % 2 })}>
                    <td className="py-2">{contract.apporteur}</td>
                    <td>
                      {contract.souscripteur}
                      <PillTypeApporteur type={contract.type}/>
                    </td>
                    <td>
                      <div className="flex items-center text-left md:block">
                        <Progress value={contract.status.percent}/>
                        <span className="w-150">{toStatusLabel(contract.status.status)}</span>
                      </div>
                    </td>
                  </tr>))}
                </React.Fragment>
                );
              }))}
            </tbody>
          </table>
          {(totalFilteredContracts > 0 &&
          <Pagination
            onChange={onChangePage}
            current={pageIndex + 1}
            pageSize={PER_PAGE}
            total={totalFilteredContracts}
          />
          )}
        </Block>

        {isLoadedEnv && <Link to={{ pathname: `${env.SERVICE_OAV}/admin/propositions` }} target="_blank" className="f-button f-button-plum mb-3">Voir toutes mes propositions</Link>}

      </Block>
    </div>
  );
};

OavContractList.propTypes = {
  contracts: propTypes.shape({
    page: propTypes.number,
    count: propTypes.number,
    nbItemPerPage: propTypes.number,
    nbPages: propTypes.number,
    data: propTypes.arrayOf(
      propTypes.shape({
        contractNumber: propTypes.number,
        name: propTypes.any,
        num: propTypes.number,
        cotisation: propTypes.any,
        soldeContract: propTypes.any,
        date: propTypes.string,
        apporteur: propTypes.string,
        taux: propTypes.string,
        souscripteur: propTypes.string,
        type: propTypes.string,
        college: propTypes.string,
        status: propTypes.shape({
          percent: propTypes.number,
          status: propTypes.string
        })
      })
    )
  })
};

export default OavContractList;
