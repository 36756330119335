import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Bar } from 'react-chartjs-2';

import colors from '@@refs/colors';
import * as RA from 'ramda-adjunct';

const toKValue = R.compose(
  (n) => n.toLocaleString(undefined, { maximumFractionDigits: 2 }) + 'K',
  R.when(R.gte(R.__, 1), RA.round),
  R.divide(R.__, 1000)
);

const chartOptions = {
  indexAxis: 'y',
  responsive: true,
  aspectRatio: 1 / 1.5,
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label: (tooltipItem) => {
          const value = tooltipItem.raw;
          return toKValue(value);
        }
      }
    }
  },
  scales: {
    yAxes: {
      grid: {
        display: false
      }
    },
    xAxes: {
      ticks: {
        callback: (value) => toKValue(value)
      }
    }
  }
};

const ChartCotisations = ({ data }) => {
  const cleanDataset = R.compose(
    R.reverse,
    R.sortBy(R.prop('amountCotisation')),
    R.values,
    R.reduceBy(
      (acc, el) => {
        return R.evolve({ amountCotisation: R.add(R.prop('amountCotisation', acc)) }, el);
      },
      { amountCotisation: 0 },
      R.prop('productLabel')
    ),
    R.map((el) => R.evolve({ amountCotisation: (n) => Number.parseFloat(n.replace(',', '.')) })(el))
  )(data);

  const chartData = {
    labels: R.map(R.prop('productLabel'), cleanDataset),
    datasets: [{
      data: R.map(R.prop('amountCotisation'), cleanDataset),
      backgroundColor: R.map(R.always(colors.purple), cleanDataset),
      minBarLength: 2
    }]
  };

  return (
    <div className="h-64 overflow-y-auto">
      <Bar
        data={chartData}
        options={chartOptions}
      />
    </div>
  );
};

ChartCotisations.propTypes = {
  data: PropTypes.array.isRequired
};

ChartCotisations.defaultProps = {
};

export default ChartCotisations;
