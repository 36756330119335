import React from 'react';
import { graphql } from 'react-relay';

import Query from '../Query';
import { populateChildren } from './toolbox';

const QCommissionsBordereauxListQuery = graphql`
  query QCommissionsBordereauxListQuery($year: Int) {
    getBordereauxList(year: $year) {
      ok
      data {
        id
        completeFileName
        name
        networkId
        periode
        year
      }
      error
    }
  }
`;

const QCommissionsBordereauxList = ({ args, children, childDataProp, mockData }) => {
  return (
    <Query
      query={QCommissionsBordereauxListQuery}
      args={args || {}}
      mockKey='QCommissionsBordereauxListQuery'
      mockData={mockData}
    >
      {populateChildren(['getBordereauxList'])(children, childDataProp)}
    </Query>
  );
};

export default QCommissionsBordereauxList;
