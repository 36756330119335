import React from 'react';
import { Page, Block } from '@@components';
import CotisationsList from '../containers/CotisationsList';
import useCompany from '../../../hooks/useCompany';

const Index = () => {
  const { register, controls } = Block.useBlocks();
  const { getBillings } = useCompany();

  return (
    <Page title="Mes cotisations">
      <Block name="standard-cotisations"
        color="pink"
        title='Mes cotisations'
        icon="menu-sinistres.svg"
        controls={controls}
        register={register({ opened: true, canClose: false })}
      >
        <CotisationsList cotisations={getBillings()} />
      </Block>
    </Page>
  );
};

export default Index;
