import useFetching from './useFetching';
import { AddSinistreMutation } from '@@mutations/prestations';

const usePrestation = () => {
  const fetching = useFetching();

  const onAddSinistre = (type) => {
    fetching.start();
    AddSinistreMutation(type, () => {
      fetching.stop();
    });
  };

  return {
    fetching,
    onAddSinistre
  };
};

export default usePrestation;
