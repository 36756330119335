/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [

{
name: 'lastname',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Nom de l'affilié",
placeholder: "__UNDEFINED__",

disabled: true,

handlers: {
visible: undefined
}
},

{
name: 'firstname',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Prénom de l'affilié",
placeholder: "__UNDEFINED__",

disabled: true,

handlers: {
visible: undefined
}
},

{
name: 'birthdate',
required: false,
input: 'date',
source: '__UNDEFINED__',
label: "Date de naissance",
placeholder: "__UNDEFINED__",

disabled: true,

handlers: {
visible: undefined
}
},

{
name: 'nir',
required: false,
input: 'text',
source: '__UNDEFINED__',
label: "Numéro Sécurité sociale",
placeholder: "__UNDEFINED__",

disabled: true,

handlers: {
visible: undefined
}
},

{
name: 'date',
required: false,
input: 'date',
source: '__UNDEFINED__',
label: "Début d’affiliation",
placeholder: "__UNDEFINED__",

disabled: true,

handlers: {
visible: undefined
}
},

];

export default formData;