/**
 * @flow
 * @relayHash aba27bcf7724bf208942ac97b7c0d86b
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserSessionRoleEnum = "ADMIN" | "APPORTEUR" | "MANAGER" | "TIERS" | "%future added value";
export type QUserSessionQueryVariables = {||};
export type QUserSessionQueryResponse = {|
  +userSession: ?{|
    +id: ?string,
    +username: ?string,
    +email: ?string,
    +role: ?UserSessionRoleEnum,
    +companyType: ?string,
    +networkInformation: ?{|
      +groupName: ?string
    |},
    +sentence: ?string,
  |}
|};
export type QUserSessionQuery = {|
  variables: QUserSessionQueryVariables,
  response: QUserSessionQueryResponse,
|};
*/


/*
query QUserSessionQuery {
  userSession {
    id
    username
    email
    role
    companyType
    networkInformation {
      groupName
    }
    sentence
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserSession",
    "kind": "LinkedField",
    "name": "userSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "role",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "companyType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "NetworkInformation",
        "kind": "LinkedField",
        "name": "networkInformation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "groupName",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "sentence",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QUserSessionQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QUserSessionQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "aba27bcf7724bf208942ac97b7c0d86b",
    "metadata": {},
    "name": "QUserSessionQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4f54bf00a03c3f565f63e703c7f31aaf';

module.exports = node;
