import React from 'react';
import { useForm } from 'react-hook-form';
import { useApporteur, useQueryUrl } from '@@hooks';
import Layout from '@@layout';
import { Block, Page } from '@@components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const hasAtLeastOneLowercaseLetterPattern = /[a-z]+/;
const hasAtLeastOneUppercaseLetterPattern = /[A-Z]+/;
const hasAtLeastOneNumberPattern = /[0-9]+/;
const hasAtLeastOneSpecialCharacterPattern = /[-_`§£µ~!@#$%^&*()+={}[\]'";:/?.>,<]+/;

const signUpSchema = yup.object().shape({
  password: yup
    .string()
    .required('Le mot de passe est requis')
    .min(8, 'Le mot de passe doit faire au minimum 8 caractères')
    .matches(hasAtLeastOneSpecialCharacterPattern, 'Le mot de passe doit contenir au moins un caractère spécial')
    .matches(hasAtLeastOneLowercaseLetterPattern, 'Le mot de passe doit avoir au moins une minuscule')
    .matches(hasAtLeastOneUppercaseLetterPattern, 'Le mot de passe doit avoir au moins une majuscule')
    .matches(hasAtLeastOneNumberPattern, 'Le mot de passe doit avoir au moins un chiffre')
});

const SignUp = () => {
  const [query] = useQueryUrl();
  const { fetching, onSignUp, signInLink } = useApporteur.signUp(query.token);
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(signUpSchema)
  });

  const onSubmit = data => {
    onSignUp(data.password);
  };

  if (fetching.isDone()) {
    return (
      <Layout>
        <Page title="Finalisation de votre inscription" fw>
          <Block title="Définissez votre mot de passe" color="pink">
            <br/>
            <div>
              Votre mot de passe a bien été défini. Vous pouvez dès à présent bénéficier des outils UNMI en vous connectant à partir de la rubrique connexion sur le site www.unmi.eu. <br/><br/>
              <a className="f-link" href='https://www.unmi.eu'>CONNEXION</a>
            </div>
          </Block>
        </Page>
      </Layout>
    );
  }
  return (
    <Layout>
      <Page title="Finalisation de votre inscription" fw>
        <Block title="Définissez votre mot de passe" color="pink">
          <form onSubmit={handleSubmit(onSubmit)}>
            <input className="f-input" type="password" ref={register({ required: true })} placeholder="votre mot de passe" name="password"/>
            {errors.password &&
              <div className="message-danger">
                {errors.password.message}
              </div>
            }
            {fetching.isDoneWithError() && (
              <div className="mt-4">{'Le compte n\'a pas pu être créé.'}</div>
            )}
            <br/>
            <div className="mt-3 mb-10" style={{ fontStyle: 'italic' }}>
              Votre mot de passe doit contenir au moins 8 caractères (dont au moins une majuscule, une lettre, un chiffre, et un caractère spécial)
            </div>
            <button
              aria-label="créer"
              disabled={fetching.isFetching()}
              className="f-button f-button-pink mr-4" type="submit"
            >
              {'Créer'}
            </button>
          </form>
        </Block>
      </Page>
    </Layout>
  );
};

export default SignUp;
