// Todo: Re-enable when forms available
import rolesList from './rolesList';

const sinistreType = [
  { value: 'DECEASE', label: 'Déclaration de décès / IAD', roles: [rolesList.COMPANY] },
  // { value: 'SICK_LEAVE', label: 'Déclaration d’arrêt de travail', roles: [rolesList.COMPANY, rolesList.TNS] },
  { value: 'INABILITY', label: 'Déclaration d‘incapacité temporaire de travail', roles: [rolesList.COMPANY, rolesList.TNS] },
  { value: 'DISABILITY', label: 'Déclaration de mise en invalidité', roles: [rolesList.COMPANY, rolesList.TNS] }
  // { value: 'INFORMATION', label: 'Demande d’information', roles: [rolesList.COMPANY, rolesList.TNS] },
  // { value: 'OTHER', label: 'Autre', roles: [rolesList.COMPANY, rolesList.TNS] },
  // { value: 'CLAIM_FOR_BENEFITS', label: 'Réclamation prestations', roles: [rolesList.COMPANY, rolesList.TNS] }
];

export default sinistreType;
