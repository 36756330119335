import useFetchQuery from './useFetchQuery';
import { QContractStatusQuery } from '../_graphql/queries/QContractStatus';

const useContractStatus = () => {
  return useFetchQuery({
    query: QContractStatusQuery,
    dataProp: 'contractStatus'
  });
};

export default useContractStatus;
