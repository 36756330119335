import React, { forwardRef } from 'react';
import propTypes from 'prop-types';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'classnames';
import fr from 'date-fns/locale/fr';

const R = require('ramda');
registerLocale('fr', fr);

const DatePickerCustomInput = forwardRef((props, ref) => {
  const { value, onClick, onChange, onBlur, onFocus, onKeyDown, hasError, disabled, format } = props;
  const classes = classNames({
    'f-input-red': hasError,
    'f-input-gray': !hasError,
    'f-input-disabled': disabled
  });

  const handleOnChange = (e) => {
    // * Get acces to new value
    const v = R.path(['target', 'value'], e);

    // * Handle Empty
    if (R.isEmpty(v) || R.isNil(v)) { return null; }

    onChange(e);
    return v;
  };
  const opacityLogo = (R.isNil(value) || R.isEmpty(value)) * 1;

  const placeholder = R.cond([
    [R.equals('dd/MM'), R.always('JJ/MM')],
    [R.T, R.always('JJ/MM/AAAA')]
  ])(format);

  return <label>
    <input
      ref={ref}
      className={classes}
      placeholder={placeholder}
      type="text"
      onClick={onClick}
      onChange={handleOnChange}
      onBlur={onBlur}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      disabled={disabled}

      value={value}
      style={{
        position: 'relative',
        pointerEvents: 'auto',
        paddingLeft: '0.25rem',
        paddingRight: '0.25rem'
      }}
    />
    <span className="f-datepicker-icon" style={{ opacity: opacityLogo, background: 'url(/icons/calendar.svg) center / contain no-repeat' }}></span>
  </label>;
});

const DatePicker = ({ value, minDate, maxDate, format, onChange, hasError, disabled }) => {
  return <ReactDatePicker
    dateFormat={format}
    selected={value}
    minDate={minDate}
    maxDate={maxDate}
    isClearable={disabled !== true}
    onChange={onChange}
    customInput={<DatePickerCustomInput hasError={hasError} disabled={disabled || false} format={format} />}
    locale="fr"
    disabled={disabled}
  />;
};

DatePickerCustomInput.propTypes = {
  onClick: propTypes.func,
  onChange: propTypes.func,
  onBlur: propTypes.func,
  onFocus: propTypes.func,
  onKeyDown: propTypes.func,
  hasError: propTypes.bool,
  disabled: propTypes.bool
};

DatePicker.propTypes = {
  format: propTypes.string,
  onChange: propTypes.func,
  hasError: propTypes.bool,
  disabled: propTypes.bool
};

export default DatePicker;
