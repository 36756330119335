import React, { useState } from 'react';
import { Block } from '@@components';
import DocumentsList from '../components/DocumentsList';
import { SelectOneOption } from '@@components/input';
import { always, compose, equals, filter, head, ifElse, isEmpty, length, pathOr, prop } from 'ramda';
import { hasMoreThanOneElement } from '../../../lib/hasMoreThanOneElement';
import { hasOneElement } from '../../../lib/hasOneElement';
import useCompany from '../../../hooks/useCompany';

const getDefaultValue = ifElse(
  hasOneElement,
  compose(
    prop('contractId'),
    head
  ),
  always(null)
);

const DocumentsBlock = ({ contracts = [] }) => {
  const [selectedContract, setSelectedContract] = useState(hasOneElement(contracts) ? getDefaultValue(contracts) : false);
  const [selectedContractCollege, setSelectedContractCollege] = useState();
  const { register, controls } = Block.useBlocks();
  const { getDocumentsByContractId } = useCompany();

  const options = contracts.map(
    contract => (
      {
        label: `${contract.info.nomProduit} ${contract.id} ${pathOr('', ['info', 'college'], contract)}`,
        value: prop('contractId', contract),
        college: pathOr('', ['info', 'college'], contract)
      }
    )
  );

  const onSelectContract = ({ value, college }) => {
    setSelectedContract(value);
    setSelectedContractCollege(college);
  };

  const getSelectedContract = (selectedContract) => compose(
    ifElse(
      isEmpty,
      always(null),
      head
    ),
    filter(compose(
      equals(selectedContract),
      prop('value')
    ))
  );

  return (
    <>
      {length(contracts) ? (
        <Block
          name="documentsBlock"
          color="pink"
          icon="menu-sinistres.svg"
          title={hasMoreThanOneElement(contracts) ? 'Mes contrats' : 'Mon contrat'}
          register={register}
          controls={controls}
        >
          <SelectOneOption
            options={options}
            value={hasOneElement(contracts) ? head(options) : getSelectedContract(selectedContract)(options)}
            onChange={onSelectContract}
            placeholder="Sélectionner un contrat"
          />
          {selectedContract && <DocumentsList documents={getDocumentsByContractId(selectedContract)} college={selectedContractCollege}/>}
        </Block>
      ) : 'Aucun document'}
    </>
  );
};

export default DocumentsBlock;
