import './BlockField.less';
import React from 'react';
import propTypes from 'prop-types';

const R = require('ramda');

const BlockField = ({ label, value, color, children }) => (
  <div className='f-block-field'>
    <div className={`f-block-field-label ${'text-' + color}`}>{label}</div>
    {R.isNil(children) && <div>{value}</div>}
    {!R.isNil(children) && children}
  </div>
);

BlockField.propTypes = {
  color: propTypes.string,
  width: propTypes.number,
  label: propTypes.string,
  value: propTypes.string
};

export default BlockField;
