import { commitMutation, graphql } from 'react-relay';
import environment from '../../index';

const mutation = graphql`
mutation AddSinistreMutation($type: SinistreTypeEnum!) {
  addSinistre(type: $type) {
    ok
    error
  }
}
`;

export default async (args, done) => {
  const variables = { type: args };

  commitMutation(
    await environment(),
    {
      mutation,
      variables,
      updater: (proxyStore) => {},
      onCompleted: ({ addSinistre }) => {
        const { ok, error } = addSinistre;
        done(ok, error);
      },
      onError: err => console.error(err)
    }
  );
};
