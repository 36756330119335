import React from 'react';
import { applySpec, prop } from 'ramda';
import { Block } from '@@components';
import useForms from '@@formsHook';
import Forms from '@@components/forms';

const AffilieDeclarePortaBlock = ({ affiliate }) => {
  const { formsData, onFormsSubmit } = useForms('affilieDeclarePortabilite');

  const onSubmit = (data) => {
    onFormsSubmit(data);
  };

  const formatDefault = applySpec({
    firstname: prop('firstname'),
    lastname: prop('lastname')
  });

  return (
    <Block name="portabilite-list" color="pink" icon="apporteur.svg" title="Déclarer la Portabilité">
      <Forms
        defaultValues={formatDefault(affiliate)}
        formsData={formsData}
        onSubmit={onSubmit}
        submitSection={() => (<button aria-label="Déclarer la probabilité" type="submit" className="f-button f-button-pink">Déclarer la portabilité</button>)}
      />
    </Block>
  );
};

export default AffilieDeclarePortaBlock;
