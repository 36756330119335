import React from 'react';
import propTypes from 'prop-types';
import Table from '../../../components/table';
import { always, equals, ifElse, propOr, pathOr } from 'ramda';
import { SelectDateFilter } from '../../../components/table/filters';
import { Pagination } from '../../../components';
import { DateSort } from '../../../components/table/sorts/dateSort';
import Popover from '../../../components/elements/Popover';

const cellPopover = ({ cell }) => {
  const value = propOr('', 'value', cell);
  const content = pathOr('', ['row', 'original', 'statusDetail'], cell);

  return <Popover content={content}>{value}</Popover>;
};

const AccountsTable = ({ accountHistories, isApporteur, onChangePage }) => {
  const columns = ifElse(equals(true), always(React.useMemo(() => [
    { Header: 'Type', id: 'type', accessor: 'type' },
    { Header: 'Identifiant', id: 'username', accessor: 'username' },
    { Header: 'Statut', id: 'status', accessor: 'status' },
    { Header: 'Code apporteur', id: 'codeApporteur', accessor: 'codeApporteur' },
    { Header: 'Code manager', id: 'codeManager', accessor: 'codeManager' },
    { Header: 'Date de création', id: 'createdAt', Filter: SelectDateFilter, sortType: DateSort, accessor: 'createdAt' },
    { Header: 'Date de mise à jour', id: 'lastUpdatedAt', Filter: SelectDateFilter, sortType: DateSort, accessor: 'lastUpdatedAt' }
  ])), always(React.useMemo(() => [
    { Header: 'Type', id: 'type', accessor: 'type' },
    { Header: 'Identifiant', id: 'username', accessor: 'username' },
    { Header: 'Statut', id: 'status', accessor: 'status', Cell: cellPopover },
    { Header: 'Identifiant tiers', id: 'tiersId', accessor: 'tiersId' },
    { Header: 'Date de création', id: 'createdAt', Filter: SelectDateFilter, sortType: DateSort, accessor: 'createdAt' },
    { Header: 'Date de mise à jour', id: 'lastUpdatedAt', Filter: SelectDateFilter, sortType: DateSort, accessor: 'lastUpdatedAt' }
  ])))(isApporteur);

  const pagination = propOr({ page: 0, limit: 10, pages: 1, total: 0 }, 'pagination', accountHistories);

  return (

    <>
      <Table
        data={propOr([], 'items', accountHistories)}
        columns={columns}
        isBlock={false}
      />

      <Pagination
        onChange={onChangePage}
        current={pagination.page}
        pageSize={pagination.limit}
        total={pagination.total}
      />
    </>
  );
};

AccountsTable.propTypes = {
  accountHistories: propTypes.shape({
    items: propTypes.arrayOf(propTypes.shape({
      codeApporteur: propTypes.string,
      codeManager: propTypes.string,
      createdAt: propTypes.string,
      lastUpdatedAt: propTypes.string,
      status: propTypes.string,
      tiersId: propTypes.string,
      type: propTypes.string,
      username: propTypes.string
    })),
    pagination: propTypes.shape({
      limit: propTypes.number,
      page: propTypes.number,
      pages: propTypes.number,
      total: propTypes.number
    })
  }),
  onChangePage: propTypes.func,
  isApporteur: propTypes.bool
};

export default AccountsTable;
