import propTypes from 'prop-types';

const NETWORK_INFORMATION = {
  groupName: propTypes.string
};

export const USER_PROPTYPES = {
  id: propTypes.string,
  email: propTypes.string,
  username: propTypes.string,
  role: propTypes.string,
  companyType: propTypes.string,
  networkInformation: propTypes.shape(NETWORK_INFORMATION)
};
