/**
 * @flow
 * @relayHash 7777c74d20075a5aab523522c4a36371
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaginationInput = {|
  limit: number,
  page: number,
|};
export type QAccountHistoriesQueryVariables = {|
  type: string,
  pagination?: ?PaginationInput,
|};
export type QAccountHistoriesQueryResponse = {|
  +getAccountHistories: ?{|
    +items: ?$ReadOnlyArray<?{|
      +username: ?string,
      +codeApporteur: ?string,
      +codeManager: ?string,
      +createdAt: ?string,
      +lastUpdatedAt: ?string,
      +status: ?string,
      +statusDetail: ?string,
      +tiersId: ?string,
      +type: ?string,
    |}>,
    +pagination: ?{|
      +limit: number,
      +page: number,
      +pages: number,
      +total: number,
    |},
  |}
|};
export type QAccountHistoriesQuery = {|
  variables: QAccountHistoriesQueryVariables,
  response: QAccountHistoriesQueryResponse,
|};
*/


/*
query QAccountHistoriesQuery(
  $type: String!
  $pagination: PaginationInput
) {
  getAccountHistories(type: $type, pagination: $pagination) {
    items {
      username
      codeApporteur
      codeManager
      createdAt
      lastUpdatedAt
      status
      statusDetail
      tiersId
      type
    }
    pagination {
      limit
      page
      pages
      total
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pagination"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "pagination",
        "variableName": "pagination"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "concreteType": "PaginatedAccountHistory",
    "kind": "LinkedField",
    "name": "getAccountHistories",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AccountHistory",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "username",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "codeApporteur",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "codeManager",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastUpdatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "statusDetail",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tiersId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Pagination",
        "kind": "LinkedField",
        "name": "pagination",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "limit",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "page",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "total",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QAccountHistoriesQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QAccountHistoriesQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "id": "7777c74d20075a5aab523522c4a36371",
    "metadata": {},
    "name": "QAccountHistoriesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1276d1f122414fe5a017cd05980f31b9';

module.exports = node;
