import * as R from 'ramda';
import { toast } from 'react-toastify';
import { useEffect, useRef } from 'react';

// * Usage for ensure there is no exception when the fetching is null and we call a methods from it
const withDefault = R.compose(
  R.unless(R.hasIn('getStatus'), R.assoc('getStatus', R.always(null))),
  R.unless(R.hasIn('isFetching'), R.assoc('isFetching', R.always(false))),
  R.unless(R.hasIn('isDoneWithError'), R.assoc('isDoneWithError', R.always(false))),
  R.unless(R.hasIn('isDone'), R.assoc('isDone', R.always(false))),
  R.defaultTo({})
);

export const useFeedback = (fetching, params) => {
  const toastId = useRef(null);
  const valRef = useRef();

  // * To ensure not having method is undefined exception
  const fetchingWithDefault = withDefault(fetching);
  const status = fetchingWithDefault.getStatus();

  useEffect(() => {
    // * Ensure don't call at each refresh
    if (valRef.current === status) return;
    valRef.current = status;

    // * has a value
    if (R.isNil(fetching) || R.isNil(status)) {
      return;
    }

    if (fetchingWithDefault.isFetching()) {
      toastId.current = toast.info(
        R.propOr('Demande en cours...', 'queryMessage', params)
      );
    } else { // * Clean up toast
      toast.dismiss(toastId.current);
    }

    if (fetchingWithDefault.isDoneWithError()) {
      toast.error(
        R.propOr('Une erreur est survenue lors de votre demande', 'errorMessage', params)
      );
    } else if (fetchingWithDefault.isDone()) {
      toast.success(
        R.propOr('Votre demande à été prise en compte !', 'successMessage', params)
      );
    }
  }, [status]);
};

export const useFeedbackCb = (action, params) => {
  return async () => {
    const toastId = toast.info(
      R.propOr('Demande en cours...', 'queryMessage', params)
    );
    action((ok) => {
      toast.dismiss(toastId);
      if (ok) {
        toast.success(
          R.propOr('Votre demande à été prise en compte !', 'successMessage', params)
        );
      } else {
        toast.error(
          R.propOr('Une erreur est survenue lors de votre demande', 'errorMessage', params)
        );
      }
    });
  };
};

export default useFeedback;
