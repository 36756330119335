import isPersonnePhysique from './isPersonnePhysique';

const R = require('ramda');

const isTns = (values) => {
  const isPP = isPersonnePhysique(values);

  const hasSiret = R.compose(
    R.not,
    R.isNil,
    R.prop('siret')
  )(values);

  return isPP && hasSiret;
};

export default isTns;
