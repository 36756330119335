import React from 'react';
import propTypes from 'prop-types';
import colors from '@@refs/colors';

const R = require('ramda');

export const ColoredIcon = ({ icon, color, className, width, height }) => {
  const bgColor = R.cond([
    [R.equals('pink'), R.always(colors.pink)],
    [R.equals('gray'), R.always(colors.gray)],
    [R.equals('plum'), R.always(colors.plum)],
    [R.or(R.isNil, R.isEmpty), R.always(colors.gray)],
    [R.T, R.always(color)]
  ])(color);

  return <div className={className} style={{
    height,
    width,
    mask: `url(/icons/${icon} no-repeat center / contain`,
    WebkitMask: `url(/icons/${icon}) no-repeat center / contain`,
    backgroundColor: bgColor
  }}>

  </div>;
};

ColoredIcon.propTypes = {
  icon: propTypes.string.isRequired,
  color: propTypes.string,
  width: propTypes.oneOfType([propTypes.number, propTypes.string]),
  height: propTypes.oneOfType([propTypes.number, propTypes.string]),
  className: propTypes.string
};

ColoredIcon.defaultProps = {
  width: 18,
  height: 18
};

export default ColoredIcon;
