import React from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import { append, assoc, isNil, path, propOr } from 'ramda';

import colors from '@@refs/colors';

const CenterTextPlugin = ({
  beforeDraw: function (chart) {
    if (isNil(path(['config', 'options', 'elements', 'center'], chart))) return;

    const ctx = chart.ctx;

    const centerConfig = path(['config', 'options', 'elements', 'center'], chart);

    const fontStyle = propOr('Arial', 'fontStyle', centerConfig);
    const txt = propOr('', 'text', centerConfig);
    const color = propOr('#000000', 'color', centerConfig);
    const maxFontSize = propOr(75, 'maxFontSize', centerConfig);
    const minFontSize = propOr(10, 'minFontSize', centerConfig);
    const sidePadding = propOr(20, 'sidePadding', centerConfig);

    const chartWidth = chart.chartArea.right - chart.chartArea.left;
    const sidePaddingCalculated = (sidePadding / 100) * chartWidth;

    // Start with a base font of 30px
    ctx.font = `${maxFontSize}px ${fontStyle}`;
    // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
    const stringWidth = ctx.measureText(txt).width;
    const elementWidth = (chart.width * 0.75) - sidePaddingCalculated;

    // Find out how much the font can grow in width.
    const widthRatio = elementWidth / stringWidth;
    const newFontSize = Math.floor(20 * widthRatio);
    const elementHeight = (chart.innerRadius * 2);

    // Pick a new font size so it will not be larger than the height of label.
    const lineHeight = propOr(25, 'lineHeight', centerConfig);
    let fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
    let wrapText = true;

    if (minFontSize && fontSizeToUse < minFontSize) {
      fontSizeToUse = minFontSize;
      wrapText = true;
    }

    // Set font settings to draw it correctly.
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';

    const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);

    let centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
    ctx.font = fontSizeToUse + 'px ' + fontStyle;
    ctx.fillStyle = color;

    if (!wrapText) {
      ctx.fillText(txt, centerX, centerY);
      return;
    }

    const words = txt.split(' ');
    let line = '';
    let lines = [];

    // Break words up into multiple lines if necessary
    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' ';
      const metrics = ctx.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > elementWidth && n > 0) {
        lines = append(line, lines);
        line = words[n] + ' ';
      } else {
        line = testLine;
      }
    }

    // Move the center up depending on line height and number of lines
    centerY -= (lines.length / 2) * lineHeight;

    for (let n = 0; n < lines.length; n++) {
      ctx.fillText(lines[n], centerX, centerY);
      centerY += lineHeight;
    }
    // Draw text in center
    ctx.fillText(line, centerX, centerY);
  }
});

const DoughnutWithText = ({ options, data, text }) => {
  const optionsWithText = assoc('elements', {
    center: {
      text: text,
      color: colors.yellow, // Default is #000000
      fontStyle: 'Arial', // Default is Arial
      sidePadding: 40, // Default is 20 (as a percentage)
      minFontSize: 5, // Default is 20 (in px), set to false and text will not wrap.
      maxFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
      lineHeight: 25 // Default is 25 (in px), used for when text wraps
    }
  }, options);
  return (<>
    <Doughnut
      plugins={[CenterTextPlugin]}
      options={optionsWithText}
      data={data}
    />
  </>);
};

DoughnutWithText.propTypes = {
  options: PropTypes.object,
  data: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string),
    datasets: PropTypes.arrayOf(PropTypes.shape({
      data: PropTypes.arrayOf(PropTypes.number),
      backgroundColor: PropTypes.arrayOf(PropTypes.string)
    }))
  }).isRequired,
  text: PropTypes.string.isRequired
};

DoughnutWithText.defaultProps = {
};

export default DoughnutWithText;
