import useFetchQuery from './useFetchQuery';
import { QLegalFormsQuery } from '../_graphql/queries/QLegalForms';

const useLegalForms = () => {
  const { legalForms, isLoaded } = useFetchQuery({
    query: QLegalFormsQuery,
    dataProp: 'legalForms'
  });

  return { legalForms, isLoaded };
};

export default useLegalForms;
