/**
 * @flow
 * @relayHash 5e2c09c66497aa0b9ef7daf78ef9df28
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QUserTiersDetailsQueryVariables = {||};
export type QUserTiersDetailsQueryResponse = {|
  +tiersDetails: ?{|
    +title: ?string,
    +firstname: ?string,
    +lastname: ?string,
    +banner: ?string,
    +siret: ?string,
    +fiscalDate: ?string,
    +contact: ?{|
      +name: ?string,
      +email: ?string,
      +tel: ?string,
    |},
    +address: ?{|
      +line: ?string,
      +zipCode: ?string,
      +city: ?string,
    |},
  |}
|};
export type QUserTiersDetailsQuery = {|
  variables: QUserTiersDetailsQueryVariables,
  response: QUserTiersDetailsQueryResponse,
|};
*/


/*
query QUserTiersDetailsQuery {
  tiersDetails {
    title
    firstname
    lastname
    banner
    siret
    fiscalDate
    contact {
      name
      email
      tel
    }
    address {
      line
      zipCode
      city
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TiersDetails",
    "kind": "LinkedField",
    "name": "tiersDetails",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "banner",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "siret",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fiscalDate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ContactDetails",
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tel",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TiersAddress",
        "kind": "LinkedField",
        "name": "address",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "line",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "zipCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QUserTiersDetailsQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QUserTiersDetailsQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "5e2c09c66497aa0b9ef7daf78ef9df28",
    "metadata": {},
    "name": "QUserTiersDetailsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a0bb15a3fcf0ccd0b5d956b02fc179ad';

module.exports = node;
