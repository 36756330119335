/**
 * @flow
 * @relayHash d31858d7e98292a919e4abf60549d436
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QPrestationsQueryVariables = {|
  contractId: string
|};
export type QPrestationsQueryResponse = {|
  +prestations: ?$ReadOnlyArray<?{|
    +id: ?string,
    +date: ?string,
    +firstname: ?string,
    +lastname: ?string,
    +contract: ?string,
    +type: ?string,
    +to: ?string,
    +rangeIndemnisation: ?string,
    +status: ?string,
    +amount: ?number,
  |}>
|};
export type QPrestationsQuery = {|
  variables: QPrestationsQueryVariables,
  response: QPrestationsQueryResponse,
|};
*/


/*
query QPrestationsQuery(
  $contractId: String!
) {
  prestations(contractId: $contractId) {
    id
    date
    firstname
    lastname
    contract
    type
    to
    rangeIndemnisation
    status
    amount
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "contractId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "contractId",
        "variableName": "contractId"
      }
    ],
    "concreteType": "Prestation",
    "kind": "LinkedField",
    "name": "prestations",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "contract",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "to",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rangeIndemnisation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QPrestationsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QPrestationsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "d31858d7e98292a919e4abf60549d436",
    "metadata": {},
    "name": "QPrestationsQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e939695bea5710a63baf2730de94fb6a';

module.exports = node;
