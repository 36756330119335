import React, { useState } from 'react';
import { Message } from '@@components';
import { exchangeSubjects } from '@@refs';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { useLocation } from 'react-router-dom';
import { cond, equals, always, applySpec, propOr, prop, T, compose, propEq, isNil, assoc, when, map } from 'ramda';
import { ColoredIcon } from '@@components/ColoredIcon';
import { haveRequiredAttachments } from '../../../lib/requiredAttachments';
import { useBeforeUnload, useBlockTransition } from '@@hooks';
import useCompany from '../../../hooks/useCompany';
import { getOptionsByCompanyType } from '../../../lib/getOptionsByCompanyType';
import propTypes from 'prop-types';

import QUserTiers from '../../../_graphql/queries/QUserTiers';
import { COMPANY_PROPTYPES } from '../../../propTypes/user/CompanyPropTypes';
import { CONTRACTS_PROPTYPES } from '../../../propTypes/user/ContractsPropTypes';

const ContactBlock = ({ company, contracts, RefDefaultStates }) => {
  const [defaultStates, setDefaultStates] = useState(RefDefaultStates);
  useBeforeUnload(prop('contract', defaultStates));

  // Classique fetch
  const {
    fetching,
    onFormsSubmit,
    formsData
  } = useForms('contactBlock');

  const onSubmit = ({
    contract,
    subject,
    message,
    attachments
  }) => {
    onFormsSubmit({
      contract,
      subject,
      message,
      attachments
    });
  };


  if (fetching.isDone()) {
    return <Message
      value='Votre message a été envoyé'
      action="Envoyer un nouveau message"
      onClick={() => fetching.reset()}
    />;
  }

  const onDownloadHandle = () => {
    window.open('documents/mandat/prelevement', '_blank');
  };

  const messages = {
    addTransferDetails: `Bonjour,
Veuillez trouver ci joint mes coordonnées bancaires (RIB/IBAN) pour le paiement de mes futures prestations.`,
    addBankDebitDetails: `Bonjour,
Veuillez trouver ci joint mes coordonnées bancaires (RIB/IBAN) et le mandat SEPA signé pour le prélèvement de mes cotisations.`
  };

  const getMessagebySubject = (state) => {
    return compose(
      message => setDefaultStates({ ...state, message }),
      cond([
        [equals('addBankDebitDetails'), always(prop('addBankDebitDetails', messages))],
        [equals('addTransferDetails'), always(prop('addTransferDetails', messages))],
        [T, always('')]
      ]),
      prop('subject')
    )(state);
  };

  const formsDataRib = map(
    when(
      propEq('name', 'attachments'),
      assoc('placeholder', 'Joindre mon RIB/IBAN et le mandat SEPA complété et signé.')
    )
  )(formsData);

  return (
    <QUserTiers>
      {({ userSession }) => (
        <Forms
          formsData={!isNil(defaultStates) && propEq('subject', 'addBankDebitDetails', defaultStates) ? formsDataRib : formsData}
          columns={1}
          defaultValues={applySpec({
            contract: propOr(null, 'contract'),
            subject: propOr(null, 'subject'),
            message: propOr('', 'message'),
            attachments: propOr([], 'attachments')
          })(defaultStates)}
          options={{
            exchangeSubjects: getOptionsByCompanyType(exchangeSubjects, userSession),
            contractList: company.contracts.map((contract, index) => ({ label: `${contract.info.nomProduit} ${contract.id} ${propOr('', ['info', 'college'], contract)}`, value: contract.id }))
          }}
          onChange={getMessagebySubject}
          onSubmit={onSubmit}
          submitSection={({ reset }) => {
            return <>
              {equals(propOr(null, 'subject', defaultStates), 'addBankDebitDetails') && (
                <div className="flex justify-center">
                  <div className="document text-plum font-bold cursor-pointer" onClick={onDownloadHandle}>
                    <ColoredIcon
                      icon="icon-documents.svg"
                      color="pink"
                      className="document-icon"
                      height="64px"
                      width="64px"
                    />
                    <div className="document-name">
                        Télécharger, Renseigner et Signer mon mandat Sepa
                    </div>
                  </div>
                </div>
              )}
              {useBlockTransition(propOr(null, 'subject', defaultStates))}
              <div className="flex gap-2 md:gap-4 justify-center md:justify-start">
                <button
                  aria-label="Annuler"
                  className="f-button f-button-plum"
                  onClick={() => {
                    reset({
                      contract: '',
                      subject: '',
                      message: '',
                      attachments: []
                    });
                  }}
                  type="reset"
                >
                Annuler
                </button>
                <button
                  aria-label="Envoyer"
                  className="f-button f-button-pink"
                  type="submit"
                  disabled={!haveRequiredAttachments(defaultStates)}
                >
                Envoyer
                </button>
              </div>
            </>;
          }}
        />
      )}
    </QUserTiers>
  );
};

const WrapperContactBlock = () => {
  const { state } = useLocation();
  const { company } = useCompany();
  return <ContactBlock company={company} RefDefaultStates={state} />;
};

ContactBlock.propTypes = {
  company: propTypes.shape(COMPANY_PROPTYPES),
  contracts: propTypes.shape(CONTRACTS_PROPTYPES),
  RefDefaultStates: propTypes.string
};

export default WrapperContactBlock;
