/**
 * @flow
 * @relayHash 037a241f3f8617119fdebcac4d5c9e23
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AffiliateRelationsEnum = "CONJOINT" | "ENFANT_A_CHARGE" | "%future added value";
export type EmployTypeEnum = "CADRE" | "NCADRE" | "%future added value";
export type FamilySituationEnum = "CIVIL_UNION" | "COHABITATION" | "DIVORCED" | "MARRY" | "SEPARATED" | "SINGLE" | "WIDOVER" | "%future added value";
export type TitleEnum = "Madame" | "Monsieur" | "%future added value";
export type DeclareSinistreDecesInput = {|
  attachments?: ?$ReadOnlyArray<?AttachmentInput>,
  birthdate?: ?string,
  birthname?: ?string,
  childrens?: ?number,
  contactBirthdate?: ?string,
  contactBirthname?: ?string,
  contactCivility?: ?TitleEnum,
  contactEmail?: ?string,
  contactFirstname?: ?string,
  contactParentLink?: ?string,
  contactPhone?: ?string,
  contractId?: ?string,
  decease_at?: ?string,
  emploiCategory?: ?EmployTypeEnum,
  family_situation?: ?FamilySituationEnum,
  firstChildBirth?: ?string,
  firstname?: ?string,
  forInsured?: ?boolean,
  fourthChildBirth?: ?string,
  iad_at?: ?string,
  nir?: ?string,
  notAffiliateBirthname?: ?string,
  notAffiliateDeceaseAt?: ?string,
  notAffiliateFirstname?: ?string,
  notAffiliateParentLink?: ?AffiliateRelationsEnum,
  notAffiliateTitle?: ?TitleEnum,
  primesLastYear?: ?number,
  salaryTrimestrial?: ?number,
  secondChildBirth?: ?string,
  startDate?: ?string,
  thirdChildBirth?: ?string,
  title?: ?TitleEnum,
|};
export type AttachmentInput = {|
  documentType?: ?string,
  fileName: string,
  uploadId: string,
|};
export type DeclareSinistreDecesMutationVariables = {|
  input: DeclareSinistreDecesInput
|};
export type DeclareSinistreDecesMutationResponse = {|
  +declareSinistreDeces: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type DeclareSinistreDecesMutation = {|
  variables: DeclareSinistreDecesMutationVariables,
  response: DeclareSinistreDecesMutationResponse,
|};
*/


/*
mutation DeclareSinistreDecesMutation(
  $input: DeclareSinistreDecesInput!
) {
  declareSinistreDeces(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeclareSinistreDecesMutationResponse",
    "kind": "LinkedField",
    "name": "declareSinistreDeces",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeclareSinistreDecesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeclareSinistreDecesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "037a241f3f8617119fdebcac4d5c9e23",
    "metadata": {},
    "name": "DeclareSinistreDecesMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5b7997d53827277999d2abe81c1d2b84';

module.exports = node;
