import React from 'react';
import { genders } from '@@refs';
import Forms from '@@components/forms';
import useForms from '@@formsHook';
import { useFeedback } from '../../../hooks';

const MeBlock = ({ me }) => {
  const { fetching, formsData, onFormsSubmit } = useForms('editProfil');

  useFeedback(fetching);

  const onSubmit = (data) => {
    onFormsSubmit(data);
  };

  return (
    <Forms
      formsData={formsData}
      defaultValues={{ me, gender: null }}
      onSubmit={onSubmit}
      options={{ genders }}
      submitSection={() => (
        <button aria-label="Sauvegarder les modifications" type="submit" className="f-button f-button-pink text-center">Sauvegarder les modifications</button>
      )}
    />
  );
};

export default MeBlock;
