/**
 * @flow
 * @relayHash 3a2a9cc5e00522c5c19c3e3ebf5f6b45
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UpdateTiersInformationInput = {|
  city?: ?string,
  email?: ?string,
  line?: ?string,
  phone?: ?string,
  zipCode?: ?string,
|};
export type UpdateTiersInformationMutationVariables = {|
  input: UpdateTiersInformationInput
|};
export type UpdateTiersInformationMutationResponse = {|
  +updateTiersInformation: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type UpdateTiersInformationMutation = {|
  variables: UpdateTiersInformationMutationVariables,
  response: UpdateTiersInformationMutationResponse,
|};
*/


/*
mutation UpdateTiersInformationMutation(
  $input: UpdateTiersInformationInput!
) {
  updateTiersInformation(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateTiersInformationMutationResponse",
    "kind": "LinkedField",
    "name": "updateTiersInformation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateTiersInformationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateTiersInformationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "3a2a9cc5e00522c5c19c3e3ebf5f6b45",
    "metadata": {},
    "name": "UpdateTiersInformationMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7b02f69a9463dcfc3c425f5fb568229a';

module.exports = node;
