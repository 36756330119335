const titles = [
  {
    label: 'Monsieur',
    value: 'Monsieur'
  },
  {
    label: 'Madame',
    value: 'Madame'
  }
];

export default titles;
