/**
 * @flow
 * @relayHash ad1e646e9205b3e4f098d388bb89a106
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QContractStatusQueryVariables = {||};
export type QContractStatusQueryResponse = {|
  +contractStatus: ?$ReadOnlyArray<?{|
    +label: ?string
  |}>
|};
export type QContractStatusQuery = {|
  variables: QContractStatusQueryVariables,
  response: QContractStatusQueryResponse,
|};
*/


/*
query QContractStatusQuery {
  contractStatus {
    label
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ContractStatusRef",
    "kind": "LinkedField",
    "name": "contractStatus",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QContractStatusQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QContractStatusQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "ad1e646e9205b3e4f098d388bb89a106",
    "metadata": {},
    "name": "QContractStatusQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3137d6fcc3eb4740c15f47d8b2967aa2';

module.exports = node;
