/**
 * @flow
 * @relayHash 42ad4f9570baa7d840ea2c27a77a63d9
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QCommissionsBordereauxListQueryVariables = {|
  year?: ?number
|};
export type QCommissionsBordereauxListQueryResponse = {|
  +getBordereauxList: ?{|
    +ok: ?boolean,
    +data: ?$ReadOnlyArray<?{|
      +id: ?string,
      +completeFileName: ?string,
      +name: ?string,
      +networkId: ?string,
      +periode: ?string,
      +year: ?string,
    |}>,
    +error: ?string,
  |}
|};
export type QCommissionsBordereauxListQuery = {|
  variables: QCommissionsBordereauxListQueryVariables,
  response: QCommissionsBordereauxListQueryResponse,
|};
*/


/*
query QCommissionsBordereauxListQuery(
  $year: Int
) {
  getBordereauxList(year: $year) {
    ok
    data {
      id
      completeFileName
      name
      networkId
      periode
      year
    }
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "year"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "year",
        "variableName": "year"
      }
    ],
    "concreteType": "BordereauxList",
    "kind": "LinkedField",
    "name": "getBordereauxList",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Bordereau",
        "kind": "LinkedField",
        "name": "data",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "completeFileName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "networkId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "periode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "year",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QCommissionsBordereauxListQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QCommissionsBordereauxListQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "42ad4f9570baa7d840ea2c27a77a63d9",
    "metadata": {},
    "name": "QCommissionsBordereauxListQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc3af8479d818a9835290b26fa99d045';

module.exports = node;
