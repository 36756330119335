/**
 * @flow
 * @relayHash 03d234328c176b757d7169a1896b5e01
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QApporteurPortefeuilleManagerQueryVariables = {|
  year?: ?number,
  trimestre?: ?number,
|};
export type QApporteurPortefeuilleManagerQueryResponse = {|
  +apporteurContractsReferential: ?$ReadOnlyArray<?{|
    +networkId: ?string,
    +nbParticularContracts: ?number,
    +nbTnsContracts: ?number,
    +nbCollectiveContracts: ?number,
    +year: ?number,
    +trimestre: ?number,
  |}>,
  +apporteurEncaissementsReferential: ?$ReadOnlyArray<?{|
    +networkId: ?string,
    +networkLabel: ?string,
    +productLabel: ?string,
    +category: ?string,
    +amountCotisation: ?string,
    +year: ?number,
    +trimestre: ?number,
  |}>,
|};
export type QApporteurPortefeuilleManagerQuery = {|
  variables: QApporteurPortefeuilleManagerQueryVariables,
  response: QApporteurPortefeuilleManagerQueryResponse,
|};
*/


/*
query QApporteurPortefeuilleManagerQuery(
  $year: Int
  $trimestre: Int
) {
  apporteurContractsReferential(year: $year, trimestre: $trimestre) {
    networkId
    nbParticularContracts
    nbTnsContracts
    nbCollectiveContracts
    year
    trimestre
  }
  apporteurEncaissementsReferential(year: $year, trimestre: $trimestre) {
    networkId
    networkLabel
    productLabel
    category
    amountCotisation
    year
    trimestre
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "trimestre"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "year"
},
v2 = [
  {
    "kind": "Variable",
    "name": "trimestre",
    "variableName": "trimestre"
  },
  {
    "kind": "Variable",
    "name": "year",
    "variableName": "year"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "networkId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "year",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "trimestre",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": (v2/*: any*/),
    "concreteType": "ApporteurContractReferential",
    "kind": "LinkedField",
    "name": "apporteurContractsReferential",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nbParticularContracts",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nbTnsContracts",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nbCollectiveContracts",
        "storageKey": null
      },
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v2/*: any*/),
    "concreteType": "ApporteurEncaissementReferential",
    "kind": "LinkedField",
    "name": "apporteurEncaissementsReferential",
    "plural": true,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "networkLabel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "productLabel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "category",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amountCotisation",
        "storageKey": null
      },
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QApporteurPortefeuilleManagerQuery",
    "selections": (v6/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QApporteurPortefeuilleManagerQuery",
    "selections": (v6/*: any*/)
  },
  "params": {
    "id": "03d234328c176b757d7169a1896b5e01",
    "metadata": {},
    "name": "QApporteurPortefeuilleManagerQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e196f56098e4d1f7410a7297fc7bced9';

module.exports = node;
