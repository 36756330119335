import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ColoredIcon from '../ColoredIcon';

const R = require('ramda');

const Modale = ({ show, onDisable, title, footer, children }) => {
  const containerClasses = classNames(
    ['fixed top-0 right-0 bottom-0 left-0 w-screen h-screen', 'z-30 bg-black bg-opacity-75', 'flex'],
    { visible: show, hidden: !show }
  );

  return (
    <div className={containerClasses}>
      <div className='flex flex-col justify-around'>
        <div className='flex flex-row justify-center w-screen'>
          <div className='bg-white p-6 rounded-lg mx-8 md:max-w-2xl'>
            {/* Title */}
            <div className='flex flex-row justify-between'>
              <div className='text-grey-blue uppercase text-2xl'>{title}</div>
              {R.not(R.isNil(onDisable)) && (
                <button
                  type='button'
                  onClick={onDisable}
                  className='bg-gray-200 rounded-full flex flex-col justify-around'
                  style={{ height: 40, width: 40 }}
                >
                  <div className='flex justify-center w-full'>
                    <ColoredIcon icon='cross.svg' height={12} width={12} className='m-0' />
                  </div>
                </button>
              )}
            </div>

            {/* Body */}
            <div className='text-plum my-4'>{children}</div>

            {/* Footer */}
            {R.not(R.isNil(footer)) && <div className='my-6'>{footer}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

Modale.propTypes = {
  show: PropTypes.bool,
  onDisable: PropTypes.func,
  title: PropTypes.string,
  footer: PropTypes.element
};

Modale.defaultProps = {
  show: false,
  onDisable: () => {}
};

export default Modale;
