import React from 'react';
import propTypes from 'prop-types';

const Message = ({ value, action, onClick }) => (
  <div>
    <div>
      {value}
    </div>
    <button aria-label={action} className="f-button f-button-pink mt-4" onClick={onClick}>{action}</button>
  </div>
);

Message.propTypes = {
  value: propTypes.string,
  action: propTypes.string,
  onClick: propTypes.func
};

export default Message;
