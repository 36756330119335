import React from 'react';
import PropTypes from 'prop-types';

const ContactBlock = ({ title, contacts }) => {
  return (
    <div className="text-plum">
      <div className="font-bold text-lg uppercase">{title}</div>
      <div className="flex flex-col gap-2">

        {contacts.map((contact) => (
          <div key={contact.email + contact.phone}>
            <div>{contact.name}</div>
            <div>{contact.phone}</div>
            <div className="font-semibold">{contact.email}</div>
          </div>
        ))}
      </div>

    </div>
  );
};

ContactBlock.propTypes = {
  title: PropTypes.string.isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string
    })
  )
};

ContactBlock.defaultProps = {
  contacts: []
};

export default ContactBlock;
