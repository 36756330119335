import propTypes from 'prop-types';

export const BILLINGS_PROPTYPES = {
  contract: propTypes.string,
  id: propTypes.string,
  lastPayment: propTypes.string,
  status: propTypes.string,
  total: propTypes.string,
  dates: propTypes.shape({
    endDate: propTypes.string,
    startDate: propTypes.string
  })
};
export const DOCUMENTS_PROPTYPES = {
  documentId: propTypes.string,
  isGlobal: propTypes.string,
  name: propTypes.string,
  status: propTypes.string,
  type: propTypes.string
};

export const GUARANTEES_PROPTYPES = {
  name: propTypes.string
};

export const CONTRACTS_PROPTYPES = {
  afiliates: propTypes.array,
  billings: propTypes.arrayOf(propTypes.shape(BILLINGS_PROPTYPES)),
  contractId: propTypes.string,
  contractIdCrypt: propTypes.string,
  documents: propTypes.arrayOf(propTypes.shape(DOCUMENTS_PROPTYPES)),
  garanties: propTypes.arrayOf(propTypes.shape(GUARANTEES_PROPTYPES)),
  id: propTypes.string,
  status: propTypes.string,
  type: propTypes.string
};
