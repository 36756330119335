/**
 * @flow
 * @relayHash 132996c15c925549078f532be0cb3990
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QMeQueryVariables = {||};
export type QMeQueryResponse = {|
  +me: ?{|
    +lastname: ?string,
    +firstname: ?string,
    +email: ?string,
    +network: ?string,
  |}
|};
export type QMeQuery = {|
  variables: QMeQueryVariables,
  response: QMeQueryResponse,
|};
*/


/*
query QMeQuery {
  me {
    lastname
    firstname
    email
    network
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Me",
    "kind": "LinkedField",
    "name": "me",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "network",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QMeQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QMeQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "132996c15c925549078f532be0cb3990",
    "metadata": {},
    "name": "QMeQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3db3d313ae80093db7e8e72b39fc1e0d';

module.exports = node;
