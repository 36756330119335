import React from 'react';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import colors from '@@refs/colors';
import { ascend, compose, filter, last, map, path, pathOr, propEq, split } from 'ramda';
import { divideNum, round } from 'ramda-adjunct';

import ChartLegend from '../../components/ChartLegend';
import chartStackedLabelPlugin from '../../../../lib/chartStackedLabelPlugin';

const getDatasetsMaxSize = (datasets, stacks) => {
  const currentStack = filter(propEq('stack', stacks), datasets);

  const length = currentStack[0].data.length;

  const sums = new Array(length).fill(0).map((_, i) => {
    return currentStack.reduce((acc, dataset) => (acc + dataset.data[i]), 0);
  });

  return Math.max(...sums);
};

const getOptions = (data) => ({
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      intersect: true,
      mode: 'x',
      callbacks: {
        title: function (tooltipItem) {
          const label = path([0, 'dataset', 'stack'], tooltipItem);
          return label;
        },
        label: function (tooltipItem) {
          const datasetLabel = tooltipItem.dataset.label;
          const getLabel = compose(last, split('|'));
          return `${getLabel(datasetLabel)} : ${tooltipItem.formattedValue}`;
        }
      },
      itemSort: ascend(path(['dataset', 'backgroundColor']))
    },
    datalabels: {
      color: '#CCC',
      display: function (context) {
        const datasets = filter(propEq('stack', context.dataset.stack), data.datasets);

        const current = context.dataset.data[context.dataIndex];
        const highestBar = getDatasetsMaxSize(datasets, context.dataset.stack);

        return current / highestBar > 0.05;
      },
      formatter: function (value) {
        return round(value);
      },
      clip: true,
      clamp: true,
      align: 'end',
      anchor: 'end',
      offset: -15
    },
    chartStackedLabel: {
      tilt: false,
      display: true,
      stack: true,
      tick: true,
      angle: 75,
      color: '#000',
      fontSize: '12px'
    }
  },
  responsive: true,
  interaction: {
    intersect: false
  },
  scales: {
    x: {
      stacked: true,
      afterFit: (scale) => {
        scale.height = 100;
      },
      grid: {
        display: false
      },
      ticks: {
        display: false
      }
    },
    y: {
      id: 'y',
      stacked: false,
      display: false
    },
    y2: {
      id: 'y2',
      stacked: true,
      position: 'right',
      display: false
    }
  }
});

const ChartStackedContracts = ({ data, labels, className }) => {
  const originalData = {
    labels,
    datasets: [
      {
        label: 'contract|Dont transformés',
        backgroundColor: colors.pink,
        data: pathOr([], ['contracts', 'transformed'], data),
        stack: 'Contrats',
        yAxisID: 'y'
      },
      {
        label: 'contract|Potentiels',
        backgroundColor: colors.plum,
        data: pathOr([], ['contracts', 'proposition'], data),
        stack: 'Contrats',
        yAxisID: 'y'
      },
      {
        label: 'CA|Dont transformés',
        backgroundColor: colors.pink,
        data: compose(
          map(
            compose(
              round,
              divideNum(1000)
            )),
          pathOr([], ['ca', 'transformed'])
        )(data),
        stack: 'CA (en K€)',
        yAxisID: 'y2'
      },
      {
        label: 'CA|Potentiels',
        backgroundColor: colors.plum,
        data: compose(
          map(
            compose(
              round,
              divideNum(1000)
            )),
          pathOr([], ['ca', 'proposition'])
        )(data),
        stack: 'CA (en K€)',
        yAxisID: 'y2'
      },
      {
        label: 'Effectifs|Dont transformés',
        backgroundColor: colors.pink,
        data: pathOr([], ['effectifs', 'transformed'], data),
        stack: 'Effectifs',
        yAxisID: 'y2'
      },
      {
        label: 'Effectifs|Potentiels',
        backgroundColor: colors.plum,
        data: pathOr([], ['effectifs', 'proposition'], data),
        stack: 'Effectifs',
        yAxisID: 'y2'
      }
    ]
  };

  return (
    <div className={className}>
      <Bar
        plugins={[
          ChartDataLabels,
          chartStackedLabelPlugin
        ]}
        data={originalData}
        options={getOptions(originalData)}
      />
      <ChartLegend
        datasets={[
          { backgroundColor: colors.plum, label: 'Potentiels' },
          { backgroundColor: colors.pink, label: 'Dont transformés' }
        ]}
      />
    </div>
  );
};

ChartStackedContracts.propTypes = {
  className: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    contracts: PropTypes.shape({
      transformed: PropTypes.arrayOf(PropTypes.number).isRequired,
      proposition: PropTypes.arrayOf(PropTypes.number).isRequired
    }),
    ca: PropTypes.shape({
      transformed: PropTypes.arrayOf(PropTypes.number).isRequired,
      proposition: PropTypes.arrayOf(PropTypes.number).isRequired
    }),
    effectifs: PropTypes.shape({
      transformed: PropTypes.arrayOf(PropTypes.number).isRequired,
      proposition: PropTypes.arrayOf(PropTypes.number).isRequired
    })
  }).isRequired
};

ChartStackedContracts.defaultProps = {
};

export default ChartStackedContracts;
