import { applySpec, compose, defaultTo, descend, identity, map, prop, sort } from 'ramda';

export const getOptions = compose(
  map(
    applySpec({
      label: identity,
      value: identity
    })
  ),
  defaultTo([])
);

export const yearsOptions = data => sort(descend(prop('value')), getOptions(data));
