/**
 * @flow
 * @relayHash 42cb75e00b6eb35da57529a559a3b1f2
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type QApporteurSinistresGraphQueryVariables = {|
  year?: ?number,
  month?: ?number,
|};
export type QApporteurSinistresGraphQueryResponse = {|
  +apporteurSinistresChartDeces: ?$ReadOnlyArray<?{|
    +label: ?string,
    +value: ?number,
    +color: ?string,
    +category: ?string,
  |}>,
  +apporteurSinistresChartInvalidite: ?$ReadOnlyArray<?{|
    +label: ?string,
    +value: ?number,
    +color: ?string,
    +category: ?string,
  |}>,
  +apporteurSinistresChartIncapacite: ?$ReadOnlyArray<?{|
    +label: ?string,
    +value: ?number,
    +color: ?string,
    +category: ?string,
  |}>,
  +apporteurSinistresChartPerRisk: ?$ReadOnlyArray<?{|
    +label: ?string,
    +value: ?number,
    +color: ?string,
    +category: ?string,
  |}>,
|};
export type QApporteurSinistresGraphQuery = {|
  variables: QApporteurSinistresGraphQueryVariables,
  response: QApporteurSinistresGraphQueryResponse,
|};
*/


/*
query QApporteurSinistresGraphQuery(
  $year: Int
  $month: Int
) {
  apporteurSinistresChartDeces(year: $year, month: $month) {
    label
    value
    color
    category
  }
  apporteurSinistresChartInvalidite(year: $year, month: $month) {
    label
    value
    color
    category
  }
  apporteurSinistresChartIncapacite(year: $year, month: $month) {
    label
    value
    color
    category
  }
  apporteurSinistresChartPerRisk(year: $year, month: $month) {
    label
    value
    color
    category
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "month"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "year"
},
v2 = [
  {
    "kind": "Variable",
    "name": "month",
    "variableName": "month"
  },
  {
    "kind": "Variable",
    "name": "year",
    "variableName": "year"
  }
],
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "color",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "category",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": (v2/*: any*/),
    "concreteType": "GraphData",
    "kind": "LinkedField",
    "name": "apporteurSinistresChartDeces",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v2/*: any*/),
    "concreteType": "GraphData",
    "kind": "LinkedField",
    "name": "apporteurSinistresChartInvalidite",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v2/*: any*/),
    "concreteType": "GraphData",
    "kind": "LinkedField",
    "name": "apporteurSinistresChartIncapacite",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": (v2/*: any*/),
    "concreteType": "GraphData",
    "kind": "LinkedField",
    "name": "apporteurSinistresChartPerRisk",
    "plural": true,
    "selections": (v3/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QApporteurSinistresGraphQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QApporteurSinistresGraphQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "id": "42cb75e00b6eb35da57529a559a3b1f2",
    "metadata": {},
    "name": "QApporteurSinistresGraphQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '04a2f8e1d4d59c29c5e7e2b0f79de011';

module.exports = node;
