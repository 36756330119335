import * as R from 'ramda';

export const nameIsIncluded = (array) => R.compose(
  R.includes(R.__, array),
  R.prop('name')
);

export const hasAtLeastOneDocumentOfType = type => R.compose(
  R.gte(R.__, 1),
  R.length,
  R.filter(R.propEq('documentType', type))
);

export const getRequiredDocumentsValidator = R.compose(
  R.map(
    R.compose(
      hasAtLeastOneDocumentOfType,
      R.prop('value')
    )
  ),
  R.filter(R.propEq('required', true))
);

export default getRequiredDocumentsValidator;
