/* eslint-disable react/display-name */
import React from 'react';
import Table from '@@components/table';
import ColoredIcon from '@@components/ColoredIcon';
import PillTypeApporteur from '../../components/PillTypeApporteur';
import SelectColumnFilter from '@@components/table/filters/SelectColumnFilter';
import * as R from 'ramda';
import { text } from 'react-table/src/filterTypes';
import { getContractDocument } from '../../../../lib/documentDownloadHelpers';
import { collegeSort } from '../../../../components/table/sorts/collegeSort';

const selectFilter = (rows, id, filterValue) => {
  filterValue = R.prop('search', filterValue);
  return filterValue === undefined ? rows : text(rows, id, filterValue);
};

const extractDataForSelectOptions = (field) => R.compose(
  R.map(v => ({
    value: R.toLower(v),
    label: v
  })),
  R.sort((a, b) => a.localeCompare(b)),
  R.uniq,
  R.flatten,
  R.map(R.prop(field))
);

const getSortedCollegesOptionsByContracts = (data) => {
  const allColleges = extractDataForSelectOptions('colleges')(data);
  const executivesAndNotExecutives = R.filter(R.compose(
    R.includes('cadre'),
    R.prop('value')
  ));
  const notExecutivesAndNotExecutives = R.filter(R.compose(
    R.not,
    R.includes('cadre'),
    R.prop('value')
  ));
  return [
    ...executivesAndNotExecutives(allColleges),
    ...notExecutivesAndNotExecutives(allColleges)
  ];
};

const getApporteurOptionsByContracts = extractDataForSelectOptions('apporteur');

const getcontractStatusOptionsByContracts = extractDataForSelectOptions('status');

const List = ({
  apporteurContracts, onSelect
}) => {
  const pdf = React.useRef({
    download: false
  });

  const collegesOptions = getSortedCollegesOptionsByContracts(apporteurContracts);
  const statusOptions = getcontractStatusOptionsByContracts(apporteurContracts);
  const partyTypesOptions = [
    { value: 'tns', label: 'TNS' },
    { value: 'company', label: 'Entreprise' },
    { value: 'particular', label: 'Particulier' }
  ];

  const apporteursOptions = getApporteurOptionsByContracts(apporteurContracts);

  const columns = React.useMemo(() => [
    { Header: 'N°', id: 'num', accessor: 'num' },
    { Header: 'Apporteur', id: 'apporteur', accessor: 'apporteur', Filter: SelectColumnFilter(apporteursOptions), filter: selectFilter },
    { Header: 'Souscripteur', id: 'souscripteur', accessor: 'souscripteur' },
    { Header: 'Type', id: 'typeSouscripteur', accessor: 'typeSouscripteur', Filter: SelectColumnFilter(partyTypesOptions), filter: selectFilter, Cell: ({ cell }) => (<PillTypeApporteur type={cell.value} />) },
    { Header: 'Collège', id: 'colleges', accessor: 'colleges', Filter: SelectColumnFilter(collegesOptions), filter: selectFilter, sortType: collegeSort },
    { Header: 'Statut contrat', id: 'status', accessor: 'status', Filter: SelectColumnFilter(statusOptions), filter: selectFilter },
    {
      Header: 'PDF',
      id: 'pdf',
      accessor: 'pdf',
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <div
          className="flex justify-between cursor-pointer"
          onClick={() => {
            pdf.current = R.set(R.lensProp('download'), true, pdf.current);
            const unencryptedId = R.path(['row', 'original', 'unencryptedId'], cell);
            const typeSouscripteur = R.path(['row', 'original', 'typeSouscripteur'], cell);
            getContractDocument(unencryptedId, typeSouscripteur);
          }}>
          <ColoredIcon icon="icon-pdf.svg" color="pink" />
        </div>
      )
    }
  ]);

  return (<Table
    pagination={{ page: 1, pages: apporteurContracts.length, limit: 10 }}
    withPagination={true}
    data={apporteurContracts}
    columns={columns}
    isBlock={false}
    onRowClick={(row) => {
      if (pdf.current.download) {
        pdf.current = R.set(R.lensProp('download'), false, pdf.current);
      } else {
        onSelect({
          id: R.prop('idCrypt', row),
          type: R.prop('typeSouscripteur', row),
          detail: R.prop('detail', row)
        });
      }
    }}
  />);
};

const ContractList = ({ apporteurContracts, onSelect }) => {
  if (R.isEmpty(apporteurContracts)) {
    return (<div>Aucun contrat trouvé.</div>);
  }
  return (<List
    apporteurContracts={apporteurContracts}
    onSelect={onSelect}
  />);
};

export default ContractList;
