import { useEffect, useState } from 'react';
import { fetchQuery } from 'react-relay';
import environment from '../_graphql';
import { prop, inc } from 'ramda';

const useFetchQuery = ({
  defaultData = {},
  query,
  dataProp,
  args = {}
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(defaultData);
  const [key, setKey] = useState(0);
  const reFetch = () => setKey(inc);

  useEffect(() => {
    let hasBeenCancelled = false;
    const fetchData = async () => {
      if (isLoaded) setIsLoaded(false);
      const data = await fetchQuery(environment(), query, args);

      if (!hasBeenCancelled) {
        setData(data);
        setIsLoaded(true);
      }
    };
    fetchData();
    return () => (hasBeenCancelled = true);
  }, [key]);

  return {
    data,
    [dataProp]: prop(dataProp, data),
    reFetch,
    isLoaded
  };
};

export default useFetchQuery;
