import Tokens from './Tokens';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { HttpGone, HttpNotFound, HttpUnauthorized } from './httpError';

export const request = async (url, method = 'GET', body = undefined, refreshToken = false) => {
  const accessToken = await Tokens.getAccessToken(refreshToken);
  const response = await fetch(url, {
    method,
    credentials: 'same-origin',
    headers: {
      Accept: '*/*',
      Authorization: `Bearer ${accessToken}`
    },
    body
  });

  if (response.status === 200) {
    return response;
  }

  if (response.status === 401 && !refreshToken) {
    throw new HttpUnauthorized('Accès non autorisé');
  }

  // Aucun document
  if (response.status === 404) {
    throw new HttpNotFound('Aucune resource disponible');
  }

  if (response.status === 410) {
    throw new HttpGone('Aucune resource disponible');
  }

  return new Error('request failed');
};

export const upload = async (url, body, refreshToken = false) => {
  const response = await request(url, 'POST', body);

  if (response.status === 200) {
    const json = await response.json();
    const { errors } = json;

    if (RA.isNilOrEmpty(errors)) return json;
    throw Error(R.pathOr('request failed', [0, 'message'])(errors));
  }
};

export const fetchDocument = async (url) => {
  const response = await request(url);
  const filename = R.compose(
    R.nth(1),
    R.match(/filename=\"(.*)\"/)
  )(response.headers.get('Content-Disposition'));
  return {
    filename,
    blob: await response.blob()
  };
};

export const fetchDocuments = async (url) => {
  const response = await request(url);
  const data = await response.json();
  return R.prop('documents', data);
};
