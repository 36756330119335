import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import { Block } from '@@components';
import Forms from '@@components/forms';
import PostalFiles from '../../../components/PostalFiles';

const hasAtLeastOneDocumentOfType = type => R.compose(
  R.gte(R.__, 1),
  R.length,
  R.filter(R.propEq('documentType', type))
);

const DocumentUploadBlock = ({
  name,
  register,
  controls,
  listAttachments,
  showErrorMessage,
  form,
  formData,
  postalFiles
}) => {
  const haveDocument = type => hasAtLeastOneDocumentOfType(type)(R.propOr([], 'attachments', form.getValues()));

  return (
    <Block
      name={name}
      color="pink"
      icon="icon-documents.svg"
      title={'Documents'}
    >
      <div style={{ margin: '0 1rem' }}>
          Pour déclarer votre sinistre, nous vous remercions de nous transmettre les documents obligatoires suivants :
        <ul>
          {listAttachments
            .filter(d => d.required)
            .map(documentType => (
              <li className={haveDocument(documentType.value) ? 'line-through' : ''}
                key={documentType.value}
              >
                  - {documentType.label}
              </li>
            ))}
        </ul>
      </div>
      {showErrorMessage && <div className="text-red-600">Tous les documents n'ont pas été ajoutés</div>}

      <div>
        <PostalFiles files={postalFiles} />
      </div>
      <Forms
        columns={1}
        formsData={formData}
        options={{
          listAttachments
        }}
        wrapInForm={false}
        forwardedUseForm={form}
        submitSection={() => {}}
      />
    </Block>
  );
};

DocumentUploadBlock.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  controls: PropTypes.any.isRequired,
  showErrorMessage: PropTypes.bool,
  form: PropTypes.shape({
    handleSubmit: PropTypes.func,
    register: PropTypes.func,
    control: PropTypes.any,
    reset: PropTypes.func,
    getValues: PropTypes.func,
    watch: PropTypes.func,
    errors: PropTypes.any,
    setValue: PropTypes.func
  }).isRequired,
  formData: PropTypes.arrayOf(PropTypes.object),
  listAttachments: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      required: PropTypes.bool
    })
  )
};

DocumentUploadBlock.defaultProps = {
  listAttachments: [],
  showErrorMessage: false,
  postalFiles: []
};

export default DocumentUploadBlock;
