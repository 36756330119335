/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [

{
name: 'phone',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Telephone",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'email',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Email",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'line',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Adresse",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'zipCode',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Code postal",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

{
name: 'city',
required: true,
input: 'text',
source: '__UNDEFINED__',
label: "Ville",
placeholder: "__UNDEFINED__",



handlers: {
visible: undefined
}
},

];

export default formData;