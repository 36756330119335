/**
 * @flow
 * @relayHash 00af6be03edbb7164e2382d13c1296b8
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ContractTypeEnum = "COMPANY" | "PARTICULAR" | "TNS" | "%future added value";
export type QChartApporteurQueryVariables = {|
  year: number,
  type?: ?ContractTypeEnum,
|};
export type QChartApporteurQueryResponse = {|
  +apporteurContractsStats: ?{|
    +currentYear: ?{|
      +contractsPotentiels: ?number,
      +contractsActifs: ?number,
    |},
    +previousYear: ?{|
      +contractsPotentiels: ?number,
      +contractsActifs: ?number,
    |},
  |}
|};
export type QChartApporteurQuery = {|
  variables: QChartApporteurQueryVariables,
  response: QChartApporteurQueryResponse,
|};
*/


/*
query QChartApporteurQuery(
  $year: Int!
  $type: ContractTypeEnum
) {
  apporteurContractsStats(year: $year, type: $type) {
    currentYear {
      contractsPotentiels
      contractsActifs
    }
    previousYear {
      contractsPotentiels
      contractsActifs
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "year"
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "contractsPotentiels",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "contractsActifs",
    "storageKey": null
  }
],
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      },
      {
        "kind": "Variable",
        "name": "year",
        "variableName": "year"
      }
    ],
    "concreteType": "ApporteurContractsStats",
    "kind": "LinkedField",
    "name": "apporteurContractsStats",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ApporteurContractsStatsYear",
        "kind": "LinkedField",
        "name": "currentYear",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ApporteurContractsStatsYear",
        "kind": "LinkedField",
        "name": "previousYear",
        "plural": false,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "QChartApporteurQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "QChartApporteurQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "id": "00af6be03edbb7164e2382d13c1296b8",
    "metadata": {},
    "name": "QChartApporteurQuery",
    "operationKind": "query",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '72cab5660bf00727f142ad2025497af6';

module.exports = node;
