import React from 'react';
import { graphql } from 'react-relay';

import Query from '../Query';
import { populateChildren } from './toolbox';

const QOavContractsListByApporteurQuery = graphql`
  query QOavContractsListByApporteurQuery($apporteur: String, $page: Int, $nbItemPerPage: Int) {
    oavContractsListByApporteur(apporteur: $apporteur, page: $page, nbItemPerPage: $nbItemPerPage) {
      page
      count
      nbItemPerPage
      nbPages
      data {
        contractNumber
        name
        num
        cotisation
        soldeContract
        date
        apporteur
        taux
        souscripteur
        type
        college
        status {
          percent
          status
        }
      }
    }
  }
`;

const QOavContractsListByApporteur = ({
  args,
  children,
  childDataProp,
  mockData
}) => {
  return (
    <Query
      query={QOavContractsListByApporteurQuery}
      args={args}
      mockKey="QOavContractsListByApporteur"
      mockData={mockData}
    >
      {populateChildren(['oavContractsListByApporteur'])(children, childDataProp)}
    </Query>
  );
};

export default QOavContractsListByApporteur;
