import React from 'react';
import { Page } from '@@components';
import { useHistory, useParams } from 'react-router-dom';

import {
  AffilieInformationBlock
} from '../containers/AffilieDetail';
import useCompany from '../../../hooks/useCompany';
import DecompteDetail from './DecompteDetail';

const AffilieDetail = () => {
  const history = useHistory();
  const { id, contractIdCrypt } = useParams();
  const { getAffiliate } = useCompany();
  const affiliate = getAffiliate(contractIdCrypt, id);

  return (
    <Page title={
      <div className="flex">
        <img
          className="pr-3 cursor-pointer mt-2 h-6 w-6"
          src="/icons/back.svg"
          alt="Icone flèche de retour"
          onClick={() => history.goBack()}
        />
        Fiche affilié
      </div>
    }>
      <AffilieInformationBlock affiliate={affiliate} />
      <DecompteDetail affiliate={affiliate}/>
    </Page>
  );
};

export default AffilieDetail;
