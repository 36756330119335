import React from 'react';
import propTypes from 'prop-types';

const Hidden = ({ register, field }) => {
  return (<input type="hidden" ref={register({ required: field.required })} disabled={field.disabled} name={field.name} />);
};

Hidden.propTypes = {
  field: propTypes.object,
  register: propTypes.func
};

export default Hidden;
