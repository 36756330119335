import { Page, Block } from '@@components';
import React, { useState } from 'react';

import { DeclareSinistreBlock, PrestationListBlock } from '../containers/Prestations';
import useFetchQuery from '../../../hooks/useFetchQuery';
import { QUserTiersDetailsQuery } from '../../../_graphql/queries/QUserTiersDetails';
import PreventLoading from '../../../components/PreventLoading';
import * as R from 'ramda';
import useCompany from '../../../hooks/useCompany';
// import { SelectOneOption } from '@@components/input';
// import { isTns } from '../../../lib/getCompanyType';
import withUser from '../../userSession/withUser';
import { isNilOrEmpty } from 'ramda-adjunct';
import { hasOneElement } from '../../../lib/hasOneElement';
import { optionsForSelectList } from '../../../lib/optionsForSelectList';

const getDefaultValue = R.ifElse(
  hasOneElement,
  R.compose(
    R.prop('contractId'),
    R.head
  ),
  R.always(null)
);

export const MesPrestations = ({ user }) => {
  const { getContracts } = useCompany();
  const contracts = getContracts();
  const { tiersDetails, isLoaded } = useFetchQuery({
    query: QUserTiersDetailsQuery,
    dataProp: 'tiersDetails'
  });

  const [selectedContract, setSelectedContract] = useState(hasOneElement(contracts) ? getDefaultValue(contracts) : false);

  const options = optionsForSelectList(contracts);

  const onSelectContract = ({ value }) => setSelectedContract(value);

  const getSelectedContract = (selectedContract) => R.compose(
    R.prop(0),
    R.filter(R.propEq('value', selectedContract))
  );

  return (
    <Page title={'Mes prestations'}>
      {isNilOrEmpty(contracts) ? <p>Vous n'avez aucun contrat</p> :
        <>
          {/* TODO put it back when mes prestations is ready 
            <PreventLoading isLoaded={isLoaded}>
            {isTns(user) &&
            <Block
              name="prestation-list-select"
              color="pink"
              title="Sélectionner un contrat"
              icon="menu-sinistres.svg"
            >
              <SelectOneOption
                options={options}
                value={hasOneElement(contracts) ? R.head(options) : getSelectedContract(selectedContract)(options)}
                onChange={onSelectContract}
                placeholder="Sélectionner un contrat"
              />
            </Block>
            }
            {selectedContract &&
                <PrestationListBlock contractId={selectedContract} />
            }
          </PreventLoading> */}
          <PreventLoading isLoaded={isLoaded}>
            <DeclareSinistreBlock address={R.prop('address', tiersDetails)} />
          </PreventLoading>
        </>
      }
    </Page>
  );
};

export default withUser(MesPrestations);
