const R = require('ramda');

export const optionsForSelectList = (contracts) => {
  return contracts.map(
    contract => (
      {
        label: `${R.pathOr('', ['info', 'nomProduit'], contract)} ${contract.id} ${R.pathOr('', ['info', 'college'], contract)}`,
        value: R.prop('contractId', contract)
      }
    )
  );
};
