/**
*
* WARNING : fichier généré par forms, ne pas le modifier directement
*
**/



const formData = [

{
name: 'attachment',
required: false,
input: 'file',
source: '__UNDEFINED__',
label: "__UNDEFINED__",
placeholder: "Joindre le fichier excel avec la liste des tiers",



handlers: {
visible: undefined
}
},

];

export default formData;