import React from 'react';
import { graphql } from 'react-relay';

import Query from '../Query';
import { populateChildren } from './toolbox';

const QApporteurCommissionsManagerQuery = graphql`
  query QApporteurCommissionsManagerQuery($year: Int, $commissionType: String) {
    apporteurCommissionsReferential(year: $year, commissionType: $commissionType) {
      category
      amountCommission
      year
      trimestre
    }
  }
`;

const QApporteurCommissionsManager = ({ args, children, childDataProp, mockData }) => {
  return (
    <Query
      query={QApporteurCommissionsManagerQuery}
      args={args || {}}
      mockKey='QApporteurPortefeuilleManager'
      mockData={mockData}
    >
      {populateChildren(['apporteurCommissionsReferential'])(children, childDataProp)}
    </Query>
  );
};

export default QApporteurCommissionsManager;
