/**
 * @flow
 * @relayHash 224e271d034ff86d2f2ea55934dcdde6
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type onDeclarationMiseInvaliditeMutationVariables = {||};
export type onDeclarationMiseInvaliditeMutationResponse = {|
  +onDeclarationMiseInvalidite: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type onDeclarationMiseInvaliditeMutation = {|
  variables: onDeclarationMiseInvaliditeMutationVariables,
  response: onDeclarationMiseInvaliditeMutationResponse,
|};
*/


/*
mutation onDeclarationMiseInvaliditeMutation {
  onDeclarationMiseInvalidite {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "EmailMutationResponse",
    "kind": "LinkedField",
    "name": "onDeclarationMiseInvalidite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "onDeclarationMiseInvaliditeMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "onDeclarationMiseInvaliditeMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "id": "224e271d034ff86d2f2ea55934dcdde6",
    "metadata": {},
    "name": "onDeclarationMiseInvaliditeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bc273aaab042bc0a8ac6d858286a1dc4';

module.exports = node;
