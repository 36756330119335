/**
 * @flow
 * @relayHash 3bd7ac9f350d3661d8879814a40c2284
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TitleEnum = "Madame" | "Monsieur" | "%future added value";
export type WorkStoppingEnum = "ACCIDENT" | "SICKNESS" | "%future added value";
export type DeclareSinistreInvaliditeInput = {|
  accidentTiers?: ?boolean,
  address?: ?string,
  addressTiers?: ?string,
  assurAddress?: ?string,
  assurCity?: ?string,
  assurPostal?: ?string,
  assurSociety?: ?string,
  birthdate?: ?string,
  birthname?: ?string,
  birthnameTiers?: ?string,
  city?: ?string,
  cityTiers?: ?string,
  contractId?: ?string,
  dateAccident?: ?string,
  dateWorkStopping?: ?string,
  detailAccident?: ?string,
  firstname?: ?string,
  firstnameTiers?: ?string,
  folderTiers?: ?string,
  genderTiers?: ?TitleEnum,
  hasPoliceInvestigation?: ?boolean,
  invalidityDate?: ?string,
  invalidityRegime?: ?$ReadOnlyArray<?AttachmentInput>,
  nir?: ?string,
  paymentStatement?: ?$ReadOnlyArray<?AttachmentInput>,
  postal?: ?string,
  postalTiers?: ?string,
  procesVerbal?: ?string,
  reasonAccident?: ?string,
  reasonAccidentOther?: ?string,
  rib?: ?string,
  taxNotice?: ?$ReadOnlyArray<?AttachmentInput>,
  title?: ?TitleEnum,
  workStoppingReason?: ?WorkStoppingEnum,
|};
export type AttachmentInput = {|
  documentType?: ?string,
  fileName: string,
  uploadId: string,
|};
export type DeclareSinistreInvaliditeMutationVariables = {|
  input: DeclareSinistreInvaliditeInput
|};
export type DeclareSinistreInvaliditeMutationResponse = {|
  +declareSinistreInvalidite: ?{|
    +ok: boolean,
    +error: ?string,
  |}
|};
export type DeclareSinistreInvaliditeMutation = {|
  variables: DeclareSinistreInvaliditeMutationVariables,
  response: DeclareSinistreInvaliditeMutationResponse,
|};
*/


/*
mutation DeclareSinistreInvaliditeMutation(
  $input: DeclareSinistreInvaliditeInput!
) {
  declareSinistreInvalidite(input: $input) {
    ok
    error
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeclareSinistreInvaliditeMutationResponse",
    "kind": "LinkedField",
    "name": "declareSinistreInvalidite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ok",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeclareSinistreInvaliditeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeclareSinistreInvaliditeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "3bd7ac9f350d3661d8879814a40c2284",
    "metadata": {},
    "name": "DeclareSinistreInvaliditeMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '6d6cbc1fbb0665665cc17e40af7e22e4';

module.exports = node;
